import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./ShiftDetailScreen.css";
import { Box, ButtonGroup, Grid, Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import {
  CommonMapTypes,
  ShiftDetailsTypes,
  ShiftListTypes,
} from "../wizrads.types";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import TextInput from "../../../components/formlib/TextInput";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
  fetchAllShiftsDataApi,
  publishShiftMaster,
} from "../../../api/wizardsApi/Common";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { ReactComponent as NotAvialableIcon } from "../../../assets/images/woNotFound.svg";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import TimePicker from "../../../components/formlib/TimePicker";
import RadioComponent from "../../../components/formlib/RadioComponent";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import InfoIcon from "@mui/icons-material/Info";
import { useIntl } from "react-intl";

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};

interface Shiftsinterface {
  isMenuOpen: boolean;
  selectedSubGroupId: number;
}

const initialShiftData: ShiftDetailsTypes = {
  shiftId: 0,
  name: "",
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  availableMembers: 0,
  isGeneralShift: false,
  selectedShiftMasterId: 0,
  subgroupUserCount: 0,
  subgroupId: 0,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  startDate: Yup.date().required("validation.fieldRequired"),
  endDate: Yup.date().required("validation.fieldRequired"),
  startTime: Yup.date()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string" && originalValue.trim() !== "") {
        const parsedDate = dayjs(originalValue, "HH:mm:ss", true);
        return parsedDate.isValid() ? parsedDate.toDate() : null;
      }
      return null;
    })
    .required("validation.fieldRequired")
    .typeError("Enter a valid time in HH:mm am/pm format"),
  endTime: Yup.date()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string" && originalValue.trim() !== "") {
        const parsedDate = dayjs(originalValue, "HH:mm:ss", true);
        return parsedDate.isValid() ? parsedDate.toDate() : null;
      }
      return null;
    })
    .required("validation.fieldRequired")
    .typeError("Enter a valid time in HH:mm am/pm format"),
});

const ShiftDetailScreen: FC<Shiftsinterface> = ({
  isMenuOpen,
  selectedSubGroupId,
}) => {
  const availableWidth = useAvailableWidth(540);
  const availableWidth1 = useAvailableWidth(200);
  const availableHeight = useAvailableHeight(420);
  const availableHeight1 = useAvailableHeight(650);
  const availableHeight2 = useAvailableHeight(300);
  const [expandedRow, setExpandedRow] = useState<number>(0);
  const formikRef = useRef<FormikProps<ShiftDetailsTypes>>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [shiftLists, setShiftLists] = useState<ShiftDetailsTypes[]>([]);
  const [shiftMaster, setShiftMaster] = useState<CommonMapTypes[]>([]);
  const [shiftValues, setShiftValues] =
    useState<ShiftDetailsTypes>(initialShiftData); // Initialize with default values>
  const [statusBtn, setStatusBtn] = useState<boolean>(true);
  const [shiftDetailResp, setShiftDetailResp] = useState<ShiftListTypes>(
    {} as ShiftListTypes
  );
  const [shiftStatusType, setShiftStatusType] = useState<string>("active");
  const [apiResponseModal, setApiResponseModal] = useState(false);
  const intl = useIntl();

  const handleRowClick = (
    id: number,
    actualId: number | undefined,
    shiftValuesRow: ShiftDetailsTypes
  ) => {
    if (expandedRow !== id) {
      setExpandedRow(id); // Only open the clicked row
    }
    // else {
    //   setExpandedRow(0);
    // }
    if (actualId) {
      setShiftValues(shiftValuesRow);
    }
  };

  useEffect(() => {
    getAllShhifts();
  }, []);

  const getAllShhifts = (status: string = "active") => {
    const fetchAllShiftsUrl = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/shift`;

    fetchAllShiftsDataApi<ShiftListTypes>(fetchAllShiftsUrl, "en", "", status)
      .then((response) => {
        setShiftDetailResp(response as ShiftListTypes);
        setShiftMaster(response.shiftMasters);
        const updatedResponse = response.shifts.map((shift) => ({
          ...initialShiftData,
          ...shift,
          shiftId: shift.id ?? 0,
        }));
        setShiftLists(updatedResponse);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const defaultShift: ShiftDetailsTypes = {
    shiftId: shiftLists.length + 1, // Assign serial number
    name: "",
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
    availableMembers: 0,
    selectedShiftMasterId: 0,
    isGeneralShift: false,
    subgroupUserCount: 0,
    subgroupId: selectedSubGroupId,
  };

  const handleAddNew = () => {
    const newShift = { ...defaultShift, shiftId: shiftLists.length + 1 };
    setShiftLists((prev) => [...prev, newShift]);
    setShiftValues(newShift);
    setExpandedRow(newShift.id as number); // Expand the newly added row
  };

  const saveShiftPlan = (data: ShiftDetailsTypes) => {
    const saveShiftURL = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/shift/save`;
    const { shiftId, ...params } = data;
    const finalParams = { ...params, subgroupId: selectedSubGroupId };

    customSaveDataApi(saveShiftURL, finalParams)
      .then(() => {
        getAllShhifts();
        setExpandedRow(0);
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const updateShiftPlan = (
    data: ShiftDetailsTypes,
    removeShift: boolean = false
  ) => {
    const updateShiftURL = `${WORK_FLOW_BASE_URL}shift/${data.id}/update`;
    const { shiftId, ...params } = data;
    const finalParams = { ...params, subgroupId: selectedSubGroupId };
    if (removeShift) {
      const formattedDate = dayjs(new Date()).format("YYYY-MM-DD");
      finalParams.endDate = formattedDate;
    }

    customUpdateDataApi(updateShiftURL, finalParams)
      .then(() => {
        getAllShhifts();
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const handlePublish = () => {
    toggleLoader(true);
    publishShiftMaster(selectedSubGroupId)
      .then(() => {
        getAllShhifts();
        setApiResponseModal(true);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const handleRemoveShift = (shiftId: number) => {
    const updatedShiftLists = shiftLists.filter(
      (shift) => shift.shiftId !== shiftId
    );
    setShiftLists(updatedShiftLists);
  };

  return (
    <Box>
      {apiResponseModal && (
        <ModalPopup
          descriptionText={"Publish.successSave"}
          open={apiResponseModal}
          handleClose={() => setApiResponseModal(false)}
          onPositiveClick={() => {
            setApiResponseModal(false);
          }}
          hideButton={true}
          positiveButtonLabel="Ok"
          positiveButtonLabelId="WarningText.ok"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography
          labelId="ShiftMaster.title"
          defaultLabel="Shift Master"
          fontSize="20px"
          fontWeight={600}
        />
        <Box className="button_class_box">
          <ButtonGroup
            variant="contained"
            aria-label="Basic button group"
            className="mr-md"
            sx={{ textTransform: "none", width: "160px", height: "40px" }}
          >
            <ButtonComponent
              className={`button_group_active yearMonthDay_btn ${
                statusBtn ? "workOrderCategory_Toggle_btn_active" : ""
              }`}
              labelId="Shifts.activeText"
              defaultLabelId="Active"
              onClick={() => {
                setStatusBtn(true);
                getAllShhifts("active");
                setShiftStatusType("active");
              }}
            />
            <ButtonComponent
              className={`button_group_active yearMonthDay_btn ${
                !statusBtn ? "workOrderCategory_Toggle_btn_active" : ""
              }`}
              labelId="Shifts.inactiveText"
              defaultLabelId="Inactive"
              onClick={() => {
                setStatusBtn(false);
                getAllShhifts("inactive");
                setShiftStatusType("inactive");
              }}
            />
          </ButtonGroup>
          {shiftStatusType === "active" && (
            <>
              <ButtonComponent
                className={`btn-primary mr-sm ${
                  shiftDetailResp.shiftCreateStatus === "DRAFT"
                    ? "btn-submit"
                    : "btn-disabled"
                }`}
                labelId="btn.publish"
                defaultLabelId="Publish"
                onClick={() => handlePublish()}
                disabled={shiftDetailResp.shiftCreateStatus !== "DRAFT"}
              />

              <ButtonComponent
                className="btn-primary btn-submit"
                variantType="contained"
                defaultLabelId={"Add New"}
                labelId={"btn.AddNew"}
                onClick={handleAddNew} // Handle Add New Button
              />
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          width: isMenuOpen ? availableWidth : availableWidth1,
          height: availableHeight,
        }}
      >
        <Box
          className="flex__ inner_box_class"
          sx={{
            minHeight: availableHeight2,
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
            "-webkit-overflow-scrolling": "touch",
            "scroll-behavior": "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {shiftLists.length > 0 &&
            shiftLists.map((shift, index) => (
              <Box
                key={shift.id}
                className="shift_inner_class cursor__pointer"
                onClick={() =>
                  handleRowClick(shift.shiftId ?? 0, shift.id, shift)
                }
              >
                <Box
                  className="flex__justify__space-between p-md"
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <MediumTypography
                      label="Sr. No."
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="indexBox_css">{`0${index + 1}`}</Box>
                  </Box>

                  <Box sx={{ flexBasis: shift?.name ? "10%" : "0%" }}>
                    <MediumTypography
                      label="Shift Name"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="nameBox_css">
                      {shift.name ? shift.name : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      label="Start Time"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {shift.startTime ? shift.startTime.toString() : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      label="End Time"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {shift.endTime ? shift.endTime.toString() : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      label="Start Date"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {shift.startDate ? shift.startDate?.toString() : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      label="End Date"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {shift.endDate ? shift.endDate?.toString() : "-"}
                    </Box>
                  </Box>
                </Box>
                {expandedRow === shift.shiftId && (
                  <Box className="formClass_css">
                    <Formik
                      initialValues={shiftValues}
                      enableReinitialize
                      innerRef={formikRef}
                      validateOnChange
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        if (values.id) {
                          updateShiftPlan(values);
                        } else {
                          saveShiftPlan(values);
                        }
                      }}
                    >
                      {({
                        values,
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                        dirty,
                      }) => {
                        return (
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                overflowY: "auto",
                                height: availableHeight1,
                              }}
                            >
                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  className="input-label"
                                  labelId="ShiftText"
                                  defaultLabel="Shift"
                                />
                                <CustomDropdown
                                  disableCreate={true}
                                  sxStyles={{
                                    textField: {
                                      padding: "0px 4px 0px 0px",
                                    },
                                  }}
                                  options={getOptions(shiftMaster)}
                                  selectedValue={values.selectedShiftMasterId}
                                  onSelectedValueChange={(value) => {
                                    const statusId = value as string;
                                    setFieldValue(
                                      "selectedShiftMasterId",
                                      statusId
                                    );
                                  }}
                                  disabled={shiftStatusType === "inactive"}
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  className="input-label"
                                  labelId="Shifts.shiftName"
                                  defaultLabel="Shift Name"
                                />
                                <TextInput
                                  className="text-input-field"
                                  type="text"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: false,
                                  }}
                                  labelId="common.placeHolderText"
                                  defaultLabelId="--- type here ---"
                                  Value={values.name}
                                  handlechange={(value: string) => {
                                    setFieldValue("name", value);
                                  }}
                                  disabled={shiftStatusType === "inactive"}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="name"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.startTime"
                                  defaultLabel="Start Time"
                                  textColor={"rgba(159, 173, 188, 1)"}
                                  className="mb-sm"
                                />

                                <TimePicker
                                  value={
                                    values.startTime ?? undefined
                                      ? dayjs(`2024-11-25T${values.startTime}`)
                                      : null
                                  }
                                  labelid="common.placeHolderTextTime"
                                  defaultlabelid="---HH:mm---"
                                  // handlechange={(date: Dayjs | null) => {
                                  //   const formattedDate =
                                  //     dayjs(date).format("HH:mm:ss");
                                  //   setFieldValue("startTime", formattedDate);
                                  // }}
                                  handlechange={(date: Dayjs | null) => {
                                    const formattedDate = dayjs(date)
                                      .set("second", 0)
                                      .format("HH:mm:ss");
                                    setFieldValue("startTime", formattedDate);
                                  }}
                                  textWidth="100%"
                                  width="100%"
                                  disabledTime={shiftStatusType === "inactive"}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="startTime"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.endTime"
                                  defaultLabel="End Time"
                                  textColor={"rgba(159, 173, 188, 1)"}
                                  className="mb-sm"
                                />

                                <TimePicker
                                  value={
                                    values.endTime ?? undefined
                                      ? dayjs(`2024-11-25T${values.endTime}`)
                                      : null
                                  }
                                  labelid="common.placeHolderTextTime"
                                  defaultlabelid="---HH:mm---"
                                  // handlechange={(date: Dayjs | null) => {
                                  //   const formattedDate =
                                  //     dayjs(date).format("HH:mm:ss");
                                  //   setFieldValue("endTime", formattedDate);
                                  // }}
                                  handlechange={(date: Dayjs | null) => {
                                    const formattedDate = dayjs(date)
                                      .set("second", 0)
                                      .format("HH:mm:ss");
                                    setFieldValue("endTime", formattedDate);
                                  }}
                                  textWidth="100%"
                                  width="100%"
                                  disabledTime={shiftStatusType === "inactive"}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="endTime"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.startDate"
                                  defaultLabel="Start Date"
                                  textColor={"rgba(159, 173, 188, 1)"}
                                  className="mb-sm"
                                />

                                <DateTimePickerComponent
                                  value={
                                    values.startDate !== null
                                      ? dayjs(values.startDate)
                                      : null
                                  }
                                  labelid="commonDateText"
                                  defaultlabelid="DD MMM YYYY"
                                  handlechange={(date: Dayjs) => {
                                    const formattedDate =
                                      dayjs(date).format("YYYY-MM-DD");
                                    setFieldValue("startDate", formattedDate);
                                  }}
                                  disabledDate={
                                    shiftStatusType === "inactive" ||
                                    values.woMasterAssociated
                                  }
                                  inputFormat="DD MMM YYYY"
                                  textWidth="100%"
                                  width="100%"
                                  views={["year", "month", "day"]}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="startDate"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.endDate"
                                  defaultLabel="End Date"
                                  textColor={"rgba(159, 173, 188, 1)"}
                                  className="mb-sm"
                                />

                                <DateTimePickerComponent
                                  value={
                                    values.endDate !== null
                                      ? dayjs(values.endDate)
                                      : null
                                  }
                                  labelid="commonDateText"
                                  defaultlabelid="DD MMM YYYY"
                                  handlechange={(date: Dayjs) => {
                                    const formattedDate =
                                      dayjs(date).format("YYYY-MM-DD");
                                    setFieldValue("endDate", formattedDate);
                                  }}
                                  disabledDate={shiftStatusType === "inactive"}
                                  inputFormat="DD MMM YYYY"
                                  textWidth="100%"
                                  width="100%"
                                  minDateTime={
                                    shiftDetailResp.shiftPlannedTillDate !==
                                    null
                                      ? dayjs(
                                          shiftDetailResp.shiftPlannedTillDate
                                        )
                                      : dayjs(new Date())
                                  }
                                  views={["year", "month", "day"]}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="endDate"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <Box className="flex__">
                                  <MediumTypography
                                    className="input-label mr-sm"
                                    labelId="Shifts.availableTeamMembers"
                                    defaultLabel="Available Team Members"
                                  />
                                  <Tooltip
                                    title={intl.formatMessage({
                                      id: "shift.info",
                                      defaultMessage: "Available Team Members",
                                    })}
                                    followCursor
                                    arrow
                                  >
                                    <InfoIcon
                                      style={{
                                        color: "#98acc4",
                                        width: "16px",
                                        height: "16px",
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                                <TextInput
                                  className="text-input-field"
                                  type="text"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: false,
                                    type: "number",
                                  }}
                                  labelId="common.placeHolderText"
                                  defaultLabelId="--- type here ---"
                                  Value={
                                    values.availableMembers !== null &&
                                    values.availableMembers !== 0
                                      ? String(values.availableMembers)
                                      : ""
                                  }
                                  handlechange={(value: string) => {
                                    const formattedValue = value
                                      .replace(/,/g, "")
                                      .replace(/[^0-9.]/g, "");
                                    const validValue =
                                      parseFloat(formattedValue);
                                    setFieldValue(
                                      "availableMembers",
                                      isNaN(validValue) ? 0 : validValue
                                    );
                                  }}
                                  disabled={shiftStatusType === "inactive"}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="availableTeamMembers"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.GeneralShift"
                                  defaultLabel="General Shift"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <Box>
                                  <RadioComponent
                                    name="multipleBirth"
                                    value={values.isGeneralShift}
                                    handleChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setFieldValue(
                                        "isGeneralShift",
                                        e.target.value
                                      );
                                    }}
                                    disable={shiftStatusType === "inactive"}
                                    labelid=""
                                    defaultlabelid=""
                                    labelidA="YesText"
                                    defaultlabelidA="Yes"
                                    labelidB="NoText"
                                    defaultlabelidB="No"
                                  />
                                </Box>
                              </Grid>
                            </Grid>

                            {!shift.id && (
                              <ButtonComponent
                                className="btn-delete-code"
                                variantType="contained"
                                type="submit"
                                labelId="RemoveShifts.text"
                                defaultLabelId="+ Remove Shift"
                                onClick={() => {
                                  handleRemoveShift(shift.shiftId);
                                }}
                              />
                            )}

                            <Box
                              mt={"10px"}
                              className="flex__ justifyContent-FlexEnd"
                            >
                              <ButtonComponent
                                sxProps={{ color: "#B6C2CF !important" }}
                                className="btn-primary btn-cancel mr-sm"
                                variantType="outlined"
                                defaultLabelId={"Close"}
                                labelId={"CloseText"}
                                onClick={() => setExpandedRow(0)}
                              />
                              {shiftStatusType === "active" && (
                                <ButtonComponent
                                  className="btn-primary btn-submit"
                                  variantType="contained"
                                  defaultLabelId={"Save"}
                                  labelId={shift.id ? "btn.update" : "btn.save"}
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        );
                      }}
                    </Formik>
                  </Box>
                )}
              </Box>
            ))}
          {shiftLists.length <= 0 && (
            <Box
              className="position__relative"
              sx={{
                display: "flex", // Flex container
                flexDirection: "column", // Stack items vertically
                justifyContent: "center", // Center items vertically
                alignItems: "center", // Center items horizontally
                textAlign: "center", // Optional: Center the text
                top: "40%",
              }}
            >
              <NotAvialableIcon width={`50px`} height={`50px`} />
              <MediumTypography
                className="noWOTitle"
                labelId="Shift.noShiftsText"
                defaultLabel="No Shifts Available"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ShiftDetailScreen;
