import { Box, Button, Divider, Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import TextInput from "../../../components/formlib/TextInput";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  fetchWizardsDataApi,
} from "../../../api/wizardsApi/Common";
import { CommonMapTypes, LoadDistributionResponeTypes } from "../wizrads.types";
import { handleError } from "../../../utils/commonFunctions";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import VerticalSlider from "../../../components/formlib/VerticalSlider";

interface LoadDistributioninterface {
  isMenuOpen: boolean;
  selectedSubGroupId: number;
}

const getSliderLabelPrefix = (days: number): string => {
  if (days <= 7) {
    return "Day";
  } else if (days > 7 && days <= 15) {
    return "Day";
  } else if (days > 15 && days <= 30) {
    return "Week";
  } else if (days > 30 && days <= 90) {
    return "Month";
  } else if (days > 90 && days <= 180) {
    return "Month";
  } else if (days > 180) {
    return "Month";
  }
  return "Day"; // Fallback
};

const LoadDistributionScreen: FC<LoadDistributioninterface> = ({
  isMenuOpen,
  selectedSubGroupId,
}) => {
  const availableWidth = useAvailableWidth(540);
  const availableWidth1 = useAvailableWidth(220);
  const availableHeight = useAvailableHeight(400);
  const [sliders, setSliders] = useState<number[]>(Array(7).fill(0)); // Default to Weekly (7 days)
  const [gracePeriod, setGracePeriod] = useState<number>(0);
  const [selectedDays, setSelectedDays] = useState(7); // Default to 7 days (Weekly)
  const [totalPercentage, setTotalPercentage] = useState(0); // To keep track of the total percentage
  const [isSaveDisabled, setIsSaveDisabled] = useState(true); // Save button disabled by default
  const [loadDistributionId, setLoadDistributionId] = useState<
    number | undefined
  >();
  const [frequencyArray, selectedFrequencyArray] = useState<CommonMapTypes[]>(
    []
  );
  const [selectedFreqId, setSelectedFreqId] = useState<number>(0);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [loadDistributionList, setLoadDistributionList] = useState<
    CommonMapTypes[]
  >([]);

  const handleButtonClick = (index: number, freqId: number) => {
    const selectedFrequency = frequencyArray[index]; // Get the selected frequency object
    setSelectedDays(Number(selectedFrequency.level)); // Update selectedDays based on frequency
    setSelectedFreqId(selectedFrequency.id);
    fetchEachFrequencyData(freqId);
  };

  const handleSliderChange = (value: number, index: number) => {
    const updatedSliders = [...sliders];
    updatedSliders[index] = value;
    setSliders(updatedSliders);
    const updatedLoadDistributionList = [...loadDistributionList];
    if (updatedLoadDistributionList[index]) {
      updatedLoadDistributionList[index].code = value.toString();
    }
    setLoadDistributionList(updatedLoadDistributionList); // Update state
  };

  // Function to calculate label prefix and the number of sliders
  const calculateFrequency = (days: number) => {
    if (days <= 7) {
      return { prefix: "Day", count: days }; // Weekly
    } else if (days > 7 && days <= 15) {
      return { prefix: "Day", count: days }; // Fort Nightly
    } else if (days >= 28 && days <= 30) {
      return { prefix: "Week", count: 4 }; // Monthly
    } else if (days > 30 && days <= 90) {
      return { prefix: "Week", count: Math.floor(days / 30) }; // Quaterly
    } else if (days > 90 && days <= 180) {
      return { prefix: "Week", count: Math.floor(days / 30) }; // Half Yearly
    } else if (days > 180) {
      return { prefix: "Month", count: Math.floor(days / 30) }; // Yearly
    }
    return { prefix: "Period", count: 0 }; // Fallback
  };

  const calculateTotalPercentage = () => {
    const total = sliders.reduce((acc, val) => acc + val, 0);
    setTotalPercentage(total);
    setIsSaveDisabled(total !== 100); // Disable Save button if total is not 100%
  };

  // Recalculate the total percentage whenever sliders change
  useEffect(() => {
    calculateTotalPercentage();
  }, [sliders]); // Runs whenever sliders change

  const getMetaData = () => {
    const fetchFrequencyMetaUrl = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/loadDistribution`;
    const idExist = loadDistributionId ? loadDistributionId : undefined;

    fetchWizardsDataApi<LoadDistributionResponeTypes>(
      fetchFrequencyMetaUrl,
      idExist,
      "en"
    )
      .then((resp) => {
        const frequencyList = resp.frequencyList.filter(
          (item) => item.level && parseInt(item.level) > 1
        );
        selectedFrequencyArray(frequencyList);
        setSelectedFreqId(frequencyList[0].id);
        if (resp.loadDistributionList?.length > 0) {
          const initialSliders = resp.loadDistributionList.map(
            (item) => Number(item.code) || 0 // Ensure valid numbers
          );
          setSliders(initialSliders);
          setGracePeriod(resp.frequencyGracePeriod);
        }
        setLoadDistributionList(resp.loadDistributionList);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const validateGracePeriod = () => {
    const maxGracePeriod = Math.floor(selectedDays * 0.3); // Calculate 30% of selectedDays
    if (gracePeriod > maxGracePeriod) {
      setErrorDesc(
        `The grace period (${gracePeriod} days) exceeds 30% (${maxGracePeriod} days) of the selected frequency.`
      );
      setOpenErrorModal(true); // Show the error modal
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validateGracePeriod()) return; // Exit if grace period is invalid
    toggleLoader(true);

    const prefix = getSliderLabelPrefix(selectedDays); // Determine the prefix
    const distribution = sliders.map((value, index) => ({
      ...loadDistributionList[index], // Retain other properties
      code: value.toString(), // Update code with slider value
      name: `${prefix} ${index + 1}`, // Add the dynamic name property
    }));

    let Obj = {
      loadDistributionList: distribution,
      frequencyGracePeriod: gracePeriod,
      selectedFrequencyId: selectedFreqId,
    };

    const url = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/loadDistribution/save`;

    customSaveDataApi(url, Obj)
      .then(() => {
        toggleLoader(false);
        fetchEachFrequencyData(selectedFreqId);
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const generateDefaultLoadDistribution = (days: number) => {
    const { prefix, count } = calculateFrequency(days);
    return Array.from({ length: count }, (_, index) => ({
      name: `${prefix} ${index + 1}`,
      code: "0", // Default to 0%,
      id: 0,
      isSelected: false,
    }));
  };

  const fetchEachFrequencyData = (id: number) => {
    toggleLoader(true);
    const fetchFrequencyUrl = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/loadDistribution`;
    fetchWizardsDataApi<LoadDistributionResponeTypes>(
      fetchFrequencyUrl,
      id,
      "en"
    )
      .then((resp) => {
        setSelectedFreqId(id);
        if (resp.loadDistributionList?.length > 0) {
          const initialSliders = resp.loadDistributionList.map(
            (item) => Number(item.code) || 0 // Ensure valid numbers
          );
          setSliders(initialSliders);
          setLoadDistributionList(resp.loadDistributionList);
          setGracePeriod(resp.frequencyGracePeriod);
        } else {
          const defaultDistribution =
            generateDefaultLoadDistribution(selectedDays);
          const defaultSliders = defaultDistribution.map((item) =>
            Number(item.code)
          );
          setSliders(defaultSliders);
          setGracePeriod(999);
          setLoadDistributionList(defaultDistribution);
        }
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <Box>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography
          labelId="Loaddistribution.title"
          defaultLabel="Load Distribution"
          fontSize="20px"
          fontWeight={600}
        />
      </Box>
      <Box
        sx={{
          width: isMenuOpen ? availableWidth : availableWidth1,
          height: availableHeight,
        }}
      >
        <Box sx={{ padding: "24px", backgroundColor: "rgba(34, 39, 43, 1)" }}>
          {/* Frequency Buttons */}
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              label="Select Frequency"
              fontSize="16px"
              fontWeight={500}
              textColor="rgba(148, 149, 151, 1)"
            />
            <Box>
              {frequencyArray.map(
                (item, index) => (
                  // item.code !== "DLY" && (
                  <Button
                    key={index}
                    onClick={() => handleButtonClick(index, item.id)}
                    className={`button-ui p-md flex__ `}
                    sx={{
                      width: "73px",
                      height: "33px",
                      borderRadius: "4px",
                      padding: "8px 12px",
                      gap: "4px",
                      border: "1px solid rgba(148, 149, 151, 0.24)",
                      backgroundColor:
                        selectedFreqId === item.id
                          ? "rgba(34, 139, 230, 0.9)" // Highlight selected button
                          : "rgba(148, 149, 151, 0.08)", // Default background
                      color:
                        selectedFreqId === item.id
                          ? "white"
                          : "rgba(148, 149, 151, 1)",
                      margin: "0px 2px",
                    }}
                  >
                    <Box>
                      <MediumTypography
                        label={item.name}
                        fontSize="14px"
                        fontWeight={600}
                        textColor={
                          selectedFreqId === item.id
                            ? "white"
                            : "rgba(148, 149, 151, 1)"
                        }
                      />
                    </Box>
                  </Button>
                )
                // )
              )}
            </Box>
          </Box>

          <Grid container className="mt-xs">
            <Grid item xs={12} lg={12} md={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{
                    borderTop: "1px solid #333b43",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box className="position__relative">
            <Box
              className="flex__justify__space-between mt-lg mb-lg"
              sx={{
                display: "flex",
                alignItems: "center", // Align items vertically in the center
                justifyContent: "space-between", // Distribute space evenly between elements
              }}
            >
              <MediumTypography
                label="Grace Period"
                fontSize="18px"
                fontWeight={600}
                textColor="rgba(255, 255, 255, 1)"
                sxProps={{ marginRight: "10px" }} // Add spacing between the label and input
              />

              <TextInput
                type="number"
                textColor="rgba(255, 255, 255, 1)"
                sxProps={{
                  border: "1px solid rgba(166, 197, 226, 0.16)",
                  backgroundColor: "rgba(34, 39, 43, 1)",
                  marginRight: "auto", // Push the Save button to the end
                  width: "100px", // Adjust input width if necessary
                  height: "40px",
                  "& .MuiOutlinedInput-root": {
                    width: "98px",
                    height: "40px",

                    "&:hover fieldset": {
                      border: "1px solid rgba(166, 197, 226, 0.16)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid rgba(166, 197, 226, 0.16)",
                    },
                    "& input": {
                      padding: "8px 4px",
                    },
                  },
                }}
                variant="outlined"
                inputProps={{
                  readOnly: false,
                }}
                Value={gracePeriod.toString()}
                handlechange={(value: string) => {
                  setGracePeriod(Number(value));
                }}
              />

              <MediumTypography
                label="Total Load Distribution: "
                textColor="rgba(148, 149, 151, 1)"
                fontSize="16px"
                fontWeight={500}
              />

              <MediumTypography
                // label="100%"
                label={`${totalPercentage}%`}
                textColor="rgba(148, 149, 151, 1)"
                fontSize="16px"
                fontWeight={600}
              />

              <ButtonComponent
                labelId="btn.save"
                defaultLabelId="Save"
                className="btn-primary btn-submit cursor__pointer ml-lg"
                disabled={isSaveDisabled}
                sxProps={{
                  padding: "10px 20px",
                  borderRadius: "4px",
                }}
                onClick={handleSave}
              />
            </Box>

            <Box
              className="position__relative"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
              sx={{
                display: "flex",
                gap: "8px", // Add gap between sliders
                overflowY: "auto",
                WebkitOverflowScrolling: "touch",
                "-webkit-overflow-scrolling": "touch",
                "scroll-behavior": "smooth",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {Array.from({
                length: calculateFrequency(selectedDays).count,
              }).map((_, index) => (
                <Box
                  key={index}
                  className="flex__"
                  sx={{
                    margin: "10px",
                    display: "flex",
                    flexDirection: "column", // Arrange items vertically
                    alignItems: "center", // Center align items
                  }}
                >
                  <VerticalSlider
                    inputValue={sliders[index]}
                    onInputChange={(val: number) =>
                      handleSliderChange(val, index)
                    }
                  />
                  <MediumTypography
                    label={`${getSliderLabelPrefix(selectedDays)} ${index + 1}`}
                    fontSize="12px"
                    fontWeight={500}
                    className="mt-sm"
                    textColor="rgba(255, 255, 255, 1)"
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoadDistributionScreen;
