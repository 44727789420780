import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../../MasterDataManagement/styles.css";
import TextInput from "../../../components/formlib/TextInput";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";

import RadioComponent from "../../../components/formlib/RadioComponent";
import {
  FilteredDataType,
  SavedFilterType,
  SaveFilterType,
} from "../types/Types";
import { saveFilterApi } from "../../../api/ticketing/TicketingAPI";

interface ModalProps {
  open: boolean;
  selectedFilter?: SavedFilterType;
  subGroupIds: number[];
  handleClose: () => void;
  filterData: FilteredDataType[];
  saveCallback: (savedFilter: SavedFilterType) => void;
}

interface SaveDataType {
  filterName: string;
  isPrivate: boolean;
  subGroupId?: number;
}

const validationSchema = Yup.object().shape({
  filterName: Yup.string().required("validation.fieldRequired"),
});

const muiColumnProps = {
  xs: 12,
};

const FilterSaveModal: FC<ModalProps> = ({
  open,
  selectedFilter,
  subGroupIds,
  handleClose,
  filterData,
  saveCallback,
}) => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<SaveDataType>>(null);

  const initialData: SaveDataType = {
    filterName: "",
    isPrivate: false,
    subGroupId: subGroupIds.length === 1 ? subGroupIds[0] : undefined,
  };

  if (selectedFilter) {
    initialData.filterName = selectedFilter.name;
    initialData.isPrivate = selectedFilter.isPrivate;
  }

  const saveData = (params: SaveDataType) => {
    toggleLoader(true);

    const updatedParams = {
      ...params,
      filterData,
    };

    saveFilterApi<SaveFilterType, SavedFilterType>(
      updatedParams,
      selectedFilter?.id,
      updatedParams?.subGroupId
    )
      .then((res) => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        saveCallback(res);
        toggleLoader(false);
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Formik
        initialValues={initialData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={"filterSaveModal.title"}
                  defaultLabel="Name this Filter?"
                  textColor="#FFF"
                  fontSize="18px"
                  fontWeight={500}
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.filterName}
                      handlechange={(value: string) => {
                        setFieldValue("filterName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="filterName" //check lTER
                    />
                  </Grid>
                  {values.subGroupId && (
                    <Grid
                      item
                      {...muiColumnProps}
                      className="standard-radio-div"
                    >
                      <RadioComponent
                        name="isPrivate"
                        value={values.isPrivate}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setFieldValue("isPrivate", e.target.value);
                        }}
                        labelid=""
                        defaultlabelid=""
                        labelidA="Private"
                        defaultlabelidA="Private"
                        labelidB="Public"
                        defaultlabelidB="Public"
                      />

                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="isPrivate"
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Cancel"}
                        labelId={"btn.cancel"}
                        onClick={() => {
                          formikRef.current?.resetForm();
                          handleClose();
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={"Save"}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default FilterSaveModal;
