import { FC, useContext, useState } from "react";
import { Box, Divider } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import TextInput from "../../../components/formlib/TextInput";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import "../css/ticketDetails.css";
import {
  categorizedTemplateType,
  NumericOptionType,
  TemplateFieldType,
  TicketDetailsType,
  TicketMetadataType,
  TransitionType,
  UserType,
} from "../types/Types";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import { OptionsObjectType, SelectedObjectType } from "../TicketDetails";
import { getOptions } from "../../../utils/commonFunctions";
import moment from "moment";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { createDropdownOptionApi } from "../../../api/ticketing/TicketingAPI";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";

interface TicketValueCurrent {
  customField: {
    [key: string]: any;
  } | null;
}

interface DetailsSidebarProps {
  templateFields: TemplateFieldType[];
  categorizedTemplateFields: categorizedTemplateType;
  ticketDetails: TicketDetailsType;
  ticketMetadata: TicketMetadataType | null;
  isInitialStatus: boolean;
  selected: SelectedObjectType;
  options: OptionsObjectType;
  users: UserType[];
  handleSelectionChange: (updatedFields: Partial<SelectedObjectType>) => void;
  handleDetailsChange: (updatedDetails: Partial<TicketDetailsType>) => void;
  handleStatusChange: (transition: TransitionType, remarks?: string) => void;
  updateOptions: React.Dispatch<React.SetStateAction<OptionsObjectType>>;
}

const DetailsSidebar: FC<DetailsSidebarProps> = ({
  templateFields,
  categorizedTemplateFields,
  ticketDetails,
  ticketMetadata,
  isInitialStatus,
  selected,
  options,
  users,
  handleSelectionChange,
  handleDetailsChange,
  handleStatusChange,
  updateOptions,
}) => {
  const initialShowMore = categorizedTemplateFields.detailsFields.length > 5;

  const [showMore, setShowMore] = useState(initialShowMore);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const handleChange = (field: TemplateFieldType, value: any) => {
    if (field.code === "status") {
      const transition = options.status.find(
        (status) => status.id.toString() === value.toString()
      )?.transition;

      if (transition) {
        handleStatusChange(transition);
      }
    } else {
      let payloadObject = { [field.code]: value };

      if (field.initialFieldType === "CUSTOM") {
        payloadObject = {
          customField: payloadObject,
        };
      }

      if (field.code in selected) {
        handleSelectionChange(payloadObject);
      } else {
        handleDetailsChange(payloadObject);
      }
    }
  };

  if (!ticketMetadata) return <></>;

  const sectionHeadings = {
    summaryFields: {
      labelId: "common.summary",
      defaultLabel: "Summary",
    },
    detailsFields: {
      labelId: "common.details",
      defaultLabel: "Details",
    },
    effortsFields: {
      labelId: "common.efforts",
      defaultLabel: "Efforts",
    },
    accountInfoFields: {
      labelId: "common.accountInfo",
      defaultLabel: "Account Info",
    },
  };

  return (
    <Box className="common-flex-column">
      {Object.keys(categorizedTemplateFields).map((categoryKey) => (
        <Box key={categoryKey} className="ticket-details-sidebar">
          <Box p={"12px 20px"}>
            <MediumTypography
              labelId={
                sectionHeadings[categoryKey as keyof typeof sectionHeadings]
                  .labelId ?? "common.details"
              }
              defaultLabel={
                sectionHeadings[categoryKey as keyof typeof sectionHeadings]
                  .defaultLabel ?? "Details"
              }
              className="ticket-header-2"
            />
          </Box>

          <Divider className="ticket-sidebar-divider" />

          <Box p={2} pl={"6px"} className="ticket-input-container">
            {categorizedTemplateFields?.[
              categoryKey as keyof categorizedTemplateType
            ]?.map((field, fieldIndex) => {
              if (
                categoryKey === "detailsFields" &&
                initialShowMore &&
                showMore &&
                fieldIndex >= 5
              )
                return <></>;

              // templateFields?.map((field) => {
              let optionsList: NumericOptionType[] = [];

              if (field.initialFieldType === "CUSTOM") {
                optionsList = field.customConfig?.options ?? [];
              }

              if (field.code in options) {
                optionsList = options[field.code as keyof OptionsObjectType];
              }

              if (field.code === "reporterId") {
                optionsList = [...users];
              }

              return (
                <Box className="ticket-input-item" key={field.id}>
                  <Box className="ticket-input-label-box">
                    <MediumTypography
                      label={field.alias}
                      className="ticket-grey-text"
                    />
                    {field.isRequired && (
                      <span className="ticket-asterisk">*</span>
                    )}
                  </Box>
                  <Box className="ticket-input-box">
                    {["text", "number", "email", "url"].includes(
                      field.type
                    ) && (
                      <TextInput
                        inline
                        className="text-input-field inline-text-input"
                        type={field.type}
                        Value={ticketDetails[field.code] ?? ""}
                        handlechange={(value: string) => {
                          handleChange(field, value);
                        }}
                        disabled={field.isDisabled}
                      />
                    )}

                    {["single_select", "radio"].includes(field.type) && (
                      <>
                        <CustomDropdown
                          inline
                          showImage={
                            field.code === "assignee" ||
                            field.code === "reporterId" ||
                            field.code === "reporterEmail"
                          }
                          showIcon={
                            field.code === "severity" ||
                            field.code === "priority" ||
                            field.code === "state" ||
                            field.code === "channel"
                          }
                          disableCreate
                          options={getOptions(optionsList)}
                          selectedValue={ticketDetails[field.code] ?? ""}
                          onSelectedValueChange={(value) => {
                            handleChange(field, value);
                          }}
                          disabled={field.isDisabled}
                          sx={{
                            display:
                              field.code === "reporterId" &&
                              !ticketDetails[field.code]
                                ? "none"
                                : undefined,
                          }}
                        />
                        {field.code === "reporterId" &&
                          !ticketDetails[field.code] &&
                          ticketDetails["reporterName"] && (
                            <TextInput
                              inline
                              className="text-input-field inline-text-input"
                              type={"text"}
                              Value={ticketDetails["reporterName"]}
                              disabled={true}
                            />
                          )}
                        {field.code === "reporterId" &&
                          !ticketDetails[field.code] &&
                          ticketDetails["reporterEmail"] && (
                            <TextInput
                              inline
                              className="text-input-field inline-text-input"
                              type={"text"}
                              Value={`(${ticketDetails["reporterEmail"]})`}
                              disabled={true}
                            />
                          )}
                      </>
                    )}

                    {["multiple_select", "checkbox"].includes(field.type) && (
                      <CustomDropdown
                        inline
                        multiple
                        options={getOptions(optionsList)}
                        selectedValue={ticketDetails[field.code] ?? []}
                        onSelectedValueChange={(value) => {
                          handleChange(field, value);
                        }}
                        disabled={field.isDisabled}
                        disableCreate={field.code === "tags" ? false : true}
                        onCreateOption={(option) => {
                          toggleLoader(true);
                          createDropdownOptionApi("TAGS", "tags", option)
                            .then((res) => {
                              updateOptions({
                                ...options,
                                tags: res,
                              });
                            })
                            .catch((error) => {
                              if (isCustomError(error)) {
                                const apiError = error as ApiErrorResponse;
                                setErrorDesc(apiError.issue[0].diagnostics);
                              } else {
                                setErrorDesc(error.id);
                              }
                              setOpenErrorModal(true);
                            })
                            .finally(() => {
                              toggleLoader(false);
                            });
                        }}
                      />
                    )}

                    {field.type === "date_time" && (
                      <Box mt={"12px"} className="added-margin">
                        <DateTimePickerComponent
                          value={
                            ticketDetails[field.code]
                              ? dayjs(ticketDetails[field.code] as string)
                              : null
                          }
                          labelid="accounts.selectPlaceholder"
                          defaultlabelid="--- select ---"
                          handlechange={(date: Dayjs) => {
                            const formattedText = date.format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            handleChange(field, formattedText);
                          }}
                          inputFormat="DD MMM YYYY HH:mm:ss"
                          width="100%"
                          textWidth="100%"
                          inputClassName="text-input-field"
                          disabledDate={field.isDisabled}
                        />
                      </Box>
                    )}

                    {field.type === "date" && (
                      <Box mt={"12px"} className="added-margin">
                        <DateTimePickerComponent
                          dateOnly={true}
                          value={
                            ticketDetails[field.code]
                              ? dayjs(ticketDetails[field.code] as string)
                              : null
                          }
                          labelid="accounts.selectPlaceholder"
                          defaultlabelid="--- select ---"
                          handlechange={(date: Dayjs) => {
                            const formattedText = date.format("YYYY-MM-DD");
                            handleChange(field, formattedText);
                          }}
                          inputFormat="DD MMM YYYY"
                          width="100%"
                          textWidth="100%"
                          inputClassName="text-input-field"
                          disabledDate={field.isDisabled}
                        />
                      </Box>
                    )}

                    {field.type === "phone_number" && (
                      <Box
                        mt={"12px"}
                        ml={"8px"}
                        className="inline-phone-container"
                      >
                        <PhoneNumberInput
                          inline
                          inlineValue={ticketDetails[field.code] ?? ""}
                          onInlineChange={(value: string) => {
                            handleChange(field, value);
                          }}
                          labelId="common.placeHolderText"
                          defaultLabelId="--- type here ---"
                          disabled={false}
                        />
                      </Box>
                    )}

                    {field.type === "duration" && (
                      <TextInput
                        inline
                        className="text-input-field inline-text-input"
                        labelId="duration.hoursPlaceholder"
                        defaultLabelId="--- duration (hours) ---"
                        type="number"
                        Value={
                          ticketDetails[field.code]
                            ? moment
                                .duration(ticketDetails[field.code])
                                .asHours()
                                .toString()
                            : ""
                        }
                        handlechange={(value: string) => {
                          let durationValue = "";
                          if (Number(value) > 0) {
                            durationValue = moment
                              .duration(value, "hours")
                              .toISOString();
                          }
                          handleChange(field, durationValue);
                        }}
                        disabled={field.isDisabled}
                      />
                    )}
                  </Box>
                </Box>
              );
            })}

            {categoryKey === "detailsFields" && initialShowMore && showMore && (
              <Box
                className="ticket-input-item-load ticket-anchor ticket-link"
                pl={"6px"}
              >
                <MediumTypography
                  labelId="showMoreText"
                  defaultLabel={"Show More"}
                  onClick={() => {
                    setShowMore(false);
                  }}
                />
              </Box>
            )}
            {categoryKey === "detailsFields" &&
              initialShowMore &&
              !showMore && (
                <Box
                  className="ticket-input-item-load ticket-anchor ticket-link"
                  pl={"6px"}
                >
                  <MediumTypography
                    labelId="showLessText"
                    defaultLabel={"Show Less"}
                    onClick={() => {
                      setShowMore(true);
                    }}
                  />
                </Box>
              )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DetailsSidebar;
