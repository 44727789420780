import { Box } from "@mui/material";
import { FunctionComponent } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as NotAvialableIcon } from "../../../assets/images/woNotFound.svg";

interface NoPermissionProps {
  labelId?: string;
  defaultLabel?: string;
}

const NoPermission: FunctionComponent<NoPermissionProps> = ({
  labelId,
  defaultLabel,
}) => {
  return (
    <Box className="no-permission-container">
      <Box className="woNotAvialable">
        <NotAvialableIcon width={`50px`} height={`50px`} />
        <MediumTypography
          className="noWOTitle"
          labelId={labelId ?? "permission.deniedText"}
          defaultLabel={
            defaultLabel ?? "You don't have permission to access this resource"
          }
        />
      </Box>
    </Box>
  );
};

export default NoPermission;
