import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import MediumTypography from "./MediumTypography";
import { FormikErrors } from "formik";

interface FormikErrorComponentProps {
  errors: FormikErrors<any>;
  field: string;
}

const FormikErrorOnlyComponent: React.FC<FormikErrorComponentProps> = ({
  errors,
  field,
}) => {
  if (errors[field]) {
    return (
      <Box className="loginError" sx={{ mt: "8px" }}>
        <Box sx={{ mr: "3px" }}>
          <ErrorIcon />
        </Box>
        <MediumTypography
          labelId={errors[field] as string}
          defaultLabel={"Please enter a valid value"}
          fontSize="12px"
          textColor="#AE2E24"
          fontWeight={400}
        />
      </Box>
    );
  }

  return <></>;
};

export default FormikErrorOnlyComponent;
