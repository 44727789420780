import { Box } from "@mui/material";
import { ReactComponent as ParamLogo } from "../../assets/images/ParamLogo.svg";
import MediumTypography from "../../components/formlib/MediumTypography";
import TextInput from "../../components/formlib/TextInput";
import ButtonComponent from "../../components/formlib/ButtonComponent";
//import { ReactComponent as Google } from "../../assets/images/googleIcon.svg";
import "./css/Login.css";
import { Formik, FormikProps } from "formik";
import { FC, useRef } from "react";
import * as Yup from "yup";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorLogo.svg";
import { useError } from "./context/ApiErrorContext";
import ModalPopup from "../../components/formlib/modal/ModalPopup";

export interface ForgotPasswordProps {
  email: string;
}
const initialValues: ForgotPasswordProps = {
  email: "",
};
export interface ForgotPasswordScreenProps {
  onReturnToLogin: () => void;
  handlePassword: (data: ForgotPasswordProps) => void;
  testId?: string;
  description?: string;
  modalRequired: boolean;
  handleModalClick: () => void;
}
const ValidationSchema = Yup.object({
  email: Yup.string()
    .email("ForgotPassword.emailInvalidError")
    .required("ForgotPassword.emailError"),
});

const ForgotPassword: FC<ForgotPasswordScreenProps> = ({
  onReturnToLogin,
  handlePassword,
  testId,
  description,
  modalRequired,
  handleModalClick,
}) => {
  const formRef = useRef<FormikProps<ForgotPasswordProps>>(null);
  const { error, setError } = useError();

  const handleForgotPassword = (value: ForgotPasswordProps) => {
    handlePassword(value);
  };

  return (
    <Box className="mainContainer login" data-testid={testId}>
      <Box sx={{ marginTop: "50px", padding: "65px" }}>
        <ParamLogo data-testid="param-logo" />
        <MediumTypography
          className="title"
          labelId="ForgotPassword.Header"
          defaultLabel="Trouble logging in?"
        />
        <MediumTypography
          className="subText width_300"
          labelId="ForgotPassword.SubText"
          defaultLabel="Enter your email and we will send you a link to get back to your account."
        />
        <Formik
          initialValues={initialValues}
          validateOnMount={true}
          innerRef={formRef}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleForgotPassword(values);
          }}
          validationSchema={ValidationSchema}
        >
          {({ handleSubmit, setFieldValue, values, errors, touched }) => (
            <Box component="div">
              <Box className="inputContainer">
                {modalRequired && (
                  <ModalPopup
                    descriptionText={""}
                    descriptionMessage={description}
                    open={modalRequired}
                    hideButton={true}
                    positiveButtonLabel="Ok"
                    positiveButtonLabelId="OkText"
                    handleClose={handleModalClick}
                    onPositiveClick={handleModalClick}
                  />
                )}
                <MediumTypography
                  className="inputLabel"
                  labelId="ForgotPassword.email"
                  defaultLabel="Email"
                />
                <TextInput
                  labelId="Forgot.emailPlaceholder"
                  defaultLabelId="Enter email id"
                  variant="outlined"
                  className="textinput"
                  fieldheight="40px"
                  name="email"
                  Value={values.email}
                  inputProps={{
                    maxLength: 50,
                  }}
                  handlechange={(value: string) => {
                    setFieldValue("email", value);
                    setError("");
                  }}
                  onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                {errors.email && touched.email && (
                  <Box className="loginError">
                    <ErrorIcon />
                    <MediumTypography
                      labelId={errors.email}
                      defaultLabel="Email is required"
                      fontSize="12px"
                      textColor="#AE2E24"
                      fontWeight={400}
                    />
                  </Box>
                )}
                {error !== "" && error !== undefined && error !== null && (
                  <Box className="loginError">
                    <Box>
                      <ErrorIcon />
                    </Box>
                    <MediumTypography
                      labelId={error}
                      fontSize="12px"
                      textColor="#AE2E24"
                      fontWeight={400}
                    />
                  </Box>
                )}
              </Box>
              <Box className="forgotPassword">
                <MediumTypography
                  className="forgotPasswordText"
                  labelId="ForgotPassword.returnToLogin"
                  defaultLabel="Return to Login"
                  onClick={() => {
                    onReturnToLogin();
                  }}
                />
              </Box>

              <ButtonComponent
                type="submit"
                labelId="ForgotPassword.Submit"
                variantType="outlined"
                className="btn-primary btn-submit width__100 mt-md"
                defaultLabelId="Submit"
                onClick={handleSubmit}
              />
              {/* <MediumTypography
                labelId="Login.Or"
                defaultLabel="OR"
                className="mt-md orText"
              /> */}
              {/* <ButtonComponent
                variantType="outlined"
                labelId="Login.GoogleBtn"
                className="btn-primary btn-GoogleBtn width__100 mt-md"
                defaultLabelId="Continue with Google"
                LeftIcon={<Google />}
              /> */}
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
