import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import MediumTypography from "../components/formlib/MediumTypography";
import {
  ApiErrorResponse,
  fetchAppVersionDataApi,
  saveAppVersionDataApi,
} from "../api/UserApi/User";
import { LoaderContext, LoaderContextType } from "../layouts/appSidebar";
import { isCustomError } from "../api/ApiResponseHandler";
import ErrorModal from "../components/formlib/modal/ErrorModal";
import CustomDropdown from "../components/formlib/CustomDropdown";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

interface AppForceUpdateProps {}

type AppVersionDataType = {
  appId: string;
  latestVersion: string;
  previousVersion: string;
  deviceType: string;
  forceUpdate: boolean;
  modifiedDateTime: string;
};

const AppForceUpdate: FunctionComponent<AppForceUpdateProps> = () => {
  const intl = useIntl();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [tableData, setTableData] = useState<AppVersionDataType[]>([]);

  const getTranslation = (labelId: string, defaultLabelId: string) => {
    return intl.formatMessage({
      id: labelId,
      defaultMessage: defaultLabelId,
    });
  };

  const tableHeaders: { [key: string]: string } = {
    appId: getTranslation("appId", "App ID"),
    latestVersion: getTranslation("latestVersion", "Latest Version"),
    previousVersion: getTranslation("previousVersion", "Previous Version"),
    deviceType: getTranslation("deviceType", "Device Type"),
    forceUpdate: getTranslation("forceUpdate", "Force Update"),
    modifiedDateTime: getTranslation("modifiedDateTime", "Last Modified"),
  };

  const yesText = getTranslation("YesText", "Yes");
  const noText = getTranslation("NoText", "No");

  useEffect(() => {
    fetchAppVersionData();
  }, []);

  const fetchAppVersionData = () => {
    toggleLoader(true);
    fetchAppVersionDataApi<AppVersionDataType[]>()
      .then((res) => {
        setTableData(res);
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      })
      .finally(() => toggleLoader(false));
  };

  const saveAppVersionData = (
    rowData: AppVersionDataType,
    newData: Partial<AppVersionDataType>
  ) => {
    const param = { ...rowData, ...newData };
    toggleLoader(true);
    saveAppVersionDataApi(param)
      .then(() => {
        fetchAppVersionData();
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      })
      .finally(() => toggleLoader(false));
  };

  return (
    <Box component="main" className="app-force-update">
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <MediumTypography
        labelId={"appVersionHistory"}
        defaultLabel="App Version History"
        sxProps={{ mb: 2, fontSize: "18px", fontWeight: 600 }}
      />

      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(tableHeaders).map((key) => (
                <TableCell
                  key={key}
                  variant="head"
                  className="table-header-cell"
                >
                  <MediumTypography
                    label={tableHeaders[key]}
                    className="table-header-cell-value"
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.appId}>
                {Object.keys(tableHeaders).map((key) => {
                  let cellValue =
                    row[key as keyof AppVersionDataType]?.toString() ?? "";

                  if (key === "modifiedDateTime") {
                    cellValue = cellValue
                      ? dayjs(cellValue).format("MMM D, YYYY [at] h:mm A")
                      : "";
                  }

                  return (
                    <TableCell key={key} className="table-body-cell">
                      {key === "forceUpdate" ? (
                        <CustomDropdown
                          disableSearch={true}
                          disableCreate={true}
                          options={[
                            { id: "true", name: yesText },
                            { id: "false", name: noText },
                          ]}
                          selectedValue={cellValue}
                          onSelectedValueChange={(value) => {
                            const updatedValue = value as string;
                            saveAppVersionData(row, {
                              [key]: updatedValue === "true",
                            });
                          }}
                          overrideWidth="100px"
                        />
                      ) : (
                        <MediumTypography
                          label={cellValue}
                          className="table-cell-value"
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AppForceUpdate;
