import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { FC, useState } from "react";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { Type } from "../types/workOrderTypes";
import { AddCircle, CheckCircle } from "@mui/icons-material";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import SearchBox from "../../../components/formlib/SearchBox";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  assetsList: Type[];

  onApply: (selectedAssets: Type[]) => void;
}

const AddAssetSetModal: FC<ModalProps> = ({
  open,
  handleClose,
  assetsList,
  onApply,
}) => {
  const availableHeight = useAvailableHeight(450);
  const [selectedAssets, setSelectedAssets] = useState<Type[]>([]);
  const [assetSelection, setAssetSelection] = useState<{
    [id: number]: boolean;
  }>({});
  const [searchQuery, setSearchQuery] = useState("");

  const filteredAssetsList = assetsList.filter((asset) => {
    return asset.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleAssetSelection = (asset: Type) => {
    const isSelected = assetSelection[asset.id];
    setAssetSelection((prev) => ({ ...prev, [asset.id]: !isSelected }));
    if (!isSelected) {
      setSelectedAssets((prev) => [...prev, asset]);
    } else {
      setSelectedAssets((prev) => prev.filter((item) => item.id !== asset.id));
    }
  };

  const handleApply = () => {
    onApply(selectedAssets);
    handleClose();
  };

  return (
    <Dialog
      className="account-dialog main-wrapper-box"
      open={open}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle
        className="padding_20 flex__justify__space-between"
        sx={{ backgroundColor: "#22272B" }}
      >
        <MediumTypography
          labelId={"W/O.AvailableAssets"}
          defaultLabel="Available Assets"
          textColor="#FFF"
          fontSize="16px"
          fontWeight={700}
        />
        <SearchBox
          labelId="defaultSearch"
          defaultlabel="Search"
          backgroundColor="#22272B"
          onChange={(value) => setSearchQuery(value)} // Update search value
        />
      </DialogTitle>
      <DialogContent
        dividers
        className="padding_20"
        sx={{ backgroundColor: "#22272B", height: "500px" }}
      >
        <Box
          sx={{
            height: "460px",
            overflowY: "scroll",
            border: "1px solid #575B5E7A",
            backgroundColor: "#323337",
          }}
          className="flex__ p-md"
        >
          <Grid
            container
            direction={"row"}
            spacing={2}
            sx={{ height: "100px" }}
          >
            {filteredAssetsList &&
              filteredAssetsList.map((asset, index) => (
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Box
                    className="flex__justify__space-between mt-sm mr-sm padding_12"
                    sx={{
                      backgroundColor: "#22272B",
                      borderRadius: "4px",
                    }}
                  >
                    <Tooltip
                      followCursor
                      title={asset.name.length > 15 ? asset.name : ""}
                    >
                      <Box>
                        <MediumTypography
                          label={
                            asset.name.length > 15
                              ? asset.name.substring(0, 15) + "..."
                              : asset.name
                          }
                          textColor="#9FADBC"
                          className="mr-sm mt-xs"
                        />
                      </Box>
                    </Tooltip>
                    {assetSelection[asset.id] ? (
                      <CheckCircle
                        sx={{
                          cursor: "pointer",
                          width: "20px",
                          color: "#4BE74B",
                        }}
                        onClick={() => handleAssetSelection(asset)}
                      />
                    ) : (
                      <AddCircle
                        sx={{
                          cursor: "pointer",
                          width: "20px",
                          color: "#ffff",
                        }}
                        onClick={() => handleAssetSelection(asset)}
                      />
                    )}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        className="dialogActions"
        sx={{ backgroundColor: "#22272B" }}
      >
        <Box>
          <Grid
            className="flex__ justifyContent-FlexEnd"
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <ButtonComponent
                className="btn-primary btn-cancel mr-md"
                variantType="outlined"
                defaultLabelId={"Cancel"}
                labelId={"CancelBtnText"}
                onClick={() => {
                  setSelectedAssets([]);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ButtonComponent
                className="btn-primary btn-submit"
                variantType="contained"
                defaultLabelId={"Apply"}
                labelId={"Apply"}
                onClick={handleApply}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddAssetSetModal;
