import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import RadioPickList from "../components/RadioPickList";
import TextInput from "../../../components/formlib/TextInput";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { AddEditCustomFieldType } from "../types/formBuilder";
import { FC, useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import {
  saveCustomField,
  saveCustomFieldById,
  fetchCustomFieldById,
} from "../../../api/ticketing/TicketingAPI";
import { useLocation, useNavigate } from "react-router-dom";

const initialData: AddEditCustomFieldType = {
  code: "",
  name: "",
  alias: "",
  info: "",
  type: "",
  option: "",
  customConfig: { options: [], defaultOption: 0 },
};

interface fieldType {
  isNew: boolean;
  selectedFieldType: string;
  selectedFieldName?: string;
  fieldId?: number | undefined;
  handleCancel: () => {};
  callGetApi: () => void;
}

const apiUri = "customField";
const apiUriSave = "customField/save";

const PickListType = [
  { id: 1, name: "Single Select" },
  { id: 2, name: "Multi Select" },
];
const AddEditCustomFieldsForm: FC<fieldType> = ({
  isNew,
  selectedFieldType,
  selectedFieldName,
  fieldId,
  handleCancel,
  callGetApi,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const formikRef = useRef<FormikProps<AddEditCustomFieldType>>(null);
  const [radioVal, setRadioVal] = useState<number>(0);
  const [apiData, setApiData] = useState<AddEditCustomFieldType>(initialData);
  const [selectedOptionApi, setSelectedOptionApi] = useState<number>(0);
  const [optionOriginalValue, setoptionOriginalValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<number>(
    PickListType[0].id
  );
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const enableMulti = useRef(true);

  useEffect(() => {
    if (fieldId !== undefined) {
      getCustomFieldsById();
    } else {
      setApiData({
        code: "",
        name: "",
        alias: "",
        info: "",
        type: "",
        option: "",
        customConfig: { options: [], defaultOption: 0 },
      });
    }
  }, [fieldId]);

  const getCustomFieldsById = (language: string = "en") => {
    toggleLoader(true);
    if (fieldId !== undefined) {
      fetchCustomFieldById<AddEditCustomFieldType>(apiUri, fieldId, language)
        .then((response: AddEditCustomFieldType) => {
          toggleLoader(false);
          if (response) {
            if (
              response.type === "radio" ||
              response.type === "checkbox" ||
              response.type === "dropdown" ||
              response.type === "single_select" ||
              response.type === "multiple_select"
            ) {
              selectedFieldType = response.type;
              const optionsString = response.customConfig?.options
                .map((option: { name: string }) => option.name)
                .join(",");
              apiData.option = optionsString;
              const updatedRes = { ...apiData, ...response };
              setoptionOriginalValue(optionsString as string);
              if (response.type === "multiple_select") {
                setSelectedValue(2);
              } else {
                setSelectedValue(1);
              }

              setApiData(updatedRes);
              setSelectedOptionApi(
                response.customConfig?.defaultOption as number
              );
              setRadioVal(response.customConfig?.defaultOption as number);
            } else {
              const updatedRes = { ...apiData, ...response };
              setApiData(updatedRes);
            }
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          if (isCustomError(error)) {
            const apiError = error as ApiErrorResponse;
            setErrorDesc(apiError.issue[0].diagnostics);
          } else {
            setErrorDesc(error.id);
          }
        });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("validation.name"),
    info: Yup.string().required("validation.info"),
  });

  const getSelectedValue = (val: number) => {
    setSelectedOptionApi(val);
    setRadioVal(val);
  };

  const saveData = (formValue: AddEditCustomFieldType) => {
    let obj = {};
    if (
      (selectedFieldType === "radio" ||
        selectedFieldType === "dropdown" ||
        selectedFieldType === "checkbox" ||
        selectedFieldType === "single_select" ||
        selectedFieldType === "multiple_select") &&
      isNew
    ) {
      const singleOption = formValue?.option ? formValue.option.split(",") : [];
      const result = singleOption.map((op: string, index: number) => ({
        id: index + 1,
        name: op.trim(),
      }));

      let customConfig = {
        options: result,
        defaultOption: radioVal,
      };

      obj = {
        name: formValue.name,
        info: formValue.info,
        type:
          selectedFieldType === "dropdown"
            ? selectedValue === 1
              ? "single_select"
              : "multiple_select"
            : selectedFieldType,
        customConfig,
      };

      saveCustomField(apiUriSave, "en", obj)
        .then((response) => {
          if (response) {
            if (location.state.origin !== undefined) {
              const currentState = location.state;
              const existingNumbers = currentState?.numbers || [];

              navigate("/master-data-management", {
                state: {
                  id: id,
                  toggle: location?.state?.toggle,
                  numbers: [...existingNumbers, response.id],
                  origin: location?.state?.origin,
                },
              });
            }
            callGetApi();
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          if (isCustomError(error)) {
            const apiError = error as ApiErrorResponse;
            setErrorDesc(apiError.issue[0].diagnostics);
          } else {
            setErrorDesc(error.id);
          }
        });
    } else if (
      selectedFieldType !== "radio" &&
      selectedFieldType !== "dropdown" &&
      selectedFieldType !== "checkbox" &&
      selectedFieldType !== "single_select" &&
      selectedFieldType !== "multiple_select" &&
      isNew
    ) {
      obj = {
        name: formValue.name,
        info: formValue.info,
        type: selectedFieldType,
      };

      saveCustomField(apiUriSave, "en", obj)
        .then((response) => {
          if (response) {
            if (origin !== undefined) {
              const currentState = location.state;
              const existingNumbers = currentState?.numbers || [];

              navigate("/master-data-management", {
                state: {
                  id: id,
                  toggle: location?.state?.toggle,
                  numbers: [...existingNumbers, response.id],
                  origin: location?.state?.origin,
                },
              });
            }

            callGetApi();
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          if (isCustomError(error)) {
            const apiError = error as ApiErrorResponse;
            setErrorDesc(apiError.issue[0].diagnostics);
          } else {
            setErrorDesc(error.id);
          }
        });
    } else if (
      selectedFieldType === "radio" ||
      selectedFieldType === "dropdown" ||
      selectedFieldType === "checkbox" ||
      selectedFieldType === "single_select" ||
      (selectedFieldType === "multiple_select" && !isNew)
    ) {
      const singleOption = formValue?.option ? formValue.option.split(",") : [];
      const result = singleOption.map((op: string, index: number) => ({
        id: index + 1,
        name: op.trim(),
      }));
      let customConfig = {
        options: result,
        defaultOption: radioVal,
      };

      obj = {
        name: formValue.name,
        info: formValue.info,
        type: selectedValue === 1 ? "single_select" : "multiple_select",
        customConfig,
      };
      if (fieldId !== undefined) {
        saveCustomFieldById(apiUriSave, fieldId, "en", obj)
          .then((response) => {
            if (response) {
              callGetApi();
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setOpenErrorModal(true);
            if (isCustomError(error)) {
              const apiError = error as ApiErrorResponse;
              setErrorDesc(apiError.issue[0].diagnostics);
            } else {
              setErrorDesc(error.id);
            }
          });
      }
    } else {
      obj = {
        name: formValue.name,
        info: formValue.info,
        type: selectedFieldType,
      };
      if (fieldId !== undefined) {
        saveCustomFieldById(apiUriSave, fieldId, "en", obj)
          .then((response) => {
            if (response) {
              callGetApi();
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setOpenErrorModal(true);
            if (isCustomError(error)) {
              const apiError = error as ApiErrorResponse;
              setErrorDesc(apiError.issue[0].diagnostics);
            } else {
              setErrorDesc(error.id);
            }
          });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = PickListType.find(
      (itemval) => itemval.name === event.target.value
    );
    if (item) {
      setSelectedValue(item.id);
    }
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Box>
              <Box>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box>
                    <MediumTypography
                      className="input-label"
                      labelId={"field.name"}
                      defaultLabel="Field Name"
                    />
                    <TextInput
                      className="text-input-field custom-text-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Box>
                  <Box>
                    <MediumTypography
                      className="input-label"
                      labelId={"info.text"}
                      defaultLabel="Info Text"
                      sxProps={{ marginTop: "10px" }}
                    />
                    <TextInput
                      className="text-input-field custom-text-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.info}
                      handlechange={(value: string) => {
                        setFieldValue("info", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="info"
                    />
                  </Box>

                  {(selectedFieldType === "dropdown" ||
                    selectedFieldType === "single_select" ||
                    selectedFieldType === "multiple_select") && (
                    <Box className="standard-radio-div custom-radio-div">
                      <MediumTypography
                        className="input-label"
                        labelId="dropdown.type"
                        defaultLabel="Dropdown Type"
                        sxProps={{ marginTop: "10px" }}
                      />
                      <RadioGroup onChange={handleChange}>
                        {PickListType.map((item, index) => (
                          <FormControlLabel
                            key={item.id}
                            value={item.name}
                            control={
                              <Radio
                                checked={selectedValue === item.id}
                                disabled={fieldId !== undefined}
                              />
                            }
                            label={<MediumTypography labelId={item.name} />}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  )}
                  {(selectedFieldType === "checkbox" ||
                    selectedFieldType === "radio" ||
                    selectedFieldType === "dropdown" ||
                    selectedFieldType === "single_select" ||
                    selectedFieldType === "multiple_select") && (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                        }}
                      >
                        <Box
                          sx={{
                            flex: "1 0 45%",
                          }}
                        >
                          <MediumTypography
                            className="input-label"
                            labelId={"dropdown.options"}
                            defaultLabel="Options (Comma separated)"
                            sxProps={{ marginTop: "10px" }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <TextInput
                              className="text-input-field custom-text-field"
                              type="text"
                              variant="outlined"
                              inputProps={{
                                readOnly: false,
                              }}
                              labelId="common.placeHolderText"
                              defaultLabelId="--- type here ---"
                              Value={values.option}
                              handlechange={(value: string) => {
                                if (selectedValue === 2 && value !== "") {
                                  enableMulti.current = false;
                                } else {
                                  enableMulti.current = true;
                                }
                                if (value.startsWith(optionOriginalValue)) {
                                  setFieldValue("option", value);
                                }
                              }}
                            />
                          </Box>
                          <FormikErrorComponent
                            errors={errors}
                            touched={touched}
                            field="option"
                          />
                        </Box>
                      </Box>
                      {values.option && selectedValue === 1 && (
                        <Box mt={2}>
                          <MediumTypography
                            className="input-label"
                            labelId={"dropdown.defaultOption"}
                            defaultLabel="Default Option"
                            sxProps={{ marginTop: "10px" }}
                          />
                          <RadioPickList
                            value={values.option}
                            getSelectedValue={getSelectedValue}
                            defaultOptionFromApi={selectedOptionApi}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider className="big-divider" />
              <Box>
                <Box className="btn-container_custom_field">
                  <Box
                    className="flex__ center"
                    sx={{
                      gap: 2,
                    }}
                  >
                    <ButtonComponent
                      className="btn-primary btn-ticket-text"
                      labelId="btn.cancel"
                      defaultLabelId="Cancel"
                      onClick={() => handleCancel()}
                    />

                    <ButtonComponent
                      className="btn-primary btn-ticket"
                      variantType="contained"
                      labelId="btn.save"
                      defaultLabelId="Save"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={
                        radioVal === 0 &&
                        ((selectedValue === 1 &&
                          selectedFieldType === "dropdown") ||
                          (selectedValue === 2 && enableMulti.current) ||
                          selectedFieldType === "checkbox" ||
                          selectedFieldType === "radio")
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditCustomFieldsForm;
