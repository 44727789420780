import { Box, Divider, Grid } from "@mui/material";
import { RoleType } from "../types/workOrderTypes";
import { FC } from "react";
import CheckBox from "../../../components/formlib/CheckBox";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";

import { Add, Remove } from "@mui/icons-material";
import "./WoMaster.css";

interface PreferredRoleProps {
  roles: RoleType[];
  handleCheckBoxClick: (updatedRoles: RoleType[]) => void;
  handleCountIncrease: (updatedRoles: RoleType[]) => void;
  handleCountDecrease: (updatedRoles: RoleType[]) => void;
  handleApply: () => void;
}

const PreferredRoles: FC<PreferredRoleProps> = ({
  roles,
  handleCheckBoxClick,
  handleCountIncrease,
  handleCountDecrease,
  handleApply,
}) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid #545558",
            }}
          />
        </Box>
      </Grid>
      <Grid
        container
        direction={"row"}
        className=" pb-sm pl-md pr-md mt-sm "
        spacing={2}
      >
        {roles.map((role) => (
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className="p-sm ml-md mt-sm flex__justify__space-between"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.08)",
              borderRadius: "4px",
            }}
          >
            <Box className="flex__ align__items__center">
              <CheckBox
                value={role.isSelected}
                onCheckBoxClick={() => {
                  const updatedRoleList = roles.map((r) => {
                    if (r.id === role.id) {
                      return {
                        ...r,
                        isSelected: !r.isSelected,
                        level: !r.isSelected ? 1 : null,
                      };
                    }
                    return r;
                  });
                  handleCheckBoxClick(updatedRoleList);
                }}
                className="p-none"
              />
              <MediumTypography label={role.name} className={"ml-sm "} />
            </Box>
            {role.isSelected && (
              <Box
                className="flex__ align__items__center "
                sx={{
                  backgroundColor: "#1D2125",
                  borderRadius: "4px",
                }}
              >
                <Remove
                  sx={{
                    cursor:
                      role.level === null ||
                      role.level === undefined ||
                      role.level === 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className="mt-xs mb-xs ml-xs mr-xs icon"
                  onClick={() => {
                    const updatedRoleList = roles.map((r) => {
                      if (r.id === role.id) {
                        return {
                          ...r,
                          level:
                            r.level === null ? 1 : Math.max(1, r.level - 1),
                        };
                      }
                      return r;
                    });
                    handleCountDecrease(updatedRoleList);
                  }}
                />
                <MediumTypography
                  label={`${
                    role.level === null || role.level === undefined
                      ? 1
                      : role.level
                  }/${role.count}`}
                />
                <Add
                  sx={{
                    cursor:
                      role.level === role.count ? "not-allowed" : "pointer",
                  }}
                  className="mt-xs mb-xs ml-xs mr-xs icon"
                  onClick={() => {
                    const updatedRoleList = roles.map((r) => {
                      if (r.id === role.id) {
                        return {
                          ...r,
                          level:
                            r.level === null
                              ? 1
                              : Math.min(r.count, r.level + 1),
                        };
                      }
                      return r;
                    });
                    handleCountIncrease(updatedRoleList);
                  }}
                />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <Box className=" mb-sm flex__ width__100 justifyContent-FlexEnd">
        <ButtonComponent
          className={
            !roles.some((r) => r.isSelected)
              ? "btn-primary btn-disabled mr-md"
              : "btn-primary btn-submit mr-md"
          }
          variantType="contained"
          defaultLabelId={"Apply"}
          labelId={"Apply"}
          disabled={!roles.some((r) => r.isSelected)}
          onClick={async () => {
            if (roles.some((r) => r.isSelected)) {
              handleApply();
            }
          }}
        />
      </Box>
    </>
  );
};

export default PreferredRoles;
