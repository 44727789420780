import { Avatar, Box } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { useContext, useEffect, useState } from "react";
import { fetchTicketActivity } from "../../../api/ticketing/TicketDetails";
import RightArrowIcon from "@mui/icons-material/East";
import dayjs from "dayjs";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { convertToRGBA } from "../../../utils/commonFunctions";
import moment from "moment";

interface ActivityValueType {
  id: number;
  name: string;
  code: string;
  image?: string;
  colour?: string;
}

interface ActivityProps {
  fieldId: number;
  fieldCode: string;
  fieldName: string;
  oldValue: ActivityValueType;
  newValue: ActivityValueType;
  modifiedBy: ActivityValueType;
  modifiedDate: string;
}
const ActivityMessage = ({ ticketId }: { ticketId: number }) => {
  const [activityHistoryList, setActivityHistoryList] = useState<
    ActivityProps[]
  >([]);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  useEffect(() => {
    toggleLoader(true);
    const url = `ticket/${ticketId}/fetchActivityLogs?page=0&size=500`;
    fetchTicketActivity(url)
      .then((response: any) => {
        if (response) {
          setActivityHistoryList(response.activityLogs);
        }
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  }, []);
  return (
    <Box sx={{ minHeight: "150px" }}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box display={"flex"} flexDirection={"column"} gap={4}>
        {activityHistoryList?.map((activityItem: ActivityProps) => {
          let oldValueUI = (
            <MediumTypography
              label={activityItem.oldValue?.name}
              className="ticket-grey-text"
            />
          );

          let newValueUI = (
            <MediumTypography
              label={activityItem.newValue?.name}
              className="ticket-grey-text"
            />
          );

          if (
            activityItem.fieldCode === "actualEffort" ||
            activityItem.fieldCode === "estimatedEffort"
          ) {
            oldValueUI = (
              <MediumTypography
                label={
                  activityItem.oldValue?.name
                    ? moment
                        .duration(activityItem.oldValue?.name)
                        .asHours()
                        .toString() + " Hours"
                    : ""
                }
                className="ticket-grey-text"
              />
            );

            newValueUI = (
              <MediumTypography
                label={
                  activityItem.newValue?.name
                    ? moment
                        .duration(activityItem.newValue?.name)
                        .asHours()
                        .toString() + " Hours"
                    : ""
                }
                className="ticket-grey-text"
              />
            );
          } else if (
            activityItem.fieldCode === "assignee" ||
            activityItem.fieldCode === "reporterId"
          ) {
            oldValueUI = (
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Avatar
                  src={activityItem.oldValue?.image ?? ""}
                  alt="Profile Photo"
                  className="avatar-style"
                />
                <MediumTypography
                  label={activityItem.oldValue?.name}
                  className="ticket-detail-text"
                />
              </Box>
            );

            newValueUI = (
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Avatar
                  src={activityItem.newValue?.image ?? ""}
                  alt="Profile Photo"
                  className="avatar-style"
                />
                <MediumTypography
                  label={activityItem.newValue?.name}
                  className="ticket-detail-text"
                />
              </Box>
            );
          } else if (activityItem.fieldCode === "Status") {
            oldValueUI = (
              <Box
                sx={{
                  padding: "4px 8px 3px",
                  borderRadius: "2px",
                  bgcolor: convertToRGBA(activityItem.oldValue?.colour, 0.1),
                }}
                display={"inline-block"}
              >
                <MediumTypography
                  label={activityItem.oldValue?.name}
                  textColor={activityItem.oldValue?.colour ?? "#25BBFA"}
                />
              </Box>
            );

            newValueUI = (
              <Box
                sx={{
                  padding: "4px 8px 3px",
                  borderRadius: "2px",
                  bgcolor: convertToRGBA(activityItem.newValue?.colour, 0.1),
                }}
                display={"inline-block"}
              >
                <MediumTypography
                  label={activityItem.newValue?.name}
                  textColor={activityItem.newValue?.colour ?? "#25BBFA"}
                />
              </Box>
            );
          }

          return (
            <Box
              key={activityItem.modifiedDate}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
            >
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Avatar
                  src={activityItem.modifiedBy?.image ?? ""}
                  alt="Profile Photo"
                  className="avatar-style"
                />
                <MediumTypography
                  labelId="activity.changedText"
                  labelValues={{
                    person: activityItem.modifiedBy?.name,
                    field: activityItem.fieldName,
                  }}
                  defaultLabel={`${activityItem.modifiedBy?.name} changed the ${activityItem.fieldName}`}
                  className="ticket-detail-text"
                />
                <MediumTypography label="|" className="ticket-detail-text" />
                <MediumTypography
                  label={dayjs(activityItem.modifiedDate).format(
                    "MMM D, YYYY [at] h:mm A"
                  )}
                  className="ticket-grey-text"
                />
              </Box>

              <Box ml={7} display={"flex"} gap={2} alignItems={"center"}>
                {oldValueUI}
                <RightArrowIcon sx={{ color: "#9fadbc" }} />
                {newValueUI}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default ActivityMessage;
