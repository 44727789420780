import { ReactNode } from "react";
import {
  LIST_VIEW,
  MANAGE_ABSENCE,
  MASTER_DATA_MANAGEMENT,
  SCHEDULER_DASHBOARD,
  SHIFT_MONTHLY_PLANNER,
  TICKET,
  WIZARDS_ONBOARDING,
} from "../routes/Routing";
import { hasPermission } from "../utils/checkPermission";

export interface SidebarMenuItem {
  id: string;
  labelId: string;
  defaultLabel: string;
  to: string;
  type: "NORMAL" | "NESTED";
  leftIcon: string;
  rightIcon?: ReactNode;
  subMenus: SubMenu[];
}

export interface SubMenu {
  id: string;
  labelId: string;
  defaultLabel: string;
  to: string;
  leftIcon: string;
}

export const getSideBarMenus = (): SidebarMenuItem[] => {
  return [
    {
      id: "1",
      labelId: "schedulerText",
      defaultLabel: "Scheduler",
      type: "NORMAL",
      to: SCHEDULER_DASHBOARD,
      leftIcon: "personalCard",
      subMenus: [],
    },

    ...(hasPermission("Manage_Absence") || hasPermission("Read_Only")
      ? [
          {
            id: "2",
            labelId: "manageAbsence",
            defaultLabel: "Manage Absence",
            type: "NORMAL" as "NORMAL" | "NESTED",
            to: MANAGE_ABSENCE,
            leftIcon: "note",
            subMenus: [],
          },
        ]
      : []),

    ...(hasPermission("Manage_Shifts") || hasPermission("Read_Only")
      ? [
          {
            id: "3",
            labelId: "shifts",
            defaultLabel: "Shifts",
            type: "NORMAL" as "NORMAL" | "NESTED",
            to: SHIFT_MONTHLY_PLANNER,
            leftIcon: "profile",
            subMenus: [],
          },
        ]
      : []),

    //Conditionally include the Master Data Management menu item
    ...(hasPermission("Master_Data_Management") || hasPermission("Read_Only")
      ? [
          {
            id: "4",
            labelId: "masterDataManagements",
            defaultLabel: "Master Data Management",
            type: "NORMAL" as "NORMAL" | "NESTED",
            to: MASTER_DATA_MANAGEMENT,
            leftIcon: "profile",
            subMenus: [],
          },
        ]
      : []),
    ...(hasPermission("Onboarding_Admin") || hasPermission("Read_Only")
      ? [
          {
            id: "4",
            labelId: "wizards.text",
            defaultLabel: "Onboarding Wizards",
            type: "NORMAL" as "NORMAL" | "NESTED",
            to: WIZARDS_ONBOARDING,
            leftIcon: "wizrads",
            subMenus: [],
          },
        ]
      : []),

    ...(hasPermission("Ticket_Management") || hasPermission("Read_Only")
      ? [
          {
            id: "5",
            labelId: "ticketing.menuText",
            defaultLabel: "Ticketing System",
            type: "NORMAL" as "NORMAL" | "NESTED",
            to: TICKET + "/" + LIST_VIEW,
            leftIcon: "ticketing",
            subMenus: [],
          },
        ]
      : []),
  ];
};
