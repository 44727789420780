import { hasPermission } from "../../../utils/checkPermission";
import {
  FieldPathsType,
  HierarchyDataType,
  HierarchyOptionType,
  NameMappingType,
} from "../types/Types";

// Type guard to remove null values
const isValidOption = (
  option: HierarchyOptionType | null
): option is HierarchyOptionType => option !== null;

export const convertToHierarchy = (
  data: HierarchyDataType[],
  fieldPaths: { [key: string]: string | null },
  currentField: string,
  parentId: number | null = null
): HierarchyOptionType[] => {
  const parentField = fieldPaths[currentField];
  const childFields = Object.keys(fieldPaths).filter(
    (key) => fieldPaths[key] === currentField
  );

  if (parentField === null) {
    return data
      .map((item) => ({
        id: item.id,
        name: item.name,
        children:
          childFields.length > 0
            ? childFields.flatMap((childField) =>
                item[childField]
                  ? convertToHierarchy(
                      item[childField],
                      fieldPaths,
                      childField,
                      item.id
                    )
                  : []
              )
            : undefined,
      }))
      .filter(isValidOption) as HierarchyOptionType[]; // Explicit cast to HierarchyOptionType[]
  }

  return data
    .map((item) => {
      if (parentId !== null && item.id !== parentId) {
        return null;
      }

      return {
        id: item.id,
        name: item.name,
        children:
          childFields.length > 0
            ? childFields.flatMap((childField) =>
                item[childField]
                  ? convertToHierarchy(
                      item[childField],
                      fieldPaths,
                      childField,
                      item.id
                    )
                  : []
              )
            : undefined,
      };
    })
    .filter(isValidOption) as HierarchyOptionType[]; // Explicit cast to HierarchyOptionType[]
};

export const mapFieldPaths = (
  fieldPaths: FieldPathsType,
  nameMapping: NameMappingType
): FieldPathsType => {
  const mappedPaths: FieldPathsType = {};

  for (const [key, value] of Object.entries(fieldPaths)) {
    const newKey = nameMapping[key] || key; // Use mapped name or default to the original
    const newValue = value ? nameMapping[value] || value : null; // Map parent if exists
    mappedPaths[newKey] = newValue;
  }

  return mappedPaths;
};

export const hasTicketEditPermission = (): boolean => {
  return hasPermission("Ticket_Management");
};
