import { Box } from "@mui/material";
import { FC } from "react";

interface TicketingProps {}

const Ticket: FC<TicketingProps> = () => {
  return (
    <Box id="ticketing_home" className="ticketing_home">
      Ticketing Index Page
    </Box>
  );
};

export default Ticket;
