import { Box, Divider } from "@mui/material";
import TextInput from "../../../components/formlib/TextInput";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { AddInitial } from "../types/formBuilder";
import { FC, useEffect, useState } from "react";
import CheckBox from "../../../components/formlib/CheckBox";

const initialData: AddInitial = {
  code: "",
  name: "",
  isRequired: false,
  initialFieldType: "",
  isVisible: "",
  showInForm: false,
  alias: "",
  info: "",
  type: "",
  option: "",
  isSaved: false,
  customConfig: { options: [], defaultOption: 0 },
  editableOn: "ALL",
  isMandatory: false,
  isDisabled: false,
};

interface fieldType {
  updateTemplate: (data: AddInitial) => void;
  fieldId?: number;
  handleCancel: (
    selectedFieldType: string,
    fieldId?: number | undefined
  ) => void;

  formData: AddInitial;
}
const TemplateFormFields: FC<fieldType> = ({ formData, updateTemplate }) => {
  const [apiData, setApiData] = useState<AddInitial>(initialData);
  useEffect(() => {
    if (
      formData.type === "radio" ||
      formData.type === "single_select" ||
      formData.type === "multiple_select"
    ) {
      const optionsString = formData.customConfig?.options
        .map((option) => option.name)
        .join(",");
      apiData.option = optionsString;
      const updatedRes = { ...apiData, ...formData };

      setApiData(updatedRes);
    } else {
      const updatedRes = { ...apiData, ...formData };

      setApiData(updatedRes);
    }
  }, []);

  const onChangeApiData = (key: string, value: string | boolean) => {
    setApiData({ ...apiData, [key]: value, isSaved: false });
    updateTemplate({ ...apiData, [key]: value, isSaved: false });
  };

  const onChangeHandler = (field: string, checked: boolean) => {
    const updatedApiData = {
      ...apiData,
      [field]: checked,
      isSaved: false,
    };
    setApiData(updatedApiData);
    updateTemplate(updatedApiData);
  };

  return (
    <Box>
      <Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Box>
            <MediumTypography
              className="input-label"
              labelId={"field.name"}
              defaultLabel="Field Name"
            />
            <TextInput
              className="text-input-field custom-text-field"
              type="text"
              variant="outlined"
              inputProps={{
                readOnly: false,
              }}
              labelId="common.placeHolderText"
              defaultLabelId="--- type here ---"
              Value={apiData.name}
              disabled
            />
          </Box>
          <Box>
            <MediumTypography
              className="input-label"
              labelId={"info.text"}
              defaultLabel="Info Text"
              sxProps={{ marginTop: "10px" }}
            />
            <TextInput
              className="text-input-field custom-text-field"
              type="text"
              variant="outlined"
              inputProps={{
                readOnly: false,
              }}
              labelId="common.placeHolderText"
              defaultLabelId="--- type here ---"
              Value={apiData.info}
              handlechange={(value: string) => {
                onChangeApiData("info", value);
              }}
            />
          </Box>
          <Box>
            <MediumTypography
              className="input-label"
              labelId={"alias.name"}
              defaultLabel="Alias Name"
              sxProps={{ marginTop: "10px" }}
            />
            <TextInput
              className="text-input-field custom-text-field"
              type="text"
              variant="outlined"
              inputProps={{
                readOnly: false,
              }}
              labelId="common.placeHolderText"
              defaultLabelId="--- type here ---"
              Value={apiData.alias}
              handlechange={(value: string) => {
                onChangeApiData("alias", value);
              }}
            />
          </Box>
          {(formData.type === "checkbox" ||
            formData.type === "radio" ||
            formData.type === "single_select" ||
            formData.type === "multiple_select") &&
            formData.initialFieldType === "CUSTOM" && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      flex: "1 0 45%",
                    }}
                  >
                    <MediumTypography
                      className="input-label"
                      labelId={"dropdown.options"}
                      defaultLabel="Options"
                      sxProps={{ marginTop: "10px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <TextInput
                        className="text-input-field custom-text-field"
                        type="text"
                        variant="outlined"
                        inputProps={{
                          readOnly: false,
                        }}
                        labelId="common.placeHolderText"
                        defaultLabelId="--- type here ---"
                        Value={apiData.option}
                        disabled
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
        </Box>
      </Box>
      <Divider className="big-divider" />
      <Box>
        <Box className="btn-container_custom_field">
          <Box
            className="flex__ center"
            sx={{
              gap: 2,
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <CheckBox
                className="custom-checkbox"
                value={apiData.showInForm ?? false}
                onCheckBoxClick={(checked) => {
                  onChangeHandler("showInForm", checked);
                }}
                disable={apiData.isDisabled}
              />
              <MediumTypography
                labelId={"show.inform"}
                defaultLabel={"show.inform"}
                textColor="#B6C2CF"
              />
            </Box>

            <Box display={"flex"} alignItems={"center"}>
              <CheckBox
                className="custom-checkbox"
                value={apiData.isRequired ?? false}
                onCheckBoxClick={(checked) => {
                  onChangeHandler("isRequired", checked);
                }}
                disable={apiData.isDisabled}
              />
              <MediumTypography
                labelId={"textLow.Required"}
                defaultLabel="Required"
                textColor="#B6C2CF;"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateFormFields;
