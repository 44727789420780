import { FC, useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import MediumTypography from "../../../components/formlib/MediumTypography";
import CheckBox from "../../../components/formlib/CheckBox";

import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { ReactComponent as Drag } from "../../../assets/images/DragIcon.svg";

import CustomMenu from "./CustomMenu";
import { TableHeaderCellType } from "../types/Types";

interface TableHeaderProps {
  data: TableHeaderCellType[];
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  open: boolean;
  onApply: (reorderedCheckedItems: TableHeaderCellType[]) => void;
  fixedItems: string[];
}

const TableHeaderSelection: FC<TableHeaderProps> = ({
  data,
  anchorEl,
  handleClose,
  open,
  onApply,
  fixedItems,
}) => {
  const [items, setItems] = useState<TableHeaderCellType[]>([]);
  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());

  const [localItems, setLocalItems] = useState<TableHeaderCellType[]>([]);
  const [localCheckedItems, setLocalCheckedItems] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const initialItems = data.filter(
      (item) =>
        !fixedItems.includes(item.code) &&
        item.code !== "id" &&
        item.dataType !== "rich_text"
    );
    const initialCheckedItems = new Set(
      data
        .filter(
          (item) => item.code !== "id" && item?.sequence && item.sequence > 0
        )
        .map((item) => item.code)
    );
    setItems(initialItems);
    setCheckedItems(initialCheckedItems);
  }, [data]);

  useEffect(() => {
    if (open) {
      setLocalItems(items);
      setLocalCheckedItems(checkedItems);
    }
  }, [open, items, checkedItems]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(localItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setLocalItems(reorderedItems);
  };

  const handleCheckBoxClick = (key: string) => {
    if (fixedItems.includes(key)) {
      return;
    }
    setLocalCheckedItems((prev) => {
      const newCheckedItems = new Set(prev);
      if (newCheckedItems.has(key)) {
        newCheckedItems.delete(key);
      } else {
        newCheckedItems.add(key);
      }
      return newCheckedItems;
    });
  };

  const handleApply = () => {
    const accountNameItems = data.filter((item) =>
      fixedItems.includes(item.code)
    );
    const reorderedCheckedItems = [
      ...accountNameItems!,
      ...localItems.filter((item) => localCheckedItems.has(item.code)),
    ];
    onApply(reorderedCheckedItems);
    setItems(localItems);
    setCheckedItems(localCheckedItems);
    handleClose();
  };

  const fixedItemsData = data
    .filter((item) => fixedItems.includes(item.code))
    .sort((a, b) => fixedItems.indexOf(a.code) - fixedItems.indexOf(b.code)); // Sort based on desired order;

  return (
    <CustomMenu
      id="demo-customized-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginLeft: "15px",
        "& .MuiPaper-root": {
          width: "230px",
          maxWidth: 310,
          maxHeight: 550,
        },
      }}
    >
      <Box className="p-md" sx={{ position: "relative" }}>
        {fixedItemsData.map((fixedItem) => (
          <ul className="no-marker " key={fixedItem.code}>
            <Box className="flex__ p-sm" sx={{ alignItems: "center" }}>
              <CheckBox value={true} disable={true} className="p-none mr-sm" />
              <Drag />
              <MediumTypography label={fixedItem.name} className={"ml-sm"} />
            </Box>
          </ul>
        ))}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provider) => (
              <Box {...provider.droppableProps} ref={provider.innerRef}>
                {localItems.map((item, index) => (
                  <Draggable
                    key={item.code}
                    draggableId={item.code}
                    index={index}
                    isDragDisabled={!localCheckedItems.has(item.code)}
                  >
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.code}
                        className="no-marker "
                      >
                        <Box
                          className="flex__ p-sm"
                          sx={{ alignItems: "center" }}
                        >
                          <CheckBox
                            value={localCheckedItems.has(item.code)}
                            onCheckBoxClick={() =>
                              handleCheckBoxClick(item.code)
                            }
                            className="p-none mr-sm"
                          />
                          <Drag />
                          <MediumTypography
                            label={item.name}
                            className={"ml-sm"}
                          />
                        </Box>
                      </ul>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box
        className="width__100 flex__container p-md"
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          backgroundColor: "#1D2125",
        }}
      >
        <ButtonComponent
          className="btn-ticket"
          variantType="contained"
          labelId="Apply"
          defaultLabelId="Apply"
          onClick={handleApply}
          sxProps={{ textTransform: "unset" }}
        />
      </Box>
    </CustomMenu>
  );
};

export default TableHeaderSelection;
