import TicketApiClient from "../TicketApi";
import { ApiResponseHandler } from "../ApiResponseHandler";
import { responseType } from "../../pages/MasterDataManagement/types/rolesAndPermission";
import {
  RecordsFilterType,
  ListViewMetaDataType,
  ListViewRecordsType,
  UpdateStatusInputType,
  NumericOptionType,
} from "../../pages/ticketing/types/Types";

export const fetchTemplateById = async <T>(
  id: number | null,
  language: string
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `/ticketType/${id}/template?languageCode=${language}`
    )
  );
};

export const fetchTemplateByIdByCustomField = async <T>(
  id: number | null,
  language: string,
  customId: number[]
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `/ticketType/${id}/template?languageCode=${language}&customFieldIds
=${customId}`
    )
  );
};
export const fetchCustomFieldById = async <T>(
  uri: string,
  id: number,
  language: string
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `${id}/${uri}?languageCode=${language}`
    )
  );
};
export const saveCustomFieldById = async <T>(
  uri: string,
  id: number,
  language: string,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}&id=${id}`,
      param
    )
  );
};

export const deleteTypeApi = async (
  uri: string,
  id: number,
  language: string
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().delete(
      `/${id}/ticketType?languageCode=${language}`
    )
  );
};

export const saveTicketTypeById = async <T>(
  uri: string,
  language: string,
  id: number,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}&id=${id}`,
      param
    )
  );
};

export const saveTicketType = async <T>(
  uri: string,
  language: string,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}`,
      param
    )
  );
};

export const saveCustomField = async <T>(
  uri: string,
  language: string,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}`,
      param
    )
  );
};

export const saveDataTemplateById = async <T>(
  id: number | null,
  language: string,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `ticketType/${id}/template?languageCode=${language}`,
      param
    )
  );
};

export const saveDataTemplateByCustomId = async <T>(
  id: number | null,
  language: string,
  param: T,
  ids?: number[]
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `ticketType/${id}/template?languageCode=${language}&customFieldIds=${ids}`,
      param
    )
  );
};

export const fetchAllCustomFieldApi = async <T>(
  uri: string,
  language: string
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get<T>(
      `${uri}/fetchAll?languageCode=${language}`
    )
  );
};

// List View
export const fetchListViewMetaDataApi = async (
  params: RecordsFilterType
): Promise<ListViewMetaDataType> => {
  let filterString = ``;
  if (params?.filterId) {
    filterString += `&filterId=${params.filterId}`;
  } else if (params?.specialFilterId) {
    filterString += `&specialFilterId=${params.specialFilterId}`;
  }

  return ApiResponseHandler(
    await TicketApiClient.getInstance().get<ListViewMetaDataType>(
      `ticket/list/metadata?languageCode=en${filterString}`
    )
  );
};

export const listViewRecordsApi = async (
  type: "fetchData" | "export",
  params: RecordsFilterType,
  fetchRelated: boolean = false
): Promise<ListViewRecordsType> => {
  let filterString = `&page=${params.page}`;
  if (params?.filterId) {
    filterString += `&filterId=${params.filterId}`;
  } else if (params?.specialFilterId) {
    filterString += `&specialFilterId=${params.specialFilterId}`;
  }

  if (fetchRelated) {
    filterString += `&relatedIssue=true`;
  }

  const { page, filterId, specialFilterId, ...restParams } = params;

  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<
      RecordsFilterType,
      ListViewRecordsType
    >(`ticket/list/${type}?languageCode=en${filterString}`, restParams)
  );
};

export const saveTableHeadersApi = async <T1, T2>(params: T1) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T1, T2>(
      `customList/save?languageCode=en`,
      params
    )
  );
};

export const saveFilterApi = async <T1, T2>(
  params: T1,
  id?: number,
  subGroupId?: number
) => {
  let filterString = "";
  if (id) {
    filterString += `&filterId=${id}`;
  }
  if (subGroupId) {
    filterString += `&subGroupId=${subGroupId}`;
  }
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T1, T2>(
      `ticket/filter/save?languageCode=en${filterString}`,
      params
    )
  );
};

export const deleteFilterDataApi = async (uri: string, id: number) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().delete(
      `ticket/${uri}/${id}/delete?languageCode=en`
    )
  );
};

export const fetchOptionsApi = async <T>(
  field: string,
  selectedIds1: number[],
  selectedIds2?: number[]
): Promise<T> => {
  let apiUrl = "";

  if (field === "ASSETCATEGORY") {
    apiUrl = `ticket/fetchByAssetGrpOrCat?type=ASSETCATEGORY&assetGrpIds=${selectedIds1}`;
  } else if (field === "ASSET") {
    apiUrl = `ticket/fetchByAssetGrpOrCat?type=ASSET&assetGrpIds=${selectedIds1}&assetCatIds=${selectedIds2}`;
  }

  return ApiResponseHandler(await TicketApiClient.getInstance().get<T>(apiUrl));
};

export const updateStatusApi = async (payloadData: UpdateStatusInputType) => {
  const remarksText = payloadData.remarks
    ? `&remarks=${payloadData.remarks}`
    : "";
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<
      UpdateStatusInputType,
      UpdateStatusInputType
    >(
      `ticket/${payloadData.ticketId}/stateTransition?ticketUniqueId=${payloadData.ticketUniqueId}&transitionId=${payloadData.transitionId}${remarksText}`
    )
  );
};

export const createDropdownOptionApi = async (
  fieldType: string,
  fieldName: string,
  option: string
): Promise<NumericOptionType[]> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post(
      `autoSaveFields?field=${fieldType}&${fieldName}=${option}`
    )
  );
};
