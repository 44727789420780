import { Box, Dialog, DialogContent } from "@mui/material";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useState, FC } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { ReactComponent as CommentIcon } from "../../../assets/images/CommentIcon.svg";
import { ReactComponent as LocTionIcon } from "../../../assets/images/LocationIcon.svg";
import { ReactComponent as StopWatchIcon } from "../../../assets/images/StopwatchIcon.svg";
import MediumTypography from "../MediumTypography";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { ReactComponent as Close } from "../../../assets/images/_CrossIcon_.svg";
import dayjs from "dayjs";

interface GalleryModalProps {
  open: boolean;
  handleClose: () => void;
  images: string[];
  metadata: {
    comments: string;
    geoStamp: { latitude: string; longitude: string };
    createdDateTime: string;
    category?: string;
  }[];
  initialSlide: number;
  titlesRequired?: boolean;
}

const GalleryPreviewModal: FC<GalleryModalProps> = ({
  open,
  handleClose,
  images,
  metadata,
  initialSlide = 0,
  titlesRequired = true,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent
        sx={{
          padding: "0px",
          background: "rgba(18, 18, 18, 1)",
        }}
      >
        <Box sx={{ height: "500px", position: "relative" }}>
          <Swiper
            loop={true}
            spaceBetween={5}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
            initialSlide={initialSlide}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <Close
                  className="cursor__pointer"
                  onClick={() => {
                    handleClose();
                    setThumbsSwiper(null);
                  }}
                  style={{ position: "absolute", right: "6px", top: "6px" }}
                />
                <img className="mainSliderImage" alt="" src={image} />

                <Box
                  sx={{
                    marginTop: !titlesRequired ? "20px" : undefined,
                    padding: "10px",
                    background: "rgba(29, 33, 37, 1)",
                    color: "white",
                  }}
                >
                  {metadata[index].category !== undefined &&
                    metadata[index].category !== "" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <MediumTypography
                          labelId={metadata[index].category}
                          sxProps={{
                            marginLeft: "8px",
                            fontSize: "12px",
                            color: "#C1C3C4",
                          }}
                        />
                      </Box>
                    )}
                  {metadata[index].comments !== undefined &&
                    metadata[index].comments !== "" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <CommentIcon />
                        <MediumTypography
                          label={metadata[index].comments}
                          sxProps={{
                            marginLeft: "8px",
                            fontSize: "12px",
                            color: "#C1C3C4",
                          }}
                        />
                      </Box>
                    )}

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LocTionIcon />
                    <MediumTypography
                      label={`${metadata[index].geoStamp.latitude}  ${metadata[index].geoStamp.longitude}`}
                      sxProps={{
                        marginLeft: "8px",
                        fontSize: "12px",
                        color: "#C1C3C4",
                      }}
                    />
                    <Box sx={{ color: "#C1C3C4", margin: "0 8px" }}>|</Box>
                    <StopWatchIcon />
                    <MediumTypography
                      label={`${dayjs(metadata[index].createdDateTime).format(
                        "DD MMM YYYY HH:mm:ss"
                      )}`}
                      sxProps={{
                        marginLeft: "8px",
                        fontSize: "12px",
                        color: "#C1C3C4",
                      }}
                    />
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box sx={{ background: "rgba(18, 18, 18, 1)" }}>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper mt-lg mb-lg"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} className="mainBoxthumbImages">
                <img className="thumbImages" alt="" src={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GalleryPreviewModal;
