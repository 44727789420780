import { useContext, useState } from "react";
import WorkOrderGallery from "../../workOrderDetailScreens/WorkOrderGallery";
import { ConversationAttachmentType, FileDataType } from "../types/Types";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { saveConversation } from "../../../api/ticketing/TicketDetails";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { hasTicketEditPermission } from "../helpers/helper";
import { Box } from "@mui/material";
import FileUpload from "./UploadFileDragDrop";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { getProcessedFiles } from "../../../utils/commonFunctions";
import MediumTypography from "../../../components/formlib/MediumTypography";

interface AttachmentDataType {
  attachments: FileDataType[];
}

const Gallery = ({
  attachment,
  ticketId,
  afterSendCallback = () => {},
}: {
  attachment: ConversationAttachmentType[];
  afterSendCallback?: () => void;
  ticketId: number;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

  const filterImageFiles = (files: ConversationAttachmentType[]) => {
    return files.filter((file) => {
      const extension = file.fileName.split(".").pop()?.toLowerCase() ?? "";
      return imageExtensions.includes(extension);
    });
  };

  const formattedAttachments = filterImageFiles(attachment).map((file) => ({
    comments: "",
    createdDateTime: file.createdDateTime,
    geoStamp: {
      latitude: "",
      longitude: "",
    },
    document: file.fileUrl,
  }));

  const addAttachment = (params: AttachmentDataType) => {
    toggleLoader(true);

    const url = `ticket/${ticketId}/addConversation?messageType=ATTACHMENT`;

    saveConversation(url, params)
      .then((response) => {
        setFiles([]);
        afterSendCallback();
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {formattedAttachments?.length > 0 && (
          <WorkOrderGallery
            dumgImage={formattedAttachments ?? []}
            beforeImage={[]}
            afterImage={[]}
            titlesRequired={false}
          />
        )}
        {hasTicketEditPermission() && (
          <Box className="common-flex-column" mt={"10px"}>
            <FileUpload
              viewType="BOX"
              width="74px"
              height="74px"
              files={files}
              onFilesChange={(files) => {
                setFiles(files);
              }}
            />
            {files.length > 0 && (
              <ButtonComponent
                className="btn-primary btn-ticket"
                variantType="contained"
                defaultLabelId={"Upload"}
                labelId={"Rca.Upload"}
                onClick={async () => {
                  try {
                    const processedFiles = await getProcessedFiles(files);
                    addAttachment({
                      attachments: processedFiles,
                    });
                  } catch (error) {
                    console.error("Unable to process files:", error);
                    setErrorDesc("Unable to process files");
                    setOpenErrorModal(true);
                  }
                }}
              />
            )}
          </Box>
        )}
        {!hasTicketEditPermission() && formattedAttachments?.length === 0 && (
          <Box
            sx={{
              bgcolor: "rgb(34, 39, 43)",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            <MediumTypography
              labelId={"No attachments found"}
              defaultLabel={"No attachments found"}
              textColor="#9fadbc80;"
            />
          </Box>
        )}
      </Box>
    </>
  );
};
export default Gallery;
