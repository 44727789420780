import { Cancel, AddCircle } from "@mui/icons-material";
import { Box, Divider, Grid, Tooltip } from "@mui/material";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import MediumTypography from "../../../components/formlib/MediumTypography";
import SearchBox from "../../../components/formlib/SearchBox";

import { Type } from "../types/workOrderTypes";
import { FC, useState } from "react";

interface SkillsComponentProps {
  skillsList: Type[];
  selectedSkillIds: number[];
  isSkillCheckRequired: boolean;
  handleApplySkills: () => void;
  handleAddSkills: (id: number) => void;
  handleRemoveSkills: (id: number) => void;
}

const SkillsComponent: FC<SkillsComponentProps> = ({
  skillsList,
  selectedSkillIds,
  isSkillCheckRequired,
  handleAddSkills,
  handleRemoveSkills,
  handleApplySkills,
}) => {
  const [assignedSkillsSearch, setAssignedSkillsSearch] = useState("");
  const [availableSkillsSearch, setAvailableSkillsSearch] = useState("");

  const filterSkills = (skills: Type[], searchValue: string) => {
    return skills.filter(
      (skill) =>
        skill.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        skill.id.toString().includes(searchValue)
    );
  };
  return (
    <Box className="p-md" sx={{ width: "100%" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid #545558",
            }}
          />
        </Box>
      </Grid>
      <Grid
        container
        sx={{ backgroundColor: " #323337", maxHeight: "500px" }}
        className="p-md"
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          sx={{ borderRight: "1px solid #5C7E9F" }}
          className="pr-md"
        >
          <MediumTypography
            labelId="W/O.assignedSkills"
            defaultLabel="Assigned Skills"
            textColor="#9FADBC"
            className="mb-md"
          />
          <SearchBox
            labelId="defaultSearch"
            defaultlabel="Search"
            backgroundColor="#22272B"
            onChange={(value) => setAssignedSkillsSearch(value)} // Update search value
          />
          <Box sx={{ maxHeight: "380px" }} className="mt-md overflow_Y">
            {skillsList &&
              filterSkills(
                skillsList.filter((skill) =>
                  selectedSkillIds.includes(skill.id)
                ),
                assignedSkillsSearch
              ).map((skills) => (
                <Box
                  key={skills.id}
                  className={
                    "flex__justify__space-between mb-sm pt-sm pb-sm pl-md pr-md"
                  }
                  sx={{
                    backgroundColor: "#22272B",
                    borderRadius: "4px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      labelId="W/O.Sr.No"
                      defaultLabel="Sr.No"
                      fontSize="12px"
                      textColor="rgba(255, 255, 255, 0.64)"
                    />
                    <MediumTypography label={skills.id} />
                  </Box>
                  <Box sx={{ width: "350px" }} className="ml-md">
                    <MediumTypography
                      labelId="skillNameText"
                      defaultLabel="Name"
                      textColor="rgba(255, 255, 255, 0.64)"
                      fontSize="12px"
                    />
                    <Tooltip title={`${skills.name}`} followCursor arrow={true}>
                      <Box>
                        <MediumTypography
                          label={skills.name}
                          className="ellipsis"
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box className="flex__ align__items__center">
                    <Cancel
                      sx={{
                        cursor: "pointer",
                        width: "20px",
                        color: "#ffff",
                      }}
                      onClick={() => handleRemoveSkills(skills.id)}
                    />
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>

        {/* Available Skills */}
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="pl-md">
          <MediumTypography
            labelId="W/O.availableSkills"
            defaultLabel="Available Skills"
            textColor="#9FADBC"
            className="mb-md"
          />
          <SearchBox
            labelId="defaultSearch"
            defaultlabel="Search"
            backgroundColor="#22272B"
            onChange={(value) => setAvailableSkillsSearch(value)} // Update search value
          />
          <Box sx={{ maxHeight: "380px" }} className="mt-md overflow_Y">
            {skillsList &&
              filterSkills(
                skillsList.filter(
                  (skill) => !selectedSkillIds.includes(skill.id)
                ),
                availableSkillsSearch
              ).map((skills) => (
                <Box
                  key={skills.id}
                  className={
                    "flex__justify__space-between mb-sm pt-sm pb-sm pl-md pr-md"
                  }
                  sx={{
                    backgroundColor: "#22272B",
                    borderRadius: "4px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      labelId="W/O.Sr.No"
                      defaultLabel="Sr.No"
                      fontSize="12px"
                      textColor="rgba(255, 255, 255, 0.64)"
                    />
                    <MediumTypography label={skills.id} />
                  </Box>
                  <Box sx={{ width: "350px" }} className="ml-md">
                    <MediumTypography
                      labelId="skillNameText"
                      defaultLabel="Name"
                      textColor="rgba(255, 255, 255, 0.64)"
                      fontSize="12px"
                    />
                    <Tooltip title={`${skills.name}`} followCursor arrow={true}>
                      <Box>
                        <MediumTypography
                          label={skills.name}
                          className="ellipsis"
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box className="flex__ align__items__center">
                    <AddCircle
                      sx={{
                        cursor: "pointer",
                        width: "20px",
                        color: "#ffff",
                      }}
                      onClick={() => handleAddSkills(skills.id)}
                    />
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
      <Box className="mt-md  flex__ width__100 justifyContent-FlexEnd">
        <ButtonComponent
          className={
            isSkillCheckRequired && !(selectedSkillIds.length > 0)
              ? "btn-primary btn-disabled "
              : "btn-primary btn-submit"
          }
          variantType="contained"
          defaultLabelId={"Apply"}
          labelId={"Apply"}
          disabled={isSkillCheckRequired && !(selectedSkillIds.length > 0)}
          onClick={async () => {
            if (isSkillCheckRequired && selectedSkillIds.length > 0) {
              handleApplySkills();
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default SkillsComponent;
