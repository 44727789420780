import {
  create,
  ApisauceInstance,
  ApiResponse,
  ApisauceConfig,
} from "apisauce";
import { WORK_ORDER_BASE_URL, USER_BASE_URL } from "./Constant";
import { RefreshTokenResponse } from "../utils/type";
import { EventBusProvider } from "../context/EventBusProvider";

export default class WorkOrderConfig {
  private static _instance: WorkOrderConfig;

  private api: ApisauceInstance;

  constructor() {
    this.api = create({
      baseURL: WORK_ORDER_BASE_URL,
    });

    this.api.addRequestTransform((request) => {
      const requestConfig = request;
      // if (requestConfig && requestConfig.headers) {
      //   requestConfig.headers = {
      //     ...requestConfig.headers,
      //     appSource: `TCS`,
      //     Authorization: `Bearer ${localStorage?.getItem("accessToken")}`,
      //     userId: `${localStorage?.getItem("userId")}`,
      //     Accept-Language:'en'
      //   };
      // }
      if (request && request.headers) {
        request.headers["appSource"] = "TCS";
        request.headers["Authorization"] = `Bearer ${localStorage?.getItem(
          "accessToken"
        )}`;
        request.headers["userId"] = Number(localStorage.getItem("id"));
        request.headers["Accept-Language"] =
          localStorage.getItem("language") ?? "en";
        request.headers["Accept-Timezone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      return requestConfig;
    });

    this.api.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        if (error.response && error.response.status === 403) {
          const api = create({
            baseURL: USER_BASE_URL,
          });
          const response = await api.post(`login`, {
            refreshToken: localStorage.getItem("refreshToken"),
            grantType: "refresh_token",
          });
          if (response.ok) {
            const data = response.data as RefreshTokenResponse;
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("refreshToken", data.refreshToken);
            originalConfig.headers.Authorization = data.accessToken;
            return this.api.axiosInstance.request(originalConfig);
          } else if (response.status && response.status === 403) {
            localStorage.clear();
            EventBusProvider.publish("logout");
            return null;
          }
        }
        return Promise.reject(error);
      }
    );
  }

  public static getInstance(): WorkOrderConfig {
    if (WorkOrderConfig._instance == null) {
      WorkOrderConfig._instance = new WorkOrderConfig();
    }
    return this._instance;
  }

  async post<T, U>(
    url: string,
    payload?: T,
    headers?: Record<string, string | null>
  ): Promise<ApiResponse<T, U>> {
    return this.api.post<T, U>(url, payload);
  }

  async get<T>(
    url: string,
    params?: object,
    config?: ApisauceConfig
  ): Promise<ApiResponse<T>> {
    return this.api.get<T>(url, params, config);
  }

  async put<T, U>(url: string, payload?: T): Promise<ApiResponse<T, U>> {
    return this.api.put<T, U>(url, payload);
  }

  async delete<T>(url: string): Promise<ApiResponse<T, T>> {
    return this.api.delete<T>(url);
  }

  async patch<T, U>(url: string, payload?: T): Promise<ApiResponse<T, U>> {
    return this.api.patch<T, U>(url, payload);
  }
}
