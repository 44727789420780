import { Box, Grid, IconButton } from "@mui/material";

import { useEffect, useRef, useState, useContext } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import TextInput from "../../../components/formlib/TextInput";
import FileUpload from "../components/UploadFileDragDrop";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import { SupportType, SeverityOptionType } from "../types/CreateTicket";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import {
  fetchMetaDataSupport,
  saveTicket,
} from "../../../api/createTicket/CreateTicketAPI";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import RichTextEditor from "../../workOrderDetailScreens/correctiveMaintenance/RichTextEditor";
import { FileDataType } from "../types/Types";
import { getOptions, getProcessedFiles } from "../../../utils/commonFunctions";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
let url = "ticket/save";
let initialData: SupportType = {
  primaryContactName: "",
  reporterEmail: "",
  summary: "",
  severity: "",
  description: "",
  attachments: [],
  severityOption: [],
  channel: 74,
};

interface ModalProps {
  handleCloseForm: () => void;
  open: boolean;
}

interface SupportMetaData {
  name: string;
  email: string;
  severityList: {
    id: number;
    name: string;
    typeList: [];
    isSelected: boolean;
  }[];
}
const newFiles: FileDataType[] = [];
const SupportForm = ({ handleCloseForm, open }: ModalProps) => {
  const [apiData, setApiData] = useState(initialData);
  const [userName, setUserName] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorDesc, setErrorDesc] = useState("");
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [createMessage, setCreateMessage] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const formikRef = useRef<FormikProps<SupportType>>(null);

  useEffect(() => {
    fetchmetadata();
  }, []);
  const fetchmetadata = () => {
    fetchMetaDataSupport<SupportMetaData>()
      .then((response: SupportMetaData) => {
        if (response) {
          setUserName(response.name);
          setApiData({
            ...apiData,
            primaryContactName: response.name,
            reporterEmail: response.email,
            severityOption: response.severityList,
          });
        }
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const validationSchema = Yup.object().shape({
    summary: Yup.string().required("validation.required"),
    description: Yup.string().required("validation.required"),
    severity: Yup.string().required("validation.required"),
  });

  const isArrayEmpty = (arr: any) => {
    return (
      arr.length === 0 || arr.every((obj: any) => Object.keys(obj).length === 0)
    );
  };
  const saveCreateTicket = (data: any) => {
    if (isArrayEmpty(data.attachments)) {
      delete data.attachments;
    }

    if (data?.severityOption) {
      delete data.severityOption;
    }

    // delete data?.severityOption;

    saveTicket(url, data)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          const TicketMessage = `Ticket created successfully! Reference: ${response.ticketUniqueId}.`;
          setCreateMessage(TicketMessage);
          setConfirmation(true);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  return (
    <>
      {confirmation && (
        <ModalPopup
          descriptionText={createMessage}
          descriptionMessage={createMessage}
          open={confirmation}
          handleClose={() => {
            setConfirmation(false);
            handleCloseForm();
          }}
          onPositiveClick={() => {
            setConfirmation(false);
            handleCloseForm();
          }}
          positiveButtonLabel="OK"
          positiveButtonLabelId="OkText"
          hideButton={true}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const processedFiles = await getProcessedFiles(values.attachments);
            saveCreateTicket({ ...values, attachments: processedFiles });
          } catch (error) {
            console.error("Unable to process files:", error);
            setErrorDesc("Unable to process files");
            setOpenErrorModal(true);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Box
              sx={{
                position: "fixed",
                bottom: 20,
                right: 20,
                zIndex: 1000,
                maxWidth: "400px",
                // maxHeight: "500px",
                background: "linear-gradient(to bottom, #175FFA, #22272b)",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <Box
                style={{
                  padding: "20px 0px",
                }}
              >
                <Box>
                  <MediumTypography
                    labelId={`Hello ${userName} 👋`}
                    defaultLabel={`Hello ${userName} 👋`}
                    textColor="#CCC"
                    fontSize="16px"
                    fontWeight={600}
                  />

                  <MediumTypography
                    labelId={"how.can"}
                    textColor="#F8F8F8"
                    fontSize="24px"
                    fontWeight={600}
                  />
                </Box>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseForm}
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                style={{
                  backgroundColor: "#22272b",
                  borderRadius: "10px",
                  padding: "16px",
                }}
              >
                <Box
                  sx={{
                    height: "400px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box>
                        <Box mt={1} mb={1}>
                          <MediumTypography
                            labelId={"your.name"}
                            defaultLabel="Your Name"
                          />
                        </Box>

                        <TextInput
                          disabled
                          className="text-input-field"
                          type="text"
                          variant="outlined"
                          inputProps={{
                            readOnly: false,
                          }}
                          labelId="common.placeHolderText"
                          defaultLabelId="--- type here ---"
                          Value={values.primaryContactName}
                          handlechange={(value: string) => {
                            setFieldValue("primaryContactName", value);
                          }}
                        />
                        <FormikErrorComponent
                          errors={errors}
                          touched={touched}
                          field="name"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mt={1} mb={1}>
                          <MediumTypography
                            labelId={"email.id"}
                            defaultLabel="Email ID"
                          />
                        </Box>

                        <TextInput
                          disabled
                          className="text-input-field"
                          type="text"
                          variant="outlined"
                          inputProps={{
                            readOnly: false,
                          }}
                          labelId="common.placeHolderText"
                          defaultLabelId="--- type here ---"
                          Value={values.reporterEmail}
                          handlechange={(value: string) => {
                            setFieldValue("reporterEmail", value);
                          }}
                        />
                        <FormikErrorComponent
                          errors={errors}
                          touched={touched}
                          field="reporterEmail"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mt={1} mb={1}>
                          <MediumTypography
                            labelId={"form.subject"}
                            defaultLabel="Subject"
                          />
                        </Box>

                        <TextInput
                          className="text-input-field"
                          type="text"
                          variant="outlined"
                          inputProps={{
                            readOnly: false,
                          }}
                          labelId="common.placeHolderText"
                          defaultLabelId="--- type here ---"
                          Value={values.summary}
                          handlechange={(value: string) => {
                            setFieldValue("summary", value);
                          }}
                        />
                        <FormikErrorComponent
                          errors={errors}
                          touched={touched}
                          field="summary"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mt={1} mb={1}>
                          <MediumTypography
                            labelId={"form.Description"}
                            defaultLabel="Description"
                          />
                        </Box>

                        <Box className="ticket-rich-text-box">
                          <RichTextEditor
                            labelId="common.placeHolderText"
                            defaultLabelId="--- type here ---"
                            data={values.description ?? ""}
                            onChangeCallBack={(value) => {
                              setFieldValue("description", value);
                            }}
                            readOnly={false}
                          />
                        </Box>

                        <FormikErrorComponent
                          errors={errors}
                          touched={touched}
                          field="description"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mt={1} mb={1}>
                          <MediumTypography
                            labelId={"form.Severity"}
                            defaultLabel="Severity"
                          />
                        </Box>

                        <CustomDropdown
                          disableCreate={true}
                          showIcon={true}
                          options={getOptions(
                            (values.severityOption as SeverityOptionType[]) ??
                              []
                          )}
                          selectedValue={values.severity ?? undefined}
                          onSelectedValueChange={(value) => {
                            setFieldValue("severity", value);
                          }}
                        />
                        <FormikErrorComponent
                          errors={errors}
                          touched={touched}
                          field="severity"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={1} mb={1}>
                        <MediumTypography
                          labelId={"form.Attachment"}
                          defaultLabel="Attachment"
                        />
                      </Box>

                      <FileUpload
                        files={values.attachments}
                        onFilesChange={(files) => {
                          setFieldValue("attachments", files);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={1} className="flex__ justifyContent-FlexEnd">
                        <ButtonComponent
                          className="btn-primary btn-ticket"
                          variantType="contained"
                          defaultLabelId={"Submit"}
                          labelId={"btn.submit"}
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={!dirty}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default SupportForm;
