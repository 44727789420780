import { Box, Divider, Grid, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ListCustomField } from "../types/formBuilder";
import SearchBox from "../../../components/formlib/SearchBox";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { ReactComponent as Add } from "../../../assets/images/open.svg";
import { ReactComponent as NotAvialableIcon } from "../../../assets/images/woNotFound.svg";
import DisplayFieldIcon from "../components/DisplayFieldIcon";
import MediumTypography from "../../../components/formlib/MediumTypography";
import CreateFieldCustom from "./CreateFieldCustom";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import AddEditCustomFieldsForm from "./AddEditCustomFieldsForm";
import { fetchAllCustomFieldApi } from "../../../api/ticketing/TicketingAPI";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ButtonComponent from "../../../components/formlib/ButtonComponent";

const apiUri = "customField";
const CustomFieldScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const availableHeight = useAvailableHeight(300);
  const availableWidth = useAvailableWidth();
  const [customFieldsList, setCustomFieldsList] = useState<ListCustomField[]>();
  const [openCloseSVG, setOpenCloseSVG] = useState<boolean>(false);
  const [chosenCustomFieldType, setCustomFieldsType] = useState<string>("");
  const [chosenCustomFieldName, setCustomFieldsName] = useState<string>("");
  const [isCustomNew, setIsCustomNew] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isClickedId, setIsClickedId] = useState<number>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [originalFields, setOriginalFields] = useState<ListCustomField[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  interface allCustomFieldType {
    customFields: ListCustomField[];
    lastUpdated: string;
  }
  useEffect(() => {
    getAllCustomFields();
  }, []);

  const getAllCustomFields = (language: string = "en") => {
    toggleLoader(true);
    fetchAllCustomFieldApi<allCustomFieldType>(apiUri, language)
      .then((response: allCustomFieldType) => {
        toggleLoader(false);
        if (response) {
          setIsVisible(false);
          setIsCustomNew(false);
          setCustomFieldsList(response.customFields);
          setOriginalFields(response.customFields);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const handleOpenFeilds = (type: string, id: number) => {
    if (isClickedId === id) {
      setOpenCloseSVG(!openCloseSVG);
      setIsClickedId(id);
      setIsVisible(!isVisible);
    } else {
      setOpenCloseSVG(true);
      setIsClickedId(id);
      setIsVisible(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);

    if (value === "") {
      setCustomFieldsList(originalFields);
    } else {
      const lowercasedQuery = value.toLowerCase();

      const searchOption = customFieldsList?.filter((item) =>
        item?.name.toLowerCase().includes(lowercasedQuery)
      );
      if (searchOption?.length === 0) {
        setCustomFieldsList([]);
      } else {
        setCustomFieldsList(searchOption);
      }
    }
  };

  const handleAddCustomField = (type: string, name: string) => {
    setCustomFieldsType(type);
    setCustomFieldsName(name);
    setIsCustomNew(true);
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box
        className="flex__"
        sx={{
          position: "relative",
          justifyContent: "center",
          flexGrow: 1,
          height: availableHeight,
          width: availableWidth - 420,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Box
              sx={{
                padding: "5px",
              }}
            >
              <Box className="flex__" sx={{ flexDirection: "column" }}>
                <Box>
                  <MediumTypography
                    defaultLabel={"Custom Fields"}
                    labelId={"custom.title"}
                    fontSize="20px"
                    fontWeight={700}
                  />
                </Box>

                {customFieldsList && customFieldsList?.length >= 0 ? (
                  <Box mt={2} mb={2}>
                    <SearchBox
                      labelId="defaultSearch"
                      defaultlabel="Search"
                      backgroundColor="#22272B"
                      sxProps={{
                        width: "100%",
                        minWidth: "75px",
                      }}
                      value={searchValue}
                      onChange={handleSearchChange}
                      cancelRequired={true}
                    />
                  </Box>
                ) : (
                  !isCustomNew &&
                  customFieldsList &&
                  customFieldsList?.length <= 0 && (
                    <Box
                      className="woNotAvialable"
                      sx={{ textAlign: "center" }}
                    >
                      <NotAvialableIcon width={`50px`} height={`50px`} />
                      <MediumTypography
                        className="noWOTitle"
                        labelId="no.customfields"
                        defaultLabel="No fields available"
                      />
                    </Box>
                  )
                )}
              </Box>

              <Box
                className="scroll-on-hover"
                sx={{
                  height: availableHeight,
                  overflowY: "auto",
                }}
              >
                {isCustomNew && (
                  <Box className="cutsom_list_main_box" mb={2}>
                    <Box display="flex" justifyContent={"space-between"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                          // marginLeft: 2,
                        }}
                      >
                        <Box>
                          <MediumTypography
                            labelId={`Create a ${chosenCustomFieldName} field`}
                            fontSize="16px"
                            fontWeight={500}
                          />
                        </Box>
                      </Box>
                      <Box
                        className="static_feild_item"
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          marginLeft: "auto",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setIsVisible(false);
                            setIsCustomNew(false);
                            return {};
                          }}
                        >
                          <CloseIcon
                            sx={{
                              color: "#fff",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box>
                      <Divider className="big-divider" />
                      <AddEditCustomFieldsForm
                        isNew={true}
                        selectedFieldName={chosenCustomFieldName}
                        selectedFieldType={chosenCustomFieldType}
                        handleCancel={() => {
                          setIsVisible(false);
                          setIsCustomNew(false);
                          return {};
                        }}
                        callGetApi={getAllCustomFields}
                      />
                    </Box>
                  </Box>
                )}
                {customFieldsList?.map((field, i) => (
                  <Box className="static_feild_item" key={field.id}>
                    <Box className="cutsom_list_main_box">
                      <Box
                        display="flex"
                        justifyContent={"space-between"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleOpenFeilds(field.type, field.id)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            // marginLeft: 2,
                          }}
                        >
                          {/* <Box sx={{ width: "24px", height: "24px" }}>
                          <DragIcon />
                        </Box> */}

                          <Box
                            sx={{
                              width: "32px",
                              height: "32px",
                              backgroundColor: "#B6C2CF14",
                              borderRadius: "4px",
                            }}
                          >
                            <DisplayFieldIcon type={field.type} />
                          </Box>
                          <Box>
                            <MediumTypography labelId={field.name} />
                          </Box>
                        </Box>
                        <Box
                          className="static_feild_item"
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            marginLeft: "auto",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              handleOpenFeilds(field.type, field.id)
                            }
                          >
                            {isClickedId === field.id && openCloseSVG ? (
                              <Add />
                            ) : (
                              <Close />
                            )}
                          </IconButton>
                        </Box>
                      </Box>

                      {isVisible && isClickedId === field.id && (
                        <Box>
                          <Divider className="big-divider" />
                          <AddEditCustomFieldsForm
                            isNew={false}
                            selectedFieldType={field.type}
                            fieldId={field.id}
                            handleCancel={() => {
                              setIsVisible(false);
                              setIsCustomNew(false);
                              return {};
                            }}
                            callGetApi={getAllCustomFields}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
                {customFieldsList && customFieldsList?.length <= 0 && (
                  <Box className="no_custom_field" sx={{}}>
                    <MediumTypography
                      labelId={"custom.noCustomFields"}
                      fontSize="14px"
                      fontWeight={500}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                height: availableHeight + 100,
                backgroundColor: "#1D2125",
                p: "16px",
              }}
            >
              <Box mb={2}>
                <MediumTypography
                  defaultLabel={"Create a Field"}
                  labelId={"custom.Createtitle"}
                  fontSize="16px"
                  fontWeight={700}
                  sxProps={{ mb: "5px" }}
                />
                {/* <MediumTypography
                    defaultLabel={updatedDate}
                    labelId={updatedDate}
                    textColor="#626F86"
                  /> */}
                <MediumTypography
                  defaultLabel="Click on a field type to create a custom field."
                  labelId={"custom.titleDescription"}
                  fontSize="12px"
                  fontWeight={500}
                  textColor="#626F86"
                />
              </Box>
              <Box
                sx={{
                  height: availableHeight - 20,
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Grid container spacing={1}>
                  <CreateFieldCustom onAdd={handleAddCustomField} />
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 35,
          top: "auto",
          bottom: 24,
        }}
      >
        <ButtonComponent
          className="btn-primary btn-ticket"
          variantType="contained"
          labelId={"go.formbuilder"}
          onClick={() => {
            navigate("/master-data-management", {
              state: {
                pageKey: "TicketFormBuilder",
                toggle: location.state.toggle,
                id: location.state.id,
                numbers: location.state.numbers,
              },
            });
          }}
        />
      </Box>
    </>
  );
};

export default CustomFieldScreen;
