import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type NullableStringOrBoolean = string | boolean | null;

interface Radioprops {
  Radioformlabel?: string;
  labelid?: string;
  defaultlabelid?: string;
  value?: NullableStringOrBoolean;
  valueA?: NullableStringOrBoolean;
  valueB?: NullableStringOrBoolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  labelidA?: string;
  defaultlabelidA?: string;
  RadiobuttonlabelA?: string;
  RadiobuttonlabelB?: string;
  labelidB?: string;
  defaultlabelidB?: string;
  disable?: boolean;
  width?: string;
}
export default function RadioComponent(props: Radioprops) {
  const {
    labelid,
    defaultlabelid,
    value,
    valueA,
    valueB,
    handleChange,
    name,
    labelidA,
    defaultlabelidA,
    labelidB,
    defaultlabelidB,
    disable,
    width,
  } = props;
  return (
    <FormControl>
      {labelid && (
        <Typography
          sx={{
            fontFamily: "Lato-Regular",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "17px",
            paddingBottom: "10px",
          }}
        >
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        </Typography>
      )}
      <RadioGroup
        value={value}
        onChange={handleChange}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
      >
        <FormControlLabel
          sx={{
            width: width ? width : "100px",
            height: "36px",
            borderRadius: "2px",
            border: "1px solid rgba(166, 197, 226, 0.16)",
          }}
          value={valueA ? valueA : true}
          control={
            <Radio
              sx={{
                "&.Mui-checked": { color: "#165FFA" },
                height: "10px",
                borderRadius: "10px",
                color: "#FFFFFF",
                boxShadow: "none",
              }}
              disabled={disable}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                color: "#FFFFFF",
              }}
            >
              <FormattedMessage
                id={labelidA}
                defaultMessage={defaultlabelidA}
              />
            </Typography>
          }
        />
        <FormControlLabel
          value={valueB ? valueB : false}
          sx={{
            width: width ? width : "100px",
            height: "36px",
            borderRadius: "2px",
            border: "1px solid rgba(166, 197, 226, 0.16)",
          }}
          control={
            <Radio
              sx={{
                "&.Mui-checked": { color: "#165FFA" },
                height: "24px",
                borderRadius: "14px",
                color: "#97A6A5",
                boxShadow: "none",
              }}
              disabled={disable}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                color: "#FFFFFF",
              }}
            >
              <FormattedMessage
                id={labelidB}
                defaultMessage={defaultlabelidB}
              />
            </Typography>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
