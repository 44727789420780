import { isCustomError } from "../api/ApiResponseHandler";
import { ApiErrorResponse } from "../api/UserApi/User";
import { OptionType } from "../components/formlib/CustomDropdown";
import { FileDataType } from "../pages/ticketing/types/Types";

export const getOptions = (
  data: any[],
  nameKey = "name",
  idKey = "id"
): OptionType[] => {
  if (data === undefined || data === null || !Array.isArray(data)) return [];
  return data.map((item) => ({
    ...item,
    id: item[idKey].toString(),
    name: item[nameKey],
  }));
};

export const convertToNumber = (value: any): number => {
  return value === undefined || value === null || value === ""
    ? 0
    : Number(value);
};
export const convertToNumberOrNull = (value: any): number | null => {
  const numberValue = convertToNumber(value);
  return numberValue === 0 ? null : numberValue;
};

export const convertToNumberArray = (value: any): number[] => {
  return value === undefined ||
    value === null ||
    value === "" ||
    !Array.isArray(value)
    ? []
    : value.map((item) => Number(item));
};

export const handleError = (
  error: any, // Error object
  setErrorDesc: (desc: string) => void // Function to set the error description
) => {
  if (isCustomError(error)) {
    const apiError = error as ApiErrorResponse;
    setErrorDesc(apiError.issue[0]?.diagnostics);
  } else {
    error?.id ? setErrorDesc(error.id) : setErrorDesc("errors.somethingWrong");
  }
};

export function splitBySelectionStatus(items: any) {
  const selectedItems = items.filter((item: any) => item.isSelected === true);
  const unselectedItems = items.filter(
    (item: any) => item.isSelected === false
  );

  return {
    selected: selectedItems,
    unselected: unselectedItems,
  };
}

export function convertToRGBA(
  color: string | undefined,
  alpha: number,
  fallbackColor = "#25BBFA"
) {
  if (!color) return fallbackColor;
  if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/i.test(color)) {
    // Convert hex to RGB
    const hex =
      color.length === 4
        ? color
            .slice(1)
            .split("")
            .map((c) => c + c)
            .join("")
        : color.slice(1);
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return color; // If not hex, assume it's already in rgba/rgb format
}

export const getProcessedFiles = (files: File[]): Promise<FileDataType[]> => {
  return new Promise((resolve, reject) => {
    if (files.length === 0) {
      resolve([]); // Resolve immediately if the files array is empty
      return;
    }

    const newFiles: FileDataType[] = [];
    let filesProcessed = 0;

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64StringWithPrefix = reader.result as string;
        const base64String = base64StringWithPrefix.split(",")[1];
        const extension = file.name.split(".").pop() ?? "";

        const fileObj = {
          document: base64String,
          fileName: file.name,
          extension: extension,
        };

        newFiles.push(fileObj);
        filesProcessed += 1;

        // Resolve the promise after all files are processed
        if (filesProcessed === files.length) {
          resolve(newFiles);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  });
};

export function arraysAreEqual(arr1: string[], arr2: string[]) {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check if all elements are equal
  for (let i = 0; i < arr1.length; i++) {
    if (String(arr1[i]) !== String(arr2[i])) {
      return false;
    }
  }

  return true;
}

export const isRichTextEmpty = (htmlContent: string) => {
  // Create a temporary DOM element to parse the HTML
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;

  // Extract inner text and trim whitespace
  const sanitizedContent = tempElement.innerText.trim();

  // Check if sanitized content is empty
  return sanitizedContent === "";
};
