import { Delete } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import MediumTypography from "../../../components/formlib/MediumTypography";
import SearchDropDown from "../../../components/formlib/SearchDropdown";
import { FC, useEffect, useState } from "react";
import { Tools, TypeList } from "../types/workOrderTypes";
import {
  CategoryRows,
  RowData,
  SelectedSubCategoryMap,
} from "./AddEditWorkOrderMaster";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";

interface MaterialsComponentProps {
  materialsData: Tools[];
  activeItem: string;
  toolsData: CategoryRows;
  handleStatusChange: () => void;
  handleApply: (toolsData: CategoryRows, active: string) => void;
}

const MaterialsComponent: FC<MaterialsComponentProps> = ({
  materialsData,
  activeItem,
  toolsData,
  handleStatusChange,
  handleApply,
}) => {
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [toolsTableData, setToolsTableData] = useState<CategoryRows>({});
  const [activeRowId, setActiveRowId] = useState<string | null>(null);
  //subCategory
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [categories, setCategories] = useState<DropDownType[]>([]);

  //codes
  const [openCode, setOpenCode] = useState(false);
  const [anchorElCode, setAnchorElCode] = useState<null | HTMLElement>(null);
  const [codes, setCodes] = useState<DropDownType[]>([]);
  //description
  const [openDescription, setOpenDescription] = useState(false);
  const [anchorElDescription, setAnchorElDescription] =
    useState<null | HTMLElement>(null);
  const [description, setDescription] = useState<DropDownType[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SelectedSubCategoryMap>({});
  const availableHeight = useAvailableHeight(280);
  useEffect(() => {
    setActiveCategory(activeItem);
    setToolsTableData(toolsData);
  }, []);

  useEffect(() => {
    const currentCategory = materialsData.find(
      (category) => category.name === activeCategory
    );

    const transformedSubCategories =
      currentCategory?.typeList.map((subCat, index) => ({
        id: index + 1,
        name: subCat.name,
      })) || [];

    setCategories(transformedSubCategories);
  }, [activeCategory]);

  useEffect(() => {
    if (activeRowId && activeCategory) {
      const selectedCategory = materialsData.find(
        (category) => category.name === activeCategory
      );

      if (selectedCategory) {
        const selectedSubCat = selectedCategory.typeList.find(
          (subCat) => subCat.name === selectedSubCategory[activeRowId]?.name
        );

        if (selectedSubCat) {
          // Extract already added codes from the API response
          const alreadyAddedCodes = new Set(
            Object.values(toolsTableData)
              .flat()
              .filter(
                (row) =>
                  row.data.subCategory ===
                  selectedSubCategory[activeRowId]?.name
              )
              .map((row) => row.data.id)
          );

          // Filter out already added codes from the dropdown options
          const filteredCodes = selectedSubCat.typeList
            .filter((code: TypeList) => !alreadyAddedCodes.has(code.id))
            .map((code: TypeList) => ({
              id: code.id,
              name: code.code ?? "",
            }));

          const filteredDescriptions = selectedSubCat.typeList
            .filter((code: TypeList) => !alreadyAddedCodes.has(code.id))
            .map((code: TypeList) => ({
              id: code.id,
              name: code.name ?? "",
            }));

          setCodes(filteredCodes);
          setDescription(filteredDescriptions);
        }
      }
    }
  }, [
    materialsData,
    selectedSubCategory,
    activeRowId,
    activeCategory,
    toolsTableData,
  ]);

  const addNewToolsRow = () => {
    const newRowId = `row-${activeCategory}-${Date.now()}`;

    const newRow: RowData = {
      id: newRowId,
      data: {
        code: "",
        description: "",
        subCategory: "",
        id: 0,
      },
    };

    setToolsTableData((prevState: CategoryRows): CategoryRows => {
      const updatedRows = [
        ...(prevState[activeCategory] || []), // Spread to copy existing rows
        newRow, // Add the new row at the end
      ];

      // Return the updated state with the new row added to the active category
      return {
        ...prevState,
        [activeCategory]: updatedRows,
      };
    });
    // Update the toolsTableData with the new row

    // Update the selectedSubCategory state to include the new row with a default or empty value
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));

    setCodes([]);
    setDescription([]);
  };
  //subcategory

  const handleClickCategory = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleSelectCategory = (
    rowId: string,
    subId: number,
    subName: string
  ) => {
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [rowId]: { id: subId, name: subName },
    }));
    // Assuming setToolsTableData is a state updater function for your state that holds the entire structure
    setToolsTableData((prevState: CategoryRows): CategoryRows => {
      // Map through the rows to find the row to update
      const updatedRows = prevState[activeCategory].map((row) => {
        if (row.id === rowId) {
          // Found the row to update, apply the updateData
          return {
            ...row,
            data: {
              ...row.data,
              subCategory: subName, // Use spread to update the row's data
            },
          };
        }
        return row; // Return unmodified row
      });

      // Return the updated state with the updated row data
      return {
        ...prevState,
        [activeCategory]: updatedRows,
      };
    });

    handleStatusChange();
  };

  //codes

  const handleClickCodes = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElCode(event.currentTarget);
    setOpenCode(true);
  };

  const handleCloseCodes = () => {
    setOpenCode(false);
    setAnchorElCode(null);
  };

  const handleSelectCodes = (rowId: string, codeId: number, code: string) => {
    // Find the selected code details and update description and UOM
    const selectedCode = materialsData
      .filter((category) => category.name === activeCategory) // Filter by active category
      .flatMap((category) => category.typeList)
      .flatMap((subCat) => subCat.typeList)
      .find((c) => c.code === code);
    if (selectedCode) {
      setToolsTableData((prevState: CategoryRows): CategoryRows => {
        // Map through the rows to find the row to update
        const updatedRows = prevState[activeCategory].map((row) => {
          if (row.id === rowId) {
            // Found the row to update, apply the updateData
            return {
              ...row,
              data: {
                ...row.data,
                code: code,
                description: selectedCode.name,
                id: selectedCode.id,
                uom: selectedCode.uom,
              },
            };
          }
          return row; // Return unmodified row
        });

        // Return the updated state with the updated row data
        return {
          ...prevState,
          [activeCategory]: updatedRows,
        };
      });

      handleStatusChange();
    }
  };
  //description

  const handleClickDescription = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElDescription(event.currentTarget);
    setOpenDescription(true);
  };
  const handleSelectDescription = (
    rowId: string,
    desId: number,
    desc: string
  ) => {
    // Find the selected code details and update description and UOM
    const selectedDescription = materialsData
      .flatMap((category) => category.typeList)
      .flatMap((subCat) => subCat.typeList)
      .find((c) => c.name === desc);

    if (selectedDescription) {
      setToolsTableData((prevState: CategoryRows): CategoryRows => {
        // Map through the rows to find the row to update
        const updatedRows = prevState[activeCategory].map((row) => {
          if (row.id === rowId) {
            // Found the row to update, apply the updateData
            return {
              ...row,
              data: {
                ...row.data,
                code: selectedDescription.code,
                description: desc,
                id: selectedDescription.id,
                uom: selectedDescription.uom,
              },
            };
          }
          return row; // Return unmodified row
        });

        // Return the updated state with the updated row data
        return {
          ...prevState,
          [activeCategory]: updatedRows,
        };
      });
      handleStatusChange();
    }
  };

  const handleCloseCategory = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
    setAnchorElDescription(null);
  };
  const handleQuantChange = (
    rowId: string,
    index: number,
    newValue: string
  ) => {
    setToolsTableData((prevState: CategoryRows): CategoryRows => {
      // Map through the rows to find the row to update
      const updatedRows = prevState[activeCategory].map((row) => {
        if (row.id === rowId) {
          // Found the row to update, apply the updateData
          return {
            ...row,
            data: {
              ...row.data,
              quantity: newValue,
            },
          };
        }
        return row; // Return unmodified row
      });

      // Return the updated state with the updated row data
      return {
        ...prevState,
        [activeCategory]: updatedRows,
      };
    });
    handleStatusChange();
  };

  const handleDeleteTools = (rowId: number) => {
    const updatedRows = toolsTableData[activeCategory].filter(
      (row) => row.data.id !== rowId
    );
    setToolsTableData({ ...toolsTableData, [activeCategory]: updatedRows });
  };

  return (
    <>
      <Grid item xs={12} lg={12} md={12} xl={12}>
        <Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid #545558",
            }}
          />
        </Box>
      </Grid>

      <Grid container>
        <Grid item className="ml-md mr-md width__100">
          <Box className="mt-sm mb-sm flex__justify__space-between ">
            <Box className="flex__">
              {materialsData.map((item, index) => {
                return (
                  <Box
                    className="flex__container  mr-md align__items__center"
                    sx={{
                      backgroundColor:
                        activeCategory === item.name
                          ? "rgba(37, 186, 250, 0.16)"
                          : "rgba(84, 85, 88, 0.16)",
                      padding: "0px 12px",
                      borderRadius: "45px",
                      height: "32px",
                      width: "auto",
                      minWidth: "100px",

                      border:
                        activeCategory === item.name
                          ? "1px solid rgba(37, 186, 250, 1)"
                          : "1px solid rgba(84, 85, 88, 1)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setActiveCategory(item.name);
                    }}
                  >
                    <MediumTypography
                      label={item.name}
                      textColor={
                        activeCategory === item.name ? "#25BAFA" : "#949597"
                      }
                    />
                  </Box>
                );
              })}
            </Box>
            <Box className="flex__">
              <ButtonComponent
                className="btn-primary btn-submit mr-md"
                variantType="contained"
                defaultLabelId={"Add Row"}
                labelId={"btn.AddRow"}
                onClick={addNewToolsRow}
              />
              <ButtonComponent
                className={
                  toolsTableData[activeCategory]?.length > 0 &&
                  toolsTableData[activeCategory]?.some((row) => {
                    if (
                      toolsTableData[activeCategory] ===
                      toolsTableData[Object.keys(toolsTableData)[0]]
                    ) {
                      return (
                        !row.data.subCategory ||
                        !row.data.code ||
                        !row.data.description ||
                        !row.data.uom
                      );
                    } else {
                      return (
                        !row.data.subCategory ||
                        !row.data.code ||
                        !row.data.description ||
                        !row.data.uom ||
                        !row.data.quantity
                      );
                    }
                  })
                    ? "btn-primary btn-disabled mr-md"
                    : "btn-primary btn-submit mr-md"
                }
                variantType="contained"
                defaultLabelId={"Apply"}
                labelId={
                  toolsTableData[activeCategory]?.length > 0
                    ? "Apply"
                    : "btn.None"
                }
                disabled={
                  toolsTableData[activeCategory]?.length > 0 &&
                  toolsTableData[activeCategory]?.some((row) => {
                    if (
                      toolsTableData[activeCategory] ===
                      toolsTableData[Object.keys(toolsTableData)[0]]
                    ) {
                      return (
                        !row.data.subCategory ||
                        !row.data.code ||
                        !row.data.description ||
                        !row.data.uom
                      );
                    } else {
                      return (
                        !row.data.subCategory ||
                        !row.data.code ||
                        !row.data.description ||
                        !row.data.uom ||
                        !row.data.quantity
                      );
                    }
                  })
                }
                onClick={async () => {
                  handleApply(toolsTableData, activeCategory);
                }}
              />
            </Box>
          </Box>
        </Grid>
        <TableContainer
          sx={{
            height:
              toolsTableData[activeCategory] &&
              toolsTableData[activeCategory].length > 5
                ? availableHeight
                : "auto",
          }}
          className="ml-md mr-md mb-sm overflow_Y"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    border: "1px solid #323337",
                    backgroundColor: "#22272B",
                    width: "50px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFF",
                        whiteSpace: "nowrap",
                      }}
                      labelId={"W/O.Sr.No"}
                      defaultLabel="Sr. No."
                      className="flex__ align__items__center"
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    border: "1px solid #323337",
                    backgroundColor: "#22272B",
                    width: "200px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      className="flex__ align__items__center"
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFF",
                        whiteSpace: "nowrap",
                      }}
                      labelId="table.Category"
                      defaultLabel="Category"
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    border: "1px solid #323337",
                    backgroundColor: "#22272B",
                    width: "200px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      className="flex__ align__items__center"
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFF",
                        whiteSpace: "nowrap",
                      }}
                      labelId={"table.materialCode"}
                      defaultLabel="Material Code"
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    border: "1px solid #323337",
                    backgroundColor: "#22272B",
                    width: "250px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFF",
                        whiteSpace: "nowrap",
                      }}
                      className="flex__ align__items__center"
                      labelId={"table.description"}
                      defaultLabel="Description"
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    border: "1px solid #323337",
                    backgroundColor: "#22272B",
                    width: "100px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFF",
                        whiteSpace: "nowrap",
                      }}
                      className="flex__ align__items__center"
                      labelId={"table.uom"}
                      defaultLabel="UoM"
                    />
                  </Box>
                </TableCell>
                {activeCategory === materialsData[1]?.name && (
                  <TableCell
                    sx={{
                      verticalAlign: "top",
                      border: "1px solid #323337",
                      backgroundColor: "#22272B",
                      width: "100px",
                    }}
                  >
                    <Box>
                      <MediumTypography
                        sxProps={{
                          fontWeight: 700,
                          color: "#FFFF",
                          whiteSpace: "nowrap",
                        }}
                        className="flex__ align__items__center"
                        labelId={"W/O.quantityProposed"}
                        defaultLabel="Quant Proposed"
                      />
                    </Box>
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    verticalAlign: "top",
                    border: "1px solid #323337",
                    backgroundColor: "#22272B",
                    width: "50px",
                  }}
                >
                  <Box>
                    <MediumTypography
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFF",
                        whiteSpace: "nowrap",
                      }}
                      className="flex__ align__items__center"
                      labelId={""}
                      defaultLabel=""
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {toolsTableData[activeCategory] &&
                toolsTableData[activeCategory].map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          border: "1px solid #323337",
                          backgroundColor:
                            index % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                      >
                        <MediumTypography label={(index + 1).toString()} />
                      </TableCell>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          border: "1px solid #323337",
                          backgroundColor:
                            index % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                      >
                        <Box
                          sx={{
                            height: "32px",
                          }}
                          className="flex__ width__100"
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleClickCategory(event, row.id)
                          }
                        >
                          <MediumTypography label={row.data.subCategory} />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          border: "1px solid #323337",
                          backgroundColor:
                            index % 2 === 0 ? "#2B3033" : "#22272B",
                          width: "50px",
                        }}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleClickCodes(event, row.id)
                        }
                      >
                        <MediumTypography label={row.data.code} />
                      </TableCell>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          border: "1px solid #323337",
                          backgroundColor:
                            index % 2 === 0 ? "#2B3033" : "#22272B",
                          width: "50px",
                        }}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleClickDescription(event, row.id)
                        }
                      >
                        <MediumTypography label={row.data.description} />
                      </TableCell>
                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          border: "1px solid #323337",
                          backgroundColor:
                            index % 2 === 0 ? "#2B3033" : "#22272B",
                          width: "50px",
                        }}
                      >
                        <MediumTypography label={row.data.uom} />
                      </TableCell>

                      {activeCategory === materialsData[1]?.name && (
                        <TableCell
                          sx={{
                            verticalAlign: "top",
                            border: "1px solid #323337",
                            backgroundColor:
                              index % 2 === 0 ? "#2B3033" : "#22272B",
                            width: "50px",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            sx={{
                              backgroundColor: "#22272B",

                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },

                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                                "& input": {
                                  padding: 0,
                                  color: "rgba(255, 255, 255, 1)",
                                },
                              },
                            }}
                            value={row.data.quantity}
                            onChange={(event) => {
                              handleQuantChange(
                                row.id,
                                index,
                                event.target.value
                              );
                            }}
                          />
                        </TableCell>
                      )}

                      <TableCell
                        sx={{
                          verticalAlign: "top",
                          border: "1px solid #323337",
                          backgroundColor:
                            index % 2 === 0 ? "#2B3033" : "#22272B",
                          width: "50px",
                        }}
                      >
                        <Delete
                          onClick={() => {
                            handleDeleteTools(row.data.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <SearchDropDown
              open={open}
              onClose={handleCloseCategory}
              anchorE1={anchorEl}
              width="200px"
              searchWidth="170px"
              Data={categories}
              onSelect={(id, name) => {
                if (activeRowId) {
                  handleSelectCategory(activeRowId, id, name);
                  setActiveRowId(null);
                }
              }}
            />
            <SearchDropDown
              open={openCode}
              onClose={handleCloseCodes}
              anchorE1={anchorElCode}
              width="200px"
              searchWidth="170px"
              Data={codes}
              onSelect={(id, name) => {
                if (activeRowId) {
                  handleSelectCodes(activeRowId, id, name);
                  setActiveRowId(null);
                }
              }}
            />
            <SearchDropDown
              open={openDescription}
              onClose={handleCloseDescription}
              anchorE1={anchorElDescription}
              width="400px"
              searchWidth="350px"
              Data={description}
              onSelect={(id, name) => {
                if (activeRowId) {
                  handleSelectDescription(activeRowId, id, name);
                  setActiveRowId(null);
                }
              }}
            />
          </Table>
          {(toolsTableData[activeCategory]?.length === 0 ||
            !toolsTableData[activeCategory]) && (
            <Box
              className="p-lg flex__container width__100 "
              sx={{
                backgroundColor: "#22272B",

                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <MediumTypography
                labelId="CM.EmptyMessage"
                defaultLabel="No records added yet"
              />
            </Box>
          )}
        </TableContainer>
      </Grid>
    </>
  );
};

export default MaterialsComponent;
