import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { trim } from "lodash";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";

import {
  createDropdownOptionApi,
  customUpdateDataApi,
} from "../../../api/MasterDataManagement/Common";
import {
  AssetCategoryOptionType,
  MakeOptionType,
} from "../types/checklistTypes";
import { CommonOptionType } from "../../../utils/type";
import SwitchComponent from "../../../components/SwitchComponent";
import { LineItemSettingsType } from "../types/mapChecklistTypes";
import { handleError } from "../../../utils/commonFunctions";

// Configurations

type FetchDataType = LineItemSettingsType;
type SaveDataType = LineItemSettingsType;

const apiUri = "checklistLineMap";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id: number;
  description: string;
  data: LineItemSettingsType;
  getDataCallback: () => void;
  isWORelated?: boolean;
}

const initialData: FetchDataType = {
  referenceValue: "",
  imageCapture: false,
  valueCapture: false,
  isNA: false,
};

const validationSchema = Yup.object().shape({});

const muiColumnProps = {
  xs: 12,
};

const SettingDrawer: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  description,
  data,
  getDataCallback,
  isWORelated,
}) => {
  const [apiData, setApiData] = useState<FetchDataType>(data);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<FetchDataType>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);
  const [isReferenceValueRequired, setIsReferenceValueRequired] =
    useState<boolean>(data.referenceValue?.trim()?.length > 0);

  useEffect(() => {
    if (id && data) {
      setApiData(data);
      setIsReferenceValueRequired(data.referenceValue?.trim()?.length > 0);
    }
  }, [id, data]);

  const saveData = (params: SaveDataType) => {
    toggleLoader(true);
    let saveUrl = "";
    if (isWORelated) {
      saveUrl = `woMasterSchedule/${id}/lineItem/update`;
    } else {
      saveUrl = `${apiUri}/${id}/lineItem/update?isVersionRequired=${isVersionRequired}`;
    }

    customUpdateDataApi<SaveDataType>(saveUrl, params)
      .then(() => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Drawer
              variant="persistent"
              anchor="right"
              open={open}
              onClose={handleClose}
              className="account-dialog main-wrapper-box"
              sx={{
                width: "536px",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: "536px",
                },
              }}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={"common.settings"}
                  defaultLabel="Settings"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                sx={{
                  position: "absolute",
                  right: 16,
                  top: 16,
                  color: "rgba(159, 173, 188, 1)",
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MediumTypography
                      label={description}
                      textColor="#FFF"
                      fontSize="16px"
                      fontWeight={500}
                      sxProps={{ mb: "10px" }}
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <Box className="setting-input-box">
                      <MediumTypography
                        className="input-label"
                        labelId="checklistMap.valueCapture"
                        defaultLabel="Value Capture"
                      />
                      <SwitchComponent
                        value={values.valueCapture}
                        handleChange={() => {
                          setFieldValue("valueCapture", !values.valueCapture);
                        }}
                      />
                    </Box>
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="valueCapture"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <Box className="setting-input-box">
                      <MediumTypography
                        className="input-label"
                        labelId="pm.referenceValueText"
                        defaultLabel="Reference Value"
                      />
                      <Box display={"flex"} gap={2}>
                        {isReferenceValueRequired && (
                          <TextInput
                            fieldheight="28px"
                            sxProps={{
                              "&.MuiFormControl-root": { width: "150px" },
                            }}
                            className="text-input-field"
                            type="text"
                            variant="outlined"
                            inputProps={{
                              readOnly: false,
                            }}
                            labelId="common.placeHolderText"
                            defaultLabelId="--- type here ---"
                            Value={values.referenceValue}
                            handlechange={(value: string) => {
                              setFieldValue("referenceValue", value);
                            }}
                          />
                        )}
                        <SwitchComponent
                          value={isReferenceValueRequired}
                          handleChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (!event.target.checked) {
                              setFieldValue("referenceValue", "");
                            }
                            setIsReferenceValueRequired(
                              !isReferenceValueRequired
                            );
                          }}
                        />
                      </Box>
                    </Box>
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="referenceValue"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <Box className="setting-input-box">
                      <MediumTypography
                        className="input-label"
                        labelId="checklistMap.imageCapture"
                        defaultLabel="Image Capture"
                      />
                      <SwitchComponent
                        value={values.imageCapture}
                        handleChange={() => {
                          setFieldValue("imageCapture", !values.imageCapture);
                        }}
                      />
                    </Box>
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <Box className="setting-input-box">
                      <MediumTypography
                        className="input-label"
                        labelId="checklistMap.isNA"
                        defaultLabel="Is NA"
                      />
                      <SwitchComponent
                        value={values.isNA}
                        handleChange={() => {
                          setFieldValue("isNA", !values.isNA);
                        }}
                      />
                    </Box>
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="isNA"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={"btn.update"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Drawer>
          );
        }}
      </Formik>
    </>
  );
};

export default SettingDrawer;
