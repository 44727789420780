import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { PMMasterType, PMScheduleType } from "../wizrads.types";
import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { ReactComponent as RoleSave } from "../../../assets/images/tickAdd-circle.svg";
import { ReactComponent as RoleDelete } from "../../../assets/images/closeAdd-circle.svg";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customUpdateDataApi,
  fetchAllEventsDataApi,
  generatePmSchedules,
} from "../../../api/wizardsApi/Common";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { handleError } from "../../../utils/commonFunctions";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ManageMapChecklistComponent from "../../MasterDataManagement/mapChecklistLineItems/ManageMapChecklistComponent";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../components/formlib/CheckBox";
import ButtonComponent from "../../../components/formlib/ButtonComponent";

interface PMSchedulePropType {
  isMenuOpen: boolean;
  assetGroupId: number;
  childCallBack: () => void;
  openModal: boolean;
  closeModalCallBack: () => void;
  subGroupId: number;
}

const dataFields = [
  { key: "Category", value: "Category" },
  { key: "Frequency", value: "Frequency" },
  { key: "checklistType", value: "Checklist Type" },
  { key: "checklist", value: "Checklist" },
];

const PMScheduleDetailScreen: FC<PMSchedulePropType> = ({
  isMenuOpen,
  assetGroupId,
  childCallBack,
  openModal,
  closeModalCallBack,
  subGroupId,
}) => {
  const availableHeight = useAvailableHeight(420);
  const availableWidth = useAvailableWidth(540);
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const addEditOpen = Boolean(anchorEl);
  const [selectedChecklistTypeId, setSelectedChecklistTypeId] = useState<{
    [key: number]: number;
  }>({});
  const [isCheclistTypeSelected, setIsCheclistTypeSelected] = useState<{
    [key: number]: boolean;
  }>({});
  const [isChecklistSelected, setIsSelectedChecklistId] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedChecklistId, setSelectedChecklistId] = useState<{
    [key: number]: number;
  }>({});

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [jsonData, setJsonData] = useState<PMScheduleType>(
    {} as PMScheduleType
  );
  const [tableRows, setTableRows] = useState<PMMasterType[]>([]);
  const [showEditComponent, setShowEditComponent] = useState(false);
  const [woMasterId, setWoMasterId] = useState<number>(0);
  const [checkListId, setChecklistId] = useState<number>(0);
  const [isSaved, setIsSaved] = useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const handleChecklistTypeClick = (rowIndex: number) => {
    // Find the asset category for the current row's assetCategoryId
    const assetCategoryId = tableRows[rowIndex]?.assetCategoryId; // Assuming tableRows holds row data
    const assetCategory = jsonData.assetCategoryIdNameBeans.find(
      (category) => category.id === assetCategoryId
    );

    // Access the frequecyList from the found asset category
    const frequencyData = assetCategory?.frequecyList.find(
      (freq) => freq.frequency.id === tableRows[rowIndex]?.selectedFrequencyId
    );

    const defaultChecklistTypeId =
      frequencyData?.checklistTypes[0]?.checklistType.id;

    if (defaultChecklistTypeId !== undefined) {
      setSelectedChecklistTypeId((prev) => ({
        ...prev,
        [rowIndex]: defaultChecklistTypeId, // Set the correct checklist type ID
      }));

      // Automatically select the first checklist for the new type
      const defaultChecklistId =
        frequencyData?.checklistTypes[0]?.checklists[0]?.id;

      if (defaultChecklistId !== undefined) {
        setSelectedChecklistId((prev) => ({
          ...prev,
          [rowIndex]: defaultChecklistId,
        }));
      }
    }

    setIsCheclistTypeSelected((prevState) => ({
      ...prevState,
      [rowIndex]: true,
    }));
  };

  const handleChecklistClick = (rowIndex: number) => {
    // Find the asset category for the current row's assetCategoryId
    const assetCategoryId = tableRows[rowIndex]?.assetCategoryId; // Assuming tableRows holds row data
    const assetCategory = jsonData.assetCategoryIdNameBeans.find(
      (category) => category.id === assetCategoryId
    );

    // Access the frequecyList from the found asset category
    const frequencyData = assetCategory?.frequecyList.find(
      (freq) => freq.frequency.id === tableRows[rowIndex]?.selectedFrequencyId
    );

    if (!frequencyData) {
      return;
    }

    // Find the selected checklist type within the frequency
    const selectedType = frequencyData?.checklistTypes.find(
      (type) => type.checklistType.id === selectedChecklistTypeId[rowIndex]
    );

    // Get the default checklist ID from the selected type
    const defaultChecklistId = selectedType?.checklists[0]?.id;

    if (defaultChecklistId !== undefined) {
      // Update the selected checklist ID for the row
      setSelectedChecklistId((prev) => ({
        ...prev,
        [rowIndex]: defaultChecklistId,
      }));
    }

    // Mark the checklist as selected
    setIsSelectedChecklistId((prevState) => ({
      ...prevState,
      [rowIndex]: true,
    }));
  };

  // const handleChecklistTypeClick = (rowIndex: number) => {
  //   const frequencyData = jsonData.assetCategoryIdNameBeans.frequecyList[rowIndex];
  //   const defaultChecklistTypeId =
  //     frequencyData?.checklistTypes[0]?.checklistType.id;

  //   if (defaultChecklistTypeId !== undefined) {
  //     setSelectedChecklistTypeId((prev) => ({
  //       ...prev,
  //       [rowIndex]: defaultChecklistTypeId, // Set the correct checklist type ID.
  //     }));

  //     // Automatically select the first checklist for the new type.
  //     const defaultChecklistId =
  //       frequencyData?.checklistTypes[0]?.checklists[0]?.id;
  //     if (defaultChecklistId !== undefined) {
  //       setSelectedChecklistId((prev) => ({
  //         ...prev,
  //         [rowIndex]: defaultChecklistId,
  //       }));
  //     }
  //   }

  //   setIsCheclistTypeSelected((prevState) => ({
  //     ...prevState,
  //     [rowIndex]: true,
  //   }));
  // };

  // const handleChecklistClick = (rowIndex: number) => {
  //   const frequencyData = jsonData.frequecyList[rowIndex];
  //   const selectedType = frequencyData?.checklistTypes.find(
  //     (type) => type.checklistType.id === selectedChecklistTypeId[rowIndex]
  //   );

  //   const defaultChecklistId = selectedType?.checklists[0]?.id;

  //   if (defaultChecklistId !== undefined) {
  //     setSelectedChecklistId((prev) => ({
  //       ...prev,
  //       [rowIndex]: defaultChecklistId,
  //     }));
  //   }

  //   setIsSelectedChecklistId((prevState) => ({
  //     ...prevState,
  //     [rowIndex]: true,
  //   }));
  // };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleSave = (values: PMMasterType, rowIndex: number) => {
    toggleLoader(true);
    const updatedValues = {
      ...values,
      selectedCheckListMasterFreqId: selectedChecklistId[rowIndex],
    };

    // Remove display before sending to backend
    if ("display" in updatedValues) {
      const { display, isSaved, ...backendRow } = updatedValues;
      let url = `woMasterSchedule/update`;
      customUpdateDataApi(url, backendRow)
        .then(() => {
          setIsSelectedChecklistId((prev) => ({
            ...prev,
            [rowIndex]: false,
          }));
          setIsCheclistTypeSelected((prev) => ({
            ...prev,
            [rowIndex]: false,
          }));
          setIsSaved(true);
          // Reset the popover state
          setAnchorEl(null);

          // Optionally update the table rows to reflect any changes from the backend
          setTableRows((prevRows) =>
            prevRows.map((row, index) =>
              index === rowIndex
                ? {
                    ...row,
                    display: {
                      ...row.display,
                      // Update display values based on saved data
                      checklist:
                        values.display?.checklist || row.display.checklist,
                      checklistType:
                        values.display?.checklistType ||
                        row.display.checklistType,
                    },
                  }
                : row
            )
          );
          getAllSchedules();
          toggleLoader(false);
        })
        .catch((error) => {
          handleError(error, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }
  };

  useEffect(() => {
    getAllSchedules();
  }, []);

  const getAllSchedules = () => {
    const fetchAllShiftsUrl = `${WORK_FLOW_BASE_URL}${assetGroupId}/woMasterSchedule`;

    // fetchAllEventsDataApi<PMScheduleType>(fetchAllShiftsUrl, "en")
    //   .then((response) => {
    //     setJsonData(response);

    //     const tableRowsData = response?.woMasterSchedules?.map((master) => {
    //       const assetCategory = response.assetCategoryIdNameBeans.find(
    //         (category) => category.id === master.assetCategoryId
    //       );

    //       const frequencyData = response.assetCategoryIdNameBeans.fr.find(
    //         (freq) => freq.frequency.id === master.selectedFrequencyId
    //       );
    //       const checklistType = frequencyData?.checklistTypes.find(
    //         (type) => type.checklistType.id === master.selectedCheckListTypeId
    //       );

    //       const checklist = checklistType?.checklists.find(
    //         (checklist) => checklist.id === master.selectedCheckListMasterFreqId
    //       );

    //       const isSaved =
    //         !!master.selectedCheckListTypeId &&
    //         !!master.selectedCheckListMasterFreqId;
    //       if (
    //         !master.selectedCheckListTypeId ||
    //         !master.selectedCheckListMasterFreqId
    //       ) {
    //         setIsSaved(false);
    //       } else {
    //         setIsSaved(true);
    //       }

    //       return {
    //         assetCategoryId: master.assetCategoryId,
    //         selectedFrequencyId: master.selectedFrequencyId,
    //         selectedCheckListTypeId: master.selectedCheckListTypeId,
    //         selectedCheckListMasterFreqId: master.selectedCheckListMasterFreqId,
    //         woMasterBean: master.woMasterBean,
    //         hasWoMasterChecklistItems: master.hasWoMasterChecklistItems,
    //         isSaved,
    //         // Additional fields for display purposes
    //         display: {
    //           category: assetCategory?.name || "",
    //           frequency: frequencyData?.frequency.name || "",
    //           checklistType: checklistType?.checklistType.name || "",
    //           checklist: checklist?.name || "",
    //         },
    //       };
    //     });

    //     setTableRows(tableRowsData);
    //   })
    //   .catch((error) => {
    //     handleError(error, setErrorDesc);
    //     setOpenErrorModal(true);
    //     toggleLoader(false);
    //   });
    fetchAllEventsDataApi<PMScheduleType>(fetchAllShiftsUrl, "en")
      .then((response) => {
        setJsonData(response);

        const tableRowsData = response?.woMasterSchedules?.map((master) => {
          // Get the corresponding asset category
          const assetCategory = response.assetCategoryIdNameBeans.find(
            (category) => category.id === master.assetCategoryId
          );

          // Get the frequency data for the selected frequency ID
          const frequencyData = assetCategory?.frequecyList.find(
            (freq) => freq.frequency.id === master.selectedFrequencyId
          );

          // Get the checklist type for the selected checklist type ID
          const checklistType = frequencyData?.checklistTypes.find(
            (type) => type.checklistType.id === master.selectedCheckListTypeId
          );

          // Get the specific checklist for the selected checklist ID
          const checklist = checklistType?.checklists.find(
            (checklist) => checklist.id === master.selectedCheckListMasterFreqId
          );

          // Determine if the row is saved
          const isSaved =
            !!master.selectedCheckListTypeId &&
            !!master.selectedCheckListMasterFreqId;

          // Return the mapped row
          return {
            assetCategoryId: master.assetCategoryId,
            selectedFrequencyId: master.selectedFrequencyId,
            selectedCheckListTypeId: master.selectedCheckListTypeId,
            selectedCheckListMasterFreqId: master.selectedCheckListMasterFreqId,
            woMasterBean: master.woMasterBean,
            hasWoMasterChecklistItems: master.hasWoMasterChecklistItems,
            isSaved,
            // Additional fields for display purposes
            display: {
              category: assetCategory?.name || "",
              frequency: frequencyData?.frequency.name || "",
              checklistType: checklistType?.checklistType.name || "",
              checklist: checklist?.name || "",
            },
          };
        });

        setTableRows(tableRowsData);
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <Box>
      {showEditComponent && (
        <ManageMapChecklistComponent
          onDataFetch={{} as any}
          onOpenAddModal={openModal}
          handleModalClose={() => {
            setShowEditComponent(false);
            closeModalCallBack();
          }}
          isWOMasterRelated={true}
          woMasterId={woMasterId}
          checklistMasterId={checkListId}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {!showEditComponent && (
        <Box className="flex__justify__space-between mb-md">
          <MediumTypography
            labelId="Schedule.titleText"
            defaultLabel="Schedule"
            fontSize="20px"
            fontWeight={700}
          />
          {selectedRowIds.length > 0 && (
            <ButtonComponent
              className="btn-primary btn-submit ml-md"
              variantType="contained"
              defaultLabelId={"Generate Schedules"}
              labelId={"btn.generate"}
              onClick={() => {
                const url = `subgroup/${subGroupId}/assetGroup/${assetGroupId}`;
                generatePmSchedules(url, selectedRowIds)
                  .then(() => {
                    toggleLoader(false);
                    setSelectedRowIds([]);
                    getAllSchedules();
                  })
                  .catch((error) => {
                    toggleLoader(false);
                    setOpenErrorModal(true);
                    handleError(error, setErrorDesc);
                  });
              }}
            />
          )}
        </Box>
      )}
      {!showEditComponent && (
        <Box
          sx={{
            height: availableHeight,

            "&::-webkit-scrollbar": { display: "none" },
          }}
          className={"position__relative overflow_Y"}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: availableWidth,

              height: availableHeight,
              "&::-webkit-scrollbar": { display: "none" },
            }}
            className={"tableContainer"}
            onScroll={() => {}}
            ref={containerRef}
          >
            <Table>
              <CustomTableHeader
                headerNames={dataFields}
                searchableKeys={[]}
                stickyItems="Sr.No"
                emptyHeader={true}
              />
              <TableBody>
                {tableRows.length !== 0 &&
                  tableRows.map((row, rowIndex) => (
                    <TableRow
                      sx={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                      }}
                    >
                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                        style={{ width: "100px" }}
                        className="stickyColumn"
                      >
                        {row.hasWoMasterChecklistItems === true ? (
                          <CheckBox
                            value={selectedRowIds.includes(row.woMasterBean.id)}
                            onCheckBoxClick={() => {
                              if (
                                selectedRowIds.includes(row.woMasterBean.id)
                              ) {
                                setSelectedRowIds(
                                  selectedRowIds.filter(
                                    (id) => id !== row.woMasterBean.id
                                  )
                                ); // Unselect the row
                              } else {
                                setSelectedRowIds([
                                  ...selectedRowIds,
                                  row.woMasterBean.id,
                                ]); // Select the row
                              }
                            }}
                            className="p-none"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <MediumTypography labelId="" defaultLabel="" />
                        )}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          verticalAlign: "top",
                          borderBottom: "none",
                          position: "sticky",
                          top: 0,
                          left: 60,
                          zIndex: 1,
                        }}
                        className="stickyColumn"
                      >
                        <MediumTypography
                          label={(rowIndex + 1).toString()}
                          className="mt-xs"
                        />
                      </TableCell>
                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                        className="stickyColumn"
                      >
                        <MediumTypography label={row.display.category} />
                      </TableCell>
                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                        className="stickyColumn"
                      >
                        <MediumTypography label={row.display.frequency} />
                      </TableCell>

                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                        className="stickyColumn"
                      >
                        {!row.isSaved ? (
                          <CustomDropdown
                            disableCreate={true}
                            sxStyles={{
                              textField: {
                                padding: "0.5px 0px 0px 0px",
                              },
                            }}
                            options={
                              // Find the relevant asset category first
                              jsonData.assetCategoryIdNameBeans
                                .find(
                                  (category) =>
                                    category.id === row.assetCategoryId
                                )
                                ?.frequecyList.find(
                                  (freq) =>
                                    freq.frequency.id ===
                                    row.selectedFrequencyId
                                )
                                ?.checklistTypes.map((type) => ({
                                  id: type.checklistType.id.toString(),
                                  name: type.checklistType.name,
                                })) || []
                            }
                            // options={
                            //   jsonData.frequecyList
                            //     .find(
                            //       (freq) =>
                            //         freq.frequency.id ===
                            //         row.selectedFrequencyId
                            //     )
                            //     ?.checklistTypes.map((type) => ({
                            //       id: type.checklistType.id.toString(),
                            //       name: type.checklistType.name,
                            //     })) || []
                            // }
                            selectedValue={selectedChecklistTypeId[rowIndex]}
                            onSelectedValueChange={(value) => {
                              setSelectedChecklistTypeId((prev) => ({
                                ...prev,
                                [rowIndex]: Number(value),
                              }));
                              setIsSaved(false); // Reset save state on change
                              setTableRows((prevRows) =>
                                prevRows.map((row, index) =>
                                  index === rowIndex
                                    ? {
                                        ...row,
                                        selectedCheckListTypeId: Number(value),
                                        display: {
                                          ...row.display,
                                          checklistType:
                                            jsonData.assetCategoryIdNameBeans
                                              .find(
                                                (category) =>
                                                  category.id ===
                                                  row.assetCategoryId
                                              )
                                              ?.frequecyList.find(
                                                (freq) =>
                                                  freq.frequency.id ===
                                                  row.selectedFrequencyId
                                              )
                                              ?.checklistTypes.find(
                                                (type) =>
                                                  type.checklistType.id ===
                                                  Number(value)
                                              )?.checklistType.name || "",
                                        },
                                      }
                                    : row
                                )
                              );

                              // setTableRows((prevRows) =>
                              //   prevRows.map((row, index) =>
                              //     index === rowIndex
                              //       ? {
                              //           ...row,
                              //           selectedCheckListTypeId: Number(value),
                              //           display: {
                              //             ...row.display,
                              //             checklistType:
                              //               jsonData.frequecyList
                              //                 .find(
                              //                   (freq) =>
                              //                     freq.frequency.id ===
                              //                     row.selectedFrequencyId
                              //                 )
                              //                 ?.checklistTypes.find(
                              //                   (type) =>
                              //                     type.checklistType.id ===
                              //                     Number(value)
                              //                 )?.checklistType.name || "",
                              //           },
                              //         }
                              //       : row
                              //   )
                              // );
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => {
                              setIsSaved(false);
                              setTableRows((prevRows) =>
                                prevRows.map((row, index) =>
                                  index === rowIndex
                                    ? {
                                        ...row,
                                        isSaved: !row.isSaved, // Update isSaved to false
                                      }
                                    : row
                                )
                              );
                              handleChecklistTypeClick(rowIndex);
                            }}
                          >
                            <MediumTypography
                              label={row.display.checklistType}
                            />
                          </Box>
                        )}
                      </TableCell>

                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                        className="stickyColumn"
                      >
                        {!row.isSaved ? (
                          <CustomDropdown
                            sxStyles={{
                              textField: {
                                padding: "0.5px 0px 0px 0px",
                              },
                            }}
                            disableCreate={true}
                            // options={
                            //   jsonData.frequecyList
                            //     .find(
                            //       (freq) =>
                            //         freq.frequency.id ===
                            //         row.selectedFrequencyId // Match the frequency based on the row's frequency ID
                            //     )
                            //     ?.checklistTypes.find(
                            //       (type) =>
                            //         type.checklistType.id ===
                            //         (selectedChecklistTypeId[rowIndex] ||
                            //           row.selectedCheckListTypeId) // Use selected checklist type ID or fallback to the row's saved value
                            //     )
                            //     ?.checklists.map((checklist) => ({
                            //       id: checklist.id.toString(),
                            //       name: checklist.name,
                            //     })) || [] // Return an empty array if no matching data is found
                            // }
                            options={
                              jsonData.assetCategoryIdNameBeans
                                .find(
                                  (category) =>
                                    category.id === row.assetCategoryId
                                ) // Find the category by assetCategoryId
                                ?.frequecyList.find(
                                  (freq) =>
                                    freq.frequency.id ===
                                    row.selectedFrequencyId // Match the frequency based on the row's frequency ID
                                )
                                ?.checklistTypes.find(
                                  (type) =>
                                    type.checklistType.id ===
                                    (selectedChecklistTypeId[rowIndex] ||
                                      row.selectedCheckListTypeId) // Use selected checklist type ID or fallback to the row's saved value
                                )
                                ?.checklists.map((checklist) => ({
                                  id: checklist.id.toString(),
                                  name: checklist.name,
                                })) || [] // Return an empty array if no matching data is found
                            }
                            selectedValue={
                              selectedChecklistId[rowIndex]?.toString() || ""
                            }
                            onSelectedValueChange={(value) => {
                              setSelectedChecklistId((prev) => ({
                                ...prev,
                                [rowIndex]: Number(value),
                              }));
                              setIsSaved(false); // Reset save state on change
                              setTableRows((prevRows) =>
                                prevRows.map((row, index) =>
                                  index === rowIndex
                                    ? {
                                        ...row,
                                        selectedCheckListMasterFreqId:
                                          Number(value), // Update checklist master frequency ID
                                        display: {
                                          ...row.display,
                                          checklist:
                                            jsonData.assetCategoryIdNameBeans
                                              .find(
                                                (category) =>
                                                  category.id ===
                                                  row.assetCategoryId
                                              ) // Find asset category by ID
                                              ?.frequecyList.find(
                                                (freq) =>
                                                  freq.frequency.id ===
                                                  row.selectedFrequencyId // Match the frequency by ID
                                              )
                                              ?.checklistTypes.find(
                                                (type) =>
                                                  type.checklistType.id ===
                                                  (selectedChecklistTypeId[
                                                    rowIndex
                                                  ] ||
                                                    row.selectedCheckListTypeId) // Find checklist type
                                              )
                                              ?.checklists.find(
                                                (checklist) =>
                                                  checklist.id === Number(value) // Find the checklist by ID
                                              )?.name || "", // Set the checklist name or fallback to an empty string
                                        },
                                      }
                                    : row
                                )
                              );

                              // setTableRows((prevRows) =>
                              //   prevRows.map((row, index) =>
                              //     index === rowIndex
                              //       ? {
                              //           ...row,
                              //           selectedCheckListMasterFreqId:
                              //             Number(value),
                              //           display: {
                              //             ...row.display,
                              //             checklist:
                              //               jsonData.frequecyList[
                              //                 rowIndex
                              //               ]?.checklistTypes
                              //                 .find(
                              //                   (type) =>
                              //                     type.checklistType.id ===
                              //                     (selectedChecklistTypeId[
                              //                       rowIndex
                              //                     ] ||
                              //                       row.selectedCheckListTypeId)
                              //                 )
                              //                 ?.checklists.find(
                              //                   (checklist) =>
                              //                     checklist.id === Number(value)
                              //                 )?.name || "",
                              //           },
                              //         }
                              //       : row
                              //   )
                              // );
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => {
                              setIsSaved(false);
                              setTableRows((prevRows) =>
                                prevRows.map((row, index) =>
                                  index === rowIndex
                                    ? {
                                        ...row,
                                        isSaved: !row.isSaved, // Update isSaved to false
                                      }
                                    : row
                                )
                              );
                              handleChecklistClick(rowIndex);
                            }}
                          >
                            <MediumTypography label={row.display.checklist} />
                          </Box>
                        )}
                      </TableCell>

                      <TableCell
                        align={"left"}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                        className="stickyColumn_right"
                      >
                        <Box>
                          {!row.isSaved ? (
                            <Box className={"flex__"} alignItems={"center"}>
                              <IconButton>
                                <RoleSave
                                  onClick={() => handleSave(row, rowIndex)}
                                />
                              </IconButton>
                              <IconButton>
                                <RoleDelete onClick={() => setIsSaved(true)} />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box>
                              <Tooltip title="More">
                                <IconButton
                                  onClick={(event) =>
                                    handleClick(event, rowIndex)
                                  }
                                >
                                  <More />
                                </IconButton>
                              </Tooltip>
                              <Popover
                                anchorEl={anchorEl}
                                open={addEditOpen && selectedRow === rowIndex}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                sx={{
                                  "& .MuiPaper-root": {
                                    backgroundColor: "#2B3033",
                                  },
                                }}
                              >
                                <MenuItem
                                  style={{ backgroundColor: "2B3033" }}
                                  onClick={() => {
                                    setWoMasterId(row.woMasterBean.id);
                                    setChecklistId(
                                      row.selectedCheckListMasterFreqId
                                    );
                                    setShowEditComponent(true);
                                    childCallBack();
                                  }}
                                >
                                  <MediumTypography
                                    labelId="btn.edit"
                                    defaultLabel="Edit"
                                  />
                                </MenuItem>
                                <MenuItem style={{ backgroundColor: "2B3033" }}>
                                  <MediumTypography
                                    labelId="planner.deleteText"
                                    defaultLabel="Delete"
                                  />
                                </MenuItem>
                              </Popover>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default PMScheduleDetailScreen;
