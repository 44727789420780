import { Delete } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import MediumTypography from "../../../components/formlib/MediumTypography";
import SearchDropDown from "../../../components/formlib/SearchDropdown";
import { Permit } from "../types/workOrderTypes";
import { FC, useEffect, useState } from "react";
import { PermitData, SelectedSubCategoryMap } from "./AddEditWorkOrderMaster";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";
import useAvailableHeight from "../../../utils/useAvailableHeight";

interface PermitsComponentProps {
  permitData: PermitData[];
  permitList: Permit[];
  handleApply: (permits: PermitData[]) => void;
  handleStatusChange: () => void;
}

const PermitsComponent: FC<PermitsComponentProps> = ({
  permitData,
  permitList,
  handleApply,
  handleStatusChange,
}) => {
  const [permitsTableData, setPermitsTableData] = useState<PermitData[]>([]);
  const [selectedPermitName, setSelectedPermitName] =
    useState<SelectedSubCategoryMap>({});
  const [selectedPermitType, setSelectedPermitType] =
    useState<SelectedSubCategoryMap>({});
  const [permitNames, setPermitNames] = useState<DropDownType[]>([]);
  const [openPermitType, setOpenPermitType] = useState(false);
  const [anchorElPermitType, setAnchorElPermitType] =
    useState<null | HTMLElement>(null);
  const [anchorElPermitName, setAnchorElPermitName] =
    useState<null | HTMLElement>(null);
  const [openPermitName, setOpenPermitName] = useState(false);
  const [permitTypes, setPermitTypes] = useState<DropDownType[]>([]);
  const [activePermitRowId, setActivePermitRowId] = useState<string | null>(
    null
  );
  const availableHeight = useAvailableHeight(280);

  useEffect(() => {
    // Assuming toolsList is available in your component's scope
    const transformedPermits =
      permitList.map((permit, index) => ({
        id: permit?.id, // or any other unique identifier
        name: permit?.name || "",
      })) || [];

    setPermitTypes(transformedPermits);
  }, [permitList]);

  const handleDeletePermits = (rowId: number) => {
    const deletedRow = permitsTableData.find((row) => row.data.id === rowId);
    if (deletedRow) {
      setSelectedPermitName((prev) => {
        const newSelectedPermitName = { ...prev };
        delete newSelectedPermitName[rowId];
        return newSelectedPermitName;
      });
    }
    setPermitsTableData((prev) => prev.filter((row) => row.data.id !== rowId));
  };

  const handleClosePermitType = () => {
    setOpenPermitType(false);
    setAnchorElPermitType(null);
  };

  useEffect(() => {
    setPermitsTableData(permitData);
  }, [permitData]);
  const addNewPermitsRow = () => {
    // Correctly calculate and store the new row ID beforehand

    const newRowId = `row-${Date.now()}`;
    handleStatusChange();
    // Define the new row using the newRowId
    const newRow: PermitData = {
      id: newRowId, // Use the newRowId here
      data: {
        id: 0,
        name: "",
        permitType: "",
        description: "",
      },
    };

    // Update the toolsTableData with the new row
    const updatedPermitsTableData: PermitData[] = [...permitsTableData, newRow];
    setPermitsTableData(updatedPermitsTableData);

    // Update the selectedSubCategory state to include the new row with a default or empty value
    setSelectedPermitType((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));

    setSelectedPermitType((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));
    setSelectedPermitName((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" },
    }));

    setPermitNames([]);
  };

  const handleSelectPermitType = (
    rowId: string,
    subId: number,
    subName: string
  ) => {
    setSelectedPermitType((prevState) => ({
      ...prevState,
      [rowId]: { id: subId, name: subName },
    }));
    // Assuming setToolsTableData is a state updater function for your state that holds the entire structure
    setPermitsTableData((prevTableData: PermitData[]) => {
      return prevTableData.map((row) => {
        // Find the row by ID and update its subcategory
        if (row.id === rowId) {
          return {
            ...row,
            data: {
              ...row.data,
              permitType: subName, // Update the subcategory name
              // You might also need to update other related fields here
            },
          };
        }
        // Leave other rows unchanged
        return row;
      });
    });
    handleStatusChange();
  };

  const handleClickPermitType = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActivePermitRowId(rowId);
    setAnchorElPermitType(event.currentTarget);
    setOpenPermitType(true);
  };

  const handleClickPermitName = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActivePermitRowId(rowId);
    setAnchorElPermitName(event.currentTarget);
    setOpenPermitName(true);
  };

  const handleClosePermitName = () => {
    setOpenPermitName(false);
    setAnchorElPermitName(null);
  };

  const handleSelectPermitName = (
    rowId: string,
    permitNameId: number,
    permitName: string
  ) => {
    setSelectedPermitName((prevState) => ({
      ...prevState,
      [rowId]: { id: permitNameId, name: permitName },
    }));

    // Find the selected code details and update description and UOM

    const selectedName = permitList
      .flatMap((permit) => permit.typeList)
      .find((c) => c.name === permitName);

    if (selectedName) {
      setPermitsTableData((prevTableData: PermitData[]) => {
        return prevTableData.map((row) => {
          // Find the row by ID and update its subcategory
          if (row.id === rowId) {
            return {
              ...row,
              data: {
                ...row.data,
                name: selectedName.name,
                description: selectedName.code || "",
                id: selectedName.id, // Update the subcategory name
                // You might also need to update other related fields here
              },
            };
          }
          // Leave other rows unchanged
          return row;
        });
      });
      handleStatusChange();
    }
  };
  useEffect(() => {
    if (activePermitRowId) {
      const selectedType = permitList.find(
        (permit) => permit.name === selectedPermitType[activePermitRowId]?.name
      );

      if (selectedType) {
        const alreadySelectedPermitNames = new Set(
          permitsTableData.map((row) => row.data.name)
        );

        const filteredNames =
          selectedType?.typeList
            ?.filter((name) => !alreadySelectedPermitNames.has(name.name))
            ?.map((code) => ({
              id: code.id,
              name: code.name,
            })) || [];

        setPermitNames(filteredNames);
      }
    }
  }, [
    permitList,
    selectedPermitType,
    selectedPermitName,
    activePermitRowId,
    permitsTableData, // Ensure permitsTableData is included in dependencies
  ]);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid #545558",
            }}
          />
        </Box>
      </Grid>
      <Box
        className="mt-sm mb-sm flex__ "
        sx={{ justifyContent: "flex-end", width: "100%" }}
      >
        <ButtonComponent
          className="btn-primary btn-submit mr-md"
          variantType="contained"
          defaultLabelId={"Add Row"}
          labelId={"btn.AddRow"}
          onClick={addNewPermitsRow}
        />
        <ButtonComponent
          className={
            permitsTableData?.length > 0 &&
            permitsTableData?.some((row) => {
              return !row.data.permitType || !row.data.name;
            })
              ? "btn-primary btn-disabled mr-md"
              : "btn-primary btn-submit mr-md"
          }
          variantType="contained"
          defaultLabelId={"Apply"}
          labelId={permitsTableData?.length > 0 ? "Apply" : "btn.None"}
          disabled={
            permitsTableData?.length > 0 &&
            permitsTableData?.some((row) => {
              return !row.data.permitType || !row.data.name;
            })
          }
          onClick={async () => {
            handleApply(permitsTableData);
          }}
        />
      </Box>

      <TableContainer
        sx={{
          height: permitsTableData.length > 5 ? availableHeight : "auto",
        }}
        className="ml-md mr-md mt-sm mb-sm overflow_Y"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  verticalAlign: "top",
                  border: "1px solid #323337",
                  backgroundColor: "#22272B",
                  width: "50px",
                }}
              >
                <Box>
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFF",
                      whiteSpace: "nowrap",
                    }}
                    className="flex__ align__items__center"
                    labelId={"table.srNo"}
                    defaultLabel="Sr. No."
                  />
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: "top",
                  border: "1px solid #323337",
                  backgroundColor: "#22272B",
                  width: "200px",
                }}
              >
                <Box>
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFF",
                      whiteSpace: "nowrap",
                    }}
                    className="flex__ align__items__center"
                    labelId="permitMap.type"
                    defaultLabel="Permit Type"
                  />
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: "top",
                  border: "1px solid #323337",
                  backgroundColor: "#22272B",
                  width: "200px",
                }}
              >
                <Box>
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFF",
                      whiteSpace: "nowrap",
                    }}
                    className="flex__ align__items__center"
                    labelId="skillNameText"
                    defaultLabel="Name"
                  />
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  verticalAlign: "top",
                  border: "1px solid #323337",
                  backgroundColor: "#22272B",
                  width: "200px",
                }}
              >
                <Box>
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFF",
                      whiteSpace: "nowrap",
                    }}
                    className="flex__ align__items__center"
                    labelId={"table.description"}
                    defaultLabel="Description"
                  />
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: "top",
                  border: "1px solid #323337",
                  backgroundColor: "#22272B",
                  width: "50px",
                }}
              >
                <Box>
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFF",
                      whiteSpace: "nowrap",
                    }}
                    className="flex__ align__items__center"
                    labelId={""}
                    defaultLabel=""
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permitsTableData &&
              permitsTableData.map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        verticalAlign: "top",
                        border: "1px solid #323337",
                        backgroundColor:
                          index % 2 === 0 ? "#2B3033" : "#22272B",
                        width: "50px",
                      }}
                    >
                      <MediumTypography label={(index + 1).toString()} />
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: "top",
                        border: "1px solid #323337",
                        backgroundColor:
                          index % 2 === 0 ? "#2B3033" : "#22272B",
                        width: "100px",
                      }}
                    >
                      <Box
                        sx={{
                          height: "32px",
                        }}
                        className="flex__ width__100"
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleClickPermitType(event, row.id)
                        }
                      >
                        <MediumTypography label={row.data.permitType} />
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: "top",
                        border: "1px solid #323337",
                        backgroundColor:
                          index % 2 === 0 ? "#2B3033" : "#22272B",
                        width: "50px",
                      }}
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleClickPermitName(event, row.id)
                      }
                    >
                      <MediumTypography label={row.data.name} />
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: "top",
                        border: "1px solid #323337",
                        backgroundColor:
                          index % 2 === 0 ? "#2B3033" : "#22272B",
                        width: "50px",
                      }}
                    >
                      <MediumTypography label={row.data.description} />
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: "top",
                        border: "1px solid #323337",
                        backgroundColor:
                          index % 2 === 0 ? "#2B3033" : "#22272B",
                        width: "50px",
                      }}
                    >
                      <Delete
                        onClick={() => {
                          handleDeletePermits(row.data.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <SearchDropDown
            open={openPermitType}
            onClose={handleClosePermitType}
            anchorE1={anchorElPermitType}
            width="200px"
            searchWidth="170px"
            Data={permitTypes}
            onSelect={(id, name) => {
              if (activePermitRowId) {
                handleSelectPermitType(activePermitRowId, id, name);
                setActivePermitRowId(null);
              }
            }}
          />
          <SearchDropDown
            open={openPermitName}
            onClose={handleClosePermitName}
            anchorE1={anchorElPermitName}
            width="200px"
            searchWidth="170px"
            Data={permitNames}
            onSelect={(id, name) => {
              if (activePermitRowId) {
                handleSelectPermitName(activePermitRowId, id, name);
                setActivePermitRowId(null);
              }
            }}
          />
        </Table>
        {permitsTableData?.length === 0 && (
          <Box
            className="p-lg flex__container width__100"
            sx={{
              backgroundColor: "#22272B",

              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            <MediumTypography
              labelId="CM.EmptyMessage"
              defaultLabel="No records added yet"
            />
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default PermitsComponent;
