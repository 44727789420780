import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Grid,
  Backdrop,
  TableHead,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";

import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { hasPermission } from "../../../utils/checkPermission";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import TableHeaderSelection from "../TableHeaderSelection";
import { ReactComponent as Menu } from "../../../assets/images/HorizontalMenu.svg";
import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { ReactComponent as NoMappingIcon } from "../../../assets/images/NoMapping.svg";

import { useLocation } from "react-router-dom";
import {
  createAdvancedDropdownOptionApi,
  customSaveDataApi,
  fetchAllDataApi,
  fetchDataApi,
} from "../../../api/MasterDataManagement/Common";
import { HeadCell } from "../types/accountTypes";
import ImageIcon from "@mui/icons-material/Image";
import MapLineItemsModal from "./MapLineItemsModal";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import {
  ChecklistNameTypes,
  FetchMapChecklistMetadataType,
  ManageMapChecklistRecordsType,
  ManageMapChecklistRecordType,
  SaveMapChecklist,
  TaskGroupType,
} from "../types/mapChecklistTypes";
import SettingDrawer from "./SettingDrawer";
import { ReactComponent as DragIcon } from "../../../assets/images/DragIcon.svg";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import TextInput from "../../../components/formlib/TextInput";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { customUpdateDataApi } from "../../../api/wizardsApi/Common";

// Configurations

type RecordType = ManageMapChecklistRecordType;
type RecordsType = ManageMapChecklistRecordsType;

interface FormattedRecordType extends RecordType {
  type: string;
  serial: string;
}

interface FormattedTaskGroupType extends TaskGroupType {
  type: string;
}

type TableDataType = FormattedRecordType | FormattedTaskGroupType;

const apiUri = "checklistLineMap";

const staticColumn = "description";

export interface ManageComponentProps {
  onDataFetch: (data: RecordsType) => void;
  onOpenAddModal: boolean;
  handleModalClose: () => void;
  isWOMasterRelated?: boolean;
  woMasterId?: number;
  checklistMasterId?: number;
}

const ManageMapChecklistComponent: FC<ManageComponentProps> = ({
  onDataFetch,
  onOpenAddModal,
  handleModalClose,
  isWOMasterRelated,
  woMasterId,
  checklistMasterId,
}) => {
  const availableWidth = useAvailableWidth(420);
  const availableWidth1 = useAvailableWidth(540);
  const availableHeight = useAvailableHeight(360);
  const availableHeight1 = useAvailableHeight(360);
  const emptyTableHeight = useAvailableHeight(380);

  const containerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [listData, setListData] = useState<RecordsType | null>(null);
  const [checklistData, setChecklistData] = useState<ChecklistNameTypes[]>([]);
  const [selectedChecklistId, setSelectedChecklistId] = useState<number>(
    checklistMasterId ? checklistMasterId : 0
  );
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [taskGroups, setTaskGroups] = useState<TaskGroupType[]>([]);
  const [selectedTaskGroupId, setSelectedTaskGroupId] = useState<number>();
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<null | HTMLElement>(null);

  const open = Boolean(filterOpen);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerRow, setDrawerRow] = useState<RecordType>();

  const dataFieldsWithIcons = reorderedFields?.map((field) => {
    return { ...field, icon: <Sort /> };
  });

  const lineItemsCount = tableData.filter(
    (item) => item.type === "lineItem"
  ).length;

  const generateSerial = (items: TableDataType[]) => {
    let groupIndex = -1;
    let itemIndex = 0;

    return items.map((item) => {
      if (item.type === "taskGroup") {
        // groupIndex++;
        itemIndex = 0;
      } else if (item.type === "lineItem") {
        if (itemIndex === 0) {
          groupIndex++;
        }
        (item as FormattedRecordType).serial = `${groupIndex + 1}.${
          itemIndex + 1
        }`;
        itemIndex++;
      }
      return item;
    });
  };

  const getFormattedData = (
    data: RecordType[],
    taskGroupsData: TaskGroupType[]
  ): TableDataType[] => {
    const updatedTaskGroups = [
      { id: 0, name: "Ungrouped", sequenceId: 0 },
      ...taskGroupsData,
    ];
    return updatedTaskGroups
      .flatMap((taskGroup) => {
        const records = data.filter(
          (record) => record.taskGroupId === taskGroup.id
        );
        return [
          { ...taskGroup, type: "taskGroup" },
          ...records.map((record) => ({
            ...record,
            type: "lineItem",
            serial: "",
          })),
        ];
      })
      .filter((record) => !(record.type === "taskGroup" && record.id === 0));
  };

  const location = useLocation();

  useEffect(() => {
    // if (!isWOMasterRelated) {
    fetchMetadata();
    // }
  }, [location?.state]);

  useEffect(() => {
    getRecords(true);
  }, [selectedChecklistId]);

  const fetchMetadata = () => {
    toggleLoader(true);
    fetchDataApi<FetchMapChecklistMetadataType>(apiUri)
      .then(async (res) => {
        setChecklistData(res?.checklistNameAndTypes ?? []);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const getRecords = (resetData: boolean = false, language: string = "en") => {
    if (selectedChecklistId <= 0) return;

    toggleLoader(true);

    let filterQuery = Object.entries(activeFilters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    if (isWOMasterRelated) {
      filterQuery += `woMasterId=${woMasterId}`;
    } else {
      filterQuery += `&checkListMasterId=${selectedChecklistId}`;
    }
    let finalUrl = "";

    if (isWOMasterRelated) {
      finalUrl = "woMasterSchedule/checkLineItems";
    } else {
      finalUrl = apiUri;
    }

    fetchAllDataApi<RecordsType>(finalUrl, language, filterQuery)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          if (response.checkListBean) {
            setSelectedChecklistId(response.checkListBean.id);
          }
          setListData(response);

          setTaskGroups(response.taskGroups);

          const formattedData = getFormattedData(
            response.checkListLineItems,
            response.mappedTaskGroups
          );

          const newFormattedData = generateSerial(formattedData);

          setTableData(newFormattedData);

          setReorderedFields(response.dataFields);

          if (!isWOMasterRelated) {
            onDataFetch(response);
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const saveData = (params: TableDataType[]) => {
    toggleLoader(true);

    let taskGroupsList: FormattedTaskGroupType[] = [];
    let lineItemsList: FormattedRecordType[] = [];
    let currentTaskGroupId: number = 0;

    params.forEach((item) => {
      if (item.type === "taskGroup") {
        currentTaskGroupId = item.id;
        if (
          item.id !== 0 &&
          !taskGroupsList.find((taskGroup) => taskGroup.id === item.id)
        ) {
          taskGroupsList.push(item as FormattedTaskGroupType);
        }
      } else if (item.type === "lineItem") {
        const updatedItem = {
          ...(item as FormattedRecordType),
          taskGroupId: currentTaskGroupId,
        };
        lineItemsList.push(updatedItem);
      }
    });

    // Remove task groups with no line items
    taskGroupsList = taskGroupsList.filter((taskGroup) =>
      lineItemsList.find((lineItem) => lineItem.taskGroupId === taskGroup.id)
    );

    const updatedParams = {
      selectedCheckListMasterId: selectedChecklistId ?? 0,

      checkListLineItems: lineItemsList.map((item, index) => {
        const { type, serial, ...restData } = item;
        const updatedItem = {
          ...restData,
          sequenceId: index + 1,
        };
        return updatedItem;
      }),
      mappedTaskGroups: taskGroupsList.map((item, index) => {
        const { type, ...restData } = item;
        const updatedMappedItem = {
          ...restData,
          sequenceId: index + 1,
        };
        return updatedMappedItem;
      }),
    };
    let taskUpdateUrl = "";

    if (isWOMasterRelated) {
      const updatedWOMasterParams: Omit<
        SaveMapChecklist,
        "selectedCheckListMasterId"
      > = {
        checkListLineItems: lineItemsList.map((item, index) => {
          const { type, serial, ...restData } = item;
          const updatedItem = {
            ...restData,
            sequenceId: index + 1,
          };
          return updatedItem;
        }),
        mappedTaskGroups: taskGroupsList.map((item, index) => ({
          ...item,
          sequenceId: index + 1,
        })),
      };
      const WOUrl = `woMasterSchedule/checkLineItems/${woMasterId}/update?languageCode=en`;

      customSaveDataApi<SaveMapChecklist>(WOUrl, updatedWOMasterParams)
        .then(() => {
          getRecords(true);
          toggleLoader(false);
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    } else {
      taskUpdateUrl = `${apiUri}/${selectedChecklistId}/update?isVersionRequired=false&languageCode=en&isSequenceChanged=false`;
      customSaveDataApi<SaveMapChecklist>(taskUpdateUrl, updatedParams)
        .then(() => {
          getRecords(true);
          toggleLoader(false);
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }
  };

  const handleDrawerOpen = (row: RecordType) => {
    setOpenDrawer(true);
    setDrawerRow(row);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setDrawerRow(undefined);
  };

  const handleCloseMenu = () => {
    setFilterOpen(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterOpen(event.currentTarget);
  };

  const handleApply = (reorderedCheckedItems: HeadCell[]) => {
    setReorderedFields(reorderedCheckedItems);
    // Perform any additional actions with the reorderedCheckedItems
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = [...tableData];
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    // Update sequence
    const updatedItems = generateSerial(reorderedItems);

    setTableData(updatedItems);
  };

  const columnWidthObject: { [key: string]: string } = {
    serial: "100px",
    description: "auto",
    settings: "150px",
    weights: "180px",
    actions: "50px",
  };
  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Box>
      {openDrawer && drawerRow?.settings && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={openDrawer}
          />
          <SettingDrawer
            id={drawerRow.id}
            description={drawerRow.description}
            data={drawerRow.settings}
            open={openDrawer}
            handleClose={handleDrawerClose}
            getDataCallback={() => getRecords(true)}
            isWORelated={isWOMasterRelated}
          />
        </>
      )}
      {onOpenAddModal && selectedChecklistId > 0 && checklistData && (
        <MapLineItemsModal
          checklistData={checklistData}
          id={selectedChecklistId}
          onIdChange={(idValue: number) => setSelectedChecklistId(idValue)}
          open={onOpenAddModal}
          handleClose={() => {
            handleModalClose();
          }}
          getDataCallback={() => getRecords(true)}
          isWORelated={isWOMasterRelated}
          woMasterId={woMasterId}
          checkListMasterId={selectedChecklistId}
        />
      )}
      {onOpenAddModal && selectedChecklistId <= 0 && (
        <ErrorModal
          descriptionText={"Please select checklist"}
          open={onOpenAddModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleModalClose();
          }}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box>
        <Box className="flex__justify__space-between mb-md">
          <MediumTypography
            labelId="checklistMap.title2"
            defaultLabel="Map Line Items"
            fontSize="20px"
          />
          {listData && tableData?.length > 0 && (
            <Box className="display-flex-center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                sxProps={{ marginRight: "4px" }}
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData?.totalRecords || 0
                    : `${tableData.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />
              <Box
                className="ml-md display-flex-center menu-item"
                onClick={handleMenuClick}
              >
                <Menu />
              </Box>
              <TableHeaderSelection
                data={listData.dataFields}
                anchorEl={filterOpen}
                handleClose={handleCloseMenu}
                open={open}
                onApply={handleApply}
                fixedItem={staticColumn}
              />
            </Box>
          )}
        </Box>
        <Box className="pb-md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <MediumTypography
                className="input-label"
                labelId="checklist.title"
                defaultLabel="Check List"
              />

              <CustomDropdown
                disableCreate={true}
                disabled={isWOMasterRelated}
                options={getOptions(checklistData)}
                selectedValue={selectedChecklistId}
                onSelectedValueChange={(value) => {
                  setSelectedChecklistId(Number(value as string) ?? 0);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MediumTypography
                className="input-label"
                labelId="checklist.checklistType"
                defaultLabel="Check List Type"
              />

              <TextInput
                className="text-input-field"
                type="text"
                variant="outlined"
                inputProps={{
                  readOnly: true,
                }}
                disabled={true}
                Value={
                  checklistData?.find(
                    (item) =>
                      item.id?.toString() === selectedChecklistId?.toString()
                  )?.checklistType ?? ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MediumTypography
                className="input-label"
                labelId="btn.addTG"
                defaultLabel="Add Task Group"
              />
              <Box display={"flex"}>
                <Box flexGrow={1}>
                  <CustomDropdown
                    options={getOptions(taskGroups)}
                    selectedValue={selectedTaskGroupId}
                    onSelectedValueChange={(value) => {
                      setSelectedTaskGroupId(
                        Number(value as string) || undefined
                      );
                    }}
                    onCreateOption={(option) => {
                      toggleLoader(true);
                      createAdvancedDropdownOptionApi<TaskGroupType>(
                        "TASK_GROUP_TYPE",
                        "TaskGroupType",
                        option
                      )
                        .then((res) => {
                          setTaskGroups(() => res);
                          setSelectedTaskGroupId(
                            res.find((acc) => acc.name === option)?.id ||
                              undefined
                          );
                          toggleLoader(false);
                        })
                        .catch((err) => {
                          handleError(err, setErrorDesc);
                          setOpenErrorModal(true);
                          toggleLoader(false);
                        });
                    }}
                  />
                </Box>
                <Box>
                  {selectedTaskGroupId && selectedTaskGroupId > 0 && (
                    <Box sx={{ pl: "15px" }}>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Add"}
                        labelId={"btn.Add"}
                        onClick={() => {
                          const selectedTaskGroup = taskGroups.find(
                            (taskGroup) => taskGroup.id === selectedTaskGroupId
                          );

                          if (selectedTaskGroup) {
                            if (
                              !tableData.some(
                                (tblData) =>
                                  tblData.type === "taskGroup" &&
                                  tblData.id === selectedTaskGroup.id
                              )
                            ) {
                              setTableData((prev) => [
                                {
                                  ...selectedTaskGroup,
                                  type: "taskGroup",
                                },
                                ...prev,
                              ]);
                            } else {
                              setErrorDesc("tg.addedWarning");
                              setOpenErrorModal(true);
                            }
                          }

                          setSelectedTaskGroupId(undefined);
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            height: isWOMasterRelated ? availableHeight1 : availableHeight,

            "&::-webkit-scrollbar": { display: "none" },
          }}
          className={"position__relative overflow_Y"}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: isWOMasterRelated ? availableWidth1 : availableWidth,
              height: lineItemsCount !== 0 ? availableHeight : "auto",
              "&::-webkit-scrollbar": { display: "none" },
            }}
            className={"tableContainer"}
            onScroll={() => {}}
            ref={containerRef}
          >
            <Table>
              <TableHead>
                {lineItemsCount !== 0 && (
                  <TableRow>
                    {/* Sticky First Column */}
                    <TableCell
                      align={"left"}
                      sx={{
                        verticalAlign: "top",
                        backgroundColor: "#22272B",
                        padding: "16px 16px",
                        borderBottom: "none",
                        position: "sticky",
                        top: 0,
                        left: 0,
                        zIndex: 2,
                      }}
                      style={{ width: columnWidthObject["serial"] }}
                    >
                      <MediumTypography
                        sxProps={{
                          fontWeight: 700,
                          color: "#FFFFFF",
                          whiteSpace: "nowrap",
                        }}
                        labelId="srNo"
                        defaultLabel="Sr. No."
                      />
                    </TableCell>

                    {dataFieldsWithIcons.map((headItem) => (
                      <TableCell
                        key={headItem.key}
                        align={"left"}
                        sx={{
                          verticalAlign: "top",
                          backgroundColor: "#22272B",
                          padding: "16px 16px",
                          borderBottom: "none",
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                        }}
                        style={{ width: columnWidthObject[headItem.key] }}
                      >
                        <Box className="flex__justify__space-between">
                          <MediumTypography
                            sxProps={{
                              fontWeight: 700,
                              color: "#FFFFFF",
                              whiteSpace: "nowrap",
                            }}
                            label={
                              headItem.value === "Weightage - 100%"
                                ? "Weightage"
                                : headItem.value
                            }
                          />
                        </Box>
                      </TableCell>
                    ))}

                    {/* Sticky Last Column */}

                    <TableCell
                      align={"left"}
                      sx={{
                        verticalAlign: "top",
                        backgroundColor: "#22272B",
                        padding: "8px 16px",
                        borderBottom: "none",
                        position: "sticky",
                        top: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                      style={{ width: columnWidthObject["action"] }}
                    />
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} sx={{ p: "5px" }} />
                </TableRow>
              </TableBody>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provider) => {
                    return (
                      <TableBody
                        {...provider.droppableProps}
                        ref={provider.innerRef}
                      >
                        {lineItemsCount !== 0 &&
                          tableData.map((tableRow, rowIndex) => {
                            return (
                              <>
                                {rowIndex === 0 && (
                                  <TableRow>
                                    <TableCell colSpan={5} sx={{ padding: 0 }}>
                                      <Box
                                        sx={{
                                          mt: "10px",
                                          mb: "15px",
                                          pt: "10px",
                                          pb: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box sx={{ mr: "10px" }}>
                                          <MediumTypography
                                            sxProps={{
                                              color: "#ccc",
                                              fontSize: "16px",
                                            }}
                                            defaultLabel="Ungrouped"
                                            labelId="mapCheckList.ungrouped"
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            flexGrow: 1,

                                            borderTop: "2px #666 solid",
                                          }}
                                        ></Box>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                                <Draggable
                                  key={tableRow.type + tableRow.id}
                                  draggableId={tableRow.type + tableRow.id}
                                  index={rowIndex}
                                >
                                  {(provided) => {
                                    if (tableRow.type === "taskGroup") {
                                      const taskGroupRow =
                                        tableRow as TaskGroupType;
                                      return (
                                        <TableRow
                                          key={"taskGroup" + taskGroupRow.id}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <TableCell
                                            colSpan={5}
                                            sx={{ padding: 0 }}
                                          >
                                            <Box
                                              sx={{
                                                mt: "10px",
                                                mb: "15px",
                                                pt: "10px",
                                                pb: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Box sx={{ mr: "10px" }}>
                                                <MediumTypography
                                                  sxProps={{
                                                    color: "#ccc",
                                                    fontSize: "16px",
                                                  }}
                                                  label={taskGroupRow.name}
                                                />
                                              </Box>
                                              <Box
                                                sx={{
                                                  flexGrow: 1,

                                                  borderTop: "2px #666 solid",
                                                }}
                                              ></Box>
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }

                                    const row = tableRow as FormattedRecordType;

                                    return (
                                      <TableRow
                                        key={"lineItem" + row.id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="no-marker "
                                        sx={{
                                          backgroundColor:
                                            rowIndex % 2 === 0
                                              ? "#2B3033"
                                              : "#22272B",
                                        }}
                                      >
                                        {/* Sticky First Column */}
                                        <TableCell
                                          align={"left"}
                                          className="stickyColumn"
                                          sx={{
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                        >
                                          <Box className="flex__justify__space-between">
                                            <MediumTypography
                                              label={row.serial}
                                            />
                                            <DragIcon />
                                          </Box>
                                        </TableCell>

                                        {dataFieldsWithIcons.map(
                                          (cell: HeadCell) => {
                                            const cellValue =
                                              row[cell.key as keyof RecordType];

                                            if (cell.key === "settings") {
                                              return (
                                                <TableCell
                                                  key={cell.key}
                                                  align={"left"}
                                                  sx={{
                                                    backgroundColor:
                                                      rowIndex % 2 === 0
                                                        ? "#2B3033"
                                                        : "#22272B",
                                                  }}
                                                  className="p-md"
                                                >
                                                  <Box
                                                    className="display-flex-center"
                                                    gap={1}
                                                  >
                                                    {row.settings
                                                      ?.valueCapture && (
                                                      <Box
                                                        sx={{
                                                          border:
                                                            "1px solid rgba(159, 173, 188, 1)",
                                                          borderRadius: "4px",
                                                          padding: "2px",
                                                        }}
                                                      >
                                                        <MediumTypography
                                                          label={"123"}
                                                          fontSize="12px"
                                                          textColor="rgba(159, 173, 188, 1)"
                                                          sxProps={{
                                                            lineHeight:
                                                              "normal",
                                                          }}
                                                        />
                                                      </Box>
                                                    )}
                                                    {row.settings
                                                      ?.imageCapture && (
                                                      <ImageIcon
                                                        sx={{
                                                          width: "22px",
                                                          height: "22px",
                                                          color:
                                                            "rgba(159, 173, 188, 1)",
                                                        }}
                                                      />
                                                    )}
                                                    {row.settings
                                                      ?.referenceValue &&
                                                      row.settings
                                                        .referenceValue !==
                                                        "" && (
                                                        <Box
                                                          sx={{
                                                            border:
                                                              "1px solid rgba(159, 173, 188, 1)",
                                                            borderRadius: "4px",
                                                            padding: "2px",
                                                          }}
                                                        >
                                                          <MediumTypography
                                                            label={"REF"}
                                                            fontSize="12px"
                                                            textColor="rgba(159, 173, 188, 1)"
                                                            sxProps={{
                                                              lineHeight:
                                                                "normal",
                                                            }}
                                                          />
                                                        </Box>
                                                      )}
                                                    {!row.settings
                                                      ?.valueCapture &&
                                                      !row.settings
                                                        ?.imageCapture &&
                                                      (!row.settings
                                                        ?.referenceValue ||
                                                        row.settings
                                                          .referenceValue ===
                                                          "") && (
                                                        <MediumTypography
                                                          label={"-"}
                                                        />
                                                      )}
                                                  </Box>
                                                </TableCell>
                                              );
                                            }

                                            return (
                                              <>
                                                {cell.key === staticColumn ? (
                                                  <TableCell
                                                    key={cell.key}
                                                    align={"left"}
                                                    sx={{
                                                      backgroundColor:
                                                        rowIndex % 2 === 0
                                                          ? "#2B3033"
                                                          : "#22272B",
                                                    }}
                                                    className="p-md position__sticky"
                                                  >
                                                    <Box
                                                      display={"flex"}
                                                      gap={2}
                                                      alignItems={"center"}
                                                    >
                                                      {/* <Box sx={{ width: "12px" }}>
                                                    <DragIcon />
                                                  </Box> */}
                                                      <Box>
                                                        <MediumTypography
                                                          label={
                                                            cellValue !==
                                                              undefined &&
                                                            cellValue !==
                                                              null &&
                                                            cellValue !== ""
                                                              ? cellValue.toString()
                                                              : "-"
                                                          }
                                                        />
                                                      </Box>
                                                    </Box>
                                                  </TableCell>
                                                ) : (
                                                  <TableCell
                                                    key={cell.key}
                                                    align={"left"}
                                                    sx={{
                                                      backgroundColor:
                                                        rowIndex % 2 === 0
                                                          ? "#2B3033"
                                                          : "#22272B",
                                                    }}
                                                    className="p-md"
                                                  >
                                                    <MediumTypography
                                                      label={
                                                        cellValue !==
                                                          undefined &&
                                                        cellValue !== null &&
                                                        cellValue !== ""
                                                          ? cellValue.toString()
                                                          : "-"
                                                      }
                                                    />
                                                  </TableCell>
                                                )}
                                              </>
                                            );
                                          }
                                        )}

                                        {/* Sticky Last Column */}
                                        <TableCell
                                          align={"left"}
                                          sx={{
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                          className="stickyColumn_right"
                                        >
                                          <Tooltip
                                            title={
                                              <MediumTypography
                                                labelId="common.settings"
                                                defaultLabel="Settings"
                                                fontSize="10px"
                                              />
                                            }
                                          >
                                            <IconButton
                                              onClick={() => {
                                                if (
                                                  hasPermission(
                                                    "Onboarding_Admin"
                                                  )
                                                ) {
                                                  handleDrawerOpen(row);
                                                }
                                              }}
                                            >
                                              <More />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }}
                                </Draggable>
                              </>
                            );
                          })}
                        {provider.placeholder}
                      </TableBody>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
          {lineItemsCount === 0 && (
            <Box
              sx={{
                height: emptyTableHeight,
              }}
              className="map_lineItem "
            >
              <NoMappingIcon />
              <MediumTypography
                labelId="checklistMap.noMapping"
                defaultLabel="No Line Items Mapped"
                fontSize="16px"
                fontWeight={600}
                textColor="rgba(140, 155, 171, 1)"
              />
            </Box>
          )}
        </Box>
      </Box>
      {lineItemsCount > 0 && (
        <Box
          // mt={isWOMasterRelated ? "-10px" : "10px"}
          mt={"10px"}
          // mr={"-20px"}
          className="flex__ justifyContent-FlexEnd"
        >
          <ButtonComponent
            className="btn-primary btn-submit"
            variantType="contained"
            defaultLabelId={"Save"}
            labelId={"btn.save"}
            onClick={() => {
              saveData(tableData);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ManageMapChecklistComponent;
