import React, { FC, useState } from "react";
import { Popover, Box, IconButton } from "@mui/material";
import CustomDropdown, {
  OptionType,
} from "../../../components/formlib/CustomDropdown";

interface CustomDropdownPopoverProps
  extends React.ComponentProps<typeof CustomDropdown> {
  selectedOption: OptionType | OptionType[] | undefined;
  renderTrigger: (
    selectedOption: OptionType | OptionType[] | undefined
  ) => React.ReactNode;
  popoverProps?: Partial<React.ComponentProps<typeof Popover>>;
}

const CustomDropdownPopover: FC<CustomDropdownPopoverProps> = ({
  selectedOption,
  renderTrigger,
  popoverProps,
  ...props // Capture additional props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "custom-dropdown-popover" : undefined;

  let selectedValue: string | string[] = "";
  if (selectedOption) {
    if (props?.multiple) {
      selectedValue = selectedOption.map((item: OptionType) => item.id);
    } else {
      selectedValue = (selectedOption as OptionType).id;
    }
  }

  return (
    <Box>
      {/* Trigger */}
      <Box
        onClick={props.disabled ? undefined : handleClick}
        sx={{ cursor: props.disabled ? "initial" : "pointer" }}
      >
        {renderTrigger(selectedOption)}
      </Box>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            background: "none",
          },
        }}
        {...popoverProps}
      >
        <Box>
          <CustomDropdown
            autoOpen
            selectedValue={selectedValue}
            onPopoverClose={handleClose}
            {...props}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default CustomDropdownPopover;
