import { Avatar, Tooltip } from "@mui/material";
import { FunctionComponent } from "react";
import MediumTypography from "./MediumTypography";

interface CustomAvatarProps {
  name: string;
  image: string;
  avatarProps?: Partial<React.ComponentProps<typeof Avatar>>;
  tooltipProps?: Partial<React.ComponentProps<typeof Tooltip>>;
}

const CustomAvatar: FunctionComponent<CustomAvatarProps> = ({
  name,
  image,
  avatarProps,
  tooltipProps,
}) => {
  return (
    <Tooltip
      title={<MediumTypography className="tooltip-text" label={name} />}
      arrow
      {...tooltipProps}
    >
      <Avatar src={image} alt="Profile Photo" {...avatarProps} />
    </Tooltip>
  );
};

export default CustomAvatar;
