import { useState, useEffect, useContext } from "react";
import { Box, List, ListItem, IconButton, ListItemButton } from "@mui/material";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MediumTypography from "../../../components/formlib/MediumTypography";
import "../css/TicketFormBuilder.css";
import AddEditTicketTypeModal from "./AddEditTicketTypeModal";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import {
  deleteTypeApi,
  fetchAllCustomFieldApi,
} from "../../../api/ticketing/TicketingAPI";
import { TicketType } from "../types/formBuilder";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import "../css/styles.css";
import { useLocation, useNavigate } from "react-router-dom";

interface Ticket {
  selectedTicketID: (id: number, toggle: boolean) => void;
  refreshTicketTypes: boolean;
  resetRefreshTicketTypes: () => void;
}

const initialData: TicketType = {
  id: 0,
  name: "",
  description: "",
  isMapped: false,
};

const apiUri = "ticketType";

const EditableList = ({
  selectedTicketID,
  refreshTicketTypes,
  resetRefreshTicketTypes,
}: Ticket) => {
  const location = useLocation();
  const availableHeight = useAvailableHeight(300);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [Individualitem, setItem] = useState<TicketType>(initialData);
  const [modalAccountId, setModalAccountId] = useState<number>();
  const [onOpenAddModal, setOnOpenAddModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<null | number>();
  const [ticketTypeArray, setTicketTypeArray] = useState<TicketType[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const navigate = useNavigate();

  const handleItemClick = (id: number) => {
    navigate("/master-data-management", {
      state: {
        toggle: true,
        id: id,
        numbers: [],
      },
    });
    setSelectedItem((prevSelected) => (prevSelected === id ? null : id));
    selectedTicketID(id, true);
  };

  useEffect(() => {
    if (refreshTicketTypes) {
      getAllCustomFields();
      resetRefreshTicketTypes();
    }
  }, [refreshTicketTypes]);
  useEffect(() => {
    getAllCustomFields();
  }, []);

  const getAllCustomFields = (language: string = "en") => {
    toggleLoader(true);
    fetchAllCustomFieldApi<TicketType[]>(apiUri, language)
      .then((response: TicketType[]) => {
        toggleLoader(false);
        if (response) {
          if (response.length !== 0) {
            setTicketTypeArray(response);
            if (location.state !== null) {
              if (
                location.state?.id !== undefined &&
                location.state?.id !== null
              ) {
                setSelectedItem(location.state?.id);
                selectedTicketID(location.state?.id, false);
              } else {
                setSelectedItem(response[0].id);
                selectedTicketID(response[0].id, false);
              }
            } else {
              setSelectedItem(response[0].id);
              selectedTicketID(response[0].id, false);
            }
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const handleEdit = (id: number) => {
    setModalAccountId(id);
    ticketTypeArray.filter((item_tick) => {
      if (item_tick.id === id) {
        setItem(item_tick);
      }
      return;
    });

    setOnOpenAddModal(true);
  };

  const handleDelete = (id: number) => {
    toggleLoader(true);
    deleteTypeApi(apiUri, id, "en")
      .then(() => {
        getAllCustomFields();
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });

    setModalAccountId(undefined);
  };
  const handleConfirmDelete = () => {
    if (modalAccountId) {
      handleDelete(modalAccountId);
    }
    setWarningModal(false);
  };
  return (
    <>
      {warningModal && (
        <ModalPopup
          descriptionText={"tickettype.deleteWarningmessage"}
          open={warningModal}
          handleClose={() => setWarningModal(!warningModal)}
          onPositiveClick={() => handleConfirmDelete()}
          onNegativeClick={() => {
            setWarningModal(false);
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      {onOpenAddModal && (
        <AddEditTicketTypeModal
          tickettypes={ticketTypeArray}
          getDataCallback={getAllCustomFields}
          formData={Individualitem}
          id={modalAccountId}
          open={onOpenAddModal}
          handleClose={() => {
            setModalAccountId(undefined);
            setOnOpenAddModal(false);
          }}
        />
      )}

      <Box
        className="flex__"
        sx={{
          flexDirection: "column",
          height: availableHeight - 50,
        }}
      >
        <Box className="scroll-on-hover special-filter-box ticket-list-box">
          <List>
            {ticketTypeArray?.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                  onClick={() =>
                    selectedItem !== item.id
                      ? handleItemClick(item.id ?? 0)
                      : ""
                  }
                  selected={selectedItem === item.id}
                  className="filter-list-item"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Box>
                    <MediumTypography labelId={item.name} />
                  </Box>
                  <Box display={"flex"} gap={2}>
                    {selectedItem === item.id && (
                      <IconButton
                        sx={{
                          padding: 0,
                        }}
                        onClick={() => handleEdit(item.id ?? 0)}
                      >
                        <EditIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#fff",
                            "&:hover": {
                              color: "rgba(37, 186, 250, 1)",
                            },
                          }}
                        />
                      </IconButton>
                    )}

                    {selectedItem === item.id && !item.isMapped && (
                      <IconButton
                        sx={{
                          padding: 0,
                        }}
                        onClick={() => {
                          setModalAccountId(item.id);
                          setWarningModal(true);
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#fff",
                            "&:hover": {
                              color: "rgba(37, 186, 250, 1)",
                            },
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <ButtonComponent
        className="btn-primary btn-ticket"
        variantType="contained"
        onClick={() => {
          setOnOpenAddModal(true);
        }}
        labelId={"tickettype.addButton"}
        defaultLabelId="Add Ticket Type"
      />
    </>
  );
};

export default EditableList;
