import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import {
  Asset,
  CreateWOResponse,
  Role,
  TableContentData,
  createWorkOrderType,
  shiftDetailsType,
} from "./types";
import { useContext, useEffect, useState } from "react";
import DropdownComponent from "../../../components/formlib/DropdownComponent";
import { OptionType } from "../../../utils/type";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";
import TextInput from "../../../components/formlib/TextInput";
import { ReactComponent as DownArrow } from "../../../assets/images/downarrow2.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/uparrow2.svg";
import CheckBox from "../../../components/formlib/CheckBox";
import { useIntl } from "react-intl";
import AssetGroupCategoryDropDown from "./AssetGroupCategoryDropDown";
import { ReactComponent as Delete } from "../../../assets/images/trash.svg";
import SearchDropDown from "../../../components/formlib/SearchDropdown";
import SetAssetsAssignee, {
  AssigneeType,
  CMSetType,
} from "./SetAssetsAssignee";
import SwitchComponent from "../../../components/SwitchComponent";
import {
  DropdownContext,
  LoaderContext,
  LoaderContextType,
} from "../../../layouts/appSidebar";
import {
  SaveWORequest,
  getCreateWorkOrderDetailsApi,
  getSkillSetAssignees,
  saveWorkOrder,
} from "../../../api/correctiveMaintenance/createWorkOrder";
import dayjs from "dayjs";

import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { useLocation, useNavigate } from "react-router-dom";

import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import { SCHEDULER_DASHBOARD } from "../../../routes/Routing";
import { handleError } from "../../../utils/commonFunctions";

interface RowData {
  id: string;
  data: TableContentData;
}

interface CategoryRows {
  [categoryName: string]: RowData[];
}
interface SelectedSubCategoryMap {
  [key: string]: DropDownType;
}
interface ValidationErrors {
  selectedWorkOrderType: string;
  selectedAssets: string;
  estimatedEfforts: string;
  approver: string;
  selectedPermits: string;
  assigneeDetailsSets: string;
  observation: string;
}

const CreateWorkOrder = () => {
  const [createWorkOrder, setCreateWorkOrder] =
    useState<createWorkOrderType | null>(null);
  const [estimatedEfforts, setEstimatedEfforts] = useState(0);
  const [observation, setObservation] = useState("");
  const [permitExpand, setPermitExpand] = useState(false);
  const [selectedPermits, setSelectedPermits] = useState<number[]>([]);
  const [selectedSkills, setselectedSkills] = useState<number[]>([]);
  const [toolsSection, setToolsSection] = useState(false);
  const [approverExpand, setApproverExpand] = useState(false);
  const [openSkills, setOpenSkills] = useState(false);
  const [safetyCheckRequired, setSafetyCheckRequired] = useState(false);
  const intl = useIntl();
  const [selectedShiftId, setSelectedShiftId] = useState<number>(0);
  const [selectApprover1, setSelectApprover1] = useState<number>(0);
  const [selectApprover2, setSelectApprover2] = useState<number>(0);
  const [selectApprover3, setSelectApprover3] = useState<number>(0);
  const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
  const [selectedAssetCategoryId, setSelectedAssetCategoryId] = useState(0);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState(0);
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [toolsTableData, setToolsTableData] = useState<CategoryRows>({});
  const [subCategories, setSubCategories] = useState<DropDownType[]>([]);
  const [applyPermits, setApplyPermits] = useState(selectedPermits.length);
  const [woUniqueID, setWoUniqueID] = useState(0);
  // const [woId, setWOId] = useState("");
  const [assigneeDetails, setAssigneeDetails] = useState<AssigneeType>({
    shift: {
      shiftId: 0,
      shiftName: "",
      shiftStartTime: "",
      shiftEndTime: "",
      memberCount: 0,
      shiftStaticName: "",
      teams: [],
    },
    sets: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SelectedSubCategoryMap>({});
  const [codes, setCodes] = useState<DropDownType[]>([]);
  const [assigneeExpand, setAssigneeExpand] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState<SelectedSubCategoryMap>(
    {}
  );
  const [description, setDescription] = useState<DropDownType[]>([]);
  const [selectedDescriptions, setSelectedDescriptions] =
    useState<SelectedSubCategoryMap>({});
  //subCategory
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //codes
  const [openCode, setOpenCode] = useState(false);
  const [anchorElCode, setAnchorElCode] = useState<null | HTMLElement>(null);
  //description
  const [openDescription, setOpenDescription] = useState(false);
  const [anchorElDescription, setAnchorElDescription] =
    useState<null | HTMLElement>(null);

  const [activeRowId, setActiveRowId] = useState<string | null>(null);
  const [selectedWorkOrderType, setSelectedWorkOrderType] = useState<number>(0);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openErrorModalSkills, setOpenErrorModalSkills] =
    useState<boolean>(false);

  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false);
  const [createErrorModal, setCreateErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [shiftArrayCopy, setShiftArrayCopy] = useState<shiftDetailsType[] | []>(
    []
  );
  const [skillsShiftArrayCopy, setSkillsShiftArrayCopy] = useState<
    shiftDetailsType[] | []
  >([]);
  const [teamLength, setTeamLength] = useState(0);

  const location = useLocation();
  const selectedSubgroupId = location.state.selectedSubgroupId;
  const navState = location?.state;

  const context = useContext(DropdownContext);
  const navigate = useNavigate();

  //subcategory
  const handleClickSubCategory = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseSubCategory = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  //codes
  const handleClickCodes = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElCode(event.currentTarget);
    setOpenCode(true);
  };

  const handleCloseCodes = () => {
    setOpenCode(false);
    setAnchorElCode(null);
  };
  //description
  const handleClickDescription = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElDescription(event.currentTarget);
    setOpenDescription(true);
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
    setAnchorElDescription(null);
  };

  const getCreateWorkOrderDetails = () => {
    toggleLoader(true);

    const date = location?.state
      ? dayjs(location?.state?.date).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");

    if (selectedSubgroupId) {
      getCreateWorkOrderDetailsApi(selectedSubgroupId, date)
        .then((response) => {
          setCreateWorkOrder(response);
          toggleLoader(false);
          setSelectedShiftId(response.selectedShiftId);
          setShiftArrayCopy(response.shiftDetails);
          setActiveCategory(response.toolsBeanList[0]?.toolCategoryName ?? "");
          setSelectApprover3(response.selectedApprover3RoleId);
          setSafetyCheckRequired(response.safetyCheckReqAfterCompletion);
        })
        .catch((err) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          handleError(err, setErrorDesc);
        });
    }
  };

  const extractSelectedIdsFromToolsTableData = (
    data: CategoryRows
  ): number[] => {
    const selectedIds: number[] = [];

    Object.entries(data).forEach(([_, categoryData]) => {
      if (Array.isArray(categoryData)) {
        categoryData.forEach((row) => {
          // Check if both code and description indicate a selection is made
          if (row.data.code && row.data.description) {
            selectedIds.push(row.data.id);
          }
        });
      }
    });

    return selectedIds;
  };

  const isAssignButtonDisabled = (sets: CMSetType[]) => {
    return sets.some(
      (set) =>
        (set.assets.length > 0 &&
          (set.assignedUsers.length === 0 ||
            set.assignedUsers[0].assigneeId === 0)) ||
        (set.assets.length === 0 &&
          set.assignedUsers.length > 0 &&
          set.assignedUsers[0].assigneeId !== 0)
    );
  };

  const extractedIds = extractSelectedIdsFromToolsTableData(toolsTableData);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    selectedWorkOrderType: "",
    selectedAssets: "",
    estimatedEfforts: "",
    approver: "",
    selectedPermits: "",
    assigneeDetailsSets: "",
    observation: "",
  });

  const validateSelectedAssets = () => {
    let errors: Partial<ValidationErrors> = {};
    let isValid = true;

    if (isAssignButtonDisabled(assigneeDetails.sets)) {
      isValid = false;
      errors["assigneeDetailsSets"] = intl.formatMessage({
        id: "validation.assigneeDetailsRequired",
        defaultMessage: "Assignee required for asset set",
      });
    }

    // Update the state with the new errors object or handle it accordingly
    setValidationErrors((currentErrors) => ({
      ...currentErrors,
      ...errors,
    }));

    return isValid;
  };
  // Validation function
  const validateForm = () => {
    let errors: Partial<ValidationErrors> = {};
    let formIsValid = true;

    // Use intl.formatMessage to get localized messages
    // Validate selectedWorkOrderType
    if (!selectedWorkOrderType) {
      formIsValid = false;
      errors["selectedWorkOrderType"] = intl.formatMessage({
        id: "validation.workOrderTypeRequired",
        defaultMessage: "Work order type is required.",
      });
    }

    // Repeat for other validations...
    if (!selectedAssets.length) {
      formIsValid = false;
      errors["selectedAssets"] = intl.formatMessage({
        id: "validation.assetsRequired",
        defaultMessage: "Assets are required.",
      });
    }

    if (estimatedEfforts === 0) {
      formIsValid = false;
      errors["estimatedEfforts"] = intl.formatMessage({
        id: "validation.effortsRequired",
        defaultMessage: "Estimated efforts are required.",
      });
    }

    if (!selectedPermits.length) {
      formIsValid = false;
      errors["selectedPermits"] = intl.formatMessage({
        id: "validation.permitsRequired",
        defaultMessage: "At least one permit must be required.",
      });
    }

    if (!selectApprover1 || !selectApprover2) {
      formIsValid = false;
      errors["approver"] = intl.formatMessage({
        id: "validation.approversRequired",
        defaultMessage: "Approvers are required.",
      });
    }

    if (observation.trim() === "") {
      formIsValid = false;
      errors["observation"] = intl.formatMessage({
        id: "validation.observationRequired",
        defaultMessage: "Observation is required.",
      });
    }

    // Continue with the rest of the validations...

    setValidationErrors((currentErrors) => ({
      ...currentErrors, // Spread the current state to maintain all existing properties
      ...errors, // Apply updates, overriding only the properties provided in errors
    }));
    return formIsValid;
  };
  useEffect(() => {
    validateSelectedAssets();
  }, [selectedAssets, assigneeDetails.sets]);

  const handleCreate = () => {
    const isAssetsValid = validateSelectedAssets();

    if (validateForm() && isAssetsValid) {
      const setsWithAssignee = assigneeDetails.sets?.filter((set) =>
        set.assignedUsers.some((user) => user.assigneeId !== 0)
      );
      const totalAssetsCount = setsWithAssignee.reduce(
        (acc, currentSet) => acc + currentSet.assets.length,
        0
      );

      let isAsset0 = false;

      if (totalAssetsCount === 0) {
        return;
      } else if (totalAssetsCount === 1) {
        isAsset0 = true;
      }

      const updatedSetAssignees = setsWithAssignee.map((set, index) => {
        return {
          ...set,
          setId: isAsset0 ? index : index + 1,
        };
      });

      const body: SaveWORequest = {
        woTypeId: selectedWorkOrderType,
        assetGroupId: selectedAssetGroupId,
        assetCategoryId: selectedAssetCategoryId,
        approver1: selectApprover1,
        approver2: selectApprover2,
        approver3: selectApprover3,
        assetId: selectedAssets,
        estimatedEffort: estimatedEfforts,
        skillCheckRequired: openSkills,
        safetyCheckReqAfterCompletion: safetyCheckRequired,
        selectedPermitIds: selectedPermits,
        selectedShiftId: selectedShiftId,
        selectedSkills: selectedSkills,
        setAssignees: updatedSetAssignees,
        selectedToolIds: extractedIds,
        observation: observation,
      };

      if (selectedSubgroupId) {
        saveWorkOrder(
          context.selectedSubgroupId,
          dayjs().format("YYYY-MM-DD"),
          body
        )
          .then((response: CreateWOResponse) => {
            toggleLoader(false);
            setWoUniqueID(response.woUniqueId);
            setSaveSuccessModal(true);
          })
          .catch((err) => {
            toggleLoader(false);
            setCreateErrorModal(true);
            handleError(err, setErrorDesc);
          });
      }
    }
  };

  //skills api
  const getSkilledAssignees = () => {
    toggleLoader(true);

    const date = dayjs().format("YYYY-MM-DD");
    if (context.selectedSubgroupId) {
      getSkillSetAssignees(context.selectedSubgroupId, date, selectedSkills)
        .then((response) => {
          if (response && createWorkOrder) {
            setCreateWorkOrder({
              ...createWorkOrder,
              shiftDetails: response,
            });
            setSkillsShiftArrayCopy(response);
          }
          toggleLoader(false);
        })
        .catch((err) => {
          toggleLoader(false);
          setOpenErrorModalSkills(true);
          handleError(err, setErrorDesc);
        });
    }
  };

  useEffect(() => {
    getCreateWorkOrderDetails();
  }, []);

  useEffect(() => {
    if (createWorkOrder?.shiftDetails && selectedShiftId) {
      const selectedShift = createWorkOrder.shiftDetails.find(
        (shift) => shift.shiftId === selectedShiftId
      );

      if (selectedShift) {
        setAssigneeDetails((prevDetails) => ({
          ...prevDetails,
          shift: {
            shiftId: selectedShift.shiftId,
            shiftName: selectedShift.shiftName,
            shiftStaticName: selectedShift.shiftStaticName,
            shiftStartTime: selectedShift.shiftStartTime,
            shiftEndTime: selectedShift.shiftEndTime,
            teams: selectedShift.shiftMembers,
            memberCount:
              selectedShift.shiftMembers && selectedShift.shiftMembers.length,
          },
        }));
        setTeamLength(selectedShift.shiftMembers.length);
      }
    }
  }, [createWorkOrder?.shiftDetails, selectedShiftId]);

  // Update for asset details
  useEffect(() => {
    if (createWorkOrder?.assetGroups && selectedAssets) {
      const selectedAssetsDetails: Asset[] =
        createWorkOrder.assetGroups.flatMap((assetGroup) =>
          assetGroup.assetCategories.flatMap((assetCategory) =>
            assetCategory.assets.filter((asset) =>
              selectedAssets.includes(asset.id)
            )
          )
        );

      if (selectedAssetsDetails.length > 0) {
        setAssigneeDetails((prevDetails) => ({
          ...prevDetails,
          sets: [
            {
              setId: 0, // Adjust setId as needed
              assignedUsers: [],
              assets: selectedAssetsDetails,
            },
          ],
        }));
      }
    }
  }, [selectedAssets]);
  const assetCallBack = (
    value: number[],
    selectedAssetGroups: number,
    assetCategories: number
  ) => {
    setSelectedAssets(value);
    setSelectedAssetCategoryId(assetCategories);
    setSelectedAssetGroupId(selectedAssetGroups);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      selectedAssets: "",
    }));
  };

  const convertToOptionType = (data: DropDownType[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.name,
      };
      return itemData;
    });
  };
  const convertToOptionTypeFromRole = (data: Role[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.roleName,
      };
      return itemData;
    });
  };

  const filterRoles = (
    approver1: number,
    approver2: number,
    approver3: number
  ) => {
    return (role: Role) => {
      return ![approver1, approver2, approver3].includes(role.id);
    };
  };

  const addNewRow = () => {
    // Correctly calculate and store the new row ID beforehand
    const newRowId = `row-${activeCategory}-${
      (toolsTableData[activeCategory] || []).length + 1
    }`;

    // Define the new row using the newRowId
    const newRow: RowData = {
      id: newRowId, // Use the newRowId here
      data: {
        code: "",
        description: "",
        subCategory: "",
        id: 0,
      },
    };

    setToolsTableData((prevState: CategoryRows): CategoryRows => {
      const updatedRows = [
        ...(prevState[activeCategory] || []), // Spread to copy existing rows
        newRow, // Add the new row at the end
      ];

      // Return the updated state with the new row added to the active category
      return {
        ...prevState,
        [activeCategory]: updatedRows,
      };
    });
    // Update the toolsTableData with the new row

    // Update the selectedSubCategory state to include the new row with a default or empty value
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));
    setSelectedCodes((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" },
    }));

    setCodes([]);
    setDescription([]);
  };
  const handleDelete = (rowId: string) => {
    const updatedRows = toolsTableData[activeCategory].filter(
      (row) => row.id !== rowId
    );
    setToolsTableData({ ...toolsTableData, [activeCategory]: updatedRows });
    setSelectedCodes((prevState) => {
      const newState = { ...prevState };
      delete newState[rowId]; // Remove the entry for the deleted row
      return newState;
    });

    setSelectedDescriptions((prevState) => {
      const newState = { ...prevState };
      delete newState[rowId]; // Same for descriptions
      return newState;
    });
  };

  useEffect(() => {
    // Assuming toolsList is available in your component's scope

    const currentCategory = createWorkOrder?.toolsBeanList.find(
      (category) => category.toolCategoryName === activeCategory
    );

    const transformedSubCategories =
      currentCategory?.toolSubCategory.map((subCat, index) => ({
        id: index + 1, // or any other unique identifier
        name: subCat.subCategory,
      })) || [];

    setSubCategories(transformedSubCategories);
  }, [activeCategory]);

  useEffect(() => {
    if (activeRowId) {
      const selectedCategory = createWorkOrder?.toolsBeanList
        .flatMap((category) => category.toolSubCategory)
        .find(
          (subCat) =>
            subCat.subCategory === selectedSubCategory[activeRowId]?.name
        );

      if (selectedCategory) {
        const alreadySelectedCodes = new Set(
          Object.values(selectedCodes).map((code) => code.name)
        );
        const alreadySelectedDescriptions = new Set(
          Object.values(selectedDescriptions).map((desc) => desc.name)
        );

        const filteredCodes = selectedCategory.codes
          .filter((code) => !alreadySelectedCodes.has(code.code))
          .map((code, index) => ({
            id: index + 1, // or any other unique identifier
            name: code.code,
          }));
        const filteredDescriptions = selectedCategory.codes
          .filter(
            (code) => !alreadySelectedDescriptions.has(code.codeDescription)
          )
          .map((code, index) => ({
            id: index + 1,
            name: code.codeDescription,
          }));
        setCodes(filteredCodes);
        setDescription(filteredDescriptions);
      }
    }
  }, [
    createWorkOrder,
    selectedSubCategory,
    selectedCodes,
    selectedDescriptions,
    activeRowId,
  ]);

  const handleSelectSubcategory = (
    rowId: string,
    subId: number,
    subName: string
  ) => {
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [rowId]: { id: subId, name: subName },
    }));
    // Assuming setToolsTableData is a state updater function for your state that holds the entire structure
    setToolsTableData((prevState: CategoryRows): CategoryRows => {
      // Map through the rows to find the row to update
      const updatedRows = prevState[activeCategory].map((row) => {
        if (row.id === rowId) {
          // Found the row to update, apply the updateData
          return {
            ...row,
            data: {
              ...row.data,
              subCategory: subName, // Use spread to update the row's data
            },
          };
        }
        return row; // Return unmodified row
      });

      // Return the updated state with the updated row data
      return {
        ...prevState,
        [activeCategory]: updatedRows,
      };
    });
  };
  const handleSelectCodes = (rowId: string, codeId: number, code: string) => {
    setSelectedCodes((prevState) => ({
      ...prevState,
      [rowId]: { id: codeId, name: code },
    }));

    // Find the selected code details and update description and UOM
    const selectedCode = createWorkOrder?.toolsBeanList
      .flatMap((category) => category.toolSubCategory)
      .flatMap((subCat) => subCat.codes)
      .find((c) => c.code === code);

    if (selectedCode) {
      setSelectedDescriptions((prevState) => ({
        ...prevState,
        [rowId]: {
          id: selectedCode.toolId,
          name: selectedCode.codeDescription,
        },
      }));

      setToolsTableData((prevState: CategoryRows): CategoryRows => {
        // Map through the rows to find the row to update
        const updatedRows = prevState[activeCategory].map((row) => {
          if (row.id === rowId) {
            // Found the row to update, apply the updateData
            return {
              ...row,
              data: {
                ...row.data,
                code: code,
                description: selectedCode.codeDescription,
                id: selectedCode.toolId,
              },
            };
          }
          return row; // Return unmodified row
        });

        // Return the updated state with the updated row data
        return {
          ...prevState,
          [activeCategory]: updatedRows,
        };
      });
    }
  };
  const handleAssignee = (data: AssigneeType) => {
    setAssigneeDetails(data);
    if (!isAssignButtonDisabled(data.sets)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        assigneeDetailsSets: "",
      }));
      setAssigneeExpand(false);
    }
  };
  const handleSelectDescription = (
    rowId: string,
    desId: number,
    description: string
  ) => {
    setSelectedDescriptions((prevState) => ({
      ...prevState,
      [rowId]: { id: desId, name: description },
    }));

    // Find the selected code details and update description and UOM
    const selecteddescriptions = createWorkOrder?.toolsBeanList
      .flatMap((category) => category.toolSubCategory)
      .flatMap((subCat) => subCat.codes)
      .find((c) => c.codeDescription === description);

    if (selecteddescriptions) {
      setSelectedCodes((prevState) => ({
        ...prevState,
        [rowId]: {
          id: selecteddescriptions.toolId,
          name: selecteddescriptions.code,
        },
      }));

      setToolsTableData((prevState: CategoryRows): CategoryRows => {
        // Map through the rows to find the row to update
        const updatedRows = prevState[activeCategory].map((row) => {
          if (row.id === rowId) {
            // Found the row to update, apply the updateData
            return {
              ...row,
              data: {
                ...row.data,
                code: selecteddescriptions.code,
                description: description,
                id: selecteddescriptions.toolId,
              },
            };
          }
          return row; // Return unmodified row
        });

        // Return the updated state with the updated row data
        return {
          ...prevState,
          [activeCategory]: updatedRows,
        };
      });
    }
  };

  useEffect(() => {
    const now = dayjs();
    let defaultShiftId = null;

    if (createWorkOrder !== null && createWorkOrder?.shiftDetails !== null) {
      createWorkOrder.shiftDetails.forEach((shift) => {
        const [startHour, startMinute] = shift.shiftStartTime
          .split(":")
          .map(Number);
        const [endHour, endMinute] = shift.shiftEndTime.split(":").map(Number);

        let shiftStart = dayjs().hour(startHour).minute(startMinute);
        let shiftEndOriginal = dayjs().hour(endHour).minute(endMinute);
        let shiftEnd = shiftEndOriginal;

        // Check if the shift ends the next day
        if (shiftEnd.isBefore(shiftStart)) {
          shiftEnd = shiftEnd.add(1, "day"); // Add a day for shifts that end the next day
        }

        // Determine if the shift is selectable based on current time
        //const isSelectable = now.isBefore(shiftEnd);

        // Set the default selected shift if current time is within the shift hours
        if (now.isAfter(shiftStart) && now.isBefore(shiftEnd)) {
          defaultShiftId = shift.shiftId;
        }
      });
    }

    // Set the default selected shift
    if (defaultShiftId !== null) {
      setSelectedShiftId(defaultShiftId);
    }
  }, [createWorkOrder?.shiftDetails]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenSkills(event.target.checked);
  };

  const handleSwitchChangeForSafetyCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSafetyCheckRequired(event.target.checked);
  };

  useEffect(() => {
    if (!openSkills && createWorkOrder) {
      setselectedSkills([]);

      setAssigneeDetails((prevDetails) => ({
        ...prevDetails,
        sets: prevDetails.sets.map((set) => ({
          ...set,
          assignedUsers: [],
        })),
      }));
      setCreateWorkOrder({
        ...createWorkOrder,
        shiftDetails: shiftArrayCopy,
      });
    } else if (selectedShiftId && createWorkOrder) {
      setAssigneeDetails((prevDetails) => ({
        ...prevDetails,
        sets: prevDetails.sets.map((set) => ({
          ...set,
          assignedUsers: [],
        })),
      }));

      setCreateWorkOrder({
        ...createWorkOrder,
        shiftDetails: skillsShiftArrayCopy,
      });
    }
  }, [openSkills, selectedShiftId]);

  const formattedDescription = intl.formatMessage({
    id: "CreatedWo.text",
    defaultMessage: "Workorder created successfully!",
  });

  // Concatenate the formatted description with woUniqueID
  const descriptionTextData = `${woUniqueID}-${formattedDescription}`;

  const handleTeamLengthUpdate = (newTeamLength: number) => {
    setTeamLength(newTeamLength);
  };

  return (
    <Box component="main">
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            navigate(SCHEDULER_DASHBOARD, {
              state: {
                date: navState.date,
                dateRange: navState.dateRange,
                schedulerViewChange: "",
              },
            });
          }}
        />
      )}
      {createErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={createErrorModal}
          handleClose={() => setCreateErrorModal(false)}
          onPositiveClick={() => {
            setCreateErrorModal(false);
          }}
        />
      )}
      {saveSuccessModal && (
        <ModalPopup
          descriptionText={""}
          descriptionMessage={descriptionTextData}
          open={saveSuccessModal}
          hideButton={true}
          positiveButtonLabel="Ok"
          positiveButtonLabelId="OkText"
          handleClose={() => {
            setSaveSuccessModal(false);
          }}
          onPositiveClick={() => {
            setSaveSuccessModal(false);
            navigate(SCHEDULER_DASHBOARD, {
              state: {
                date: navState.date,
                dateRange: navState.dateRange,
                schedulerViewChange: "",
              },
            });
          }}
        />
      )}
      {openErrorModalSkills && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModalSkills}
          handleClose={() => setOpenErrorModalSkills(false)}
          onPositiveClick={() => {
            setOpenErrorModalSkills(false);
            setselectedSkills([]);
          }}
        />
      )}

      <Box sx={{ padding: "24px 0px 24px 0px" }}>
        <Box
          className="flex__ mt-sm"
          sx={{ marginBottom: "10px", justifyContent: "space-between" }}
        >
          <Box>
            <MediumTypography
              labelId="CreateWorkOrderText"
              defaultLabel="Create Work Order"
              fontSize="20px"
              fontWeight={700}
            />
          </Box>
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel"
                  variantType="contained"
                  defaultLabelId={"Cancel"}
                  labelId={"btn.cancel"}
                  onClick={() => {
                    navigate(SCHEDULER_DASHBOARD, {
                      state: {
                        date: navState.date,
                        dateRange: navState.dateRange,
                        schedulerViewChange: "",
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit mr-md"
                  variantType="contained"
                  defaultLabelId={"Create"}
                  labelId={"btn.create"}
                  onClick={handleCreate}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid container className="mt-md mb-md">
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Box>
              <Divider
                variant="fullWidth"
                sx={{ borderTop: "1px solid #333b43" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box component={"div"}>
          <Grid
            container
            rowSpacing={"16px"}
            columnSpacing={"40px"}
            className="mt-md mb-md"
          >
            <Grid item>
              <MediumTypography
                labelId="WorkOrderselectText"
                defaultLabel="Select W/O Type"
                fontSize="12px"
                fontWeight={500}
                textColor={"#9FADBC"}
                sxProps={{ marginBottom: "10px" }}
              />
              <DropdownComponent
                names={convertToOptionType(
                  createWorkOrder?.workOrderTypes || []
                )}
                value={
                  selectedWorkOrderType === 0
                    ? ""
                    : selectedWorkOrderType.toString()
                }
                handleChange={(value: string) => {
                  if (value) {
                    setSelectedWorkOrderType(parseInt(value));
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      selectedWorkOrderType: "",
                    }));
                  }
                }}
                labelid="workOrderTypeLabel"
                defaultlabelid="Select work order type"
                minWidth="400px"
                sxProps={{ height: "40px" }}
              />
              {validationErrors.selectedWorkOrderType && (
                <MediumTypography
                  label={validationErrors.selectedWorkOrderType}
                  className="mt-md"
                  textColor="#FF453A"
                />
              )}
            </Grid>
            <Grid item>
              <MediumTypography
                labelId="AssetLabelText"
                defaultLabel="Select Assets"
                sxProps={{ marginBottom: "8px" }}
                textColor={"rgba(159, 173, 188, 1)"}
              />
              <AssetGroupCategoryDropDown
                AssetData={createWorkOrder?.assetGroups || []}
                assetCallBack={assetCallBack}
                selectedAssetsParent={selectedAssets}
                selectedAssetGroupsParent={selectedAssetGroupId}
                selectedAssetCategoryParent={selectedAssetCategoryId}
              />
              {validationErrors.selectedAssets && (
                <MediumTypography
                  label={validationErrors.selectedAssets}
                  className="mt-md"
                  textColor="#FF453A"
                />
              )}
            </Grid>
            <Grid item>
              <MediumTypography
                labelId="CM.estimatedEffort"
                defaultLabel="Estimated Effort"
                sxProps={{ marginBottom: "10px" }}
              />
              <Box className="flex__">
                <TextInput
                  labelId="CM.estimatedEffort"
                  defaultLabelId="Estimated Effort"
                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                  type={"number"}
                  Value={
                    estimatedEfforts === 0 ? "" : estimatedEfforts.toString()
                  }
                  handlechange={(value: string) => {
                    const onlyNums = value.replace(/[^0-9]/g, ""); // Remove any non-digit characters
                    const maxTenChars = onlyNums.substring(0, 10);
                    setEstimatedEfforts(parseInt(maxTenChars));
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      estimatedEfforts: "",
                    }));
                  }}
                  variant="outlined"
                  backGroundColor="#22272B"
                  fieldheight="40px"
                  textColor={"rgba(255, 255, 255, 1)"}
                  sxProps={{
                    width: "400px",
                  }}
                />
                <Box
                  sx={{
                    padding: "4px 8px 4px 8px",
                    backgroundColor: "#191E22",
                    border: "1px solid #374048",
                    height: "24px",
                    borderRadius: "4px",
                    right: "65px",
                    top: "8px",
                    position: "relative",
                  }}
                >
                  <MediumTypography
                    labelId="CM.mins"
                    defaultLabel="minutes"
                    sxProps={{
                      lineHeight: "normal",
                      fontSize: "10px",
                    }}
                  />
                </Box>
              </Box>
              {validationErrors.estimatedEfforts && (
                <MediumTypography
                  label={validationErrors.estimatedEfforts}
                  className="mt-md"
                  textColor="#FF453A"
                />
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "20px" }}>
            <Grid xs={12} lg={12} md={12}>
              <MediumTypography
                labelId="cm.observationText"
                defaultLabel="Observation"
                sxProps={{ marginBottom: "10px" }}
                textColor={"rgba(159, 173, 188, 1)"}
              />
              <TextField
                variant="outlined"
                multiline
                sx={{
                  backgroundColor: "#22272B",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgba(166, 197, 226, 0.16)",
                    },

                    "&:hover fieldset": {
                      borderColor: "rgba(166, 197, 226, 0.16)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(166, 197, 226, 0.16)",
                    },
                    "& input": {
                      color: "#fffff",
                    },
                    color: "#FFFFFF",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#bbb", // Placeholder color
                  },
                }}
                minRows={3}
                fullWidth
                value={observation}
                onChange={(event) => {
                  setObservation(event.target.value.trimStart());
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    observation: "",
                  }));
                }}
              />
              {validationErrors.observation && (
                <MediumTypography
                  label={validationErrors.observation}
                  className="mt-md"
                  textColor="#FF453A"
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Grid
              item
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="PermitsText"
                defaultLabel="Permits"
                sxProps={{
                  fontSize: "14px",
                  lineHeight: "normal",
                }}
              />
              <Box className="flex__">
                <Box
                  sx={{
                    backgroundColor: "#5A607F",
                    borderRadius: "36px",
                    padding: "2px 8px ",
                  }}
                >
                  <MediumTypography
                    label={`${applyPermits} / ${
                      createWorkOrder?.permitDetails.length || 0
                    }`}
                  />
                </Box>
                {permitExpand ? (
                  <UpArrow
                    onClick={() => setPermitExpand(false)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "8px",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <DownArrow
                    onClick={() => setPermitExpand(true)}
                    style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      marginLeft: "8px",
                    }}
                  />
                )}
              </Box>
            </Grid>
            {permitExpand && (
              <>
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        borderTop: "1px solid #545558",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  sx={{ padding: "24px 20px 24px 20px" }}
                  rowSpacing={"16px"}
                  columnSpacing={"24px"}
                >
                  {createWorkOrder &&
                    createWorkOrder.permitDetails.map((item, index) => {
                      return (
                        <Grid
                          item
                          key={item.permitId}
                          xs={3.5}
                          lg={3.5}
                          md={3.5}
                          xl={3.5}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#22272B",
                              borderRadius: "4px",
                              padding: "16px",
                              border: "1px solid rgba(166, 197, 226, 0.16)",
                            }}
                          >
                            <Box className="flex__ p-sm">
                              <CheckBox
                                value={selectedPermits.includes(item.permitId)}
                                onCheckBoxClick={() => {
                                  setSelectedPermits((prevSelectedPermits) => {
                                    if (
                                      prevSelectedPermits.includes(
                                        item.permitId
                                      )
                                    ) {
                                      return prevSelectedPermits.filter(
                                        (id) => id !== item.permitId
                                      );
                                    } else {
                                      return [
                                        ...prevSelectedPermits,
                                        item.permitId,
                                      ];
                                    }
                                  });
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    selectedAssets: "",
                                  }));
                                }}
                                className="p-none"
                              />
                              <MediumTypography
                                label={item.permitName}
                                className={"ml-sm"}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
                <Box
                  className="width__100 flex__  p-md"
                  sx={{
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    labelId="Apply"
                    defaultLabelId="Apply"
                    sxProps={{ width: "100px" }}
                    disabled={selectedPermits.length === 0}
                    onClick={() => {
                      setApplyPermits(selectedPermits.length);
                      setPermitExpand(false);
                    }}
                  />
                </Box>
              </>
            )}
          </Grid>
          {validationErrors.selectedPermits && (
            <MediumTypography
              label={validationErrors.selectedPermits}
              className="mt-md"
              textColor="#FF453A"
            />
          )}
          <Grid
            container
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Grid
              item
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="tools&ppeText"
                defaultLabel="Tools & PPE"
                sxProps={{
                  fontSize: "14px",
                }}
              />
              <Box className="flex__">
                <Box
                  sx={{
                    backgroundColor: "#5A607F",
                    borderRadius: "36px",
                    padding: "4px 8px",
                  }}
                >
                  <MediumTypography
                    label={`${intl.formatMessage({
                      id: "toolsTextLabel",
                      defaultMessage: "Tools:",
                    })}${
                      toolsTableData[
                        intl.formatMessage({
                          id: "toolsText",
                          defaultMessage: "Tools",
                        })
                      ]
                        ? toolsTableData[
                            intl.formatMessage({
                              id: "toolsText",
                              defaultMessage: "Tools",
                            })
                          ].length
                        : 0
                    } | ${intl.formatMessage({
                      id: "ppeText",
                      defaultMessage: "PPE:",
                    })}${
                      toolsTableData[
                        intl.formatMessage({
                          id: "PPEText",
                          defaultMessage: "PPE",
                        })
                      ]
                        ? toolsTableData[
                            intl.formatMessage({
                              id: "PPEText",
                              defaultMessage: "PPE",
                            })
                          ].length
                        : 0
                    }`}
                    sxProps={{ lineHeight: "normal" }}
                  />
                </Box>
                {toolsSection ? (
                  <UpArrow
                    onClick={() => setToolsSection(false)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "8px",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <DownArrow
                    onClick={() => setToolsSection(true)}
                    style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      marginLeft: "8px",
                    }}
                  />
                )}
              </Box>
            </Grid>
            {toolsSection && (
              <>
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        borderTop: "1px solid #545558",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid container>
                  <Grid item sx={{ margin: "16px", width: "100%" }}>
                    <Box
                      className="mt-md mb-md flex__ "
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Box className="flex__">
                        {createWorkOrder &&
                          createWorkOrder.toolsBeanList.map((item, index) => {
                            return (
                              <Box
                                sx={{
                                  backgroundColor:
                                    activeCategory === item.toolCategoryName
                                      ? "rgba(37, 186, 250, 1)"
                                      : "#22272B",
                                  padding: "0px 12px",
                                  borderRadius: "45px",
                                  height: "32px",
                                  width: "auto",
                                  minWidth: "100px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  marginRight: "16px",
                                  border:
                                    activeCategory === item.toolCategoryName
                                      ? ""
                                      : "1px solid rgba(166, 197, 226, 0.16)",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setActiveCategory(item.toolCategoryName);
                                }}
                              >
                                <MediumTypography
                                  label={item.toolCategoryName}
                                />
                              </Box>
                            );
                          })}
                      </Box>
                      <ButtonComponent
                        className="btn-primary btn-submit mr-md"
                        variantType="contained"
                        defaultLabelId={"Add Row"}
                        labelId={"btn.AddRow"}
                        onClick={addNewRow}
                      />
                    </Box>
                  </Grid>
                  <TableContainer
                    sx={{ maxHeight: "auto", marginBottom: "10px" }}
                    className="ml-md mr-md tableStyles"
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "50px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.srNo"}
                                defaultLabel="Sr. No."
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "100px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId="table.subCategory"
                                defaultLabel="Sub-Category"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "100px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.materialCode"}
                                defaultLabel="Material Code"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "250px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.description"}
                                defaultLabel="Description"
                              />
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "50px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={""}
                                defaultLabel=""
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {toolsTableData[activeCategory]?.length > 0 &&
                          toolsTableData[activeCategory]?.map((row, index) => (
                            <TableRow key={row.id}>
                              <TableCell
                                sx={{
                                  verticalAlign: "top",
                                  border: "1px solid #323337",
                                  backgroundColor: "#22272B",
                                  width: "50px",
                                }}
                              >
                                <MediumTypography
                                  label={(index + 1).toString()}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  verticalAlign: "top",
                                  border: "1px solid #323337",
                                  backgroundColor: "#22272B",
                                  width: "100px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    height: "32px",
                                    width: "100%",
                                  }}
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => handleClickSubCategory(event, row.id)}
                                >
                                  <MediumTypography
                                    label={
                                      selectedSubCategory[row.id]
                                        ? selectedSubCategory[row.id].name
                                        : "" // Default text or leave it empty
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{
                                  verticalAlign: "top",
                                  border: "1px solid #323337",
                                  backgroundColor: "#22272B",
                                  width: "50px",
                                }}
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>
                                ) => handleClickCodes(event, row.id)}
                              >
                                <MediumTypography
                                  label={
                                    selectedCodes[row.id]
                                      ? selectedCodes[row.id].name
                                      : "" // Default text or leave it empty
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  verticalAlign: "top",
                                  border: "1px solid #323337",
                                  backgroundColor: "#22272B",
                                  width: "50px",
                                }}
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>
                                ) => handleClickDescription(event, row.id)}
                              >
                                <MediumTypography
                                  label={
                                    selectedDescriptions[row.id]
                                      ? selectedDescriptions[row.id].name
                                      : "" // Default text or leave it empty
                                  }
                                />
                              </TableCell>

                              <TableCell
                                sx={{
                                  verticalAlign: "top",
                                  border: "1px solid #323337",
                                  backgroundColor: "#22272B",
                                  width: "50px",
                                }}
                              >
                                <Delete
                                  onClick={() => {
                                    handleDelete(row.id);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <SearchDropDown
                        open={open}
                        onClose={handleCloseSubCategory}
                        anchorE1={anchorEl}
                        width="300px"
                        searchWidth="270px"
                        Data={subCategories}
                        onSelect={(id, name) => {
                          if (activeRowId) {
                            handleSelectSubcategory(activeRowId, id, name);
                            setActiveRowId(null);
                          }
                        }}
                      />
                      <SearchDropDown
                        open={openCode}
                        onClose={handleCloseCodes}
                        anchorE1={anchorElCode}
                        width="300px"
                        searchWidth="270px"
                        Data={codes}
                        onSelect={(id, name) => {
                          if (activeRowId) {
                            handleSelectCodes(activeRowId, id, name);
                            setActiveRowId(null);
                          }
                        }}
                      />
                      <SearchDropDown
                        open={openDescription}
                        onClose={handleCloseDescription}
                        anchorE1={anchorElDescription}
                        width="500px"
                        searchWidth="450px"
                        Data={description}
                        onSelect={(id, name) => {
                          if (activeRowId) {
                            handleSelectDescription(activeRowId, id, name);
                            setActiveRowId(null);
                          }
                        }}
                      />
                    </Table>
                    {(toolsTableData[activeCategory]?.length === 0 ||
                      !toolsTableData[activeCategory]) && (
                      <Box
                        sx={{
                          backgroundColor: "#22272B",
                          width: "100%",
                          padding: "24px",
                          display: "flex",
                          borderBottomLeftRadius: "4px",
                          borderBottomRightRadius: "4px",
                          justifyContent: "center",
                        }}
                      >
                        <MediumTypography
                          labelId="EmptyData.message"
                          defaultLabel="No Data added yet"
                        />
                      </Box>
                    )}
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Grid
              item
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="SkillsText"
                defaultLabel="Skills"
                sxProps={{
                  fontSize: "14px",
                }}
              />
              <SwitchComponent
                value={openSkills}
                handleChange={handleSwitchChange}
              />
            </Grid>
            {openSkills && (
              <>
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        borderTop: "1px solid #545558",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  sx={{ padding: "24px 20px 24px 20px" }}
                  rowSpacing={"16px"}
                  columnSpacing={"24px"}
                >
                  {createWorkOrder &&
                    createWorkOrder.skillSetBeans.map((item, index) => {
                      return (
                        <Grid
                          item
                          key={item.id}
                          xs={3.5}
                          lg={3.5}
                          md={3.5}
                          xl={3.5}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#22272B",
                              borderRadius: "4px",
                              padding: "8px",
                              border: "1px solid rgba(166, 197, 226, 0.16)",
                            }}
                          >
                            <Box className="flex__ p-sm">
                              <CheckBox
                                value={selectedSkills.includes(item.id)}
                                onCheckBoxClick={() => {
                                  setselectedSkills((prevSelectedSkills) => {
                                    if (prevSelectedSkills.includes(item.id)) {
                                      return prevSelectedSkills.filter(
                                        (id) => id !== item.id
                                      );
                                    } else {
                                      return [...prevSelectedSkills, item.id];
                                    }
                                  });
                                }}
                                className="p-none"
                              />
                              <Box className="ml-md" width={"50px"}>
                                <MediumTypography
                                  labelId="table.srNo"
                                  defaultLabel="Sr. No."
                                  textColor="rgba(255, 255, 255, 0.64)"
                                />
                                <MediumTypography
                                  label={item.id.toString()}
                                  sxProps={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                />
                              </Box>
                              <Box sx={{ marginLeft: "16px", width: "350px" }}>
                                <MediumTypography
                                  labelId="skillNameText"
                                  defaultLabel="Name"
                                  textColor="rgba(255, 255, 255, 0.64)"
                                />
                                <Tooltip
                                  title={`${item.name}-${item.description}`}
                                  followCursor
                                  arrow={true}
                                >
                                  <Box>
                                    <MediumTypography
                                      label={item.name}
                                      className="ellipsis"
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
                <Box
                  className="width__100 flex__  p-md"
                  sx={{
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    labelId="Apply"
                    defaultLabelId="Apply"
                    sxProps={{ width: "100px" }}
                    disabled={selectedSkills.length === 0}
                    onClick={() => {
                      getSkilledAssignees();
                    }}
                  />
                </Box>
              </>
            )}
          </Grid>
          <Grid
            container
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Grid
              item
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="CM.Shifts"
                defaultLabel="Shift"
                sxProps={{
                  fontSize: "14px",
                }}
              />
              <Box className="flex__">
                {createWorkOrder &&
                  createWorkOrder.shiftDetails.map((item, index) => {
                    const now = dayjs();
                    const startTime = dayjs()
                      .hour(+item.shiftStartTime.split(":")[0])
                      .minute(+item.shiftStartTime.split(":")[1]);
                    const endTimeOriginal = dayjs()
                      .hour(+item.shiftEndTime.split(":")[0])
                      .minute(+item.shiftEndTime.split(":")[1]);
                    let endTime = endTimeOriginal;

                    // Check if the shift ends the next day and adjust endTime for comparison
                    if (endTime.isBefore(startTime)) {
                      endTime = endTime.add(1, "day"); // Shift ends the next day
                    }

                    // Adjust "now" for comparison if it's before the startTime and endTime is the next day
                    // This is useful when "now" is after midnight but the shift started before midnight the previous day
                    if (
                      now.isBefore(startTime) &&
                      now.hour() <= endTime.hour() &&
                      endTime.day() !== startTime.day()
                    ) {
                      now.add(1, "day");
                    }

                    // Determine if the current time is before the adjusted end time for the shift
                    const isSelectable = now.isBefore(endTime);

                    return (
                      <Box
                        sx={{
                          backgroundColor:
                            selectedShiftId === item.shiftId
                              ? "#175FFA"
                              : "rgba(161, 189, 217, 0.08)",
                          padding: "5px 7px",
                          borderRadius: "3px",
                          marginRight: "8px",
                          cursor: isSelectable ? "pointer" : "not-allowed",
                          opacity: isSelectable ? 1 : 0.5,
                        }}
                        key={item.shiftId}
                        display={"flex"}
                        onClick={() => {
                          if (isSelectable) {
                            setSelectedShiftId(item.shiftId);
                          }
                        }}
                      >
                        <MediumTypography
                          label={item.shiftStaticName}
                          sxProps={{
                            lineHeight: "normal",
                            border:
                              selectedShiftId === item.shiftId
                                ? "2px solid #FFFF"
                                : "2px solid #8C9BAB",
                          }}
                          className="shift_btn_1 mr-sm"
                          textColor={
                            selectedShiftId === item.shiftId
                              ? "#FFFFFF"
                              : "#8C9BAB"
                          }
                        />
                        <MediumTypography
                          label={`${item.shiftStartTime.substring(
                            0,
                            5
                          )}-${item.shiftEndTime.substring(0, 5)}`}
                          textColor={
                            selectedShiftId === item.shiftId
                              ? "#FFFFFF"
                              : "#8C9BAB"
                          }
                        />
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>

          <Box
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Box
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="AssigneeTextLabel"
                defaultLabel="Assignee"
                sxProps={{
                  fontSize: "14px",
                  lineHeight: "normal",
                }}
              />
              <Box className="flex__">
                <Box
                  sx={{
                    backgroundColor: "#5A607F",
                    borderRadius: "36px",
                    padding: "2px 8px ",
                  }}
                >
                  <MediumTypography
                    label={`${assigneeDetails.sets.reduce((acc, set) => {
                      return (
                        acc +
                        set.assignedUsers.filter(
                          (user) => user.assigneeId !== 0
                        ).length
                      );
                    }, 0)} / ${teamLength}`}
                  />
                </Box>
                {assigneeExpand ? (
                  <UpArrow
                    onClick={() => setAssigneeExpand(false)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "8px",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <DownArrow
                    onClick={() => setAssigneeExpand(true)}
                    style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      marginLeft: "8px",
                    }}
                  />
                )}
              </Box>
            </Box>
            {assigneeExpand && (
              <>
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        borderTop: "1px solid #545558",
                      }}
                    />
                  </Box>
                </Grid>
                <Box
                  className="mt-lg"
                  sx={{
                    backgroundColor: "#323337",
                    borderRadius: "4px",
                  }}
                >
                  <SetAssetsAssignee
                    assigneeDetails={assigneeDetails}
                    onApply={handleAssignee}
                    originialShiftData={shiftArrayCopy}
                    skillsOriginalShiftData={skillsShiftArrayCopy}
                    selectedShiftId={selectedShiftId}
                    skillsChecked={openSkills}
                    onTeamLengthUpdate={handleTeamLengthUpdate}
                  />
                </Box>
              </>
            )}
          </Box>
          {validationErrors.assigneeDetailsSets && (
            <MediumTypography
              label={validationErrors.assigneeDetailsSets}
              className="mt-md"
              textColor="#FF453A"
            />
          )}
          <Grid
            container
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Grid
              item
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="safetyCheckText"
                defaultLabel="Safety Checks After Completion"
                sxProps={{
                  fontSize: "14px",
                }}
              />
              <SwitchComponent
                value={safetyCheckRequired}
                handleChange={handleSwitchChangeForSafetyCheck}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-lg"
            sx={{ backgroundColor: "#323337", borderRadius: "4px" }}
          >
            <Grid
              item
              className="flex__"
              sx={{
                padding: "12px 20px 12px 20px",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <MediumTypography
                labelId="ApproverTextLabel"
                defaultLabel="Approver"
                sxProps={{
                  fontSize: "14px",
                }}
              />

              {approverExpand ? (
                <UpArrow
                  onClick={() => setApproverExpand(false)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "8px",
                    marginTop: "8px",
                  }}
                />
              ) : (
                <DownArrow
                  onClick={() => setApproverExpand(true)}
                  style={{
                    cursor: "pointer",
                    marginTop: "8px",
                    marginLeft: "8px",
                  }}
                />
              )}
            </Grid>
            {approverExpand && (
              <>
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        borderTop: "1px solid #545558",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  rowSpacing={"16px"}
                  columnSpacing={"16px"}
                  className="mt-md mb-md ml-sm"
                >
                  <Grid item>
                    <MediumTypography
                      labelId={"level1text"}
                      defaultLabel="Level 1"
                      fontSize="12px"
                      fontWeight={500}
                      textColor={"#9FADBC"}
                      sxProps={{ marginBottom: "10px" }}
                    />
                    <DropdownComponent
                      names={convertToOptionTypeFromRole(
                        (createWorkOrder?.approversList &&
                          createWorkOrder?.approversList.filter(
                            filterRoles(0, selectApprover2, selectApprover3)
                          )) ||
                          []
                      )}
                      value={
                        selectApprover1 === 0 ? "" : selectApprover1.toString()
                      }
                      handleChange={(value) =>
                        setSelectApprover1(parseInt(value))
                      }
                      labelid="approverLabel"
                      defaultlabelid="Select Approver"
                      minWidth="400px"
                      sxProps={{ height: "40px" }}
                    />
                  </Grid>
                  <Grid item>
                    <MediumTypography
                      labelId={"level2text"}
                      defaultLabel="Level 2"
                      fontSize="12px"
                      fontWeight={500}
                      textColor={"#9FADBC"}
                      sxProps={{ marginBottom: "10px" }}
                    />
                    <DropdownComponent
                      names={convertToOptionTypeFromRole(
                        (createWorkOrder?.approversList &&
                          createWorkOrder?.approversList.filter(
                            filterRoles(selectApprover1, 0, selectApprover3)
                          )) ||
                          []
                      )}
                      value={
                        selectApprover2 === 0 ? "" : selectApprover2.toString()
                      }
                      handleChange={(value) =>
                        setSelectApprover2(parseInt(value))
                      }
                      labelid="approverLabel"
                      defaultlabelid="Select Approver"
                      minWidth="400px"
                      sxProps={{ height: "40px" }}
                    />
                  </Grid>
                  <Grid item>
                    <MediumTypography
                      labelId={"level3text"}
                      defaultLabel="Level 3"
                      fontSize="12px"
                      fontWeight={500}
                      textColor={"#9FADBC"}
                      sxProps={{ marginBottom: "10px" }}
                    />
                    <DropdownComponent
                      names={convertToOptionTypeFromRole(
                        (createWorkOrder?.approversList &&
                          createWorkOrder?.approversList.filter(
                            filterRoles(selectApprover1, selectApprover2, 0)
                          )) ||
                          []
                      )}
                      value={
                        selectApprover3 === 0 ? "" : selectApprover3.toString()
                      }
                      handleChange={() => {}}
                      labelid="approverLabel"
                      defaultlabelid="Select Approver"
                      minWidth="400px"
                      sxProps={{ height: "40px" }}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {validationErrors.approver && (
            <MediumTypography
              label={validationErrors.approver}
              className="mt-md"
              textColor="#FF453A"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateWorkOrder;
