import { Box, Grid } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { Formik, FormikProps } from "formik";
import { FC, useContext, useEffect, useRef, useState } from "react";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import TextInput from "../../../components/formlib/TextInput";
import {
  AssetCategoryType,
  AssetGroupWizardsType,
  CountriesType,
} from "../types/assetGroupTypes";
import { DocumentTypeBean } from "../../workOrderDetailScreens/correctiveMaintenance/types";
import * as Yup from "yup";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import FileUploadInput from "../../workOrderDetailScreens/correctiveMaintenance/FileUploadInput";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { trim } from "lodash";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
} from "../../../api/MasterDataManagement/Common";
import {
  createDropdownOptionApi,
  fetchWizardsDataApi,
  MappedCommonTypes,
} from "../../../api/wizardsApi/Common";
import { StateType } from "../../MasterDataManagement/types/accountTypes";
import MappingModal from "../../../components/formlib/modal/MappingModal";
import TextInputMap from "../../../components/formlib/TextInputMap";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { WizardsContext } from "../WizardParentComponent";
import { CommonOptionType } from "../../../utils/type";

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};

interface AssetGroupProps {
  subGroupId: number;
  assetGroupId: number;
  assetGroupName: string;
  addAssetGroupCallback: () => void;
  assetGroupChildCallback: () => void;
  selectedLanguageCode: string;
  isAssetGroupFirst: boolean;
}

const initialAssetGroupData: AssetGroupWizardsType = {
  name: "",
  dcCapacity: 0,
  acCapacity: 0,
  sitePhoto: "",
  assetGroupImage: undefined as DocumentTypeBean | undefined,
  code: "",
  latitude: "",
  longitude: "",
  email: "",
  dateOfCommission: null,
  mobilizationDate: null,
  effectiveDate: null,
  selectedTimezoneId: 0,
  selectedCountryId: 0,
  selectedStateId: 0,
  selectedAssetGroupTypeId: 0,
  selectedEpcContractorId: 0,
  selectedStatusId: 0,
  selectedPlantTypeId: 0,
  assetGroupArea: 0,
  plannedCycleThresholdDays: 0,
  woMoveThreshold: 0,
  contactReference: "",
  countryCode: "",
  phoneNumber: 0,
  owner: "",
  spvName: "",
  location: "",
  contactPersonFirstName: "",
  contactPersonLastName: "",
  countries: [],
  states: [],
  statusList: [],
  assetGroupTypes: [],
  epcContractors: [],
  plantTypeList: [],
  assetCategories: [],
  selectedAssetCategories: [],
};

const getStateOptions = (
  countries: CountriesType[],
  selectedCountryId: number
) => {
  return (
    countries.find((country) => country.id === selectedCountryId)?.states || []
  );
};

const getTimezoneOptions = (
  countries: CountriesType[],
  selectedCountryId: number
) => {
  return (
    countries.find((country) => country.id === selectedCountryId)?.timezones ||
    []
  );
};

const urlToDocumentTypeBean = async (
  url: string
): Promise<DocumentTypeBean> => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      return new Promise<DocumentTypeBean>((resolve, reject) => {
        reader.onloadend = () => {
          const base64StringWithPrefix = reader.result as string;
          const base64String = base64StringWithPrefix.split(",")[1];
          const fileName = url.split("/").pop() || "file";
          const extension = fileName.split(".").pop() || "";
          resolve({
            document: base64String,
            fileName: fileName,
            extension: extension,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

export const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

export const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  location: Yup.string().required("validation.fieldRequired"),
  code: Yup.string()
    .matches(/^[^\s]*$/, "validation.noSpaces")
    .max(20, "validation.maxLength20")
    .required("validation.fieldRequired"),
  latitude: Yup.string().required("validation.fieldRequired"),
  longitude: Yup.string().required("validation.fieldRequired"),
  selectedAssetGroupTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedTimezoneId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedStateId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedStatusId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedCountryId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  dcCapacity: Yup.string()
    .required("validation.fieldRequired")
    .test("is-not-zero", "validation.fieldRequired", (value) => {
      const cleanValue = value?.replace(/,/g, "");
      return cleanValue !== "0"; // Return false if the value is "0"
    })
    .test("is-decimal", "validation.invalidNumberFormat", (value) => {
      const cleanValue = value?.replace(/,/g, "");
      return cleanValue !== undefined && /^\d+(\.\d+)?$/.test(cleanValue);
    })
    .transform((value) => value?.replace(/,/g, "")),

  // dcCapacity: Yup.string()
  //   .required("validation.fieldRequired")
  //   .test("is-not-zero","is-decimal", "validation.invalidNumberFormat", (value) => {
  //     const cleanValue = value?.replace(/,/g, "");
  //     return cleanValue !== undefined && /^\d+(\.\d+)?$/.test(cleanValue);
  //   })
  //   .transform((value) => value?.replace(/,/g, ""))
  //   .nullable(),
  acCapacity: Yup.string()
    .required("validation.fieldRequired")
    .test("is-not-zero", "validation.fieldRequired", (value) => {
      const cleanValue = value?.replace(/,/g, "");
      return cleanValue !== "0"; // Return false if the value is "0"
    })
    .test("is-decimal", "validation.invalidNumberFormat", (value) => {
      const cleanValue = value?.replace(/,/g, "");
      return cleanValue !== undefined && /^\d+(\.\d+)?$/.test(cleanValue);
    })
    .transform((value) => value?.replace(/,/g, "")),
  plannedCycleThresholdDays: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  woMoveThreshold: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  assetGroupArea: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  selectedAssetCategories: Yup.array()
    .of(Yup.object())
    .min(1, "validation.fieldRequired"),
});

const convertToNumber = (value: any): number => {
  return value === undefined || value === null || value === ""
    ? 0
    : Number(value);
};

const AssetGroupWizards: FC<AssetGroupProps> = ({
  subGroupId,
  assetGroupId,
  assetGroupName,
  addAssetGroupCallback,
  assetGroupChildCallback,
  selectedLanguageCode,
  isAssetGroupFirst,
}) => {
  const formikRef = useRef<FormikProps<AssetGroupWizardsType>>(null);
  const [assetGroupData, setAssetGroupData] = useState<AssetGroupWizardsType>(
    initialAssetGroupData
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [stateOptions, setStateOptions] = useState<StateType[]>([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState<CommonOptionType[]>(
    []
  );
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const [assetCategoryArr, setAssetCategoryArr] = useState<AssetCategoryType[]>(
    []
  );
  const siteContext = useContext(WizardsContext);
  const [openMapModal, setOpenMapModal] = useState<boolean>(false);
  const currentAssetGroupName = useRef<string>(assetGroupName);

  const handleApply = (mappedData: AssetCategoryType[]) => {
    setOpenMapModal(false);
    const updatedAssetCategoryArr = assetCategoryArr.map((category) => {
      const isSelected = mappedData.some((item) => item.id === category.id);
      const updatedLevel =
        mappedData.find((item) => item.id === category.id)?.level ??
        category.level;
      return {
        ...category,
        isSelected: isSelected,
        level: updatedLevel,
      };
    });
    setAssetCategoryArr(updatedAssetCategoryArr);
    formikRef.current?.setFieldValue("selectedAssetCategories", mappedData);
    // Do something with the mappedData and availableData
  };

  useEffect(() => {
    getAssetGroupById();
  }, [assetGroupId, selectedLanguageCode]);

  useEffect(() => {
    currentAssetGroupName.current = assetGroupName;
  }, [assetGroupName]);

  const getAssetGroupById = () => {
    const fetchAssetGroupUrl = `${WORK_FLOW_BASE_URL}assetGroup`;

    fetchWizardsDataApi<AssetGroupWizardsType>(
      fetchAssetGroupUrl,
      assetGroupId,
      selectedLanguageCode
    )
      .then(async (response: AssetGroupWizardsType) => {
        setAssetCategoryArr(response.assetCategories);

        if (siteContext && isAssetGroupFirst) {
          const subGroupAssetGroupData: AssetGroupWizardsType = {
            name: siteContext.selelctedSubgroupDetails.name,
            dcCapacity: 0,
            acCapacity: 0,
            sitePhoto: "",
            assetGroupImage: undefined as DocumentTypeBean | undefined,
            code: siteContext.selelctedSubgroupDetails.code,
            latitude: siteContext.selelctedSubgroupDetails.latitude,
            longitude: siteContext.selelctedSubgroupDetails.longitude,
            email: siteContext.selelctedSubgroupDetails.email,
            dateOfCommission: null,
            mobilizationDate: null,
            effectiveDate: null,
            selectedTimezoneId: 0,
            selectedCountryId: 0,
            selectedStateId: 0,
            selectedAssetGroupTypeId: 0,
            selectedEpcContractorId: 0,
            selectedStatusId: 0,
            selectedPlantTypeId: 0,
            assetGroupArea: 0,
            plannedCycleThresholdDays: 0,
            woMoveThreshold: 0,
            contactReference: "",
            countryCode: siteContext.selelctedSubgroupDetails.countryCode,
            phoneNumber: parseInt(
              siteContext.selelctedSubgroupDetails.phoneNumber
            ),
            owner: "",
            spvName: "",
            location: siteContext.selelctedSubgroupDetails.location,
            contactPersonFirstName:
              siteContext.selelctedSubgroupDetails.contactPersonFirstName,
            contactPersonLastName:
              siteContext.selelctedSubgroupDetails.contactPersonLastName,
            countries: response.countries,
            states: [],
            statusList: response.statusList,
            assetGroupTypes: response.assetGroupTypes,
            epcContractors: response.epcContractors,
            plantTypeList: response.plantTypeList,
            assetCategories: [],
            selectedAssetCategories: [],
          };

          setAssetGroupData(subGroupAssetGroupData);
          formikRef.current?.setValues(subGroupAssetGroupData);
        } else {
          const responseData = { ...initialAssetGroupData, ...response };
          const siteImageUrl = responseData.sitePhoto;

          if (
            siteImageUrl !== "" &&
            siteImageUrl !== null &&
            siteImageUrl !== undefined
          ) {
            const documentTypeBean = await urlToDocumentTypeBean(siteImageUrl);
            responseData.assetGroupImage = documentTypeBean;
          }
          setAssetGroupData({
            ...responseData,
            selectedAssetCategories: responseData.assetCategories.filter(
              (category) => category.isSelected
            ),
          });
          formikRef.current?.setValues({
            ...responseData,
            selectedAssetCategories: responseData.assetCategories.filter(
              (category) => category.isSelected
            ),
          });
        }
        setStateOptions(
          getStateOptions(response.countries, response.selectedCountryId)
        );
        setTimeZoneOptions(
          getTimezoneOptions(response.countries, response.selectedCountryId)
        );

        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (data: AssetGroupWizardsType) => {
    const {
      countries,
      states,
      statusList,
      assetGroupTypes,
      epcContractors,
      plantTypeList,
      ...updatedParams
    } = data;

    const assetGroupIdExist = assetGroupId === 0 ? undefined : assetGroupId;
    if (assetGroupIdExist) {
      const updateUri = `${WORK_FLOW_BASE_URL}assetGroup/${assetGroupIdExist}/update`;

      customUpdateDataApi(updateUri, updatedParams)
        .then(() => {
          getAssetGroupById();
          assetGroupChildCallback();
          currentAssetGroupName.current = data.name;
        })
        .catch((error) => {
          handleError(error, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    } else {
      const url = `${WORK_FLOW_BASE_URL}${subGroupId}/assetGroup/save`;
      customSaveDataApi(url, updatedParams)
        .then(() => {
          // getAssetGroupById();
          assetGroupChildCallback();
          currentAssetGroupName.current = data.name;
        })
        .catch((error) => {
          handleError(error, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }

    // Add your API call logic
  };

  const availableHeight = useAvailableHeight(270);
  return (
    <Box>
      {openMapModal && (
        <MappingModal
          titleLabelId="assetGroup.mappingTitle"
          handleClose={() => setOpenMapModal(false)}
          open={openMapModal}
          onApply={handleApply}
          availableLabel="assetGroup.available"
          mappingDetailsLabel="assetGroup.mappingDetails"
          mappedList={assetCategoryArr}
          textInputRequired={true}
          showSequence={true}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className={"flex__justify__space-between mb-lg "}>
        <Box className="flex__">
          {assetGroupId !== 0 && (
            <MediumTypography
              labelId="assetGroupText"
              defaultLabel="Asset Group :"
              fontSize="20px"
              fontWeight={600}
              className="mr-sm"
            />
          )}

          <MediumTypography
            label={currentAssetGroupName.current}
            textColor="#FFF"
            fontSize="20px"
            fontWeight={700}
          />
        </Box>

        {selectedLanguageCode === "en" && (
          <ButtonComponent
            className="btn-primary btn-submit"
            variantType="contained"
            defaultLabelId={"Add New"}
            labelId={"btn.AddNew"}
            onClick={() => {
              addAssetGroupCallback();
            }}
          />
        )}
      </Box>

      <Formik
        initialValues={assetGroupData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const paramsObj: AssetGroupWizardsType = {
            ...values,
            phoneNumber: convertToNumber(values.phoneNumber),
            selectedCountryId: convertToNumber(values.selectedCountryId),
            selectedStateId: convertToNumber(values.selectedStateId),
            selectedTimezoneId: convertToNumber(values.selectedTimezoneId),
            selectedAssetGroupTypeId: convertToNumber(
              values.selectedAssetGroupTypeId
            ),
            selectedEpcContractorId: convertToNumber(
              values.selectedEpcContractorId
            ),
            selectedPlantTypeId: convertToNumber(values.selectedPlantTypeId),
            selectedStatusId: convertToNumber(values.selectedStatusId),
          };
          saveData(paramsObj);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                  height: availableHeight,
                }}
                className="pr-sm"
              >
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="skillNameText"
                    defaultLabel="Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.name}
                    handlechange={(value: string) => {
                      setFieldValue("name", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="name"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.location"
                    defaultLabel="Location"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.location}
                    handlechange={(value: string) => {
                      setFieldValue("location", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="location"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="accounts.code"
                    defaultLabel="Code"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.code}
                    handlechange={(value: string) => {
                      setFieldValue("code", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="code"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.type"
                    defaultLabel="Asset Group Type"
                  />

                  <CustomDropdown
                    options={getOptions(values.assetGroupTypes)}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    selectedValue={values.selectedAssetGroupTypeId}
                    onSelectedValueChange={(value) => {
                      const assetTypeIdValue = value as string;
                      setFieldValue(
                        "selectedAssetGroupTypeId",
                        assetTypeIdValue
                      );
                    }}
                    onCreateOption={(option) => {
                      toggleLoader(true);
                      createDropdownOptionApi(
                        "ASSET_GROUP_TYPE",
                        "assetGroupType",
                        option
                      )
                        .then((res) => {
                          setFieldValue("assetGroupTypes", res);
                          setFieldValue(
                            "selectedAssetGroupTypeId",
                            res.find((acc) => acc.name === option)?.id || ""
                          );
                          toggleLoader(false);
                        })
                        .catch((err) => {
                          handleError(err, setErrorDesc);

                          setOpenErrorModal(true);
                          toggleLoader(false);
                        });
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedAssetGroupTypeId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.mapAssetCategory"
                    defaultLabel="Map Asset Category"
                  />

                  <TextInputMap
                    fieldValue={
                      values?.selectedAssetCategories
                        ? values.selectedAssetCategories
                            .filter((item) => item.isSelected) // Filter items where isSelected is true
                            .map((item) => item.name) // Map to only the 'name' of each selected item
                        : []
                    }
                    buttonCallBackClick={() => setOpenMapModal(true)}
                    // fieldValue={values.}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedAssetCategories"
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.status"
                    defaultLabel="Status"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    options={getOptions(values.statusList)}
                    selectedValue={values.selectedStatusId}
                    onSelectedValueChange={(value) => {
                      const statusIdValue = value as string;
                      setFieldValue("selectedStatusId", statusIdValue);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedStatusId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.owner"
                    defaultLabel="Owned By"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.owner}
                    handlechange={(value: string) => {
                      setFieldValue("owner", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="accounts.country"
                    defaultLabel="Country"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    options={getOptions(values.countries, "countryName")}
                    selectedValue={values.selectedCountryId}
                    onSelectedValueChange={(value) => {
                      const countryIdValue = value as string;
                      setFieldValue("selectedCountryId", countryIdValue);
                      setFieldValue("selectedStateId", "");
                      setFieldValue("selectedTimezoneId", "");
                      setTimeZoneOptions(
                        getTimezoneOptions(
                          values.countries,
                          Number(countryIdValue) || 0
                        )
                      );
                      setStateOptions(
                        getStateOptions(
                          values.countries,
                          Number(countryIdValue) || 0
                        )
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedCountryId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="accounts.state"
                    defaultLabel="State"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    options={getOptions(stateOptions, "stateName")}
                    selectedValue={values.selectedStateId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedStateId", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedStateId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.timeZone"
                    defaultLabel="Timezone"
                  />

                  <CustomDropdown
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    disableCreate={true}
                    options={getOptions(timeZoneOptions, "name") || []}
                    selectedValue={values.selectedTimezoneId}
                    onSelectedValueChange={(value) => {
                      const timeZoneIdValue = value as string;
                      setFieldValue("selectedTimezoneId", timeZoneIdValue);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedTimezoneId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.latitude"
                    defaultLabel="Latitude"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.latitude}
                    handlechange={(value: string) => {
                      setFieldValue("latitude", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="latitude"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.longitude"
                    defaultLabel="Longitude"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.longitude}
                    handlechange={(value: string) => {
                      setFieldValue("longitude", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="longitude"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.siteImage"
                    defaultLabel="Site Image"
                  />
                  <FileUploadInput
                    fileCategory="image"
                    labelId="accounts.uploadPlaceholder"
                    defaultLabel="--- upload ---"
                    value={
                      values.assetGroupImage !== null &&
                      values.assetGroupImage !== undefined
                        ? values?.assetGroupImage.fileName
                        : ""
                    }
                    handleChange={(value: DocumentTypeBean) =>
                      setFieldValue("assetGroupImage", value)
                    }
                    url={values.sitePhoto !== null ? values.sitePhoto : ""}
                    reset={false}
                    cardEdit={true}
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.dcCapacity"
                    defaultLabel="DC Capacity (MWp)"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                      type: "number",
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.dcCapacity !== null && values.dcCapacity !== 0
                        ? String(values.dcCapacity)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value
                        .replace(/,/g, "")
                        .replace(/[^0-9.]/g, "");
                      const validValue = parseFloat(formattedValue);
                      setFieldValue(
                        "dcCapacity",
                        isNaN(validValue) ? 0 : validValue
                      );
                    }}
                    // handlechange={(value: string) => {
                    //   const formattedValue = value
                    //     .replace(/,/g, "") // Remove commas
                    //     .replace(/[^0-9.]/g, "") // Allow only numbers and a single decimal point
                    //     .replace(/\.(?=.*\.)/g, ""); // Remove extra decimal points
                    //   const validValue = parseFloat(formattedValue);
                    //   setFieldValue(
                    //     "dcCapacity",
                    //     isNaN(validValue) ? 0 : validValue
                    //   );
                    // }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="dcCapacity"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.acCapacity"
                    defaultLabel="AC Capacity (MW)"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.acCapacity !== null && values.acCapacity !== 0
                        ? String(values.acCapacity)
                        : ""
                    }
                    handlechange={(value: string) => {
                      // const formattedValue = value.replace(/[^0-9]/g, "");
                      // setFieldValue(
                      //   "acCapacity",
                      //   formattedValue ? parseInt(formattedValue) : 0
                      // );
                      const formattedValue = value
                        .replace(/,/g, "")
                        .replace(/[^0-9.]/g, "");
                      const validValue = parseFloat(formattedValue);
                      setFieldValue(
                        "acCapacity",
                        isNaN(validValue) ? 0 : validValue
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="acCapacity"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.epcContractor"
                    defaultLabel="EPC Contractor"
                  />

                  <CustomDropdown
                    options={getOptions(values.epcContractors)}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    selectedValue={values.selectedEpcContractorId}
                    onSelectedValueChange={(value) => {
                      const EPCIdValue = value as string;
                      setFieldValue("selectedEpcContractorId", EPCIdValue);
                    }}
                    onCreateOption={(option) => {
                      toggleLoader(true);
                      createDropdownOptionApi(
                        "EPC_CONTRACTOR_TYPE",
                        "ecpContractorType",
                        option
                      )
                        .then((res) => {
                          setFieldValue("epcContractors", res);
                          setFieldValue(
                            "selectedEpcContractorId",
                            res.find((acc) => acc.name === option)?.id || ""
                          );
                          toggleLoader(false);
                        })
                        .catch((err) => {
                          handleError(err, setErrorDesc);
                          setOpenErrorModal(true);
                          toggleLoader(false);
                        });
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.dateofCommission"
                    defaultLabel="Date of Commission"
                    textColor={"rgba(159, 173, 188, 1)"}
                    className="mb-sm"
                  />

                  <DateTimePickerComponent
                    value={
                      values.dateOfCommission !== null
                        ? dayjs(values.dateOfCommission)
                        : null
                    }
                    labelid="commonDateText"
                    defaultlabelid="DD MMM YYYY"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("dateOfCommission", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    textWidth="100%"
                    width="100%"
                    views={["year", "month", "day"]}
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.efficiency"
                    defaultLabel="Effective Date"
                    textColor={"rgba(159, 173, 188, 1)"}
                    className="mb-sm"
                  />

                  <DateTimePickerComponent
                    value={
                      values.effectiveDate !== null
                        ? dayjs(values.effectiveDate)
                        : null
                    }
                    labelid="commonDateText"
                    defaultlabelid="DD MMM YYYY"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("effectiveDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    textWidth="100%"
                    width="100%"
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.mobility"
                    defaultLabel="Mobilization  Date"
                    textColor={"rgba(159, 173, 188, 1)"}
                    className="mb-sm"
                  />

                  <DateTimePickerComponent
                    value={
                      values.mobilizationDate !== null
                        ? dayjs(values.mobilizationDate)
                        : null
                    }
                    labelid="commonDateText"
                    defaultlabelid="DD MMM YYYY"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("mobilizationDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    textWidth="100%"
                    width="100%"
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.planttype"
                    defaultLabel="Plant Type"
                  />

                  <CustomDropdown
                    options={getOptions(values.plantTypeList)}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 0px 0px 0px",
                      },
                    }}
                    selectedValue={values.selectedPlantTypeId}
                    onSelectedValueChange={(value) => {
                      const assetTypeIdValue = value as string;
                      setFieldValue("selectedPlantTypeId", assetTypeIdValue);
                    }}
                    onCreateOption={(option) => {
                      toggleLoader(true);
                      createDropdownOptionApi("PLANT_TYPE", "plantType", option)
                        .then((res) => {
                          setFieldValue("plantTypeList", res);
                          setFieldValue(
                            "selectedPlantTypeId",
                            res.find((acc) => acc.name === option)?.id || ""
                          );
                          toggleLoader(false);
                        })
                        .catch((err) => {
                          handleError(err, setErrorDesc);
                          setOpenErrorModal(true);
                          toggleLoader(false);
                        });
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.plannedCycles"
                    defaultLabel="Planned Cycle Threshold Days"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.plannedCycleThresholdDays !== null &&
                      values.plannedCycleThresholdDays !== 0
                        ? String(values.plannedCycleThresholdDays)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "plannedCycleThresholdDays",
                        formattedValue ? parseInt(formattedValue) : 0
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="plannedCycleThresholdDays"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.woMoveThreshold"
                    defaultLabel="WO Move Threshold"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.woMoveThreshold !== null &&
                      values.woMoveThreshold !== 0
                        ? String(values.woMoveThreshold)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "woMoveThreshold",
                        formattedValue ? parseInt(formattedValue) : 0
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="woMoveThreshold"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.spvname"
                    defaultLabel="SPV Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.spvName}
                    handlechange={(value: string) => {
                      setFieldValue("spvName", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.assetGroupArea"
                    defaultLabel="Asset Group Area"
                    className="input-label"
                  />
                  <TextInput
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    Value={
                      values.assetGroupArea !== null &&
                      values.assetGroupArea !== 0
                        ? String(values.assetGroupArea)
                        : ""
                    }
                    handlechange={(value: string) => {
                      let formattedValue = value.replace(/[^0-9.]/g, "");

                      // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                      formattedValue = formattedValue.replace(
                        /^(\d{0,4})(\.\d{0,2})?.*$/,
                        (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                      );
                      setFieldValue("assetGroupArea", formattedValue);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="assetGroupArea"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.contactReference"
                    defaultLabel="Contact Reference"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.contactReference}
                    handlechange={(value: string) => {
                      setFieldValue("contactReference", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.contactPersonName"
                    defaultLabel="Contact Person"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.contactPersonFirstName}
                    handlechange={(value: string) => {
                      setFieldValue("contactPersonFirstName", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.email"
                    defaultLabel="Contact Person Email"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.email}
                    handlechange={(value: string) => {
                      setFieldValue("email", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.phone"
                    defaultLabel="Contact Person Phone"
                  />
                  <PhoneNumberInput
                    value={`${values.countryCode || ""}${
                      values.phoneNumber || ""
                    }`}
                    onChange={(value: string, countryData: any) => {
                      const countryCode = countryData.dialCode; // Get the country code
                      const phoneNumber = value.startsWith(countryCode)
                        ? value.slice(countryCode.length) // Remove country code from the start of the value
                        : value; // Use the value directly if no country code prefix

                      setFieldValue("countryCode", countryCode);
                      setFieldValue("phoneNumber", phoneNumber);
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Box className=" flex__ justifyContent-FlexEnd mt-md">
                {selectedLanguageCode === "en" && (
                  <ButtonComponent
                    className={"btn-primary btn-submit "}
                    defaultLabelId={"Save"}
                    labelId={assetGroupId !== 0 ? "btn.update" : "btn.save"}
                    onClick={() => {
                      handleSubmit();
                    }}
                    sxProps={{
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AssetGroupWizards;
