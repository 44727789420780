// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action_button {
  padding: 8px 16px;
  width: auto;
  background-color: #25bafa29;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.action_button:hover {
  background-color: rgba(37, 186, 250, 0.25);
  cursor: pointer;
}

.action_button p.MuiTypography-root {
  color: #25bafa;
}

.download-icon {
  background-color: #22272b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 12px;
}
.add-icon {
  width: 212px;
  height: 140px;
  background-color: #22272b;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #9fadbc66;
}
.child_issue_main {
  width: 100%;
  height: 1px;
  background-color: #a6c5e229;
  margin: 8px 2px;
  border: 1px;
}
.iput_box .MuiInputBase-root.MuiOutlinedInput-root {
  background-color: #22272b;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ticketing/css/ticketDetails.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,WAAW;EACX,2BAA2B;EAC3B,eAAe;EACf,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".action_button {\n  padding: 8px 16px;\n  width: auto;\n  background-color: #25bafa29;\n  border-radius: 2px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n}\n\n.action_button:hover {\n  background-color: rgba(37, 186, 250, 0.25);\n  cursor: pointer;\n}\n\n.action_button p.MuiTypography-root {\n  color: #25bafa;\n}\n\n.download-icon {\n  background-color: #22272b;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 4px;\n  padding: 12px;\n}\n.add-icon {\n  width: 212px;\n  height: 140px;\n  background-color: #22272b;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 2px dashed #9fadbc66;\n}\n.child_issue_main {\n  width: 100%;\n  height: 1px;\n  background-color: #a6c5e229;\n  margin: 8px 2px;\n  border: 1px;\n}\n.iput_box .MuiInputBase-root.MuiOutlinedInput-root {\n  background-color: #22272b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
