import { EventContentArg } from "@fullcalendar/core";
import { Box, Card, Grid, Tooltip } from "@mui/material";
import TimeProgressBar from "./TimeProgressBar";
import MediumTypography from "../formlib/MediumTypography";
import "./css/scheduleItem.css";
import UserStack from "../formlib/UserStack";
import { useContext } from "react";
import SchedulerCalendar from "../formlib/SchedulerCalendar";
import { dateContext } from "../../pages/scheduler/SchedulerDashboard";
import { ReactComponent as RejectedIcon } from "../../assets/images/close-circle.svg";
import { ReactComponent as ExpiredIcon } from "../../assets/images/calendar-remove.svg";
import { ReactComponent as OnHoldStatusIcon } from "../../assets/images/OnHoldStatusIcon.svg";
import { ReactComponent as SCHEDULED } from "../../assets/images/SCHEDULED.svg";
import { ReactComponent as PauseIcon } from "../../assets/images/pause.svg";
import { useNavigate } from "react-router-dom";
import {
  CORRECTIVE_MAINTENANCE,
  GRASS_CUTTING_EXECUTION,
  GRASS_CUTTING_PLANNER,
  MODULE_CLEANING_PLANNER,
  PREVENTIVE_MAINTENANCE,
  WORK_ORDER_EXECUTION,
} from "../../routes/Routing";
import { hasPermission } from "../../utils/checkPermission";
import moment from "moment";

interface ScheduleItemProps {
  eventSchedule: EventContentArg;
  callApiCallBack: () => void;
  subGroupId?: number;
  schedulerViewChange: string;
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({
  eventSchedule,
  callApiCallBack,
  subGroupId,
  schedulerViewChange,
}) => {
  const dateCtx = useContext(dateContext);
  const navigate = useNavigate();

  const schedule = eventSchedule.event;
  const scheduleCustom = schedule.extendedProps.workOrder;

  let cardBg = "rgba(19, 196, 19, 0.12)"; // SCHEDULED | DISPATCHED - green
  let lineBg = "rgba(19, 196, 19, 1)";
  let statusBg = "rgba(28, 51, 41, 1)";
  let statusIcon = <SCHEDULED className="status_btn_icon" />;

  if (scheduleCustom.status === "ONHOLD") {
    // Yellow
    cardBg = "rgba(255, 159, 10, 0.12)";
    lineBg = "rgba(255, 159, 10, 1)";
    statusBg = "rgba(255, 159, 10, 0.2)";
    statusIcon = <OnHoldStatusIcon className="status_btn_icon" />;
  } else if (scheduleCustom.status === "PAUSED") {
    // Blue
    cardBg = "rgba(24, 106, 222, 0.12)";
    lineBg = "rgba(24, 106, 222, 1)";
    statusBg = "rgba(24, 106, 222, 0.2)";
    statusIcon = <PauseIcon className="status_btn_icon" />;
  } else if (scheduleCustom.status === "REJECTED") {
    // Red
    cardBg = "rgba(217, 31, 17, 0.12)";
    lineBg = "rgba(217, 31, 17, 1)";
    statusBg = "rgba(255, 69, 58, 0.2)";
    statusIcon = <RejectedIcon className="status_btn_icon" />;
  } else if (scheduleCustom.status === "EXPIRED") {
    // Grey
    cardBg = "rgba(91, 114, 130, 0.12)";
    lineBg = "rgba(91, 114, 130, 1)";
    statusBg = "rgba(57, 61, 77, 1)";
    statusIcon = <ExpiredIcon className="status_btn_icon" />;
  }

  let codeColor = "#B8ACF6";

  if (scheduleCustom.code === "CM") {
    codeColor = "#FF453A";
  }

  return (
    <>
      <Card
        className="scheduler-card"
        sx={{
          background: `linear-gradient(to right, ${cardBg}, transparent), linear-gradient(to right, rgba(40, 45, 51, 1), rgba(40, 45, 51, 1))`,
        }}
      >
        <Box display={"flex"} className="scheduler-card-content">
          <Box className="card-vr-line" sx={{ background: `${lineBg}` }}></Box>

          <Box sx={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={10}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"}>
                    <Box display={"flex"}>
                      <MediumTypography
                        className="card-wo-category"
                        label={scheduleCustom.code}
                        sxProps={{
                          color: `${codeColor}`,
                          border: `2px solid ${codeColor}`,
                        }}
                      />
                      <Box
                        onClick={() => {
                          if (
                            (hasPermission("WO_Module_Cleaning") ||
                              hasPermission("Read_Only")) &&
                            scheduleCustom.code === "MC"
                          ) {
                            navigate(WORK_ORDER_EXECUTION, {
                              state: {
                                cardId: scheduleCustom.cardId,
                                assetGroupId:
                                  scheduleCustom.cardDetails.assetGroupId,
                                idType: "workorder",
                                date: dateCtx.activeDate,
                                dateRange: dateCtx.activeDateRange,
                                schedulerViewChange: schedulerViewChange,
                              },
                            });
                          } else if (
                            (hasPermission("WO_Grass_Cutting") ||
                              hasPermission("Read_Only")) &&
                            scheduleCustom.code === "GC"
                          ) {
                            navigate(GRASS_CUTTING_EXECUTION, {
                              state: {
                                cardId: scheduleCustom.cardId,
                                assetGroupId:
                                  scheduleCustom.cardDetails.assetGroupId,
                                idType: "workorder",
                                date: dateCtx.activeDate,
                                dateRange: dateCtx.activeDateRange,
                                schedulerViewChange: schedulerViewChange,
                              },
                            });
                          } else if (
                            (hasPermission("WO_Corrective_Maintenance") ||
                              hasPermission("Read_Only")) &&
                            scheduleCustom.code === "CM"
                          ) {
                            navigate(CORRECTIVE_MAINTENANCE, {
                              state: {
                                cardId: scheduleCustom.cardId,
                                assetGroupId:
                                  scheduleCustom.cardDetails.assetGroupId,

                                idType: "workorder",
                                date: dateCtx.activeDate,
                                dateRange: dateCtx.activeDateRange,
                                subGroupId: subGroupId,
                                schedulerViewChange: schedulerViewChange,
                              },
                            });
                          } else if (
                            (hasPermission("WO_Preventive_Maintenance") ||
                              hasPermission("Read_Only")) &&
                            scheduleCustom.code === "PM"
                          ) {
                            navigate(PREVENTIVE_MAINTENANCE, {
                              state: {
                                cardId: scheduleCustom.cardId,
                                assetGroupId:
                                  scheduleCustom.cardDetails.assetGroupId,
                                idType: "workorder",
                                date: dateCtx.activeDate,
                                dateRange: dateCtx.activeDateRange,
                                schedulerViewChange: schedulerViewChange,
                              },
                            });
                          }
                        }}
                      >
                        <MediumTypography
                          className="card-wo-category-id"
                          label={scheduleCustom.sequenceId}
                        />
                      </Box>
                    </Box>
                    <Box className="card-item-seperator"></Box>
                    <Box display={"flex"} className="ellipsis-item-container">
                      <MediumTypography
                        className="card-wo-routine"
                        label={scheduleCustom.frequency}
                      />
                      <MediumTypography
                        className="card-wo-id ellipsis-item"
                        label={scheduleCustom.asset}
                        onClick={() => {
                          if (
                            (hasPermission("Plan_MC_GC_WO") ||
                              hasPermission("Read_Only")) &&
                            scheduleCustom.code === "MC"
                          ) {
                            navigate(MODULE_CLEANING_PLANNER, {
                              state: {
                                cardId: scheduleCustom.cardId,
                                assetGroupId:
                                  scheduleCustom.cardDetails.assetGroupId,
                                idType: "workorder",
                                date: dateCtx.activeDate,
                                dateRange: dateCtx.activeDateRange,
                                schedulerViewChange: schedulerViewChange,
                              },
                            });
                          } else if (
                            (hasPermission("Plan_MC_GC_WO") ||
                              hasPermission("Read_Only")) &&
                            scheduleCustom.code === "GC"
                          ) {
                            navigate(GRASS_CUTTING_PLANNER, {
                              state: {
                                cardId: scheduleCustom.cardId,
                                assetGroupId:
                                  scheduleCustom.cardDetails.assetGroupId,
                                idType: "workorder",
                                date: dateCtx.activeDate,
                                dateRange: dateCtx.activeDateRange,
                                schedulerViewChange: schedulerViewChange,
                              },
                            });
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={"flex"} className="ellipsis-item-container">
                    <MediumTypography
                      className="card-wo-issue ellipsis-item"
                      label={scheduleCustom.description}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2}>
                {scheduleCustom?.cardDetails?.statusCode === "DISPATCHED" && (
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    {!hasPermission("Read_Only") ||
                      (hasPermission("Move_WO") && (
                        <SchedulerCalendar
                          selectedDate={dateCtx.activeDate}
                          cardDetails={scheduleCustom.cardDetails}
                          scheduleMoveCallBack={() =>
                            !hasPermission("Move_WO") ||
                            !hasPermission("Read_Only")
                              ? {}
                              : callApiCallBack()
                          }
                          shiftStartTime={scheduleCustom.shiftStartTime}
                          shiftEndTime={scheduleCustom.shiftEndTime}
                          type="WORKORDER"
                          code={scheduleCustom.code}
                        />
                      ))}
                  </Box>
                )}
              </Grid>
            </Grid>

            <Box display={"flex"} justifyContent={"space-between"}>
              <Box sx={{ width: "150px" }}>
                <TimeProgressBar
                  scheduledStartTime={parseDateText(schedule.start) ?? ""}
                  scheduledEndTime={parseDateText(schedule.end) ?? ""}
                  actualStartTime={parseDateText(
                    scheduleCustom.actualStartTime
                  )}
                  actualEndTime={parseDateText(scheduleCustom.actualEndTime)}
                  progress={scheduleCustom.progress ?? 0}
                />
              </Box>

              <Box
                display={"flex"}
                sx={{ mt: "10px", mr: "8px", ml: "8px" }}
                justifyContent={"space-between"}
              >
                <Box>
                  <UserStack users={scheduleCustom.members} showMax={2} />
                </Box>
                <Tooltip title={scheduleCustom.status}>
                  <Box
                    className="card-wo-status"
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      alignItems: "center",
                      mt: "3px",
                      background: `${statusBg}`,
                    }}
                  >
                    {statusIcon}
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ScheduleItem;

function parseDateText(dateTimeString: Date | null) {
  if (dateTimeString) {
    // Use moment to parse the date and format it to the desired format
    return moment(dateTimeString).format("YYYY-MM-DDTHH:mm:ss");

    // return formattedDate; // Outputs in format: "2024-09-22T16:44:10"
  }

  return null;
}
