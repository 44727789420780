import { Box, Grid } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import TextInput from "../../../components/formlib/TextInput";
import "../../MasterDataManagement/styles.css";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { Formik, FormikErrors, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";

import {
  Accounts,
  AddUserRecord,
  MapType,
  SaveAddUserRecord,
  SubGroup,
} from "./users"; //
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import CustomDropdown, {
  OptionType,
} from "../../../components/formlib/CustomDropdown";

import { trim } from "lodash";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import {
  createDropdownOptionApi,
  fetchWizardsDataApi,
  MappedCommonTypes,
  SaveWizardsApi,
} from "../../../api/wizardsApi/Common";
import { USER_BASE_URL } from "../../../api/Constant";
import {
  convertToNumber,
  convertToNumberArray,
  handleError,
} from "../../../utils/commonFunctions";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import MappingModal from "../../../components/formlib/modal/MappingModal";
import TextInputMap from "../../../components/formlib/TextInputMap";
import CheckBox from "../../../components/formlib/CheckBox";
import { ListType } from "../wizrads.types";
import { useIntl } from "react-intl";

interface UserAddEditProps {
  open: boolean;
  id?: number;
  handleClose: () => void;
  selectedLanguageCode?: string;
  getDataCallback: () => void;
  accountList: ListType[];
}
const phoneValidation = (val: string) => {
  let formattedPrimaryPhone = val;

  if (formattedPrimaryPhone?.length < 5) {
    formattedPrimaryPhone = "";
  } else if (!formattedPrimaryPhone.startsWith("+")) {
    formattedPrimaryPhone = "+" + formattedPrimaryPhone;
  }
  return formattedPrimaryPhone;
};

const initialData: AddUserRecord = {
  id: undefined,
  employeeId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  dateOfBirth: null,
  dateOfJoining: null,
  selectedStatusId: 0,
  selectedTitleId: 0,
  selectedUserTypeId: 0,
  selectedThemeId: 0,
  selectedDepartmentId: 0,
  selectedLanguageId: 0,
  userTypes: [],
  userTitles: [],
  mappedSubGroups: [],
  mappedRoles: [],
  mappedSkills: [],
  theme: [],
  departments: [],
  languagePref: [],
  status: [],
  // selectedRoles: [],
  // selectedSkills: [],
  // selectedSubGroups: [],
  selectedSubGroupIds: [],
  selectedRoleMapIds: [],
  selectedSkillMapIds: [],
  userExperience: 0,
  isUniversalUser: false,
  selectedAccountMapIds: [],
  channelTypes: [],
  selectedChannelId: 0,
  selectedTechnicalRoleId: 0,
  technicalRoles: [],
  activationStatus: false,
};

const phoneNumberValidation = Yup.string()
  .required("validation.phoneNumberRequired")
  .test("is-valid-phone", "validation.phoneNumber", (value) => {
    // Check if the number starts with "91"
    if (value.startsWith("91")) {
      // Validate that the number is 12 digits long
      return /^\d{12}$/.test(value);
    } else {
      // Validate that the number is at least 6 digits long
      return /^\d{6,}$/.test(value);
    }
  });
const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });
export const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required("validation.fieldRequired"),
  firstName: Yup.string().required("validation.fieldRequired"),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().required("validation.fieldRequired"),
  phone: phoneNumberValidation,
  email: Yup.string().required().email("validation.invaldiEmail"),
  dateOfBirth: Yup.date()
    .nullable()
    .required("validation.fieldRequired")
    .min(new Date(1900, 0, 1))
    .max(new Date(), "Date cannot be in the future"),
  dateOfJoining: Yup.date().nullable(),
  selectedStatusId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedTitleId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedUserTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedThemeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedDepartmentId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedLanguageId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),

  selectedRoleMapIds: Yup.array().min(1, "validation.dropdown"),
  selectedSkillMapIds: Yup.array().min(1, "validation.dropdown"),
  userExperience: Yup.string()
    .matches(/^[0-9]*\.?[0-9]*$/, "validation.invalidNumberFormat")
    .test(
      "is-greater-than-zero",
      "validation.valueMustBeGreaterThanZero",
      (value) => {
        return value !== undefined && parseFloat(value) > 0;
      }
    )
    .required("validation.fieldRequired"),
  selectedChannelId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedTechnicalRoleId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
});

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 4,
};

const UserAddEdit: FC<UserAddEditProps> = ({
  open,
  id,
  handleClose,
  selectedLanguageCode,
  getDataCallback,
  accountList,
}) => {
  const [apiData, setApiData] = useState<AddUserRecord>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<any>>(null);
  const availableHeight = useAvailableHeight(250);

  const [openMapSubGroupModal, setOpenSubGroupModal] = useState<boolean>(false);
  const [openMapRoleModal, setOpenRoleModal] = useState<boolean>(false);
  const [openMapSkillModal, setOpenSkillModal] = useState<boolean>(false);
  const [roleMap, setRoleMap] = useState<MapType[]>([]);
  const [skillMap, setSkillMap] = useState<MapType[]>([]);
  const [subGroupMap, setSubGroupMap] = useState<SubGroup[]>([]);
  const [accountData, setAccountData] = useState<Accounts[]>([]);
  const [selectedAccountMapIds, setSelectedAccountMapIds] = useState<number[]>(
    []
  );
  const intl = useIntl();
  //const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      fetchData(id);
    }
  }, [id, open]);

  const handleValidation = (values: AddUserRecord) => {
    const errors: FormikErrors<AddUserRecord> = {};

    if (
      !values.isUniversalUser &&
      values.selectedAccountMapIds &&
      values.selectedAccountMapIds.length === 0
    ) {
      errors.selectedAccountMapIds = "validation.fieldRequired";
    }

    if (!values.isUniversalUser && values.selectedSubGroupIds.length === 0) {
      errors.selectedSubGroupIds = "validation.dropdown";
    }
    return errors;
  };

  const fetchData = (accId: number | undefined) => {
    toggleLoader(true);

    const fetchUserUrl = `${USER_BASE_URL}user`;
    fetchWizardsDataApi<AddUserRecord>(
      fetchUserUrl,
      accId,
      selectedLanguageCode
    )
      .then(async (response) => {
        const data = { ...initialData, ...response };
        setAccountData(data.mappedSubGroups ?? []);
        // setSubGroupMap(
        //   data.mappedSubGroups?.find(
        //     (item) =>
        //       data.selectedAccountMapIds &&
        //       data.selectedAccountMapIds.includes(item.id)
        //   )?.subGroups ?? []
        // );
        setRoleMap(data.mappedRoles ?? []);
        setSkillMap(data.mappedSkills ?? []);

        const updatedResponse: AddUserRecord = {
          ...data,
          phone: data.phone.startsWith("+")
            ? data.phone.substring(1)
            : data.phone,
          userTypes: data.userTypes,
          userTitles: data.userTitles,
          status: data.status,
          theme: data.theme,
          departments: data.departments,
          languagePref: data.languagePref,
        };
        setSelectedAccountMapIds(
          data.mappedSubGroups
            ?.filter((item) => item.isSelected)
            .map((item) => item.id) || []
        );
        setApiData({
          ...updatedResponse,
          selectedSubGroupIds:
            (data.mappedSubGroups &&
              data.mappedSubGroups
                .filter((item) => item.isSelected)
                .flatMap((item) =>
                  item.subGroups
                    .filter((sub) => sub.isSelected)
                    .map((sub) => sub.id)
                )) ||
            [],
          selectedRoleMapIds:
            (data.mappedRoles &&
              data.mappedRoles
                .filter((role) => role.isSelected)
                .map((role) => role.id)) ||
            [],
          selectedSkillMapIds:
            (data.mappedSkills &&
              data.mappedSkills
                .filter((skill) => skill.isSelected)
                .map((skill) => skill.id)) ||
            [],
        });
        formikRef.current?.setValues({
          ...data,
          selectedAccountMapIds:
            data.mappedSubGroups
              ?.filter((item) => item.isSelected)
              .map((item) => item.id) || [],
          selectedSubGroupIds:
            (data.mappedSubGroups &&
              data?.mappedSubGroups
                .find((item) => data.selectedAccountMapIds?.includes(item.id))
                ?.subGroups.filter((sub) => sub.isSelected)
                .map((sub) => sub.id)) ||
            [],
          selectedRoleMapIds:
            (data.mappedRoles &&
              data.mappedRoles
                .filter((role) => role.isSelected)
                .map((role) => role.id)) ||
            [],
          selectedSkillMapIds:
            (data.mappedSkills &&
              data.mappedSkills
                .filter((skill) => skill.isSelected)
                .map((skill) => skill.id)) ||
            [],
        });

        //setApiData(updatedResponse);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);

        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  useEffect(() => {
    if (
      Array.isArray(selectedAccountMapIds) &&
      selectedAccountMapIds.length > 0
    ) {
      const allSubGroups = selectedAccountMapIds.reduce(
        (acc: SubGroup[], accountId: number) => {
          const account = accountData.find(
            (account) => account.id === accountId
          );
          if (account) {
            return [...acc, ...account.subGroups];
          }
          return acc;
        },
        []
      );

      setSubGroupMap(allSubGroups);
    } else {
      setSubGroupMap([]);
    }
  }, [selectedAccountMapIds, accountData]);

  const saveData = (params: AddUserRecord) => {
    toggleLoader(true);

    const {
      userTypes,
      userTitles,
      mappedSubGroups,
      mappedRoles,
      mappedSkills,
      theme,
      departments,
      languagePref,
      status,

      ...updatedParams
    } = params;

    const apiUri = `${USER_BASE_URL}user`;
    SaveWizardsApi<SaveAddUserRecord>(
      apiUri,
      updatedParams,
      false,
      updatedParams?.id
    )
      .then(() => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const getOptions = (
    data: any[],
    nameKey = "name",
    idKey = "id"
  ): OptionType[] => {
    if (data === undefined || data === null || !Array.isArray(data)) return [];
    return data.map((item) => ({
      id: item[idKey].toString(),
      name: item[nameKey],
    }));
  };

  const handleSubGroupApply = (mappedData: MappedCommonTypes[]) => {
    setOpenSubGroupModal(false);
    const updatedSubGroup = subGroupMap.map((sub) => {
      const isSelected = mappedData.some((item) => item.id === sub.id);
      return {
        ...sub,
        isSelected: isSelected,
      };
    });
    setSubGroupMap(updatedSubGroup);
    formikRef.current?.setFieldValue(
      "selectedSubGroupIds",
      mappedData.map((sub) => sub.id)
    );
  };
  const handleRoleApply = (mappedData: MappedCommonTypes[]) => {
    setOpenRoleModal(false);
    const updatedRole = roleMap.map((role) => {
      const isSelected = mappedData.some((item) => item.id === role.id);
      return {
        ...role,
        isSelected: isSelected,
      };
    });
    setRoleMap(updatedRole);
    formikRef.current?.setFieldValue(
      "selectedRoleMapIds",
      mappedData.map((role) => role.id)
    );
  };
  const handleSkillsApply = (mappedData: MappedCommonTypes[]) => {
    setOpenSkillModal(false);
    const updatedSkills = skillMap.map((skill) => {
      const isSelected = mappedData.some((item) => item.id === skill.id);
      return {
        ...skill,
        isSelected: isSelected,
      };
    });
    setSkillMap(updatedSkills);
    formikRef.current?.setFieldValue(
      "selectedSkillMapIds",
      mappedData.map((skill) => skill.id)
    );
  };

  return (
    <Box>
      <Box className="mb-sm flex__">
        <MediumTypography
          labelId={id ? "userText" : "Add User"}
          fontSize="20px"
          fontWeight={700}
          className="mr-sm"
        />
        {id !== 0 && (
          <MediumTypography
            label={
              id
                ? apiData.firstName +
                  " " +
                  apiData.middleName +
                  " " +
                  apiData.lastName
                : ""
            }
            fontSize="20px"
            fontWeight={700}
          />
        )}
      </Box>
      {openMapSubGroupModal && (
        <MappingModal
          titleLabelId="UserSubGroup.mappingTitle"
          handleClose={() => setOpenSubGroupModal(false)}
          open={openMapSubGroupModal}
          onApply={handleSubGroupApply}
          availableLabel="UserSubGroup.available"
          mappingDetailsLabel="UserSubGroup.mappingDetails"
          mappedList={subGroupMap}
          textInputRequired={false}
        />
      )}
      {openMapRoleModal && (
        <MappingModal
          titleLabelId="UserRole.mappingTitle"
          handleClose={() => setOpenRoleModal(false)}
          open={openMapRoleModal}
          onApply={handleRoleApply}
          availableLabel="UserRole.available"
          mappingDetailsLabel="UserRole.mappingDetails"
          mappedList={roleMap}
          textInputRequired={false}
        />
      )}
      {openMapSkillModal && (
        <MappingModal
          titleLabelId="UserSkills.mappingTitle"
          handleClose={() => setOpenSkillModal(false)}
          open={openMapSkillModal}
          onApply={handleSkillsApply}
          availableLabel="UserSkills.available"
          mappingDetailsLabel="UserSkills.mappingDetails"
          mappedList={skillMap}
          textInputRequired={false}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            //handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            //handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        validate={handleValidation}
        onSubmit={(values: AddUserRecord) => {
          const params: AddUserRecord = {
            ...values,
            selectedDepartmentId: convertToNumber(values.selectedDepartmentId),
            selectedLanguageId: convertToNumber(values.selectedLanguageId),
            selectedStatusId: convertToNumber(values.selectedStatusId),
            selectedTitleId: convertToNumber(values.selectedTitleId),
            selectedUserTypeId: convertToNumber(values.selectedUserTypeId),
            selectedThemeId: convertToNumber(values.selectedThemeId),
            selectedAccountMapIds: convertToNumberArray(selectedAccountMapIds),
            phone: phoneValidation(values.phone),
          };

          saveData(params);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                  height: availableHeight,
                }}
                alignItems={"center"}
              >
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.firstName"
                    defaultLabel="First Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: values.activationStatus,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.firstName}
                    handlechange={(value: string) => {
                      setFieldValue("firstName", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="firstName"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.middleName"
                    defaultLabel="Middle Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.middleName}
                    handlechange={(value: string) => {
                      setFieldValue("middleName", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.lastName"
                    defaultLabel="Last Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: values.activationStatus,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.lastName}
                    handlechange={(value: string) => {
                      setFieldValue("lastName", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="lastName"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.employeeId"
                    defaultLabel="Employee ID"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: values.activationStatus,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.employeeId}
                    handlechange={(value: string) => {
                      setFieldValue("employeeId", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="employeeId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.email"
                    defaultLabel="Email"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: values.activationStatus,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.email}
                    handlechange={(value: string) => {
                      setFieldValue("email", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="email"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.phone"
                    defaultLabel="Phone Number"
                  />
                  <PhoneNumberInput
                    value={values.phone}
                    onChange={(value: string) => {
                      setFieldValue("phone", value);
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    disabled={values.activationStatus}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="phone"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.dob"
                    defaultLabel="Date of birth "
                  />
                  <DateTimePickerComponent
                    value={
                      values.dateOfBirth !== null
                        ? dayjs(values.dateOfBirth)
                        : null
                    }
                    labelid="commonDateText"
                    defaultlabelid="DD MMM YYYY"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("dateOfBirth", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    width="100%"
                    textWidth="100%"
                    views={["year", "month", "day"]}
                  />

                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="dateOfBirth"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.doj"
                    defaultLabel="Date of Joining"
                  />

                  <DateTimePickerComponent
                    value={
                      values.dateOfJoining !== null
                        ? dayjs(values.dateOfJoining)
                        : null
                    }
                    labelid="commonDateText"
                    defaultlabelid="DD MMM YYYY"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("dateOfJoining", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    width="100%"
                    textWidth="100%"
                    disableFuture={true}
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.Title"
                    defaultLabel="Title"
                  />
                  <CustomDropdown
                    options={getOptions(values.userTitles ?? [])}
                    selectedValue={values.selectedTitleId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedTitleId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                    onCreateOption={(option) => {
                      toggleLoader(true);
                      createDropdownOptionApi("TITLE", "Title", option)
                        .then((res) => {
                          setFieldValue("userTitles", res);
                          setFieldValue(
                            "selectedTitleId",
                            res.find((acc) => acc.name === option)?.id || ""
                          );
                          toggleLoader(false);
                        })
                        .catch((err) => {
                          handleError(err, setErrorDesc);
                          setOpenErrorModal(true);
                          toggleLoader(false);
                        });
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedTitleId"
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="user.technicalRole"
                    defaultLabel="Technical Role"
                  />
                  <CustomDropdown
                    options={getOptions(values.technicalRoles ?? [])}
                    selectedValue={values.selectedTechnicalRoleId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedTechnicalRoleId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedTechnicalRoleId"
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.AccountList"
                    defaultLabel="Account List"
                  />

                  {values.isUniversalUser ? (
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: true, // Make it read-only since the value is "ALL"
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={`${intl.formatMessage({
                        id: "commonText.All",
                        defaultMessage: "ALL",
                      })}`} // Set the value to "ALL"
                      handlechange={() => {}} // No-op since it's read-only
                    />
                  ) : (
                    <CustomDropdown
                      multiple={true}
                      disableSearch={false}
                      sxStyles={{
                        textField: {
                          padding: "0.5px 2px 0px 2px",
                        },
                      }}
                      options={
                        values.isUniversalUser
                          ? [
                              {
                                id: "all",
                                name: `${intl.formatMessage({
                                  id: "commonText.All",
                                  defaultMessage: "ALL",
                                })}`,
                              },
                            ]
                          : getOptions(accountData ?? [])
                      }
                      selectedValue={
                        values.isUniversalUser ? ["all"] : selectedAccountMapIds
                      }
                      onSelectedValueChange={(value) => {
                        if (!values.isUniversalUser) {
                          setFieldValue("selectedAccountMapIds", value);
                          setSelectedAccountMapIds(
                            Array.isArray(value)
                              ? value.map(Number)
                              : [Number(value)]
                          );
                        }
                      }}
                      disabled={values.isUniversalUser}
                    />
                  )}
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedAccountMapIds"
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.subGroupMap"
                    defaultLabel="Sub Group Map"
                  />
                  <TextInputMap
                    fieldValue={
                      values?.selectedSubGroupIds
                        ? subGroupMap
                            .filter((item) => item.isSelected) // Filter items where isSelected is true
                            .map((item) => item.name) // Map to only the 'name' of each selected item
                        : []
                    }
                    buttonCallBackClick={() => setOpenSubGroupModal(true)}
                    disabled={values.isUniversalUser}
                    // fieldValue={values.}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedSubGroupIds"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.skillMap"
                    defaultLabel="Skill Map"
                  />
                  <TextInputMap
                    fieldValue={
                      values?.selectedSkillMapIds
                        ? skillMap
                            .filter((item) => item.isSelected) // Filter items where isSelected is true
                            .map((item) => item.name) // Map to only the 'name' of each selected item
                        : []
                    }
                    buttonCallBackClick={() => setOpenSkillModal(true)}
                    // fieldValue={values.}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedSkillMapIds"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.roleMap"
                    defaultLabel="Role Map"
                  />
                  <TextInputMap
                    fieldValue={
                      values?.selectedRoleMapIds
                        ? roleMap
                            .filter((item) => item.isSelected) // Filter items where isSelected is true
                            .map((item) => item.name) // Map to only the 'name' of each selected item
                        : []
                    }
                    buttonCallBackClick={() => setOpenRoleModal(true)}
                    // fieldValue={values.}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedRoleMapIds"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.type"
                    defaultLabel="User Type "
                  />
                  <CustomDropdown
                    options={getOptions(values.userTypes ?? [])}
                    selectedValue={values.selectedUserTypeId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedUserTypeId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                    onCreateOption={(option) => {
                      toggleLoader(true);
                      createDropdownOptionApi("USER_TYPE", "UserType", option)
                        .then((res) => {
                          setFieldValue("userTypes", res);
                          setFieldValue(
                            "selectedUserTypeId",
                            res.find((acc) => acc.name === option)?.id || ""
                          );
                          toggleLoader(false);
                        })
                        .catch((err) => {
                          handleError(err, setErrorDesc);
                          setOpenErrorModal(true);
                          toggleLoader(false);
                        });
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedUserTypeId"
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.Theme"
                    defaultLabel="Theme"
                  />
                  <CustomDropdown
                    options={getOptions(values.theme ?? [])}
                    selectedValue={values.selectedThemeId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedThemeId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedThemeId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.Status"
                    defaultLabel="Status"
                  />
                  <CustomDropdown
                    options={getOptions(values.status ?? [])}
                    selectedValue={values.selectedStatusId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedStatusId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedStatusId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.mapDepartment"
                    defaultLabel="Map Department"
                  />
                  <CustomDropdown
                    options={getOptions(values.departments ?? [])}
                    selectedValue={values.selectedDepartmentId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedDepartmentId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedDepartmentId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.LanguagePreference"
                    defaultLabel="Language Preference"
                  />
                  <CustomDropdown
                    options={getOptions(values.languagePref ?? [])}
                    selectedValue={values.selectedLanguageId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedLanguageId", value);
                    }}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedLanguageId"
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.YearsOfExperience"
                    defaultLabel="Years Of Experience"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.userExperience !== null &&
                      values.userExperience !== 0
                        ? String(values.userExperience)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const decimalRegex = /^[0-9]*\.?[0-9]*$/; // Allows numbers and one decimal point

                      if (decimalRegex.test(value)) {
                        setFieldValue("userExperience", value); // Only update Formik state if the value matches the decimal pattern
                      }
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="userExperience"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.ChannelType"
                    defaultLabel="Channel"
                  />
                  <CustomDropdown
                    options={getOptions(values.channelTypes ?? [])}
                    selectedValue={values.selectedChannelId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedChannelId", value);
                    }}
                    disabled={values.activationStatus ? true : false}
                    sxStyles={{
                      textField: {
                        padding: "0.5px 4px 0px 5px",
                      },
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedChannelId"
                  />
                </Grid>
                <Grid
                  item
                  {...muiColumnProps}
                  className=" flex__ align__items__center mt-md"
                >
                  <CheckBox
                    value={
                      values.isUniversalUser === undefined
                        ? false
                        : values.isUniversalUser
                    }
                    onCheckBoxClick={(checked) => {
                      setFieldValue("isUniversalUser", checked);
                      setFieldValue("selectedAccountMapIds", []);
                      setFieldValue("selectedSubGroupIds", []);
                      setSelectedAccountMapIds([]);
                      setSubGroupMap([]);
                    }}
                    className="p-none"
                  />
                  <Box className="flex__ align__items__center flex__justify__center mt-sm ml-sm">
                    <MediumTypography
                      className="input-label"
                      labelId="User.UniversalUser"
                      defaultLabel="Is Universal User"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box className="flex__ p-sm" sx={{ justifyContent: "flex-end" }}>
                <ButtonComponent
                  className="btn-primary btn-cancel ml-md"
                  variantType="contained"
                  defaultLabelId={"btn.cancel"}
                  labelId={"btn.cancel"}
                  onClick={() => {
                    handleClose();
                  }}
                />
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  variantType="contained"
                  defaultLabelId={"Save"}
                  labelId={
                    id !== undefined && id !== 0 ? "btn.update" : "btn.save"
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default UserAddEdit;
