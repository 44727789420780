import React, { FC, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import SearchBox from "./SearchBox";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/images/DownArrow.svg";
import MediumTypography from "./MediumTypography";
import ButtonComponent from "./ButtonComponent";
import {
  AssetCategoryType,
  AssetGroupsType,
  AssetsType,
} from "../../utils/type";
import CheckBox from "./CheckBox";
import { CustomMenu } from "./CustomMenu";

type ExpandedCategoriesType = {
  [key: number]: {
    [key: number]: boolean;
  };
};

interface AssetProps {
  AssetData: AssetGroupsType[];
  selectedAssetGroupsParent: number[];
  selectedAssetsParent: number[];
  selectedAssetCategoryParent: number[];

  assetCallBack: (
    assetCallBack: number[],
    selecetdAssetGroup: number[],
    allSelectedAssetCategories: number[],
    filteredAssetData: AssetGroupsType[]
  ) => void;
}

const AssetTreeDropdown: FC<AssetProps> = ({
  AssetData,
  selectedAssetGroupsParent,
  selectedAssetsParent,
  selectedAssetCategoryParent,

  assetCallBack,
}) => {
  const [expandedGroups, setExpandedGroups] = useState<Record<number, boolean>>(
    {}
  );
  const [expandedCategories, setExpandedCategories] =
    useState<ExpandedCategoriesType>({});
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const [selectedAssetsCount, setSelectedAssetsCount] = useState<number>(0);

  const [assetAnchorEl, setAssetAnchorEl] = useState<null | HTMLElement>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const openitAsset = Boolean(assetAnchorEl);
  const [selectedAssetGroups, setSelectedAssetGroups] = useState<number[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<{
    [key: number]: number[];
  }>({});
  const [allSelectedAssets, setAllSelectedAssets] = useState<number[]>([]);
  const [allSelectedAssetCategories, setAllSelectedAssetCategories] = useState<
    number[]
  >([]);

  const [filteredAssetData, setFilteredAssetData] = useState<AssetGroupsType[]>(
    []
  );
  const [initialAssetData, setInitialAssetData] = useState<AssetGroupsType[]>(
    []
  );

  const [initialExpandedCategories, setInitialExpandedCategories] =
    useState<ExpandedCategoriesType>({});

  useEffect(() => {
    if (
      selectedAssetGroupsParent.length === 0 ||
      selectedAssetsParent.length === 0 ||
      selectedAssetCategoryParent.length === 0
    ) {
      setSelectedAssetsCount(0);
    }
  }, [
    selectedAssetGroupsParent,
    selectedAssetsParent,
    selectedAssetCategoryParent,
  ]);

  useEffect(() => {
    const initialExpandedGroups: Record<number, boolean> = {};
    const expandedCategoriesInitial: ExpandedCategoriesType = {};
    // Check if any of the parent selection arrays is empty or null
    const isAnyParentSelectionEmptyOrNull =
      selectedAssetGroupsParent.length === 0 ||
      selectedAssetsParent.length === 0 ||
      selectedAssetCategoryParent.length === 0 ||
      selectedAssetGroupsParent == null ||
      selectedAssetsParent == null ||
      selectedAssetCategoryParent == null;

    const updatedAssetData = AssetData.map((group: AssetGroupsType) => {
      const isGroupSelected = selectedAssetGroupsParent.includes(
        group.assetGroupId
      );

      const assetGroupBooleanValues =
        isGroupSelected || selectedAssetGroupsParent.length === 0;

      // Initialize group expansion
      initialExpandedGroups[group.assetGroupId] =
        isAnyParentSelectionEmptyOrNull || isGroupSelected;

      const updatedCategories = group.assetCategories.map(
        (category: AssetCategoryType) => {
          const isCategorySelected = selectedAssetCategoryParent.includes(
            category.assetCategoryId
          );

          // Initialize category expansion
          if (!expandedCategoriesInitial[group.assetGroupId]) {
            expandedCategoriesInitial[group.assetGroupId] = {};
          }
          expandedCategoriesInitial[group.assetGroupId][
            category.assetCategoryId
          ] = isAnyParentSelectionEmptyOrNull || isCategorySelected;

          const updatedAssets = category.assets.map((asset: AssetsType) => ({
            ...asset,
            // Set assetBooleanValue to true if any parent selection is empty or null
            assetBooleanValue:
              (assetGroupBooleanValues && isAnyParentSelectionEmptyOrNull) ||
              selectedAssetsParent.includes(asset.id),
          }));

          const allAssetsSelected = updatedAssets.every(
            (asset) => asset.assetBooleanValue
          );
          const someAssetsSelected = updatedAssets.some(
            (asset) => asset.assetBooleanValue
          );

          return {
            ...category,
            // Set assetCategoryBooleanValue to true if any parent selection is empty or null
            assetCategoryBooleanValue:
              assetGroupBooleanValues &&
              (isAnyParentSelectionEmptyOrNull || isCategorySelected),
            assetCategoryInterminate: !allAssetsSelected && someAssetsSelected,
            assets: updatedAssets,
          };
        }
      );

      const allCategoriesSelected = updatedCategories.every(
        (category) => category.assetCategoryBooleanValue
      );
      const someCategoriesSelected = updatedCategories.some(
        (category) => category.assetCategoryBooleanValue
      );

      return {
        ...group,
        // Set assetGroupBooleanValue to true if any parent selection is empty or null
        assetGroupBooleanValue: assetGroupBooleanValues,
        assetGroupInterminate: !allCategoriesSelected && someCategoriesSelected,
        assetCategories: updatedCategories,
      };
    });

    // Set the state with the modified asset data and initial expansions
    setExpandedGroups(initialExpandedGroups);

    setExpandedCategories(expandedCategoriesInitial);
    setInitialExpandedCategories(expandedCategoriesInitial);
    if (selectedAssetsParent && selectedAssetsParent.length > 0) {
      setSelectedAssets(
        updatedAssetData.reduce((acc: { [key: number]: number[] }, group) => {
          acc[group.assetGroupId] = group.assetCategories.reduce(
            (catAcc: number[], category) => {
              return [
                ...catAcc,
                ...category.assets
                  .filter((asset) => asset.assetBooleanValue)
                  .map((asset) => asset.id),
              ];
            },
            []
          );
          return acc;
        }, {})
      );
      // Set the selected assets count
      setSelectedAssetsCount(
        updatedAssetData.reduce((count, group) => {
          group.assetCategories.forEach((cat) => {
            count += cat.assets.filter(
              (asset) => asset.assetBooleanValue
            ).length;
          });
          return count;
        }, 0)
      );
    } else {
      // If selectedAssetsParent is empty or null, set selected assets count to 0
      setSelectedAssetsCount(0);
    }
    setFilteredAssetData(updatedAssetData);
    setInitialAssetData(updatedAssetData);
  }, [
    AssetData,
    selectedAssetGroupsParent,
    selectedAssetsParent,
    selectedAssetCategoryParent,
  ]);

  useEffect(() => {
    if (searchQuery === "") {
      // Reset to the initial state when the search query is empty
      setExpandedCategories(initialExpandedCategories);
      setFilteredAssetData(initialAssetData);
      return;
    }

    const initialExpandedCategory: ExpandedCategoriesType = {};
    const updatedFilteredData = initialAssetData // Use the original data here
      .map((group) => {
        const groupMatchesQuery = group.assetGroupName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const filteredCategories = group.assetCategories
          .map((cat) => {
            const categoryMatchesQuery = cat.assetCategoryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase());

            const filteredAssets = cat.assets.filter((asset) =>
              asset.name.toLowerCase().includes(searchQuery.toLowerCase())
            );

            if (categoryMatchesQuery || filteredAssets.length > 0) {
              if (!initialExpandedCategory[group.assetGroupId]) {
                initialExpandedCategory[group.assetGroupId] = {};
              }
              initialExpandedCategory[group.assetGroupId][cat.assetCategoryId] =
                true;

              return {
                ...cat,
                assets: categoryMatchesQuery ? cat.assets : filteredAssets,
              };
            }
            return null;
          })
          .filter((cat) => cat !== null);

        if (groupMatchesQuery || filteredCategories.length > 0) {
          return {
            ...group,
            assetCategories: filteredCategories,
          };
        }
        return null;
      })
      .filter((group) => group !== null);

    setFilteredAssetData(updatedFilteredData as AssetGroupsType[]);
    setExpandedCategories(initialExpandedCategory);
  }, [searchQuery, initialAssetData, initialExpandedCategories]);

  const handleAssetGroupSelection = (assetGroupItems: AssetGroupsType) => {
    const updatedAssetData = filteredAssetData.map((group) => {
      if (group.assetGroupId === assetGroupItems.assetGroupId) {
        const updatedGroup = {
          ...group,
          assetGroupBooleanValue: !group.assetGroupBooleanValue,
          assetGroupInterminate: false,
        };

        // Update the boolean values of the underlying asset categories
        updatedGroup.assetCategories = updatedGroup.assetCategories.map(
          (assetCat) => ({
            ...assetCat,
            assetCategoryBooleanValue: updatedGroup.assetGroupBooleanValue,
          })
        );

        // Update the boolean values of the assets
        updatedGroup.assetCategories.forEach((category) => {
          category.assets.forEach((asset) => {
            asset.assetBooleanValue = updatedGroup.assetGroupBooleanValue;
          });
        });

        return updatedGroup;
      }
      return group;
    });
    calculateCount(updatedAssetData);

    // Update the state with the modified asset data

    setFilteredAssetData(updatedAssetData);
  };

  const calculateCount = (updatedAssets: AssetGroupsType[]) => {
    let assetGroupCount: number[] = [];
    let assetCategoriesCount: number[] = [];
    let assetsCount: number[] = [];
    updatedAssets.forEach((groupsCount) => {
      if (groupsCount.assetGroupBooleanValue) {
        assetGroupCount.push(groupsCount.assetGroupId);
      }
      groupsCount.assetCategories.forEach((assetCategoryCount) => {
        if (assetCategoryCount.assetCategoryBooleanValue) {
          assetCategoriesCount.push(assetCategoryCount.assetCategoryId);
        }

        assetCategoryCount.assets.forEach((ass) => {
          if (ass.assetBooleanValue) {
            assetsCount.push(ass.id);
          }
          return true;
        });
        return true;
      });
      return true;
    });
    localStorage.setItem(
      "selectedAssetGroupsIds",
      JSON.stringify(assetGroupCount)
    );
    setSelectedAssetsCount(assetsCount.length);
    setSelectedAssetGroups(assetGroupCount);
    setAllSelectedAssetCategories(assetCategoriesCount);
    setAllSelectedAssets(assetsCount);
  };

  const handleAssetCategorySelection = (
    assetGroupItems: AssetGroupsType,
    assetCategory: AssetCategoryType
  ) => {
    const updatedAssetData = filteredAssetData.map((group) => {
      if (group.assetGroupId === assetGroupItems.assetGroupId) {
        // Update the boolean value of the asset category
        group.assetCategories = group.assetCategories.map((cat) => {
          if (cat.assetCategoryId === assetCategory.assetCategoryId) {
            const isCategorySelected = !cat.assetCategoryBooleanValue;

            // Update the boolean value of the asset category
            return {
              ...cat,
              assetCategoryBooleanValue: isCategorySelected,
            };
          }
          return cat;
        });

        // Update the boolean values of the assets within the selected category
        group.assetCategories.forEach((cat) => {
          if (cat.assetCategoryId === assetCategory.assetCategoryId) {
            cat.assets.forEach((asset) => {
              asset.assetBooleanValue = cat.assetCategoryBooleanValue;
            });
          }
        });

        // Update the boolean values of the asset groups
        const allCategoriesSelected = group.assetCategories.every(
          (cat) => cat.assetCategoryBooleanValue
        );
        const allCategoriesDeselected = group.assetCategories.every(
          (cat) => !cat.assetCategoryBooleanValue
        );

        group.assetGroupBooleanValue = allCategoriesSelected;
        group.assetGroupInterminate =
          !allCategoriesDeselected && !allCategoriesSelected;

        return group;
      }
      return group;
    });
    calculateCount(updatedAssetData);

    // Update the state with the modified asset data
    setFilteredAssetData(updatedAssetData);
  };

  const handleAssets = (
    assetGroupItems: AssetGroupsType,
    assetCategory: AssetCategoryType,
    assetSelected: AssetsType
  ) => {
    const updatedAssetData = filteredAssetData.map((group) => {
      if (group.assetGroupId === assetGroupItems.assetGroupId) {
        group.assetCategories = group.assetCategories.map((cat) => {
          if (cat.assetCategoryId === assetCategory.assetCategoryId) {
            // Update the boolean value of the asset
            cat.assets = cat.assets.map((asset) => {
              if (asset.id === assetSelected.id) {
                return {
                  ...asset,
                  assetBooleanValue: !asset.assetBooleanValue,
                };
              }
              return asset;
            });

            // Check if all assets are deselected
            const allAssetsDeselected = cat.assets.every(
              (asset) => !asset.assetBooleanValue
            );

            // Update the asset category's boolean value and indeterminate state
            if (allAssetsDeselected) {
              cat.assetCategoryBooleanValue = false;
              cat.assetCategoryInterminate = false;
            } else {
              const allAssetsSelected = cat.assets.every(
                (asset) => asset.assetBooleanValue
              );
              cat.assetCategoryBooleanValue = allAssetsSelected;
              cat.assetCategoryInterminate = !allAssetsSelected;
            }

            // Check if all asset categories are deselected
            const allCategoriesDeselected = group.assetCategories.every(
              (allCat) => !allCat.assetCategoryBooleanValue
            );
            const allCategoriesSelected = group.assetCategories.every(
              (catSele) => catSele.assetCategoryBooleanValue
            );

            // Update the asset group's boolean value and indeterminate state
            group.assetGroupBooleanValue = allCategoriesSelected;
            group.assetGroupInterminate =
              !allCategoriesDeselected && !allCategoriesSelected;

            return cat;
          }
          return cat;
        });
      }
      return group;
    });

    calculateCount(updatedAssetData);
    // Update the state with the modified asset data
    setFilteredAssetData(updatedAssetData);

    // Update the count of selected assets and asset groups
  };

  const handleAssetDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAssetAnchorEl(event.currentTarget);
  };

  const handleAssetDropdownClose = () => {
    setAssetAnchorEl(null);
  };

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  const toggleSelelction = (assetGroupItems: AssetGroupsType) => {
    setExpandedGroups((prevExpanded) => ({
      ...prevExpanded,
      [assetGroupItems.assetGroupId]:
        !prevExpanded[assetGroupItems.assetGroupId],
    }));
  };
  const toggleCategorySelection = (
    assetGroupId: number,
    assetCategoryId: number
  ) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [assetGroupId]: {
        ...prevExpanded[assetGroupId],
        [assetCategoryId]: !prevExpanded[assetGroupId]?.[assetCategoryId],
      },
    }));
    setInitialExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [assetGroupId]: {
        ...prevExpanded[assetGroupId],
        [assetCategoryId]: !prevExpanded[assetGroupId]?.[assetCategoryId],
      },
    }));
  };

  return (
    <Box>
      <Box
        className="cursor__pointer"
        sx={{
          border: "1px solid rgba(166, 197, 226, 0.16)",
          width: "220px",
          height: "32px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor:
            selectedAssetsCount >= 1 ? "rgba(34, 63, 100, 1)" : "",
        }}
        onClick={handleAssetDropdown}
      >
        <Box sx={{ display: "flex" }}>
          <MediumTypography
            labelId="SelectAssets"
            defaultLabel="Select Assets"
            sxProps={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#FFFFFF",
              opacity: "0.6",
              lineHeight: "20px",
              padding: "8px",
            }}
          />
          {selectedAssetsCount >= 1 && (
            <Box
              sx={{
                margin: "auto",
                background: "#175FFA",
                padding: "0px 6px",
                borderRadius: "20px",
              }}
            >
              <MediumTypography
                label={selectedAssetsCount.toString()}
                sxProps={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#FFFFFF",
                }}
              />
            </Box>
          )}
        </Box>
        <DownArrow
          className="position__relative cursor__pointer"
          style={{ right: "10px" }}
        />
      </Box>

      <MemorizedCustomMenu
        id="demo-customized-menu"
        anchorEl={assetAnchorEl}
        open={openitAsset}
        maxWidth={400}
        minWidth={400}
        onClose={handleAssetDropdownClose}
        sx={{ marginLeft: "10px" }}
      >
        <Box
          sx={{ minWidth: "400px", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ alignSelf: "center", my: "8px" }}>
            <SearchBox
              labelId="defaultSearch"
              defaultlabel="Search"
              backgroundColor="#22272B"
              sxProps={{ minWidth: "350px !important" }}
              onChange={handleSearchChange}
            />

            <ul className="no-marker">
              {filteredAssetData.map((assetGroupItems: AssetGroupsType) => {
                const isGroupExpanded =
                  expandedGroups[assetGroupItems.assetGroupId];
                return (
                  <li key={assetGroupItems.assetGroupId}>
                    <Box
                      className="flex__"
                      sx={{
                        padding: "8px",
                        alignItems: "center",
                        gap: "0px",
                        cursor: "pointer",
                      }}
                    >
                      <CheckBox
                        value={assetGroupItems.assetGroupBooleanValue}
                        indeterminate={assetGroupItems.assetGroupInterminate}
                        onCheckBoxClick={() =>
                          handleAssetGroupSelection(assetGroupItems)
                        }
                      />

                      {isGroupExpanded ? (
                        <DownArrow
                          onClick={() => toggleSelelction(assetGroupItems)}
                        />
                      ) : (
                        <RightArrow
                          onClick={() => toggleSelelction(assetGroupItems)}
                        />
                      )}
                      <Box
                        className="flex__ position__relative"
                        alignItems="center"
                        sx={{ left: "5px" }}
                      >
                        <MediumTypography
                          label={assetGroupItems.assetGroupName}
                          fontSize="14px"
                          textColor="#ffffff"
                        />
                      </Box>
                    </Box>
                    {isGroupExpanded &&
                      assetGroupItems.assetCategories.map(
                        (assetCategory: AssetCategoryType) => {
                          const isCategoryExpanded =
                            expandedCategories[assetGroupItems.assetGroupId]?.[
                              assetCategory.assetCategoryId
                            ] ?? false;
                          return (
                            <ul
                              className="no-marker"
                              key={assetCategory.assetCategoryId}
                            >
                              <li className="listItem">
                                <Box className="flex__" alignItems="center">
                                  <CheckBox
                                    value={
                                      assetCategory.assetCategoryBooleanValue
                                    }
                                    indeterminate={
                                      assetCategory.assetCategoryInterminate
                                    }
                                    onCheckBoxClick={() =>
                                      handleAssetCategorySelection(
                                        assetGroupItems,
                                        assetCategory
                                      )
                                    }
                                  />

                                  {isCategoryExpanded ? (
                                    <DownArrow
                                      onClick={() =>
                                        toggleCategorySelection(
                                          assetGroupItems.assetGroupId,
                                          assetCategory.assetCategoryId
                                        )
                                      }
                                    />
                                  ) : (
                                    <RightArrow
                                      onClick={() =>
                                        toggleCategorySelection(
                                          assetGroupItems.assetGroupId,
                                          assetCategory.assetCategoryId
                                        )
                                      }
                                    />
                                  )}
                                  <Box
                                    className="flex__ position__relative"
                                    alignItems="center"
                                    sx={{ left: "5px" }}
                                  >
                                    <MediumTypography
                                      label={assetCategory.assetCategoryName}
                                      fontSize="14px"
                                      textColor="#ffffff"
                                    />
                                  </Box>
                                </Box>
                                {isCategoryExpanded && (
                                  <ul className="no-marker">
                                    {assetCategory.assets.map(
                                      (asset: AssetsType) => (
                                        <li key={asset.id} className="listItem">
                                          <Box
                                            className="flex__"
                                            alignItems="center"
                                          >
                                            <CheckBox
                                              value={asset.assetBooleanValue}
                                              onCheckBoxClick={() =>
                                                handleAssets(
                                                  assetGroupItems,
                                                  assetCategory,
                                                  asset
                                                )
                                              }
                                            />
                                            <MediumTypography
                                              label={asset.name}
                                              fontSize="14px"
                                              textColor="#ffffff"
                                            />
                                          </Box>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </li>
                            </ul>
                          );
                        }
                      )}
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box
            className="width__100 flex__container p-md"
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "#1D2125",
            }}
          >
            <ButtonComponent
              className="btn-primary btn-submit"
              labelId="Apply"
              defaultLabelId="Apply"
              sxProps={{ width: "100px" }}
              disabled={
                selectedAssetGroups.toString().length < 0 ||
                filteredAssetData.length === 0
              }
              onClick={() => {
                assetCallBack(
                  allSelectedAssets,
                  selectedAssetGroups,
                  allSelectedAssetCategories,
                  filteredAssetData
                );
                handleAssetDropdownClose();
              }}
            />
          </Box>
        </Box>
      </MemorizedCustomMenu>
    </Box>
  );
};

export default AssetTreeDropdown;
