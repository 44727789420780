import {
  create,
  ApisauceInstance,
  ApiResponse,
  ApisauceConfig,
} from "apisauce";
import { TICKET_BASE_URL } from "./Constant";

export default class TicketApiClient {
  private static _instance: TicketApiClient;

  private api: ApisauceInstance;

  constructor() {
    this.api = create({
      baseURL: TICKET_BASE_URL,
    });

    this.api.addRequestTransform((request) => {
      const requestConfig = request;

      if (requestConfig && requestConfig.headers) {
        requestConfig.headers["Accept-Language"] =
          localStorage.getItem("language") ?? "en";

        requestConfig.headers["Accept-Timezone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        const userId = Number(localStorage.getItem("id"));
        if (!isNaN(userId) && userId) {
          requestConfig.headers["userId"] = userId.toString();
        }

        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
      }
    });
  }

  public static getInstance(): TicketApiClient {
    if (!this._instance) {
      this._instance = new TicketApiClient();
    }
    return this._instance;
  }

  async post<T, U>(
    url: string,
    payload?: T,
    axiosConfig?: ApisauceConfig
  ): Promise<ApiResponse<T, U>> {
    return this.api.post<T, U>(url, payload, axiosConfig);
  }

  async get<T>(
    url: string,
    axiosConfig?: ApisauceConfig
  ): Promise<ApiResponse<T, T>> {
    return this.api.get<T>(url, axiosConfig);
  }

  async put<T>(
    url: string,
    payload?: T | string | number | boolean,
    axiosConfig?: ApisauceConfig
  ): Promise<ApiResponse<T, T>> {
    return this.api.put<T>(url, payload, axiosConfig);
  }

  async delete<T>(url: string): Promise<ApiResponse<T, T>> {
    return this.api.delete<T>(url);
  }
}
