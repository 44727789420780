import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import DOMPurify from "dompurify";

interface HtmlContentProps extends BoxProps {
  children: string;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ children, ...props }) => {
  const sanitizedHtml = DOMPurify.sanitize(children);

  return <Box dangerouslySetInnerHTML={{ __html: sanitizedHtml }} {...props} />;
};

export default HtmlContent;
