import { Box, Divider, Grid, Tooltip } from "@mui/material";
import axios from "axios";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import TextInput from "../../../components/formlib/TextInput";
import {
  Permit,
  PreferredTeamMember,
  RoleType,
  Tools,
  WorkOrderMasterType,
  WorkOrderTypeResponse,
} from "../types/workOrderTypes";
import { FC, useContext, useEffect, useState } from "react";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { ReactComponent as DownArrow } from "../../../assets/images/downarrow2.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/uparrow2.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/addBlue.svg";
import CheckBox from "../../../components/formlib/CheckBox";
import { Add, Cancel, CheckCircle, Remove } from "@mui/icons-material";
import SwitchComponent from "../../../components/SwitchComponent";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";
import { ReactComponent as Delete } from "../../../assets/images/trash.svg";
import AddAssetSetModal from "./AddAssetSetModal";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import WoCreatedSuccessModal from "./WoCreatedSuccessModal";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import {
  completeWoMaster,
  fetchWizardsDataApi,
  saveWorkOrderApi,
} from "../../../api/wizardsApi/Common";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import WoTypeSelection from "./WoTypeSelection";
import AssetCategorySelection from "./AssetCategorySelection";
import SkillsComponent from "./SkillsComponent";
import PermitsComponent from "./PermitsComponent";
import MaterialsComponent from "./MaterialsComponent";
import PreferredTeamMembers from "./PreferredTeamMembers";
import PreferredRoles from "./PreferredRoles";
import { set } from "lodash";
import { t } from "@fullcalendar/resource/internal-common";

const muiColumnProps = {
  xs: 12,
  sm: 8,
  md: 6,
  lg: 6,
  xl: 6,
};

export interface TableContentData {
  id: number;
  subCategory: string;
  code: string | null;
  description: string;
  uom?: string | null;
  quantity?: string;
}
export interface PermitContent {
  id: number;
  permitType: string;
  name: string;
  description: string;
}
export interface RowData {
  id: string;
  data: TableContentData;
}

export interface PermitData {
  id: string;
  data: PermitContent;
}

export interface WorkOrderProps {
  assetGroupId: number;
  openAddEditForm: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void;
  isCompleted: boolean;
}

export interface SelectedSubCategoryMap {
  [key: string]: DropDownType;
}
export interface CategoryRows {
  [categoryName: string]: RowData[];
}

const AddEditWorkOrderMaster: FC<WorkOrderProps> = ({
  assetGroupId,
  openAddEditForm,
  handleClose,
  id,
  getDataCallback,
  isCompleted,
}) => {
  const [workOrderMasterResponse, setWorkOrderMasterResponse] =
    useState<WorkOrderMasterType | null>(null);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [woTypeExpand, setWOTypeExpand] = useState(false);
  const [shiftExpand, setShiftExpand] = useState(false);
  const [assetCatExpand, setAssetCatExpand] = useState(false);
  const [skillsExpand, setSkillsExpand] = useState(false);
  const [roleExpand, setRoleExpand] = useState(false);
  const [teamMembersExpand, setTeamMembersExpand] = useState(false);
  const [permitsExpand, setPermitsExpand] = useState(false);
  const [toolsExpand, setToolsExpand] = useState(false);
  const [assetsExpand, setAssetsExpand] = useState(false);
  const [approverExpand, setApproverExpand] = useState(false);
  const [postCompletionExpand, setPostCompletionExpand] = useState(false);
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [activeTitle, setActiveTitle] = useState<string>("");
  const [permitsTableData, setPermitsTableData] = useState<PermitData[]>([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openAssetModal, setOpenAssetModal] = useState(false);
  const [toolsTableData, setToolsTableData] = useState<CategoryRows>({});

  //approvers
  const [level1, setLevel1] = useState(true);
  const [level2, setLevel2] = useState(false);
  const [level3, setLevel3] = useState(false);

  const availableHeight = useAvailableHeight(280);
  const [assetSetId, setAssetSetId] = useState(0);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [hasChanges, setHasChanges] = useState(false);
  const [toolsChanges, setToolsChanges] = useState(false);
  const [permitChanges, setPermitChanges] = useState(false);
  const [woId, setWOId] = useState<number | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  const [sectionStatus, setSectionStatus] = useState({
    inputSection: false,
    woType: false,
    assetCategory: false,
    assetList: false,
    teamMembers: false,
  });
  const [applyStatus, setApplyStatus] = useState({
    woType: false,
    assetCategory: false,
    assetList: false,
    skills: false,
    roles: false,
    teamMembers: false,
    shifts: false,
    postCompletion: false,
    tools: false,
    permits: false,
    approvers: false,
    // Add more sections as needed
  });

  const getPermitData = (data: Permit[]) => {
    let tableData: PermitData[] = [];
    data.forEach((permit) => {
      permit.typeList?.forEach((p) => {
        if (p.isSelected) {
          tableData.push({
            id: `row-${Date.now()}`,
            data: {
              id: p.id,
              permitType: permit?.name || "",
              name: p.name,
              description: p.code || "",
            },
          });
        }
      });
    });
    setPermitsTableData(tableData);
  };

  const getToolsData = (data: Tools[]) => {
    const newToolsTableData: CategoryRows = {};

    data.forEach((category) => {
      let tableData: RowData[] = [];
      category.typeList?.forEach((subCat) => {
        subCat.typeList?.forEach((sub) => {
          if (sub.isSelected) {
            const rowId = `row-${Date.now()}`;
            tableData.push({
              id: rowId,
              data: {
                id: sub.id,
                subCategory: subCat?.name || "",
                code: sub.code,
                description: sub.name,
                quantity: (sub && sub.quantity)?.toString() || "",
                uom: sub.uom,
              },
            });
          }
        });
      });
      newToolsTableData[category.name] = tableData;
    });
    setToolsTableData(newToolsTableData);
  };

  useEffect(() => {
    if (openAddEditForm) {
      fetchData(id);
    }
  }, [id, openAddEditForm]);

  useEffect(() => {
    const allFieldsFilled =
      workOrderMasterResponse?.name !== null &&
      workOrderMasterResponse?.name !== "" &&
      workOrderMasterResponse?.name !== undefined &&
      workOrderMasterResponse.description !== "" &&
      workOrderMasterResponse.description !== null &&
      workOrderMasterResponse.description !== undefined &&
      workOrderMasterResponse?.estimatedEfforts !== 0 &&
      workOrderMasterResponse?.estimatedEfforts !== undefined &&
      workOrderMasterResponse?.estimatedEfforts !== null &&
      workOrderMasterResponse.startDate !== "" &&
      workOrderMasterResponse.startDate !== null &&
      workOrderMasterResponse.startDate !== undefined &&
      workOrderMasterResponse.endDate !== "" &&
      workOrderMasterResponse.endDate !== null &&
      workOrderMasterResponse.endDate !== undefined;
    if (allFieldsFilled) {
      setSectionStatus((prevStatus) => ({
        ...prevStatus,
        woType: true,
      }));
    }
    //assets

    const hasSelectedRolesWithLevel =
      workOrderMasterResponse?.preferredRoleList.some(
        (role) => role.isSelected
      );
    if (hasSelectedRolesWithLevel) {
      setSectionStatus((prevStatus) => ({
        ...prevStatus,
        teamMembers: true,
      }));
    } else {
      setSectionStatus((prevStatus) => ({
        ...prevStatus,
        teamMembers: false,
      }));
    }
  }, [workOrderMasterResponse]);

  const fetchData = (accId: number | undefined) => {
    toggleLoader(true);

    const fetchUrl = `${assetGroupId}/woMaster`;

    fetchWizardsDataApi<WorkOrderMasterType>(fetchUrl, accId, "en")
      .then(async (response) => {
        const sortedPreferredTeamMembers = response.preferredTeamMembers.map(
          (member) => {
            return {
              ...member,
              list:
                member.list.length > 0
                  ? member.list.sort(
                      (a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity)
                    )
                  : member.list,
            };
          }
        );
        setWorkOrderMasterResponse({
          ...response,
          preferredTeamMembers: sortedPreferredTeamMembers,
        });
        if (accId) {
          setWOId(accId);
        }

        setSectionStatus((prevStatus) => ({
          ...prevStatus,
          inputSection: true,
          woType:
            response.selectedWOTypeId !== null &&
            response.selectedWOTypeId !== undefined &&
            response.selectedWOTypeId !== 0 &&
            ((selectedWOType?.code !== "MC" && selectedWOType?.code !== "GC") ||
              (response.selectedSubTypeId !== null &&
                response.selectedSubTypeId !== undefined))
              ? true
              : false,
          assetCategory:
            response.selectedAssetCategoryId !== null &&
            response.selectedAssetCategoryId !== undefined &&
            response.selectedAssetCategoryId !== 0 &&
            (selectedWOType?.code !== "CM"
              ? response.selectedFrequencyId !== null &&
                response.selectedFrequencyId !== undefined &&
                response.selectedFrequencyId !== 0
              : true)
              ? true
              : false,
          assetList:
            response.selectedAssetCategoryId !== null &&
            response.selectedAssetCategoryId !== undefined &&
            response.selectedAssetCategoryId !== 0 &&
            (selectedWOType?.code !== "CM"
              ? response.selectedFrequencyId !== null &&
                response.selectedFrequencyId !== undefined &&
                response.selectedFrequencyId !== 0
              : true)
              ? true
              : false,
          teamMembers: response.preferredRoleList.some(
            (role) => role.isSelected
          ),
        }));

        setPostCompletionExpand(response.isPostCompletionChecklist);

        if (response.approver1 !== null && response.approver1 !== undefined) {
          setLevel1(true);
        }
        if (response.approver2 !== null && response.approver2 !== undefined) {
          setLevel2(true);
        }
        if (response.approver3 !== null && response.approver3 !== undefined) {
          setLevel3(true);
        }

        getPermitData(response.permits);
        getToolsData(response.toolsAndConsumables);

        setActiveCategory(response.toolsAndConsumables[0]?.name ?? "");

        const selectedWOType = response.woTypes.find(
          (woType) => woType.id === response.selectedWOTypeId
        );
        setApplyStatus((prevStatus) => ({
          ...prevStatus,
          woType:
            response.selectedWOTypeId !== null &&
            response.selectedWOTypeId !== undefined &&
            response.selectedWOTypeId !== 0 &&
            ((selectedWOType?.code !== "MC" && selectedWOType?.code !== "GC") ||
              (response.selectedSubTypeId !== null &&
                response.selectedSubTypeId !== undefined))
              ? true
              : false,
          assetCategory:
            response.selectedAssetCategoryId !== null &&
            response.selectedAssetCategoryId !== undefined &&
            response.selectedAssetCategoryId !== 0 &&
            (selectedWOType?.code !== "CM"
              ? response.selectedFrequencyId !== null &&
                response.selectedFrequencyId !== undefined &&
                response.selectedFrequencyId !== 0
              : true)
              ? true
              : false,
          assetList:
            !response.isMultipleAssets ||
            (response.isMultipleAssets &&
              response.assetSet.length > 0 &&
              response.assetSet.some(
                (asset) => asset.list && asset.list.length > 0
              ))
              ? true
              : false,
          skills:
            !response.isSkillCheckRequired ||
            (response.isSkillCheckRequired &&
              response.selectedSkillIds.length > 0)
              ? true
              : false,
          roles: response.preferredRoleList.some((role) => role.isSelected)
            ? true
            : false,
          teamMembers: response.preferredRoleList.some(
            (role) =>
              role.isSelected &&
              response.preferredTeamMembers.length > 0 &&
              response.preferredTeamMembers.every(
                (member) => member.list.length > 0
              )
          )
            ? true
            : false,
          approvers:
            response.approver1 !== null && response.approver1 !== undefined
              ? true
              : false,
          shifts: response.selectedShiftId !== null ? true : false,
          postCompletion:
            (response.isPostCompletionChecklist &&
              response.checklistList.some(
                (checklist) => checklist.isSelected
              )) ||
            !response.isPostCompletionChecklist
              ? true
              : false,
          permits: response.permits.some(
            (permit) => permit.typeList.some((p) => p.isSelected) || isCompleted
          ),
          tools: response.toolsAndConsumables.some(
            (category) =>
              category.typeList.some((subCat) =>
                subCat.typeList.some((sub) => sub.isSelected)
              ) || isCompleted
          ),
        }));
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const handleClickWOType = async (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        selectedWOTypeId: prev.selectedWOTypeId === id ? null : id,
        selectedSubTypeId: null,
        selectedFrequencyId: null,
      };
    });

    if (id) {
      const queryParams = {
        SELECTED_WO_TYPE_ID: id,
        woMasterId: woId,
      };

      const params =
        woId !== null && woId !== undefined
          ? { SELECTED_WO_TYPE_ID: id }
          : queryParams;

      const response = await saveApi({ selectedWOTypeId: id }, params, woId);
      if (response !== ({} as WorkOrderMasterType)) {
        const woMasterId = response.id;
        setWOId(woMasterId);
      }
    }
    setApplyStatus((prev) => ({
      ...prev,
      woType: false,
    }));
  };

  useEffect(() => {
    if (
      workOrderMasterResponse &&
      workOrderMasterResponse.preferredTeamMembers &&
      workOrderMasterResponse.preferredRoleList &&
      !activeTitle
    ) {
      const activeDefaultTitle =
        workOrderMasterResponse.preferredTeamMembers.filter((teamMember) =>
          workOrderMasterResponse.preferredRoleList.some(
            (role) => role.id === teamMember.id && role.isSelected
          )
        )[0]?.name;
      setActiveTitle(activeDefaultTitle);
    }
  }, [workOrderMasterResponse]);

  const handleClickAssetCategory = async (id: number) => {
    toggleLoader(true);
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        selectedAssetCategoryId:
          prev.selectedAssetCategoryId === id ? null : id,
      };
    });

    if (id) {
      const queryParams = {
        SELECTED_ASSET_CATEGORY_ID: id,
        woMasterId: woId,
      };
      const params =
        woId !== null && woId !== undefined
          ? { SELECTED_ASSET_CATEGORY_ID: id }
          : queryParams;

      const response = await saveApi(
        { selectedAssetCategoryId: id },
        params,
        woId
      );

      
      if (response !== ({} as WorkOrderMasterType)) {
        const woMasterId = response.id;
        setWOId(woMasterId);
          
      setWorkOrderMasterResponse((prev) => {
        if (!prev) return null;

        return {
          ...prev,
          frequencyList: response.frequencyList,
        };
      });
      toggleLoader(false);


      }
    }

    setApplyStatus((prev) => ({
      ...prev,
      assetCategory: false,
    }));
  };
  const handleClickShift = (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        selectedShiftId: prev.selectedShiftId === id ? null : id,
      };
    });
    setApplyStatus((prev) => ({
      ...prev,
      shifts: false,
    }));
  };

  const selectedWOType = workOrderMasterResponse?.woTypes.find(
    (woType) => woType.id === workOrderMasterResponse?.selectedWOTypeId
  );
  const selectedAssetCategory = workOrderMasterResponse?.assetCategoryList.find(
    (cat) => cat.id === workOrderMasterResponse?.selectedAssetCategoryId
  );
  const handleSubTypeChange = async (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        selectedSubTypeId: id,
      };
    });
    setApplyStatus((prev) => ({
      ...prev,
      woType: false,
    }));
    if (id) {
      const queryParams = {
        SELECTED_SUB_TYPE_ID: id,
        woMasterId: woId,
      };
      const params =
        woId !== null && woId !== undefined
          ? { SELECTED_SUB_TYPE_ID: id }
          : queryParams;

      const response = await saveApi({ selectedSubTypeId: id }, params, woId);
      if (response !== ({} as WorkOrderMasterType)) {
        const woMasterId = response.id;
        setWOId(woMasterId);
      }
    }
  };

  const handleFrequencyChange = async (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        selectedFrequencyId: id,
      };
    });
    setApplyStatus((prev) => ({
      ...prev,
      assetCategory: false,
    }));
    if (id) {
      const queryParams = {
        SELECTED_FREQUENCY_ID: id,
        woMasterId: woId,
      };
      const params =
        woId !== null && woId !== undefined
          ? { SELECTED_FREQUENCY_ID: id }
          : queryParams;
      try {
        const response = await saveApi(
          { selectedFrequencyId: id },
          params,
          woId,
          true
        );
        if (response !== ({} as WorkOrderMasterType)) {
          const woMasterId = response.id;
          setWOId(woMasterId);
        }
      } catch (error) {
        handleApiError(error);
        setWorkOrderMasterResponse((prev) => {
          if (!prev) return null;

          return {
            ...prev,
            selectedFrequencyId: null,
          };
        });
      }
    }
  };

  const handleSkillAdd = (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      const { selectedSkillIds = [], ...rest } = prev;
      const updatedSelectedSkillIds = Array.isArray(selectedSkillIds)
        ? [...selectedSkillIds, id]
        : [id];

      return {
        ...rest,
        selectedSkillIds: updatedSelectedSkillIds,
      };
    });
    setApplyStatus((prev) => ({
      ...prev,
      skills: false,
    }));
  };

  const handleSkillRemove = (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      const { selectedSkillIds, ...rest } = prev;
      const updatedSelectedSkillIds = selectedSkillIds.filter(
        (skillId) => skillId !== id
      );

      return {
        ...rest,
        selectedSkillIds: updatedSelectedSkillIds,
      };
    });
    setApplyStatus((prev) => ({
      ...prev,
      skills: false,
    }));
  };

  const handleSwitchSkillsChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        isSkillCheckRequired: event.target.checked,
        selectedSkillIds: !event.target.checked ? [] : prev.selectedSkillIds,
      };
    });
    if (event) {
      const queryParams = {
        IS_SKILL_CHECK_REQUIRED: event.target.checked === true ? true : false,
        woMasterId: woId,
      };
      const params =
        woId !== null && woId !== undefined
          ? {
              IS_SKILL_CHECK_REQUIRED:
                event.target.checked === true ? true : false,
            }
          : queryParams;

      const response = await saveApi(
        {
          isSkillCheckRequired: event.target.checked === true ? true : false,
        },
        params,
        woId
      );
      if (response !== ({} as WorkOrderMasterType)) {
        const woMasterId = response.id;
        if (!workOrderMasterResponse?.isSkillCheckRequired) {
          const sortedPreferredTeamMembers: PreferredTeamMember[] =
            response.preferredTeamMembers.map((member: PreferredTeamMember) => {
              return {
                ...member,
                list:
                  member.list.length > 0
                    ? member.list.sort(
                        (a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity)
                      )
                    : member.list,
              };
            });
          setWorkOrderMasterResponse((prev) => {
            if (!prev) return null;
            return {
              ...prev,
              preferredTeamMembers: sortedPreferredTeamMembers,
            };
          });
        }

        setWOId(woMasterId);
      }

      setApplyStatus((prev) => ({
        ...prev,
        skills:
          (event.target.checked &&
            workOrderMasterResponse?.selectedSkillIds &&
            workOrderMasterResponse?.selectedSkillIds?.length > 0) ||
          !event.target.checked
            ? true
            : false,
      }));
    }
  };
  const handleSwitchPostCompletion = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPostCompletionExpand(event.target.checked);
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      if (!event.target.checked) {
        // If post completion is false, set isSelected to false for all checklists
        prev.checklistList = prev.checklistList.map((checklist) => ({
          ...checklist,
          isSelected: false,
        }));
      }

      return {
        ...prev,
        isPostCompletionChecklist: event.target.checked,
      };
    });

    if (event) {
      const queryParams = {
        POST_COMPLETION_CHECKLIST: event.target.checked === true ? true : false,
        woMasterId: woId,
      };
      const params =
        woId !== null && woId !== undefined
          ? {
              POST_COMPLETION_CHECKLIST:
                event.target.checked === true ? true : false,
            }
          : queryParams;

      const response = await saveApi(
        {
          isPostCompletionChecklist:
            event.target.checked === true ? true : false,
        },
        params,
        woId
      );
      if (response !== ({} as WorkOrderMasterType)) {
        const woMasterId = response.id;
        setWOId(woMasterId);
      }

      setApplyStatus((prev) => ({
        ...prev,
        postCompletion:
          (event.target.checked &&
            workOrderMasterResponse?.checklistList.some(
              (checklist) => checklist.isSelected
            )) ||
          !event.target.checked
            ? true
            : false,
      }));
    }
  };
  const handleSwitchMultipleAssets = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        isMultipleAssets: event.target.checked,
        assetSet: [],
      };
    });

    if (event) {
      const queryParams = {
        IS_MULTIPLE_ASSETS: event.target.checked === true ? true : false,
        woMasterId: woId,
      };
      const params =
        woId !== null && woId !== undefined
          ? {
              IS_MULTIPLE_ASSETS: event.target.checked === true ? true : false,
            }
          : queryParams;

      const response = await saveApi(
        { isMultipleAssets: event.target.checked === true ? true : false },
        params,
        woId
      );
      if (response !== ({} as WorkOrderMasterType)) {
        const woMasterId = response.id;
        setWOId(woMasterId);
      }
    }
  };

  const handleDeleteSet = (id: number) => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        assetSet: prev.assetSet.filter((item) => item.id !== id),
      };
    });
  };

  //assets

  const handleAddNewSet = () => {
    setWorkOrderMasterResponse((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        assetSet: [
          ...prev.assetSet,
          {
            id: prev.assetSet.length + 1,
            list: [],
          },
        ],
      };
    });
  };

  const assetListData = selectedAssetCategory?.list.filter((asset) => {
    return !workOrderMasterResponse?.assetSet.some((set) =>
      set.list.some((setType) => setType.id === asset.id)
    );
  });

  const handleRoleUpdate = (updatedRoles: RoleType[]) => {
    setWorkOrderMasterResponse((prev) => {
      if (prev) {
        return {
          ...prev,
          preferredRoleList: updatedRoles,
        };
      }
      return prev;
    });
    setApplyStatus((prev) => ({
      ...prev,
      roles: false,
    }));
  };

  //save api call

  const handleApiError = (error: any) => {
    toggleLoader(false);
    setOpenErrorModal(true);
    handleError(error, setErrorDesc);
  };
  const saveApi = async (
    payload?: any,
    queryParams?: any,
    id?: number | null,
    throwError: boolean = false
  ): Promise<WorkOrderTypeResponse> => {
    const apiEndpoint =
      id !== null && id !== undefined && id !== 0
        ? `${WORK_FLOW_BASE_URL}${assetGroupId}/woMaster/${id}/update`
        : `${WORK_FLOW_BASE_URL}${assetGroupId}/woMaster/save`;

    try {
      const response = await saveWorkOrderApi<WorkOrderTypeResponse>(
        apiEndpoint,
        id ?? undefined,
        payload,
        queryParams
      );
      toggleLoader(false);
      return response;
    } catch (error) {
      toggleLoader(false);
      if (throwError) {
        throw error;
      } else {
        handleApiError(error);
      }
      return {} as WorkOrderTypeResponse;
    }
  };

  return (
    <Box>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      {openSuccessModal && (
        <WoCreatedSuccessModal
          open={openSuccessModal}
          handleClose={() => {
            setOpenSuccessModal(false);
          }}
          getDataCallback={() => {
            handleClose();
            getDataCallback();
          }}
        />
      )}
      {openAssetModal && (
        <AddAssetSetModal
          open={openAssetModal}
          handleClose={() => {
            setOpenAssetModal(false);
          }}
          assetsList={assetListData || []}
          onApply={(selectedAssets) => {
            setWorkOrderMasterResponse((prev) => {
              if (!prev) return null;

              return {
                ...prev,
                assetSet: prev.assetSet.map((set) => {
                  if (set.id === assetSetId) {
                    // Assign sequence values to selected assets
                    const updatedList = [
                      ...set.list,
                      ...selectedAssets.map((asset, index) => ({
                        ...asset,
                        sequence: set.list.length + index + 1,
                      })),
                    ];

                    return {
                      ...set,
                      list: updatedList,
                    };
                  }
                  return set;
                }),
              };
            });
          }}
        />
      )}
      {workOrderMasterResponse && (
        <Box>
          <Box className={"flex__justify__space-between mb-lg "}>
            {id !== undefined && id !== null ? (
              <>
                <MediumTypography
                  label={workOrderMasterResponse?.name}
                  fontSize="20px"
                  fontWeight={700}
                />
              </>
            ) : (
              <>
                <MediumTypography
                  defaultLabel="Add W/O Master"
                  labelId="W/O.title"
                  fontSize="20px"
                  fontWeight={700}
                />
              </>
            )}
          </Box>
          <Box className="overflow_Y" sx={{ height: availableHeight }}>
            <Grid
              container
              spacing={2}
              sx={{
                WebkitOverflowScrolling: "touch",
                "-webkit-overflow-scrolling": "touch",
                "scroll-behavior": "smooth",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Grid item {...muiColumnProps}>
                <MediumTypography
                  className="input-label"
                  labelId="W/O.name"
                  defaultLabel="W/O Name"
                />
                <TextInput
                  className="text-input-field"
                  type="text"
                  variant="outlined"
                  inputProps={{
                    readOnly: false,
                  }}
                  labelId="common.placeHolderText"
                  defaultLabelId="--- type here ---"
                  Value={workOrderMasterResponse?.name || ""}
                  handlechange={(value: string) => {
                    setWorkOrderMasterResponse(
                      (prev) =>
                        ({
                          ...prev,
                          name: value,
                        } as WorkOrderMasterType)
                    );
                  }}
                  onblur={async () => {
                    if (workOrderMasterResponse?.name !== "") {
                      const queryParams = {
                        NAME: workOrderMasterResponse?.name,
                        START_DATE: workOrderMasterResponse?.startDate,
                        woMasterId: woId,
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? {
                              NAME: workOrderMasterResponse?.name,
                              START_DATE: workOrderMasterResponse?.startDate,
                            }
                          : queryParams;

                      const response = await saveApi(
                        {
                          name: workOrderMasterResponse?.name,
                          startDate: workOrderMasterResponse?.startDate,
                        },
                        params,
                        woId
                      );
                      if (response !== ({} as WorkOrderMasterType)) {
                        const woMasterId = response.id;
                        setWOId(woMasterId);
                      }
                    }
                  }}
                  disabled={!sectionStatus.inputSection}
                />
              </Grid>
              <Grid item {...muiColumnProps}>
                <MediumTypography
                  className="input-label"
                  labelId="W/O.Description"
                  defaultLabel="Description"
                />
                <TextInput
                  className="text-input-field"
                  type="text"
                  variant="outlined"
                  inputProps={{
                    readOnly: false,
                  }}
                  labelId="common.placeHolderText"
                  defaultLabelId="--- type here ---"
                  Value={workOrderMasterResponse?.description || ""}
                  handlechange={(value: string) => {
                    setWorkOrderMasterResponse(
                      (prev) =>
                        ({
                          ...prev,
                          description: value,
                        } as WorkOrderMasterType)
                    );
                  }}
                  onblur={async () => {
                    const queryParams = {
                      DESCRIPTION: workOrderMasterResponse?.description,
                      woMasterId: woId,
                    };
                    const params =
                      woId !== undefined
                        ? {
                            DESCRIPTION: workOrderMasterResponse?.description,
                          }
                        : queryParams;

                    const response = await saveApi(
                      { description: workOrderMasterResponse?.description },
                      params,
                      woId
                    );
                    if (response !== ({} as WorkOrderMasterType)) {
                      const woMasterId = response.id;
                      setWOId(woMasterId);
                    }
                  }}
                  disabled={!sectionStatus.inputSection}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <MediumTypography
                  labelId="W/O.estimatedEffort"
                  defaultLabel="Estimated Effort(Minutes)"
                  className="input-label"
                />

                <TextInput
                  className="text-input-field"
                  type="text"
                  variant="outlined"
                  inputProps={{
                    readOnly: false,
                  }}
                  labelId="common.placeHolderText"
                  defaultLabelId="--- type here ---"
                  Value={
                    workOrderMasterResponse?.estimatedEfforts !== null &&
                    workOrderMasterResponse?.estimatedEfforts !== 0
                      ? workOrderMasterResponse?.estimatedEfforts?.toString()
                      : ""
                  }
                  handlechange={(value: string) => {
                    const formattedValue = value.replace(/[^0-9]/g, "");

                    setWorkOrderMasterResponse(
                      (prev) =>
                        ({
                          ...prev,
                          estimatedEfforts: formattedValue
                            ? parseInt(formattedValue)
                            : 0,
                        } as WorkOrderMasterType)
                    );
                  }}
                  onblur={async () => {
                    if (workOrderMasterResponse?.estimatedEfforts !== 0) {
                      const queryParams = {
                        ESTIMATED_EFFORTS:
                          workOrderMasterResponse?.estimatedEfforts,
                        woMasterId: woId,
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? {
                              ESTIMATED_EFFORTS:
                                workOrderMasterResponse?.estimatedEfforts,
                            }
                          : queryParams;

                      const response = await saveApi(
                        {
                          estimatedEfforts:
                            workOrderMasterResponse?.estimatedEfforts,
                        },
                        params,
                        woId
                      );
                      if (response !== ({} as WorkOrderMasterType)) {
                        const woMasterId = response.id;
                        setWOId(woMasterId);
                      }
                    }
                  }}
                  disabled={!sectionStatus.inputSection}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <MediumTypography
                  labelId="W/O.startDate"
                  defaultLabel="Start Date"
                  textColor={"rgba(159, 173, 188, 1)"}
                  className="mb-sm"
                />

                <DateTimePickerComponent
                  value={
                    workOrderMasterResponse?.startDate !== null
                      ? dayjs(workOrderMasterResponse?.startDate)
                      : null
                  }
                  labelid="commonDateText"
                  defaultlabelid="DD MMM YYYY"
                  handlechange={async (date: Dayjs) => {
                    setHasInteracted(true);
                    const formattedDate = dayjs(date).format("YYYY-MM-DD");
                    setWorkOrderMasterResponse((prev) => {
                      if (!prev) return null;
                      return {
                        ...prev,
                        startDate: formattedDate,
                        endDate: null,
                      };
                    });
                    if (formattedDate) {
                      const queryParams = {
                        START_DATE: formattedDate,
                        woMasterId: woId,
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? {
                              START_DATE: formattedDate,
                            }
                          : queryParams;

                      const response = await saveApi(
                        {
                          startDate: formattedDate,
                        },
                        params,
                        woId
                      );
                      if (response !== ({} as WorkOrderMasterType)) {
                        const woMasterId = response.id;
                        setWOId(woMasterId);
                      }
                    }
                  }}
                  disabledDate={!sectionStatus.inputSection || isCompleted}
                  minDateTime={
                    hasInteracted ? dayjs().startOf("day") : undefined
                  }
                  inputFormat="DD MMM YYYY"
                  textWidth={"100%"}
                  width={"100%"}
                  views={["year", "month", "day"]}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <MediumTypography
                  labelId="W/O.endDate"
                  defaultLabel="End  Date"
                  textColor={"rgba(159, 173, 188, 1)"}
                  className="mb-sm"
                />

                <DateTimePickerComponent
                  value={
                    workOrderMasterResponse?.endDate !== null
                      ? dayjs(workOrderMasterResponse?.endDate)
                      : null
                  }
                  labelid="commonDateText"
                  defaultlabelid="DD MMM YYYY"
                  handlechange={async (date: Dayjs) => {
                    const formattedDate = dayjs(date).format("YYYY-MM-DD");
                    setWorkOrderMasterResponse((prev) => {
                      if (!prev) return null;
                      return {
                        ...prev,
                        endDate: formattedDate,
                      };
                    });
                    if (formattedDate) {
                      const queryParams = {
                        END_DATE: formattedDate,
                        woMasterId: woId,
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? {
                              END_DATE: formattedDate,
                            }
                          : queryParams;

                      const response = await saveApi(
                        {
                          endDate: formattedDate,
                        },
                        params,
                        woId
                      );
                      if (response !== ({} as WorkOrderMasterType)) {
                        const woMasterId = response.id;
                        setWOId(woMasterId);
                      }
                    }
                  }}
                  disabledDate={!sectionStatus.inputSection}
                  minDateTime={
                    workOrderMasterResponse?.startDate
                      ? dayjs(workOrderMasterResponse.startDate)
                          .add(1, "day")
                          .startOf("day")
                      : dayjs().startOf("day")
                  }
                  inputFormat="DD MMM YYYY"
                  textWidth={"100%"}
                  width={"100%"}
                  views={["year", "month", "day"]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.Types"
                    defaultLabel="W/O Type"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                      marginTop: applyStatus.woType ? "4px" : "0px",
                    }}
                  />
                  {applyStatus.woType ? (
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        label={":"}
                        className="mr-sm mt-xs ml-sm"
                        sxProps={{ fontSize: "16px" }}
                      />
                      <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm selectedData">
                        <MediumTypography
                          label={` ${selectedWOType?.name}`}
                          textColor="rgba(37, 186, 250, 1)"
                          className="mr-xs"
                        />
                        {selectedWOType?.code !== "CM" &&
                          selectedWOType?.code !== "PM" &&
                          workOrderMasterResponse?.selectedSubTypeId && (
                            <MediumTypography
                              label={` ( ${
                                selectedWOType &&
                                selectedWOType?.typeList.find(
                                  (item) =>
                                    item.id ===
                                    workOrderMasterResponse?.selectedSubTypeId
                                )?.name
                              } )`}
                              textColor="rgba(37, 186, 250, 1)"
                            />
                          )}
                      </Box>
                    </Box>
                  ) : null}
                </Box>

                <Box className="flex__ align__items__center">
                  {applyStatus.woType ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}

                  {woTypeExpand ? (
                    <Box
                      sx={{
                        cursor: sectionStatus.woType
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => setWOTypeExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        cursor: sectionStatus.woType
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => {
                        if (sectionStatus.woType) {
                          setWOTypeExpand(true);
                        }
                      }}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {woTypeExpand && (
                <WoTypeSelection
                  woTypesList={workOrderMasterResponse?.woTypes}
                  handleApply={() => {
                    setApplyStatus((prev) => ({
                      ...prev,
                      woType: true,
                    }));

                    setSectionStatus((prevStatus) => ({
                      ...prevStatus,
                      assetCategory: true,
                    }));
                    setWOTypeExpand(false);
                  }}
                  handleSelectedWOType={handleClickWOType}
                  handleSelectedSubWOType={handleSubTypeChange}
                  id={workOrderMasterResponse?.selectedWOTypeId || 0}
                  subTypeId={workOrderMasterResponse?.selectedSubTypeId || 0}
                />
              )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.AssetCategories"
                    defaultLabel="Asset Category"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                      marginTop: applyStatus.assetCategory ? "4px" : "0px",
                    }}
                    className="mr-sm"
                  />
                  {applyStatus.assetCategory ? (
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        label={":"}
                        className="mr-sm mt-xs"
                        fontSize="16px"
                      />
                      <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm selectedData">
                        <MediumTypography
                          label={`${selectedAssetCategory?.name}`}
                          textColor="rgba(37, 186, 250, 1)"
                          className="mr-xs"
                        />
                        {selectedWOType &&
                          selectedAssetCategory &&
                          workOrderMasterResponse?.selectedFrequencyId && (
                            <MediumTypography
                              label={` ( ${
                                workOrderMasterResponse.frequencyList.find(
                                  (item) =>
                                    item.id ===
                                    workOrderMasterResponse?.selectedFrequencyId
                                )?.name
                              } )`}
                              textColor="rgba(37, 186, 250, 1)"
                            />
                          )}
                      </Box>
                    </Box>
                  ) : null}
                </Box>

                <Box className="flex__ align__items__center">
                  {applyStatus.assetCategory ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {assetCatExpand ? (
                    <Box
                      sx={{
                        cursor: sectionStatus.assetCategory
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => setAssetCatExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        cursor: sectionStatus.assetCategory
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => {
                        if (sectionStatus.assetCategory) {
                          setAssetCatExpand(true);
                        }
                      }}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {assetCatExpand && (
                <AssetCategorySelection
                  assetCategoryList={workOrderMasterResponse?.assetCategoryList}
                  frequencyId={
                    workOrderMasterResponse?.selectedFrequencyId || 0
                  }
                  id={workOrderMasterResponse?.selectedAssetCategoryId || 0}
                  handleApply={() => {
                    setApplyStatus((prev) => ({
                      ...prev,
                      assetCategory: true,
                    }));

                    setSectionStatus((prevStatus) => ({
                      ...prevStatus,
                      assetList: true,
                    }));
                    setAssetCatExpand(false);
                  }}
                  handleSelectedAssetCategory={handleClickAssetCategory}
                  handleSelectedFrequency={handleFrequencyChange}
                  woTypeId={workOrderMasterResponse?.selectedWOTypeId || 0}
                  woTypeList={workOrderMasterResponse?.woTypes}
                  subTypeId={workOrderMasterResponse?.selectedSubTypeId || 0}
                  frequencyList={workOrderMasterResponse?.frequencyList}
                />
              )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  {!workOrderMasterResponse?.isMultipleAssets ? (
                    <>
                      <MediumTypography
                        labelId="W/O.Assets"
                        defaultLabel="Assets"
                        sxProps={{
                          fontSize: "16px",
                          lineHeight: "normal",
                          fontWeight: 600,
                        }}
                        className="mr-sm mt-xs"
                      />
                      {workOrderMasterResponse?.selectedAssetCategoryId && (
                        <Box className="flex__ align__items__center">
                          <MediumTypography
                            label={":"}
                            className="mr-sm mt-xs"
                            fontSize="16px"
                          />
                          <Box
                            className="flex__ align__items__center"
                            sx={{
                              backgroundColor: "rgba(37, 186, 250, 0.16)",
                              borderRadius: "36px",
                              border: " 1px solid rgba(37, 186, 250, 1)",
                              width: "50px",
                              justifyContent: "center",
                            }}
                          >
                            <MediumTypography
                              label={` ${
                                workOrderMasterResponse &&
                                workOrderMasterResponse.assetCategoryList
                                  .find(
                                    (item) =>
                                      item.id ===
                                      workOrderMasterResponse?.selectedAssetCategoryId
                                  )
                                  ?.list.length.toString()
                                  .padStart(2, "0")
                              }`}
                              textColor="rgba(37, 186, 250, 1)"
                            />
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <MediumTypography
                        labelId="W/O.AssetsSet"
                        defaultLabel="Asset Sets"
                        sxProps={{
                          fontSize: "16px",
                          lineHeight: "normal",
                          fontWeight: 600,
                        }}
                        className="mr-sm "
                      />
                      {applyStatus.assetList && (
                        <Box className="flex__ align__items__center">
                          <MediumTypography
                            label={":"}
                            className="mr-sm"
                            fontSize="16px"
                          />
                          <Box
                            className="flex__ align__items__center"
                            sx={{
                              backgroundColor: "rgba(37, 186, 250, 0.16)",
                              borderRadius: "36px",
                              border: " 1px solid rgba(37, 186, 250, 1)",
                              width: "50px",
                              justifyContent: "center",
                            }}
                          >
                            <MediumTypography
                              label={`${workOrderMasterResponse?.assetSet.length
                                .toString()
                                .padStart(2, "0")}`}
                              textColor="rgba(37, 186, 250, 1)"
                            />
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <Box className="flex__">
                  {!workOrderMasterResponse?.isMultipleAssets ||
                  (workOrderMasterResponse?.isMultipleAssets &&
                    workOrderMasterResponse?.assetSet.length > 0 &&
                    workOrderMasterResponse?.assetSet.every(
                      (asset) => asset.list && asset.list.length > 0
                    )) ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {assetsExpand ? (
                    <Box
                      sx={{
                        cursor: sectionStatus.assetList
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => setAssetsExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        cursor: sectionStatus.assetList
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => {
                        if (sectionStatus.assetList) {
                          setAssetsExpand(true);
                          setAssetCatExpand(false);
                        }
                      }}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {assetsExpand && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box>
                      <Divider
                        variant="fullWidth"
                        sx={{
                          borderTop: "1px solid #545558",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Box
                    className="p-md flex__justify__space-between "
                    sx={{ width: "100%" }}
                  >
                    <MediumTypography
                      labelId="W/O.multipleAssets"
                      defaultLabel="Do you have multiple Assets?"
                      textColor="#9FADBC"
                      fontSize="16px"
                    />
                    <Box className="flex__ align__items__center ">
                      {workOrderMasterResponse?.isMultipleAssets &&
                        workOrderMasterResponse.assetSet &&
                        assetListData &&
                        assetListData.length > 0 && (
                          <Box
                            sx={{
                              backgroundColor: "rgba(37, 186, 250, 0.2)",
                              borderRadius: "4px",
                            }}
                            className="pt-xs pb-xs pl-sm pr-sm mr-sm flex__"
                            onClick={handleAddNewSet}
                          >
                            <AddIcon className="mr-sm mt-xs" />
                            <MediumTypography
                              defaultLabel="Add New Set"
                              labelId="W/O.addNewSet"
                              textColor="#25BAFA"
                            />
                          </Box>
                        )}

                      <SwitchComponent
                        value={
                          workOrderMasterResponse?.isMultipleAssets || false
                        }
                        handleChange={handleSwitchMultipleAssets}
                      />
                    </Box>
                  </Box>
                  {workOrderMasterResponse?.isMultipleAssets && (
                    <>
                      {workOrderMasterResponse.assetSet.map(
                        (assetSet, index) => (
                          <Box
                            key={assetSet.id}
                            className="mt-sm ml-md mr-md p-md"
                            sx={{
                              backgroundColor: "#323337",
                              borderRadius: "4px",
                              width: "100%",
                            }}
                          >
                            <Box
                              className="flex__justify__space-between"
                              sx={{ width: "100%" }}
                            >
                              <Box className="flex__">
                                <MediumTypography
                                  labelId="W/O.setText"
                                  defaultLabel="Set"
                                  textColor="#9FADBC"
                                  className="mr-xs"
                                />
                                <MediumTypography
                                  label={(index + 1)
                                    .toString()
                                    .padStart(2, "0")}
                                  textColor="#9FADBC"
                                />
                              </Box>
                              <Box className="flex__">
                                <Box
                                  className="mr-sm flex__"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteSet(assetSet.id)}
                                >
                                  <Delete
                                    className="mr-sm "
                                    style={{ width: "16px" }}
                                  />
                                  <MediumTypography
                                    defaultLabel="Delete Set"
                                    labelId="W/O.Delete"
                                  />
                                </Box>
                                {assetListData && assetListData.length > 0 && (
                                  <Box
                                    className="mr-sm flex__"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setOpenAssetModal(true);
                                      setAssetSetId(assetSet.id);
                                    }}
                                  >
                                    <AddIcon className="mr-xs mt-xs" />
                                    <MediumTypography
                                      defaultLabel="Add Assets"
                                      labelId="W/O.addAssets"
                                      textColor="#25BAFA"
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction={"row"}
                              className="flex__"
                              sx={{
                                height:
                                  assetSet.list.length > 25 ? "250px" : "auto",
                                overflowY: "scroll",
                              }}
                            >
                              {assetSet.list.map((type, idx) => (
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                  <Box
                                    className="flex__justify__space-between mt-sm mr-sm padding_12"
                                    sx={{
                                      backgroundColor: "#22272B",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <Box className="flex__">
                                      <MediumTypography
                                        label={
                                          type.sequence &&
                                          type.sequence
                                            .toString()
                                            .padStart(2, "0")
                                        }
                                        textColor="#9FADBC"
                                        className="mr-sm mt-xs"
                                      />
                                      <Tooltip
                                        followCursor
                                        arrow
                                        title={
                                          type.name.length > 15
                                            ? `${type.name}`
                                            : ""
                                        }
                                      >
                                        <Box>
                                          <MediumTypography
                                            label={
                                              type.name.length > 15
                                                ? `${type.name.substring(
                                                    0,
                                                    15
                                                  )}...`
                                                : type.name
                                            }
                                            textColor="#9FADBC"
                                            className="mr-sm mt-xs"
                                          />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                    <Cancel
                                      sx={{
                                        cursor: "pointer",
                                        width: "20px",
                                        color: "#FFFF",
                                      }}
                                      onClick={() => {
                                        setWorkOrderMasterResponse((prev) => {
                                          if (!prev) return null;

                                          return {
                                            ...prev,
                                            assetSet: prev.assetSet.map(
                                              (item) => {
                                                if (item.id === assetSet.id) {
                                                  const updatedList =
                                                    item.list.filter(
                                                      (i) => i.id !== type.id
                                                    );

                                                  // Reassign sequence values
                                                  return {
                                                    ...item,
                                                    list: updatedList.map(
                                                      (asset, index) => ({
                                                        ...asset,
                                                        sequence: index + 1,
                                                      })
                                                    ),
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            ),
                                          };
                                        });
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        )
                      )}
                      <Box className="mt-sm mb-sm flex__ width__100 justifyContent-FlexEnd">
                        <ButtonComponent
                          className={
                            !(workOrderMasterResponse?.assetSet.length > 0) ||
                            (workOrderMasterResponse.assetSet.length > 0 &&
                              workOrderMasterResponse?.assetSet.some(
                                (asset) => asset.list.length === 0
                              ))
                              ? "btn-primary btn-disabled mr-md"
                              : "btn-primary btn-submit mr-md"
                          }
                          variantType="contained"
                          defaultLabelId={"Apply"}
                          labelId={"Apply"}
                          disabled={
                            !(workOrderMasterResponse?.assetSet.length > 0) ||
                            (workOrderMasterResponse.assetSet.length > 0 &&
                              workOrderMasterResponse?.assetSet.some(
                                (asset) => asset.list.length === 0
                              ))
                          }
                          onClick={async () => {
                            if (
                              workOrderMasterResponse.assetSet.length > 0 &&
                              workOrderMasterResponse?.assetSet.some(
                                (asset) => asset.list.length > 0
                              )
                            ) {
                              setApplyStatus((prev) => ({
                                ...prev,
                                assetList: true,
                              }));
                              setAssetsExpand(false);

                              const queryParams = {
                                ASSET_SET: "asset",
                                woMasterId: woId,
                              };
                              const payload = {
                                assetSet: workOrderMasterResponse?.assetSet,
                              };
                              const params =
                                woId !== null && woId !== undefined
                                  ? {
                                      ASSET_SET: "asset",
                                    }
                                  : queryParams;

                              const response = await saveApi(
                                payload,
                                params,
                                woId
                              );
                              if (response !== ({} as WorkOrderMasterType)) {
                                const woMasterId = response.id;
                                setWOId(woMasterId);
                              }
                            }
                          }}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.Skills"
                    defaultLabel="Skills"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                    }}
                    className="mr-sm mt-xs"
                  />
                  {!workOrderMasterResponse?.isSkillCheckRequired && (
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        label={":"}
                        className="mr-sm mt-xs"
                        fontSize="16px"
                      />
                      <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm mr-sm noneSelection">
                        <MediumTypography
                          labelId={"btn.None"}
                          defaultLabel="None"
                          textColor="#25BAFA"
                        />
                      </Box>
                    </Box>
                  )}
                  {applyStatus.skills &&
                    workOrderMasterResponse.selectedSkillIds.length > 0 && (
                      <Box className="flex__ align__items__center">
                        <MediumTypography
                          label={":"}
                          className="mr-sm mt-xs"
                          fontSize="16px"
                        />
                        <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                          <Tooltip
                            title={
                              workOrderMasterResponse?.skillList
                                .filter((skill) =>
                                  workOrderMasterResponse.selectedSkillIds.includes(
                                    skill.id
                                  )
                                )
                                .map((skill) => skill.name)
                                .join(", ") || ""
                            }
                            arrow
                            followCursor
                          >
                            <Box>
                              <MediumTypography
                                label={
                                  workOrderMasterResponse?.selectedSkillIds
                                    .length === 1
                                    ? workOrderMasterResponse.skillList.find(
                                        (skill) =>
                                          skill.id ===
                                          workOrderMasterResponse
                                            .selectedSkillIds[0]
                                      )?.name
                                    : `${
                                        workOrderMasterResponse?.skillList.find(
                                          (skill) =>
                                            skill.id ===
                                            workOrderMasterResponse
                                              .selectedSkillIds[0]
                                        )?.name || ""
                                      } +${
                                        workOrderMasterResponse &&
                                        workOrderMasterResponse
                                          ?.selectedSkillIds.length - 1
                                      }`
                                }
                                textColor="#25BAFA"
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                    )}
                </Box>
                <Box className="flex__">
                  {applyStatus.skills ||
                  !workOrderMasterResponse?.isSkillCheckRequired ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {skillsExpand && (
                    <>
                      <MediumTypography
                        labelId="W/O.skillsReqired"
                        defaultLabel="Skill check required"
                        className="mr-sm mt-xs"
                      />

                      <SwitchComponent
                        value={
                          workOrderMasterResponse?.isSkillCheckRequired || false
                        }
                        handleChange={handleSwitchSkillsChange}
                        disabled={
                          workOrderMasterResponse &&
                          workOrderMasterResponse?.selectedSkillIds.length > 0
                            ? true
                            : false
                        }
                      />
                    </>
                  )}
                  {skillsExpand ? (
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => setSkillsExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSkillsExpand(true);
                        setAssetsExpand(false);
                      }}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {workOrderMasterResponse?.isSkillCheckRequired &&
                skillsExpand && (
                  <SkillsComponent
                    skillsList={workOrderMasterResponse?.skillList}
                    isSkillCheckRequired={
                      workOrderMasterResponse?.isSkillCheckRequired
                    }
                    selectedSkillIds={workOrderMasterResponse?.selectedSkillIds}
                    handleAddSkills={handleSkillAdd}
                    handleRemoveSkills={handleSkillRemove}
                    handleApplySkills={async () => {
                      setApplyStatus((prev) => ({
                        ...prev,
                        skills: true,
                      }));

                      const queryParams = {
                        SELECTED_SKILL_IDS: "skills",
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? {
                              SELECTED_SKILL_IDS: "skills",
                            }
                          : queryParams;

                      const response = await saveApi(
                        {
                          selectedSkillIds:
                            workOrderMasterResponse?.selectedSkillIds,
                        },
                        params,
                        woId
                      );
                      if (response !== ({} as WorkOrderMasterType)) {
                        const woMasterId = response.id;

                        const sortedPreferredTeamMembers: PreferredTeamMember[] =
                          response.preferredTeamMembers.map(
                            (member: PreferredTeamMember) => {
                              return {
                                ...member,
                                list:
                                  member.list.length > 0
                                    ? member.list.sort(
                                        (a, b) =>
                                          (a.rank ?? Infinity) -
                                          (b.rank ?? Infinity)
                                      )
                                    : member.list,
                              };
                            }
                          );
                        setWorkOrderMasterResponse((prev) => {
                          if (!prev) return null;

                          return {
                            ...prev,
                            preferredTeamMembers: sortedPreferredTeamMembers,
                          };
                        });
                        setWOId(woMasterId);
                      }

                      setSkillsExpand(false);
                    }}
                  />
                )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.preferredRoles"
                    defaultLabel="Preferred Role"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                      marginTop: applyStatus.roles ? "4px" : "0px",
                    }}
                    className="mr-sm"
                  />
                  {applyStatus.roles && (
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        label={":"}
                        className="mr-sm mt-xs"
                        fontSize="16px"
                      />
                      <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                        <Tooltip
                          title={
                            workOrderMasterResponse?.preferredRoleList
                              ?.filter((role) => role.isSelected)
                              .map((role) => `${role.name}(${role.level})`)
                              .join(", ") || "No roles selected"
                          }
                          arrow
                          followCursor
                        >
                          <Box>
                            <MediumTypography
                              label={(() => {
                                const selectedRoles =
                                  workOrderMasterResponse?.preferredRoleList?.filter(
                                    (role) => role.isSelected
                                  );

                                if (
                                  selectedRoles &&
                                  selectedRoles?.length === 1
                                ) {
                                  const role = selectedRoles[0];
                                  return `${role.name} ( ${role.level} )`;
                                } else if (
                                  selectedRoles &&
                                  selectedRoles?.length > 1
                                ) {
                                  const firstRole = selectedRoles[0];
                                  return `${firstRole.name} ( ${
                                    firstRole.level
                                  } ) +${selectedRoles.length - 1}`;
                                }
                                return "";
                              })()}
                              textColor="#25BAFA"
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box className="flex__">
                  {applyStatus.roles ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {roleExpand ? (
                    <Box
                      className=" cursor__pointer"
                      onClick={() => setRoleExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      className="cursor__pointer"
                      onClick={() => {
                        setRoleExpand(true);
                        setSkillsExpand(false);
                      }}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {roleExpand && (
                <PreferredRoles
                  roles={workOrderMasterResponse?.preferredRoleList}
                  handleCheckBoxClick={(updatedRoles: RoleType[]) => {
                    handleRoleUpdate(updatedRoles);
                  }}
                  handleCountDecrease={(updatedRoles: RoleType[]) =>
                    handleRoleUpdate(updatedRoles)
                  }
                  handleCountIncrease={(updatedRoles: RoleType[]) =>
                    handleRoleUpdate(updatedRoles)
                  }
                  handleApply={async () => {
                    setApplyStatus((prev) => ({
                      ...prev,
                      roles: true,
                    }));
                    const queryParams = {
                      PREFERRED_ROLE_IDS: "roles",
                      woMasterId: woId,
                    };
                    const payload = {
                      preferredRoleList:
                        workOrderMasterResponse?.preferredRoleList,
                    };
                    const params =
                      woId !== null && woId !== undefined
                        ? {
                            PREFERRED_ROLE_IDS: "roles",
                          }
                        : queryParams;
                    const response = await saveApi(payload, params, woId);
                    if (response !== ({} as WorkOrderMasterType)) {
                      const woMasterId = response.id;
                      setWOId(woMasterId);
                    }
                    setRoleExpand(false);
                  }}
                />
              )}
            </Grid>

            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <MediumTypography
                  labelId="W/O.preferredTeamMembers"
                  defaultLabel="Preferred Team Members"
                  sxProps={{
                    fontSize: "16px",
                    lineHeight: "normal",
                    fontWeight: 600,
                  }}
                />
                <Box className="flex__">
                  {applyStatus.teamMembers && !hasChanges ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {teamMembersExpand ? (
                    <Box
                      sx={{
                        cursor: sectionStatus.teamMembers
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => setTeamMembersExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        cursor: sectionStatus.teamMembers
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={() => {
                        if (sectionStatus.teamMembers) {
                          setTeamMembersExpand(true);
                          setRoleExpand(false);
                        }
                      }}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {teamMembersExpand && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box>
                      <Divider
                        variant="fullWidth"
                        sx={{
                          borderTop: "1px solid #545558",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="pt-sm pb-sm pl-md pr-md flex__ "
                  >
                    <Box
                      className="flex__justify__space-between p-sm width__100"
                      sx={{
                        backgroundColor: "#323337",
                        borderRadius: "4px",
                      }}
                    >
                      <MediumTypography
                        labelId="W/O.Minimum"
                        defaultLabel="Min Required Team Members"
                        sxProps={{
                          lineHeight: "normal",
                          fontWeight: 600,
                        }}
                      />
                      <Box
                        className="flex__ align__items__center "
                        sx={{ backgroundColor: "#1D2125", borderRadius: "4px" }}
                      >
                        <Remove
                          style={{ width: "16px", height: "16px" }}
                          sx={{
                            backgroundColor: "#323337",
                            borderRadius: "4px",
                            color: "#fff",
                            cursor:
                              workOrderMasterResponse?.minRequiredTeamMembers ===
                              1
                                ? "not-allowed"
                                : "pointer",
                          }}
                          className="mt-xs mb-xs ml-xs mr-xs"
                          onClick={async () => {
                            setWorkOrderMasterResponse((prev) => {
                              if (!prev) return null;
                              return {
                                ...prev,
                                minRequiredTeamMembers: Math.max(
                                  1,
                                  (prev?.minRequiredTeamMembers ?? 1) - 1
                                ),
                              };
                            });

                            const newMinRequiredTeamMembers = Math.max(
                              1,
                              (workOrderMasterResponse?.minRequiredTeamMembers ??
                                1) - 1
                            );
                            const queryParams = {
                              MINIMUM_TEAM_MEMBERS: newMinRequiredTeamMembers,
                              woMasterId: woId,
                            };

                            const params =
                              woId !== null && woId !== undefined
                                ? {
                                    MINIMUM_TEAM_MEMBERS:
                                      newMinRequiredTeamMembers,
                                  }
                                : queryParams;

                            const response = await saveApi(
                              {
                                minRequiredTeamMembers:
                                  newMinRequiredTeamMembers,
                              },
                              params,
                              woId
                            );
                            if (response !== ({} as WorkOrderMasterType)) {
                              const woMasterId = response.id;
                              setWOId(woMasterId);
                            }
                          }}
                        />
                        <MediumTypography
                          label={
                            workOrderMasterResponse?.minRequiredTeamMembers ?? 1
                          }
                        />
                        <Add
                          style={{ width: "16px", height: "16px" }}
                          sx={{
                            backgroundColor: "#323337",
                            borderRadius: "4px",
                            color: "#fff",
                            cursor:
                              workOrderMasterResponse?.minRequiredTeamMembers ===
                              workOrderMasterResponse?.preferredRoleList
                                .filter((role) => role.isSelected)
                                .reduce(
                                  (acc, role) => acc + (role.level ?? 0),
                                  0
                                )
                                ? "not-allowed"
                                : "pointer",
                          }}
                          className="mt-xs mb-xs ml-xs mr-xs"
                          onClick={async () => {
                            const totalSelectedLevels =
                              workOrderMasterResponse?.preferredRoleList
                                .filter((role) => role.isSelected)
                                .reduce(
                                  (acc, role) => acc + (role.level ?? 0),
                                  0
                                ) || 0;
                            if (
                              workOrderMasterResponse?.minRequiredTeamMembers ===
                                undefined ||
                              workOrderMasterResponse?.minRequiredTeamMembers ===
                                null ||
                              workOrderMasterResponse?.minRequiredTeamMembers <
                                totalSelectedLevels
                            ) {
                              setWorkOrderMasterResponse((prev) => {
                                if (!prev) return null;
                                return {
                                  ...prev,
                                  minRequiredTeamMembers:
                                    prev.minRequiredTeamMembers === undefined ||
                                    prev.minRequiredTeamMembers === null
                                      ? 1
                                      : Math.min(
                                          totalSelectedLevels,
                                          prev.minRequiredTeamMembers + 1
                                        ),
                                };
                              });
                              const newMinRequiredTeamMembers = Math.min(
                                totalSelectedLevels,
                                (workOrderMasterResponse?.minRequiredTeamMembers ??
                                  1) + 1
                              );
                              const queryParams = {
                                MINIMUM_TEAM_MEMBERS: newMinRequiredTeamMembers,
                                woMasterId: woId,
                              };

                              const params =
                                woId !== null && woId !== undefined
                                  ? {
                                      MINIMUM_TEAM_MEMBERS:
                                        newMinRequiredTeamMembers,
                                    }
                                  : queryParams;

                              const response = await saveApi(
                                {
                                  minRequiredTeamMembers:
                                    newMinRequiredTeamMembers,
                                },
                                params,
                                woId
                              );
                              if (response !== ({} as WorkOrderMasterType)) {
                                const woMasterId = response.id;
                                setWOId(woMasterId);
                              }
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <PreferredTeamMembers
                    activeItem={activeTitle}
                    roleData={workOrderMasterResponse?.preferredRoleList}
                    teamMembersData={
                      workOrderMasterResponse?.preferredTeamMembers
                    }
                    handleDrag={(data: PreferredTeamMember[]) => {
                      setWorkOrderMasterResponse((prev) => {
                        if (!prev) return null;
                        return {
                          ...prev,
                          preferredTeamMembers: data,
                        };
                      });
                      setHasChanges(true);
                    }}
                  />

                  <Box className=" mb-sm mr-md flex__ width__100 justifyContent-FlexEnd">
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      defaultLabelId={"Apply"}
                      labelId={"Apply"}
                      onClick={async () => {
                        setApplyStatus((prevState) => ({
                          ...prevState,
                          teamMembers: true,
                        }));

                        const queryParams = {
                          PREFERRED_TEAM_MEMBERS: "preferredTeamMembers",
                          woMasterId: woId,
                        };
                        const payload = {
                          preferredTeamMembers:
                            workOrderMasterResponse?.preferredTeamMembers,
                        };
                        const params =
                          woId !== null && woId !== undefined
                            ? {
                                PREFERRED_TEAM_MEMBERS: "preferredTeamMembers",
                              }
                            : queryParams;

                        const response = await saveApi(payload, params, woId);
                        if (response !== ({} as WorkOrderMasterType)) {
                          const woMasterId = response.id;
                          setWOId(woMasterId);
                        }

                        if (hasChanges) {
                          setHasChanges(false);
                        }
                        setTeamMembersExpand(false);
                      }}
                      disabled={
                        workOrderMasterResponse?.preferredTeamMembers
                          ?.length === 0 ||
                        workOrderMasterResponse?.preferredTeamMembers?.every(
                          (item) => item.list.length === 0
                        ) ||
                        false
                      }
                    />
                  </Box>
                </>
              )}
            </Grid>

            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.shift"
                    defaultLabel="Preferred Shift"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                      marginTop: applyStatus.shifts ? "4px" : "0px",
                    }}
                    className="mr-sm"
                  />
                  {applyStatus.shifts ? (
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        label={":"}
                        className="mr-sm mt-xs"
                        fontSize="16px"
                      />
                      <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm selectedData">
                        <MediumTypography
                          label={
                            workOrderMasterResponse?.shiftPreference.find(
                              (item) =>
                                item.id ===
                                workOrderMasterResponse?.selectedShiftId
                            )?.name
                          }
                          textColor="#25BAFA"
                        />
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                <Box className="flex__">
                  {applyStatus.shifts ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {shiftExpand ? (
                    <Box
                      className="cursor__pointer "
                      onClick={() => setShiftExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      className="cursor__pointer "
                      onClick={() => setShiftExpand(true)}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {shiftExpand && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box>
                      <Divider
                        variant="fullWidth"
                        sx={{
                          borderTop: "1px solid #545558",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    className="pl-md pr-md pt-sm pb-sm"
                  >
                    {workOrderMasterResponse &&
                      workOrderMasterResponse.shiftPreference.map((item) => {
                        return (
                          <Grid
                            item
                            key={item.id}
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="mr-sm mb-sm"
                          >
                            <Box
                              sx={{
                                backgroundColor:
                                  workOrderMasterResponse?.selectedShiftId ===
                                  item.id
                                    ? "#0C66E4"
                                    : "#22272B",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                              }}
                              className="p-sm cursor__pointer"
                              onClick={() => handleClickShift(item.id)}
                            >
                              <Box className="flex__container p-sm">
                                <MediumTypography
                                  label={item.name}
                                  textColor={
                                    workOrderMasterResponse?.selectedShiftId ===
                                    item.id
                                      ? "#fff"
                                      : "#8C9BAB"
                                  }
                                />
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Box className=" mb-sm flex__ width__100 justifyContent-FlexEnd">
                    <ButtonComponent
                      className={
                        !(
                          workOrderMasterResponse?.selectedShiftId !== null &&
                          workOrderMasterResponse?.selectedShiftId !== 0 &&
                          workOrderMasterResponse?.selectedShiftId !== undefined
                        )
                          ? "btn-primary btn-disabled mr-md"
                          : "btn-primary btn-submit mr-md"
                      }
                      variantType="contained"
                      defaultLabelId={"Apply"}
                      labelId={"Apply"}
                      disabled={
                        workOrderMasterResponse?.selectedShiftId === null &&
                        workOrderMasterResponse?.selectedShiftId === 0 &&
                        workOrderMasterResponse?.selectedShiftId === undefined
                      }
                      onClick={async () => {
                        if (
                          workOrderMasterResponse?.selectedShiftId !== null &&
                          workOrderMasterResponse?.selectedShiftId !== 0 &&
                          workOrderMasterResponse?.selectedShiftId !== undefined
                        ) {
                          setApplyStatus((prev) => ({
                            ...prev,
                            shifts: true,
                          }));
                          const queryParams = {
                            SELECTED_SHIFT_ID:
                              workOrderMasterResponse?.selectedShiftId,
                            woMasterId: woId,
                          };
                          const params =
                            woId !== null && woId !== undefined
                              ? {
                                  SELECTED_SHIFT_ID:
                                    workOrderMasterResponse?.selectedShiftId,
                                }
                              : queryParams;

                          const response = await saveApi(
                            {
                              selectedShiftId:
                                workOrderMasterResponse?.selectedShiftId,
                            },
                            params,
                            woId
                          );
                          if (response !== ({} as WorkOrderMasterType)) {
                            const woMasterId = response.id;
                            setWOId(woMasterId);
                          }

                          setShiftExpand(false);
                        }
                      }}
                    />
                  </Box>
                </>
              )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <MediumTypography
                  labelId="W/O.pcl"
                  defaultLabel="Post Completion Required"
                  sxProps={{
                    fontSize: "16px",
                    lineHeight: "normal",
                    fontWeight: 600,
                  }}
                />
                <Box className="flex__ align__items__center">
                  {(workOrderMasterResponse?.isPostCompletionChecklist &&
                    workOrderMasterResponse.checklistList.some(
                      (item) => item.isSelected
                    )) ||
                  !workOrderMasterResponse?.isPostCompletionChecklist ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {postCompletionExpand && (
                    <Box className="mr-sm">
                      <CustomDropdown
                        disableCreate={true}
                        options={getOptions(
                          workOrderMasterResponse?.checklistList ?? []
                        )}
                        selectedValue={
                          workOrderMasterResponse?.checklistList?.find(
                            (item) => item.isSelected
                          )?.id || 0
                        }
                        onSelectedValueChange={async (value) => {
                          const updatedChecklistList =
                            workOrderMasterResponse?.checklistList.map(
                              (item) => ({
                                ...item,
                                isSelected: item.id === Number(value), // Assuming 'id' is the unique identifier
                              })
                            );
                          setWorkOrderMasterResponse((prev) => {
                            if (!prev) return null;

                            return {
                              ...prev,
                              checklistList: updatedChecklistList,
                            };
                          });

                          const queryParams = {
                            CHECKLIST_LIST: "Checklist",
                            woMasterId: woId,
                          };
                          const params =
                            woId !== null && woId !== undefined
                              ? { CHECKLIST_LIST: "Checklist" }
                              : queryParams;

                          try {
                            const response = await saveApi(
                              {
                                checklistList: updatedChecklistList,
                              },
                              params,
                              woId,
                              true
                            );
                            if (response !== ({} as WorkOrderMasterType)) {
                              const woMasterId = response.id;
                              setWOId(woMasterId);
                            }
                          } catch (error) {
                            handleApiError(error);
                            const updatedChecklistList =
                              workOrderMasterResponse?.checklistList.map(
                                (item) => ({
                                  ...item,
                                  isSelected: false, // Assuming 'id' is the unique identifier
                                })
                              );
                            setWorkOrderMasterResponse((prev) => {
                              if (!prev) return null;

                              return {
                                ...prev,
                                checklistList: updatedChecklistList,
                              };
                            });
                          }

                          setApplyStatus((prev) => ({
                            ...prev,
                            postCompletion: updatedChecklistList.some(
                              (checklist) => checklist.isSelected
                            )
                              ? true
                              : false,
                          }));
                        }}
                      />
                    </Box>
                  )}

                  <SwitchComponent
                    value={postCompletionExpand}
                    handleChange={handleSwitchPostCompletion}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.Materials"
                    defaultLabel="Materials"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                    }}
                    className="mt-xs mr-sm"
                  />
                  {applyStatus.tools && (
                    <Box className="flex__ align__items__center">
                      <MediumTypography label={":"} className="mt-xs mr-sm " />
                      <Box className="flex__ pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                        <Box className="flex__ align__items__center">
                          <MediumTypography
                            label={
                              workOrderMasterResponse.toolsAndConsumables[0]
                                .name
                            }
                            textColor="#25BAFA"
                            className="mr-xs"
                          />
                          <MediumTypography
                            label={":"}
                            className=" mr-sm"
                            fontSize="16px"
                            textColor="#25BAFA"
                          />
                          <MediumTypography
                            label={
                              Object.keys(toolsTableData).length > 0
                                ? toolsTableData[Object.keys(toolsTableData)[0]]
                                    ?.length || 0
                                : 0
                            }
                            className=" mr-sm "
                            textColor="#25BAFA"
                          />
                        </Box>
                        <Box className="flex__ align__items__center">
                          <MediumTypography
                            label={
                              workOrderMasterResponse.toolsAndConsumables[1]
                                .name
                            }
                            textColor="#25BAFA"
                            className="mr-xs"
                          />
                          <MediumTypography
                            label={":"}
                            className=" mr-sm "
                            fontSize="16px"
                            textColor="#25BAFA"
                          />
                          <MediumTypography
                            label={
                              Object.keys(toolsTableData).length > 0
                                ? toolsTableData[Object.keys(toolsTableData)[1]]
                                    ?.length || 0
                                : 0
                            }
                            className=" mr-sm "
                            textColor="#25BAFA"
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box className="flex__">
                  {applyStatus.tools && !toolsChanges ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {toolsExpand ? (
                    <Box
                      className="cursor__pointer"
                      onClick={() => setToolsExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      className="cursor__pointer"
                      onClick={() => setToolsExpand(true)}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {toolsExpand && (
                <MaterialsComponent
                  activeItem={activeCategory}
                  materialsData={workOrderMasterResponse?.toolsAndConsumables}
                  toolsData={toolsTableData}
                  handleStatusChange={() => {
                    setToolsChanges(true);
                    setApplyStatus((prev) => ({
                      ...prev,
                      tools: false,
                    }));
                  }}
                  handleApply={async (
                    toolsData: CategoryRows,
                    activeItem: string
                  ) => {
                    setToolsTableData(toolsData);
                    setActiveCategory(activeItem);
                    setApplyStatus((prev) => ({
                      ...prev,
                      tools: true,
                    }));

                    if (toolsChanges) {
                      setToolsChanges(false);
                    }

                    if (
                      toolsData[activeItem]?.length > 0 &&
                      activeItem ===
                        workOrderMasterResponse?.toolsAndConsumables[0].name
                    ) {
                      const keys = Object.keys(toolsData);
                      const firstObject = toolsData[keys[0]];

                      const firstObjectIdsAndQuantities = firstObject.map(
                        (row) => ({
                          id: row.data.id,
                          quantity: row.data.quantity,
                        })
                      );

                      const queryParams = {
                        SELECTED_TOOL_LIST: "tools",
                        woMasterId: woId,
                      };
                      const payload = {
                        selectedToolList: firstObjectIdsAndQuantities,
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? {
                              SELECTED_TOOL_LIST: "tools",
                            }
                          : queryParams;
                      toggleLoader(true);
                      const response = await saveApi(payload, params, woId);

                      if (
                        response !== ({} as WorkOrderMasterType) &&
                        response.id !== undefined &&
                        response.toolsAndConsumables !== undefined
                      ) {
                        const woMasterId = response.id;
                        getToolsData(response.toolsAndConsumables);
                        setWOId(woMasterId);
                      }
                    } else if (
                      toolsData[activeItem]?.length > 0 &&
                      activeItem ===
                        workOrderMasterResponse?.toolsAndConsumables[1].name
                    ) {
                      const keys = Object.keys(toolsData);
                      const secondObject = toolsData[keys[1]];

                      const secondObjectIdsAndQuantities = secondObject.map(
                        (row) => ({
                          id: row.data.id,
                          quantity: row.data.quantity,
                        })
                      );

                      const queryParams = {
                        SELECTED_MATERIAL_LIST: "materials",
                        woMasterId: woId,
                      };
                      const payload = {
                        selectedMaterialList: secondObjectIdsAndQuantities,
                      };
                      const params =
                        woId !== null && woId !== undefined
                          ? { SELECTED_MATERIAL_LIST: "materials" }
                          : queryParams;
                      toggleLoader(true);
                      const response = await saveApi(payload, params, woId);

                      if (
                        response !== ({} as WorkOrderMasterType) &&
                        response.id !== undefined &&
                        response.toolsAndConsumables !== undefined
                      ) {
                        const woMasterId = response.id;
                        getToolsData(response.toolsAndConsumables);
                        setWOId(woMasterId);
                      }
                    } else {
                      const queryToolParams = {
                        SELECTED_TOOL_LIST: "tools",
                      };
                      const queryMaterialParams = {
                        SELECTED_MATERIAL_LIST: "materials",
                      };

                      const queryParams =
                        activeItem ===
                        workOrderMasterResponse?.toolsAndConsumables[0].name
                          ? queryToolParams
                          : queryMaterialParams;

                      const materialsPayload = {
                        selectedMaterialList: [],
                      };

                      const toolsPayload = {
                        selectedToolList: [],
                      };

                      const payload =
                        activeItem ===
                        workOrderMasterResponse?.toolsAndConsumables[0].name
                          ? toolsPayload
                          : materialsPayload;
                      toggleLoader(true);
                      const response = await saveApi(
                        payload,
                        queryParams,
                        woId
                      );

                      if (
                        response !== ({} as WorkOrderMasterType) &&
                        response.id !== undefined &&
                        response.toolsAndConsumables !== undefined
                      ) {
                        const woMasterId = response.id;
                        getToolsData(response.toolsAndConsumables);
                        setWOId(woMasterId);
                      }
                    }
                    setToolsExpand(false);
                  }}
                />
              )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="W/O.Permits"
                    defaultLabel="Permits"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                    }}
                    className="mt-xs mr-sm"
                  />
                  {applyStatus.permits && (
                    <>
                      {permitsTableData.length === 0 ? (
                        <Box className="flex__">
                          <MediumTypography
                            label={":"}
                            className="mr-sm mt-xs"
                            fontSize="16px"
                          />
                          <Box className="flex__ pt-xs pb-xs pl-sm pr-sm mr-sm noneSelection">
                            <MediumTypography
                              labelId={"btn.None"}
                              defaultLabel="None"
                              textColor="#25BAFA"
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box className="flex__ align__items__center">
                          <MediumTypography
                            label={":"}
                            className="mr-sm mt-xs"
                            fontSize="16px"
                          />
                          <Box className="flex__ pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                            <Tooltip
                              title={workOrderMasterResponse.permits
                                .filter((permit) =>
                                  permit.typeList.some(
                                    (type) => type.isSelected
                                  )
                                )
                                .map((permit) => permit.name)
                                .join(",")}
                              arrow
                              followCursor
                            >
                              <Box>
                                <MediumTypography
                                  label={(() => {
                                    const selectedPermits =
                                      workOrderMasterResponse.permits.filter(
                                        (permit) =>
                                          permit.typeList.some(
                                            (type) => type.isSelected
                                          )
                                      );
                                    if (selectedPermits.length === 0) {
                                      return "";
                                    }

                                    // Get the first permit
                                    const firstPermit = selectedPermits[0];

                                    const displayName = `${firstPermit.name} ${
                                      selectedPermits.length > 1
                                        ? `+${selectedPermits.length - 1}`
                                        : ""
                                    }`;

                                    return `${displayName} `;
                                  })()}
                                  textColor="#25BAFA"
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <Box className="flex__">
                  {applyStatus.permits && !permitChanges ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}
                  {permitsExpand ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => setPermitsExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => setPermitsExpand(true)}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {permitsExpand && (
                <PermitsComponent
                  permitData={permitsTableData}
                  permitList={workOrderMasterResponse?.permits}
                  handleStatusChange={() => {
                    setPermitChanges(true);
                    setApplyStatus((prev) => ({
                      ...prev,
                      permits: false,
                    }));
                  }}
                  handleApply={async (data: PermitData[]) => {
                    setPermitsTableData(data);
                    let updatedPermits: Permit[];
                    if (data.length > 0) {
                      updatedPermits = workOrderMasterResponse?.permits.map(
                        (permit) => {
                          // Update typeList for each permit
                          const updatedTypeList = permit.typeList.map(
                            (type) => {
                              // Check if this type matches any row in permitsTableData
                              const isSelected = data.some(
                                (row) =>
                                  row.data.name === type.name &&
                                  row.data.permitType === permit.name
                              );
                              return { ...type, isSelected };
                            }
                          );

                          // Return updated permit with the new typeList
                          return { ...permit, typeList: updatedTypeList };
                        }
                      );
                    } else {
                      updatedPermits = workOrderMasterResponse?.permits.map(
                        (permit) => {
                          // Update typeList for each permit
                          const updatedTypeList = permit.typeList.map(
                            (type) => {
                              return { ...type, isSelected: false };
                            }
                          );

                          // Return updated permit with the new typeList
                          return { ...permit, typeList: updatedTypeList };
                        }
                      );
                    }
                    setWorkOrderMasterResponse((prev) => {
                      if (!prev) return null;
                      return {
                        ...prev,
                        permits: updatedPermits,
                      };
                    });
                    const queryParams = {
                      PERMITS: "Permits",
                      woMasterId: woId,
                    };
                    const payload = {
                      permits: updatedPermits,
                    };
                    const params =
                      woId !== null && woId !== undefined
                        ? { PERMITS: "Permits" }
                        : queryParams;
                    toggleLoader(true);
                    const response = await saveApi(payload, params, woId);
                    if (response !== ({} as WorkOrderMasterType)) {
                      const woMasterId = response.id;
                      setWOId(woMasterId);
                    }

                    setApplyStatus((prev) => ({
                      ...prev,
                      permits: true,
                    }));
                    if (permitChanges) {
                      setPermitChanges(false);
                    }
                    setPermitsExpand(false);
                  }}
                />
              )}
            </Grid>
            <Grid
              container
              className="mt-sm"
              sx={{ backgroundColor: "#22272b", borderRadius: "4px" }}
            >
              <Grid
                item
                className="flex__justify__space-between width__100 padding_12px_20px"
              >
                <Box className="flex__ align__items__center">
                  <MediumTypography
                    labelId="ApproverText"
                    defaultLabel="Approvers"
                    sxProps={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: 600,
                      marginTop: applyStatus.approvers ? "4px" : "0px",
                    }}
                    className="mr-sm"
                  />
                  {applyStatus.approvers && (
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        label={":"}
                        className="mr-sm mt-xs"
                        fontSize="16px"
                      />
                      {level1 && workOrderMasterResponse?.approver1 && (
                        <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                          <MediumTypography
                            labelId={"level1text"}
                            defaultLabel="Level 1"
                            textColor="#25BAFA"
                            className="mr-xs"
                          />
                          <MediumTypography
                            label={":"}
                            className="mr-sm"
                            fontSize="16px"
                            textColor="#25BAFA"
                          />
                          <MediumTypography
                            label={`${
                              workOrderMasterResponse?.approversList.find(
                                (item) =>
                                  item.id === workOrderMasterResponse?.approver1
                              )?.name
                            }`}
                            textColor="#25BAFA"
                          />
                        </Box>
                      )}
                      {level2 && workOrderMasterResponse?.approver2 && (
                        <Box className=" flex__ align__items__center pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                          <MediumTypography
                            labelId={"level2text"}
                            defaultLabel="Level 2"
                            textColor="#25BAFA"
                            className="mr-xs"
                          />
                          <MediumTypography
                            label={":"}
                            className="mr-sm"
                            fontSize="16px"
                            textColor="#25BAFA"
                          />
                          <MediumTypography
                            label={`${
                              workOrderMasterResponse?.approversList.find(
                                (item) =>
                                  item.id === workOrderMasterResponse?.approver2
                              )?.name
                            }`}
                            textColor="#25BAFA"
                          />
                        </Box>
                      )}
                      {level3 && workOrderMasterResponse?.approver3 && (
                        <Box className="flex__ align__items__center pt-xs pb-xs pl-sm pr-sm mr-sm selectedData">
                          <MediumTypography
                            labelId={"level3text"}
                            defaultLabel="Level 3"
                            textColor="#25BAFA"
                            className="mr-xs"
                          />
                          <MediumTypography
                            label={":"}
                            className="mr-sm"
                            fontSize="16px"
                            textColor="#25BAFA"
                          />
                          <MediumTypography
                            label={` ${
                              workOrderMasterResponse?.approversList.find(
                                (item) =>
                                  item.id === workOrderMasterResponse?.approver3
                              )?.name
                            }`}
                            textColor="#25BAFA"
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>

                <Box className="flex__">
                  {applyStatus.approvers ? (
                    <CheckCircle
                      sx={{
                        width: "20px",
                        color: "#2ABB7F",
                      }}
                      className="mr-md cursor__pointer"
                    />
                  ) : null}

                  {approverExpand ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => setApproverExpand(false)}
                    >
                      <UpArrow className="ml-sm mt-xs" />
                    </Box>
                  ) : (
                    <Box
                      className="cursor__pointer"
                      onClick={() => setApproverExpand(true)}
                    >
                      <DownArrow className="ml-sm mt-xs" />
                    </Box>
                  )}
                </Box>
              </Grid>
              {approverExpand && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box>
                      <Divider
                        variant="fullWidth"
                        sx={{
                          borderTop: "1px solid #545558",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="mt-sm mb-sm ml-sm mr-sm pb-md"
                    sx={{ backgroundColor: "#323337" }}
                  >
                    <Grid item>
                      <Box className="flex__ mb-sm">
                        <CheckBox
                          value={level1}
                          onCheckBoxClick={() => {}}
                          className="p-none"
                        />

                        <MediumTypography
                          labelId={"level1text"}
                          defaultLabel="Level 1"
                          fontSize="12px"
                          fontWeight={500}
                          textColor={"#9FADBC"}
                          className=" ml-sm"
                        />
                      </Box>

                      <CustomDropdown
                        disableCreate={true}
                        disableClear={true}
                        options={getOptions(
                          workOrderMasterResponse?.approversList ?? []
                        )}
                        labelId="approverLabel"
                        defaultLabel="Select Approver"
                        selectedValue={workOrderMasterResponse?.approver1 || 0}
                        onSelectedValueChange={async (value) => {
                          setWorkOrderMasterResponse((prev) => {
                            if (!prev) return null;
                            return {
                              ...prev,
                              approver1: Number(value),
                            };
                          });

                          const queryParams = {
                            APPROVER1: Number(value),
                            woMasterId: woId,
                          };
                          const params =
                            woId !== null && woId !== undefined
                              ? { APPROVER1: Number(value) }
                              : queryParams;

                          const response = await saveApi(
                            { approver1: Number(value) },
                            params,
                            woId
                          );
                          if (response !== ({} as WorkOrderMasterType)) {
                            const woMasterId = response.id;
                            setWOId(woMasterId);
                          }

                          setApplyStatus((prev) => ({
                            ...prev,
                            approvers: false,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Box className="flex__ mb-sm">
                        <CheckBox
                          value={level2}
                          onCheckBoxClick={async (checked: boolean) => {
                            if (workOrderMasterResponse?.approver1 !== null) {
                              setLevel2(checked);
                              setWorkOrderMasterResponse((prev) => {
                                if (!prev) return null;

                                if (!checked) {
                                  return {
                                    ...prev,
                                    approver2: null,
                                  };
                                }
                                return prev;
                              });

                              if (
                                !checked &&
                                workOrderMasterResponse?.approver2 !== null
                              ) {
                                const queryParams = {
                                  APPROVER2: null,
                                  woMasterId: woId,
                                };
                                const params =
                                  woId !== null && woId !== undefined
                                    ? { APPROVER2: null }
                                    : queryParams;

                                const response = await saveApi(
                                  { approver2: null },
                                  params,
                                  woId
                                );
                                if (response !== ({} as WorkOrderMasterType)) {
                                  const woMasterId = response.id;
                                  setWOId(woMasterId);
                                }
                              }
                            }

                            setApplyStatus((prev) => ({
                              ...prev,
                              approvers: false,
                            }));
                          }}
                          className="p-none"
                        />

                        <MediumTypography
                          labelId={"level2text"}
                          defaultLabel="Level 2"
                          fontSize="12px"
                          fontWeight={500}
                          textColor={"#9FADBC"}
                          className="ml-sm"
                        />
                      </Box>
                      <CustomDropdown
                        disableCreate={true}
                        disableClear={true}
                        options={getOptions(
                          workOrderMasterResponse?.approversList ?? []
                        )}
                        labelId="approverLabel"
                        defaultLabel="Select Approver"
                        selectedValue={workOrderMasterResponse?.approver2 || 0}
                        onSelectedValueChange={async (value) => {
                          setWorkOrderMasterResponse((prev) => {
                            if (!prev) return null;
                            return {
                              ...prev,
                              approver2: Number(value),
                            };
                          });

                          const queryParams = {
                            APPROVER2: Number(value),
                            woMasterId: woId,
                          };
                          const params =
                            woId !== null && woId !== undefined
                              ? { APPROVER2: Number(value) }
                              : queryParams;

                          const response = await saveApi(
                            { approver2: Number(value) },
                            params,
                            woId
                          );
                          if (response !== ({} as WorkOrderMasterType)) {
                            const woMasterId = response.id;
                            setWOId(woMasterId);
                          }

                          setApplyStatus((prev) => ({
                            ...prev,
                            approvers: false,
                          }));
                        }}
                        disabled={!level2}
                      />
                    </Grid>
                    <Grid item>
                      <Box className="flex__ mb-sm">
                        <CheckBox
                          value={level3}
                          onCheckBoxClick={async (checked: boolean) => {
                            if (workOrderMasterResponse?.approver2 !== null) {
                              setLevel3(checked);
                              setWorkOrderMasterResponse((prev) => {
                                if (!prev) return null;

                                if (!checked) {
                                  return {
                                    ...prev,
                                    approver3: null,
                                  };
                                }
                                return prev;
                              });

                              if (
                                !checked &&
                                workOrderMasterResponse?.approver3 !== null
                              ) {
                                const queryParams = {
                                  APPROVER3: null,
                                  woMasterId: woId,
                                };
                                const params =
                                  woId !== null && woId !== undefined
                                    ? { APPROVER3: null }
                                    : queryParams;

                                const response = await saveApi(
                                  { approver3: null },
                                  params,
                                  woId
                                );
                                if (response !== ({} as WorkOrderMasterType)) {
                                  const woMasterId = response.id;
                                  setWOId(woMasterId);
                                }
                              }
                            }

                            setApplyStatus((prev) => ({
                              ...prev,
                              approvers: false,
                            }));
                          }}
                          className="p-none"
                        />

                        <MediumTypography
                          labelId={"level3text"}
                          defaultLabel="Level 3"
                          fontSize="12px"
                          fontWeight={500}
                          textColor={"#9FADBC"}
                          className="ml-sm"
                        />
                      </Box>
                      <CustomDropdown
                        disableCreate={true}
                        options={getOptions(
                          workOrderMasterResponse?.approversList ?? []
                        )}
                        labelId="approverLabel"
                        defaultLabel="Select Approver"
                        selectedValue={workOrderMasterResponse?.approver3 || 0}
                        onSelectedValueChange={async (value) => {
                          setWorkOrderMasterResponse((prev) => {
                            if (!prev) return null;
                            return {
                              ...prev,
                              approver3: Number(value),
                            };
                          });

                          const queryParams = {
                            APPROVER3: Number(value),
                            woMasterId: woId,
                          };
                          const params =
                            woId !== null && woId !== undefined
                              ? { APPROVER3: Number(value) }
                              : queryParams;

                          const response = await saveApi(
                            { approver3: Number(value) },
                            params,
                            woId
                          );
                          if (response !== ({} as WorkOrderMasterType)) {
                            const woMasterId = response.id;
                            setWOId(woMasterId);
                          }

                          setApplyStatus((prev) => ({
                            ...prev,
                            approvers: false,
                          }));
                        }}
                        disabled={!level3}
                      />
                    </Grid>
                  </Grid>
                  <Box className="mt-sm mb-sm flex__ width__100 justifyContent-FlexEnd">
                    <ButtonComponent
                      className={
                        workOrderMasterResponse?.approver1 !== null
                          ? "btn-primary btn-submit mr-md"
                          : "btn-primary btn-disabled mr-md"
                      }
                      variantType="contained"
                      defaultLabelId={"Apply"}
                      labelId={"Apply"}
                      disabled={workOrderMasterResponse?.approver1 === null}
                      onClick={() => {
                        setApplyStatus((prev) => ({
                          ...prev,
                          approvers: true,
                        }));

                        setApproverExpand(false);
                      }}
                    />
                  </Box>
                </>
              )}
            </Grid>
          </Box>
          <Box className="mt-md  flex__ width__100 justifyContent-FlexEnd">
            <ButtonComponent
              className={
                !Object.values(applyStatus).every((status) => status)
                  ? "btn-primary btn-disabled mr-md"
                  : "btn-primary btn-submit mr-md"
              }
              variantType="contained"
              defaultLabelId={"Complete"}
              labelId={"btn.Complete"}
              onClick={() => {
                if (woId) {
                  completeWoMaster(woId, {})
                    .then(() => {
                      toggleLoader(false);
                      handleClose();
                      getDataCallback();
                    })
                    .catch((error) => {
                      toggleLoader(false);
                      setOpenErrorModal(true);

                      handleError(error, setErrorDesc);
                    });
                }
              }}
              disabled={!Object.values(applyStatus).every((status) => status)}
            />
            <ButtonComponent
              className="btn-primary btn-cancel"
              variantType="contained"
              defaultLabelId={"Cancel"}
              labelId={"btn.cancel"}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default AddEditWorkOrderMaster;
