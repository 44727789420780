import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { WoTypesData } from "../types/workOrderTypes";
import { FC } from "react";

interface WoTypeProps {
  woTypesList: WoTypesData[];
  id: number;
  subTypeId: number;

  handleSelectedWOType: (id: number) => void;
  handleSelectedSubWOType: (id: number) => void;
  handleApply: () => void;
}

const WoTypeSelection: FC<WoTypeProps> = ({
  woTypesList,
  id,

  handleSelectedWOType,
  subTypeId,
  handleApply,
  handleSelectedSubWOType,
}) => {
  const selectedWOType = woTypesList.find((woType) => woType.id === id);
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid #545558",
            }}
          />
        </Box>
      </Grid>
      <Grid container direction={"row"} className="p-md" spacing={2}>
        {woTypesList.map((item, index) => {
          return (
            <Grid item key={item.id} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box
                sx={{
                  backgroundColor: id === item.id ? "#0C66E4" : "#22272B",
                  borderRadius: "4px",
                  border: "1px solid rgba(166, 197, 226, 0.16)",
                }}
                className="p-sm cursor__pointer"
                onClick={() => handleSelectedWOType(item.id)}
              >
                <Box className="flex__container p-sm">
                  <Box
                    sx={{
                      border:
                        id === item.id
                          ? "1px solid #fff"
                          : "1px solid  #8C9BAB",
                      borderRadius: "4px",
                      padding: "2px",
                    }}
                  >
                    <MediumTypography
                      label={item.code}
                      fontSize="12px"
                      textColor={id === item.id ? "#fff" : "#8C9BAB"}
                      sxProps={{
                        lineHeight: "normal",
                      }}
                    />
                  </Box>

                  <MediumTypography
                    label={item.name}
                    className={"ml-sm"}
                    textColor={id === item.id ? "#fff" : "#8C9BAB"}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {selectedWOType?.typeList && selectedWOType?.typeList?.length > 0 && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Divider
                variant="fullWidth"
                sx={{
                  borderTop: "1px solid #545558",
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box className="pt-md pl-md pr-md">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={subTypeId || ""}
                  onChange={(e) =>
                    handleSelectedSubWOType(Number(e.target.value))
                  }
                >
                  {selectedWOType.typeList.map((type) => (
                    <Box
                      className="pl-sm pr-sm ml-sm"
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        key={type.id}
                        value={type.id}
                        control={<Radio sx={{ color: "#fff" }} />}
                        label={type.name}
                        style={{ color: "#fff" }}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </>
      )}
      <Box className="mt-sm mb-sm flex__ width__100 justifyContent-FlexEnd">
        <ButtonComponent
          className={
            !selectedWOType ||
            ((selectedWOType?.code === "MC" || selectedWOType?.code === "GC") &&
              !subTypeId)
              ? "btn-primary btn-disabled mr-md"
              : "btn-primary btn-submit mr-md"
          }
          variantType="contained"
          defaultLabelId={"Apply"}
          labelId={"Apply"}
          disabled={
            !selectedWOType ||
            ((selectedWOType?.code === "MC" || selectedWOType?.code === "GC") &&
              !subTypeId)
          }
          onClick={() => {
            if (
              selectedWOType?.code === "CM" ||
              selectedWOType?.code === "PM" ||
              ((selectedWOType?.code === "MC" ||
                selectedWOType?.code === "GC") &&
                subTypeId)
            ) {
              handleApply();
            }
          }}
        />
      </Box>
    </>
  );
};

export default WoTypeSelection;
