import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso, TableComponents } from "react-virtuoso";

import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Popover,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

import { HeadCell } from "../../MasterDataManagement/types/accountTypes";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import SearchBox from "../../../components/formlib/SearchBox";
import { FC, useContext, useEffect, useState } from "react";
import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../../MasterDataManagement/TableUtility";
import { ReactComponent as Filter } from "../../../assets/images/filter.svg";
import { visuallyHidden } from "@mui/utils";
import { AssetDataType, AssetWizardsType } from "../types/assetTypes";
import { fetchAllWizardsDataApi } from "../../../api/wizardsApi/Common";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { handleError } from "../../../utils/commonFunctions";

import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { hasPermission } from "../../../utils/checkPermission";
import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import dayjs from "dayjs";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import AddEditAssets from "./AddEditAssets";
import CheckYesIcon from "@mui/icons-material/CheckCircle";
import CheckNoIcon from "@mui/icons-material/Cancel";
import { useLocation } from "react-router-dom";

const staticColumn = "assetName";

export interface ManageComponentProps {
  onDataFetch: (data: AssetDataType) => void;
  selectedLanguageCode: string;
  assetGroupId: number;
  assetCategoryId: number;
  assetCategoryName: string;
  assetChildCallback: () => void;
  showAddEditForm: boolean;
  setShowAddEditForm: (value: boolean) => void;
  pageKey: string;
  onFilter: (filteredData: { [key: string]: string }) => void;
}

interface FixedHeaderProps {
  dataFieldsWithIcons: HeadCell[];
  staticColumn: string;
  sortDirection: Record<string, "asc" | "desc" | undefined>;
  handleSort: (column: HeadCell) => void;
  handleSearchIconClick: (
    event: React.MouseEvent<HTMLElement>,
    key: string
  ) => void;
  handleSearchClose: () => void;
  handleSearchChange: (value: string, key: string) => void;
  handleCancel: (key: string) => void;
  anchorE1Search: HTMLElement | null;
  activeCellKey: string | null;
  searchableKeys: string[];
  searchValue: Record<string, string>;
}

const renderFixedHeader = ({
  dataFieldsWithIcons,
  staticColumn,
  sortDirection,
  handleSort,
  handleSearchIconClick,
  handleSearchClose,
  handleSearchChange,
  handleCancel,
  anchorE1Search,
  activeCellKey,
  searchableKeys,
  searchValue,
}: FixedHeaderProps) => {
  return (
    <TableRow>
      <TableCell
        align="left"
        sx={{
          verticalAlign: "top",
          backgroundColor: "#22272B",
          borderBottom: "none",
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 2,
        }}
        className="p-md"
        style={{ width: "100px" }}
      >
        <MediumTypography
          sxProps={{
            fontWeight: 700,
            color: "#FFFFFF",
            whiteSpace: "nowrap",
          }}
          labelId="srNo"
          defaultLabel="Sr. No."
        />
      </TableCell>
      {dataFieldsWithIcons.map((column) => (
        <React.Fragment key={column.key}>
          <TableCell
            variant="head"
            style={{ width: "250px" }}
            sx={{
              verticalAlign: "top",
              backgroundColor: "#22272B",

              borderBottom: "none",
              position: column.key === staticColumn ? "sticky" : "relative",
              top: 0,
              left: column.key === staticColumn ? 75 : undefined,
              zIndex: column.key === staticColumn ? 2 : 1,
            }}
            className="p-md"
          >
            <Box className="flex__" sx={{ alignItems: "center" }}>
              <MediumTypography
                sxProps={{
                  fontWeight: 700,
                  color: "#FFFFFF",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
                label={column.value}
                onClick={() => handleSort(column)}
              />
              <TableSortLabel
                active={sortDirection[column.key] !== undefined}
                direction={sortDirection[column.key] || "asc"}
                onClick={() => handleSort(column)}
                sx={{
                  cursor: "pointer",
                  "&.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
                    {
                      opacity: 1,
                      color: "#FFFFFF",
                    },
                  "&.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                    opacity: 1,
                    color: "#B0B0B0",
                  },
                }}
              >
                {sortDirection[column.key] !== undefined && (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection[column.key] === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                )}
              </TableSortLabel>
              {searchValue[column.key] && <Filter className="ml-sm" />}
              {column.icon && (
                <Box
                  ml={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: searchableKeys.includes(column.key)
                      ? "pointer"
                      : "not-allowed",
                  }}
                  onClick={(event) => handleSearchIconClick(event, column.key)}
                >
                  {column.icon}
                </Box>
              )}
            </Box>
          </TableCell>
          {searchableKeys.includes(column.key) && (
            <Popover
              open={activeCellKey === column.key}
              anchorEl={anchorE1Search}
              onClose={handleSearchClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{
                left:
                  column.key === "acCapacity" ||
                  column.key === "dcCapacity" ||
                  column.key === "serialNo"
                    ? -150
                    : -90,
                top: 20,
              }}
            >
              <Box p={2} width="250px" sx={{ backgroundColor: "#323337" }}>
                <SearchBox
                  labelId="defaultSearch"
                  defaultlabel="Search"
                  backgroundColor="#22272B"
                  sxProps={{ minWidth: "180px" }}
                  value={searchValue[column.key] || ""}
                  onChange={(value: string) =>
                    handleSearchChange(value, column.key)
                  }
                  onCancel={() => handleCancel(column.key)}
                  cancelRequired={true}
                />
              </Box>
            </Popover>
          )}
        </React.Fragment>
      ))}
      <TableCell
        align="left"
        sx={{
          verticalAlign: "top",
          backgroundColor: "#22272B",
          padding: "8px 16px",
          borderBottom: "none",
          position: "sticky",
          top: 0,
          right: 0,
          zIndex: 1,
        }}
        style={{ width: "50px" }}
      />
    </TableRow>
  );
};
const CustomEmptyPlaceholder = () => {
  const emptyTableHeight = useAvailableHeight(260);
  const availableWidth = useAvailableWidth(550);
  return (
    <Box
      sx={{
        height: emptyTableHeight,

        width: availableWidth,
      }}
      className="display-flex-center flex__justify__center "
    >
      <MediumTypography
        labelId="ManageAccounts.emptymsg"
        defaultLabel="No records available"
        fontSize="20px"
        fontWeight={700}
      />
    </Box>
  );
};
const VirtuosoTableComponents: TableComponents<AssetWizardsType> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => {
    const availableWidth = useAvailableWidth(550);
    const availableHeight = useAvailableHeight(230);

    return (
      <TableContainer
        component={Paper}
        {...props}
        ref={ref}
        sx={{
          width: availableWidth,
          height: availableHeight,
          position: "relative",
        }}
        className="tableContainer"
      />
    );
  }),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} sx={{ position: "relative" }} />
  )),
  TableRow,
  TableBody: React.forwardRef<HTMLTableSectionElement>(
    (props: React.HTMLAttributes<HTMLDivElement>, ref) => (
      <TableBody {...props} ref={ref} sx={{ position: "relative" }} />
    )
  ),
  EmptyPlaceholder: CustomEmptyPlaceholder,
};

const AssetsList: FC<ManageComponentProps> = ({
  onDataFetch,
  selectedLanguageCode,
  assetChildCallback,
  onFilter,
  assetCategoryId,
  assetGroupId,
  assetCategoryName,
  showAddEditForm,
  setShowAddEditForm,
  pageKey,
}) => {
  const [rows, setRows] = React.useState<AssetWizardsType[]>([]);
  const [originalData, setOriginalData] = React.useState<AssetWizardsType[]>(
    []
  );
  const [listData, setListData] = React.useState<AssetDataType | null>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const availableHeight = useAvailableHeight(230);
  const [reorderedFields, setReorderedFields] = React.useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [activeFilters, setActiveFilters] = useState<{
    [key: string]: string;
  }>({});
  const [modalAccountId, setModalAccountId] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  //const [filterOpen, setFilterOpen] = useState<null | HTMLElement>(null);
  const [anchorE1Search, setAnchorE1Search] = useState<null | HTMLElement>(
    null
  );

  const [searchValue, setSearchValue] = useState<{ [key: string]: string }>({});
  const [activeCellKey, setActiveCellKey] = useState<string>("");

  const location = useLocation();

  const [sortDirection, setSortDirection] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});
  //const open = Boolean(filterOpen);
  const addEditOpen = Boolean(anchorEl);
  const handleSearchIconClick = (
    event: React.MouseEvent<HTMLElement>,
    columnKey: string
  ) => {
    setAnchorE1Search(event.currentTarget);
    setSearchValue((prev) => ({ ...prev, columnKey }));
    setActiveCellKey(columnKey);
  };

  const handleSearchClose = () => {
    setAnchorE1Search(null);
    setActiveCellKey("");
  };

  const handleSearchChange = (value: string, columnKey: string) => {
    setSearchValue((prev) => ({ ...prev, [columnKey]: value }));
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredRows = applySearchFilters(originalData, newFilters);
    setActiveFilters(newFilters);
    onFilter(newFilters);
    setRows(filteredRows as AssetWizardsType[]);
  };

  const handleCancel = (key: string) => {
    setSearchValue((prevValues) => ({
      ...prevValues,
      [key]: "",
    }));

    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalData,
      activeFilters,
      key
    );
    setActiveFilters(updatedFilters);
    setRows(filteredData as AssetWizardsType[]);
    handleSearchClose();
  };

  //sorting
  function handleSort(column: HeadCell) {
    const currentDirection = sortDirection[column.key];
    let newDirection: "asc" | "desc" | undefined;
    if (!currentDirection) {
      newDirection = "desc"; // First click should sort in descending order
    } else if (currentDirection === "desc") {
      newDirection = "asc"; // Second click should sort in ascending order
    } else if (currentDirection === "asc") {
      newDirection = undefined; // Third click should clear the sorting
    }
    setSortDirection({
      [column.key]: newDirection,
    });

    const sortedData = sortTableData(
      rows,
      column.key,
      newDirection ? newDirection : "",
      originalData
    );
    setRows(sortedData as AssetWizardsType[]);
  }

  const getData = async (
    resetData: boolean = false,
    language: string = selectedLanguageCode,
    applyFilters: boolean = false
  ) => {
    const fullUri = `${assetGroupId}/${assetCategoryId}/asset`;

    fetchAllWizardsDataApi<AssetDataType>(fullUri, language, "")
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setListData(response);

          if (resetData) {
            setRows(response.assets);
            setOriginalData(response.assets);
          } else {
            const updateTableData = (newRecords: AssetWizardsType[]) => {
              setRows((prevMessages) => [...prevMessages, ...newRecords]);
              setOriginalData((prevMessages) => [
                ...prevMessages,
                ...newRecords,
              ]);
            };

            // Use the helper function with response data
            updateTableData(response.assets);
          }

          setReorderedFields(response.dataFields);
          onDataFetch(response);
          if (applyFilters && Object.keys(activeFilters).length > 0) {
            const filteredData = applySearchFilters(
              response.assets,
              activeFilters
            );
            setRows(filteredData as AssetWizardsType[]);
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    setActiveFilters({});
  };

  useEffect(() => {
    if (
      pageKey === "ASSETS" &&
      assetCategoryId &&
      assetGroupId &&
      selectedLanguageCode
    ) {
      getData(true, selectedLanguageCode, false);
    } else if (selectedLanguageCode && assetCategoryId && assetGroupId) {
      getData(true);
    }
  }, [
    selectedLanguageCode,
    assetCategoryId,
    assetGroupId,
    pageKey,
    location?.state,
  ]);

  const dataFieldsWithIcons =
    reorderedFields &&
    reorderedFields.map((field) => {
      return { ...field, icon: <Sort /> };
    });

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };
  const handleEdit = (id: number) => {
    setModalAccountId(id);
    setShowAddEditForm(true);
    //handleModalOpen();
    handleClose();
  };

  const searchableKeys = [
    "assetName",
    "code",
    "serialNo",
    "make",
    "model",
    "acCapacity",
    "dcCapacity",
    "latitude",
    "longitude",
    "statusName",
    "rating",
    "calibrationFrequencyName",
    "lastCalibratedDate",
    "manufacturedDate",
    "installationDate",
    "warrantyExpiryDate",
    "insuranceExpiryDate",
  ];
  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      {showAddEditForm && (
        <AddEditAssets
          open={showAddEditForm}
          handleClose={() => {
            setModalAccountId(undefined);
            setShowAddEditForm(false);
            //handleModalClose();
          }}
          id={modalAccountId}
          selectedLanguageCode={selectedLanguageCode}
          getDataCallback={() => {
            getData(true);
            assetChildCallback();
          }}
          assetCategoryId={assetCategoryId}
          assetGroupId={assetGroupId}
        />
      )}

      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {listData && !showAddEditForm && (
        <Box>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              label={assetCategoryName}
              textColor="#FFF"
              fontSize="20px"
              fontWeight={700}
            />
            <Box className="flex__" alignItems="center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData?.totalRecords || 0
                    : `${rows.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />
              {selectedLanguageCode === "en" && (
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  variantType="contained"
                  defaultLabelId={"Add New"}
                  labelId={"btn.AddNew"}
                  onClick={() => {
                    setShowAddEditForm(true);
                  }}
                />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              height: availableHeight,
              overflowY: "auto",
              position: "relative",
            }}
          >
            <TableVirtuoso
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() =>
                renderFixedHeader({
                  dataFieldsWithIcons,
                  staticColumn,
                  sortDirection,
                  handleSort,
                  handleSearchIconClick,
                  handleSearchClose,
                  handleSearchChange,
                  handleCancel,
                  anchorE1Search,
                  activeCellKey,
                  searchableKeys,
                  searchValue,
                })
              }
              itemContent={(rowIndex, row) => {
                return (
                  <>
                    {/* Sticky First Column */}
                    <TableCell
                      align={"left"}
                      sx={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                      }}
                      style={{ width: "100px" }}
                      className="stickyColumn"
                    >
                      <MediumTypography label={(rowIndex + 1).toString()} />
                    </TableCell>

                    {dataFieldsWithIcons.map((cell: HeadCell) => {
                      const cellValue = row[cell.key as keyof AssetWizardsType];

                      if (cell.key === "lastUpdated") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                            }}
                            className="p-md"
                            style={{ width: "250px" }}
                          >
                            <Box className="display-flex-center">
                              <Avatar
                                src={
                                  row.lastUpdated !== null &&
                                  row.lastUpdated !== undefined
                                    ? row.lastUpdated.imageUrl
                                    : "-"
                                }
                                alt="abc"
                                className="avatar-style"
                              />
                              <Box className="ml-sm">
                                <MediumTypography
                                  label={
                                    row.lastUpdated !== null &&
                                    row.lastUpdated !== undefined
                                      ? row.lastUpdated.name
                                      : "-"
                                  }
                                  sxProps={{
                                    lineHeight: "normal",
                                  }}
                                  className="mt-xs"
                                />

                                <MediumTypography
                                  label={
                                    row.lastUpdated !== null &&
                                    row.lastUpdated !== undefined
                                      ? dayjs(row.lastUpdated.timestamp).format(
                                          "DD MMM YYYY HH:mm"
                                        )
                                      : ""
                                  }
                                  fontSize="12px"
                                  textColor={"rgba(255, 255, 255, 0.64)"}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      }

                      if (
                        cell.key === "installationDate" ||
                        cell.key === "insuranceExpiryDate" ||
                        cell.key === "lastCalibratedDate" ||
                        cell.key === "manufacturedDate" ||
                        cell.key === "warrantyExpiryDate"
                      ) {
                        const dateValue = row[cell.key];
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                            }}
                            className="p-md"
                          >
                            {dateValue !== null && dateValue !== undefined ? (
                              <MediumTypography
                                label={dayjs(dateValue).format("DD MMM YYYY")}
                              />
                            ) : (
                              <MediumTypography label="-" />
                            )}
                          </TableCell>
                        );
                      }

                      if (
                        cell.key === "isInsured" ||
                        cell.key === "isCalibrationRequired"
                      ) {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                            }}
                            className="p-md"
                          >
                            {row.isInsured || row.isCalibrationRequired ? (
                              <CheckYesIcon className="checkIconGreen" />
                            ) : (
                              <CheckNoIcon className="checkIconCancel" />
                            )}
                          </TableCell>
                        );
                      }

                      return (
                        <>
                          {cell.key === staticColumn ? (
                            <TableCell
                              key={cell.key}
                              align={"left"}
                              sx={{
                                backgroundColor:
                                  rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              }}
                              className="stickyColumn_left"
                              style={{ width: "250px" }}
                            >
                              <MediumTypography
                                label={
                                  cellValue !== undefined &&
                                  cellValue !== null &&
                                  cellValue !== ""
                                    ? cellValue.toString()
                                    : "-"
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              key={cell.key}
                              align={"left"}
                              sx={{
                                backgroundColor:
                                  rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              }}
                              className="p-md"
                              style={{ width: "250px" }}
                            >
                              <MediumTypography
                                label={
                                  cellValue !== undefined &&
                                  cellValue !== null &&
                                  cellValue !== ""
                                    ? cellValue.toString()
                                    : "-"
                                }
                              />
                            </TableCell>
                          )}
                        </>
                      );
                    })}

                    {/* Sticky Last Column */}
                    <TableCell
                      align={"left"}
                      sx={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                      }}
                      className="stickyColumn_right"
                      style={{ width: "50px" }}
                    >
                      <Tooltip title="More">
                        <IconButton
                          onClick={(event) => handleClick(event, rowIndex)}
                        >
                          <More />
                        </IconButton>
                      </Tooltip>
                      <Popover
                        anchorEl={anchorEl}
                        open={addEditOpen && selectedRow === rowIndex}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            backgroundColor: "#2B3033",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() =>
                            hasPermission("Onboarding_Admin")
                              ? handleEdit(row.id)
                              : () => {}
                          }
                          style={{ backgroundColor: "2B3033" }}
                          disabled={selectedLanguageCode !== "en"}
                        >
                          <MediumTypography
                            labelId="btn.edit"
                            defaultLabel="Edit"
                          />
                        </MenuItem>
                      </Popover>
                    </TableCell>
                  </>
                );
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AssetsList;
