import { Avatar, Box, Divider, IconButton, Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { useContext, useEffect, useState } from "react";
import { InitialData } from "../types/CreateTicket";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { Link } from "react-router-dom";
import { TICKET } from "../../../routes/Routing";
import CustomDropdown, {
  OptionType,
} from "../../../components/formlib/CustomDropdown";
import { convertToRGBA, getOptions } from "../../../utils/commonFunctions";
import {
  NumericOptionType,
  RecordsFilterType,
  RelatedIssueType,
  StatusDataType,
  TableRowType,
  TicketVariantType,
  UpdateStatusInputType,
} from "../types/Types";
import {
  listViewRecordsApi,
  updateStatusApi,
} from "../../../api/ticketing/TicketingAPI";
import AddIcon from "@mui/icons-material/Add";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { AssigneeType } from "../../../api/moduleCleaning/ModuleCleaningApi";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getNextPossibleStatuses, getStatusById } from "../TicketDetails";
import CustomDropdownPopover from "./CustomDropdownPopover";
import CriticalIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { editTicket } from "../../../api/createTicket/CreateTicketAPI";
import CustomAvatar from "../../../components/formlib/CustomAvatar";
import { generateCode } from "../TicketListView";
import TextInput from "../../../components/formlib/TextInput";
import { hasTicketEditPermission } from "../helpers/helper";

let url = "ticket/save";
interface StatusProps {
  id: number;
  code: string;
  name: string;
  colour: string;
}

const RelatedTickets = ({
  relatedTickets,
  parentDetails,
  saveCallback,
  getAssigneeOptions,
  severityOptions,
  ticketTypeOptions,
  updateShowCreateInput,
  showCreateInput,
}: {
  relatedTickets: RelatedIssueType[];
  parentDetails: InitialData;
  saveCallback: (values: number[]) => void;
  getAssigneeOptions: (departmentId: number) => AssigneeType[];
  severityOptions: NumericOptionType[];
  ticketTypeOptions: NumericOptionType[];
  updateShowCreateInput: (val: boolean) => void;
  showCreateInput: boolean;
}) => {
  const [relatedTicketList, setRelatedTicketList] =
    useState<RelatedIssueType[]>(relatedTickets);
  const [relatedTicketId, setRelatedTicketId] = useState<number>(0);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [options, setOptions] = useState<TableRowType[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const hasReadOnlyPermission = !hasTicketEditPermission();

  useEffect(() => {
    setRelatedTicketList(relatedTickets);
  }, [relatedTickets]);

  useEffect(() => {
    if (searchTerm) {
      fetchRecordsData({ search: searchTerm });
    } else {
      setRelatedTicketId(0);
      setOptions([]);
    }
  }, [searchTerm]);

  const relatedIssueIds = relatedTicketList?.flatMap((ci) => ci.id) ?? [];
  const updatedOptions = options.filter(
    (o) =>
      !relatedIssueIds.includes(o.id as number) && parentDetails?.id !== o.id
  );

  const relatedTicketsExist = relatedTicketList?.length > 0;

  const severityOptionsData = getOptions(severityOptions);

  const getSeverityOption = (severityId: number) => {
    return severityOptionsData.find(
      (severity) => severity.id === severityId.toString()
    );
  };

  const getAssigneeOption = (
    assigneeOptions: OptionType[],
    assigneeId: number
  ) => {
    return assigneeOptions.find(
      (assignee) => assignee.id === assigneeId.toString()
    );
  };

  const updateRelatedIssueList = (
    ticketId: number,
    field: string,
    value: any
  ) => {
    setRelatedTicketList((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === ticketId) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    });
  };

  const updateDetails = (ticketId: number, field: string, value: any) => {
    editTicket(ticketId, { [field]: value })
      .then(() => {
        updateRelatedIssueList(ticketId, field, value);
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const updateStatus = (
    ticketId: number,
    ticketUniqueId: number,
    statusId: number,
    transitionId: number,
    remarks?: string
  ) => {
    const payloadData: UpdateStatusInputType = {
      ticketId,
      ticketUniqueId,
      transitionId,
      remarks,
    };
    updateStatusApi(payloadData)
      .then(() => {
        updateRelatedIssueList(ticketId, "status", statusId);
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const fetchRecordsData = async (filterDataParams: RecordsFilterType = {}) => {
    const filterData: RecordsFilterType = {
      page: 0,
      pageSize: 10,
      search: "",
    };

    const updatedFilterData = { ...filterData, ...filterDataParams };

    listViewRecordsApi("fetchData", updatedFilterData, true)
      .then((res) => {
        const currentRows = res.data ?? [];
        const updatedRows = currentRows.map((currentRow) => ({
          ...currentRow,
          summaryWithId: `${currentRow.ticketUniqueId} : ${currentRow.summary}`,
        }));
        setOptions(updatedRows);
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  const childInputComponent = (
    <Box className="child-issue-item">
      <MediumTypography
        labelId={"ticket.newRelatedIssue"}
        sxProps={{ color: "#9fadbc", width: "200px" }}
      />

      <Box className="common-flex-row" sx={{ flex: 1, width: "100%" }}>
        <CustomDropdown
          sx={{ width: "100%" }}
          overrideWidth={"100% !important"}
          disableCreate={true}
          options={getOptions(updatedOptions, "summaryWithId")}
          selectedValue={relatedTicketId ?? ""}
          onSelectedValueChange={(value) => {
            setRelatedTicketId(Number(value));
          }}
          labelId="ticket.search"
          defaultLabel="Search by Ticket ID / Summary"
          fetchOnSearch
          fetchOptions={(search) => {
            if (search !== searchTerm) {
              setSearchTerm(search);
            }
          }}
        />

        <Box className="common-flex-row" gap={"4px"}>
          <IconButton
            onClick={() => {
              if (relatedTicketId) {
                saveCallback([...relatedIssueIds, relatedTicketId]);
              }
              setRelatedTicketId(0);
            }}
            size="small"
            sx={{
              color: "#9fadbc",
              backgroundColor: "rgb(26, 30, 34)",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgb(23, 25, 28)",
              },
            }}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setRelatedTicketId(0);
              updateShowCreateInput(false);
            }}
            size="small"
            sx={{
              color: "#9fadbc",
              backgroundColor: "rgb(26, 30, 34)",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgb(23, 25, 28)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  if (!relatedTicketsExist && !showCreateInput) return <></>;

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Divider className="details-divider" />
      <Box className="common-flex-column" gap={1} mb={1}>
        <Box className="common-flex-row" justifyContent={"space-between"}>
          <MediumTypography
            defaultLabel="Related Issue"
            labelId={"ticket.relatedIssue"}
            className="ticket-header"
          />
          {!hasReadOnlyPermission && (
            <IconButton onClick={() => updateShowCreateInput(true)}>
              <AddIcon sx={{ color: "#9fadbc" }} />
            </IconButton>
          )}
        </Box>

        {relatedTicketsExist && (
          <Box className="child-issue-container-box">
            {relatedTicketList?.map((item, index) => {
              const severityOption = getSeverityOption(item.severity);
              const assigneeOptionsData = getOptions(
                getAssigneeOptions(item.department)
              );

              const ticketTypeData = ticketTypeOptions.find(
                (option) => option.id === item.type
              );

              let nextStatuses: StatusDataType[] = [];
              let statusOptionsData: OptionType[] = [];
              let statusOption: OptionType | undefined = undefined;

              if (item.stateTransition) {
                nextStatuses = getNextPossibleStatuses(
                  item.stateTransition.scheduleExecution,
                  item.stateTransition.transitionDetails,
                  item.stateTransition.availableStatus,
                  item.status
                );

                statusOptionsData = getOptions(nextStatuses);
                const statusOptionRaw = getStatusById(
                  nextStatuses,
                  item.status
                );
                if (statusOptionRaw) {
                  statusOption = {
                    ...statusOptionRaw,
                    id: statusOptionRaw.id.toString(),
                  };
                }
              }

              const isInitialStatus =
                item?.stateTransition?.status?.length &&
                item.stateTransition.status.length === 1
                  ? true
                  : false; // Check for initial (New) status

              return (
                <Box className="child-issue-item" key={index}>
                  <Box
                    className="common-flex-row"
                    sx={{ flex: 1, width: "100%" }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                      sx={{ minWidth: "85px" }}
                    >
                      <Box className="named-icon">
                        {ticketTypeData ? (
                          <Tooltip
                            title={
                              <MediumTypography
                                label={ticketTypeData.name ?? ""}
                              />
                            }
                            arrow
                            placement="top"
                          >
                            <Box>
                              <MediumTypography
                                label={generateCode(ticketTypeData.name)}
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <MediumTypography label={"NA"} />
                        )}
                      </Box>
                      <Link
                        to={TICKET + "/" + item.ticketUniqueId?.toString()}
                        className="ticket-anchor ticket-link"
                        target="_blank"
                      >
                        <MediumTypography
                          labelId={item.ticketUniqueId?.toString()}
                        />
                      </Link>
                    </Box>

                    <TextInput
                      inline
                      className="text-input-field inline-text-input ticket-child-text"
                      type="text"
                      variant="outlined"
                      disabled={hasReadOnlyPermission}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={item?.summary ?? ""}
                      handlechange={(value) => {
                        updateDetails(item.id, "summary", value);
                      }}
                    />
                  </Box>

                  <Box className="common-flex-row" gap={2}>
                    <CustomDropdownPopover
                      disabled={hasReadOnlyPermission || isInitialStatus}
                      disableCreate
                      options={statusOptionsData}
                      selectedOption={statusOption as OptionType}
                      renderTrigger={(selectedOptionData) => {
                        if (!selectedOptionData)
                          return (
                            <Box className="child-popover-box status none">
                              <MediumTypography
                                labelId={"inline.noTextPlaceholder"}
                                defaultLabel="None"
                                textColor="#4e5861"
                              />
                            </Box>
                          );
                        const soValue = selectedOptionData as OptionType;
                        return (
                          <Box className="child-popover-box status">
                            <Box
                              className="status-box"
                              bgcolor={convertToRGBA(soValue.colour, 0.1)}
                            >
                              <MediumTypography
                                label={soValue.name}
                                fontSize="12px"
                                textColor={soValue.colour}
                              />
                            </Box>
                          </Box>
                        );
                      }}
                      onSelectedOptionChange={(value) => {
                        const newStatus = value as OptionType;
                        const transitionId = newStatus?.transition?.id;
                        if (transitionId) {
                          updateStatus(
                            item.id,
                            item.ticketUniqueId,
                            Number(newStatus.id),
                            transitionId
                          );
                        }
                      }}
                      popoverProps={{
                        transformOrigin: {
                          vertical: -9,
                          horizontal: "left",
                        },
                      }}
                    />

                    <CustomDropdownPopover
                      disabled={hasReadOnlyPermission}
                      disableCreate
                      options={severityOptionsData}
                      selectedOption={severityOption as OptionType}
                      renderTrigger={(selectedOptionData) => {
                        if (!selectedOptionData)
                          return (
                            <Box className="child-popover-box severity none">
                              <MediumTypography
                                labelId={"inline.noTextPlaceholder"}
                                defaultLabel="None"
                                textColor="#4e5861"
                              />
                            </Box>
                          );
                        const soValue = selectedOptionData as OptionType;
                        return (
                          <Box className="child-popover-box severity">
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"4px"}
                            >
                              <Box
                                sx={{ width: "24px", height: "24px" }}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <img
                                  width={"100%"}
                                  src={soValue?.image ?? ""}
                                  alt=""
                                />
                              </Box>

                              <MediumTypography
                                label={soValue.name}
                                textColor="#8c9bab"
                              />
                            </Box>
                          </Box>
                        );
                      }}
                      onSelectedValueChange={(value) => {
                        updateDetails(item.id, "severity", value);
                      }}
                      popoverProps={{
                        transformOrigin: {
                          vertical: -9,
                          horizontal: "left",
                        },
                      }}
                    />

                    <CustomDropdownPopover
                      disabled={hasReadOnlyPermission}
                      disableCreate
                      options={assigneeOptionsData}
                      selectedOption={
                        getAssigneeOption(
                          assigneeOptionsData,
                          item.assignee ?? 0
                        ) as OptionType
                      }
                      renderTrigger={(selectedOptionData) => {
                        if (!selectedOptionData)
                          return (
                            <Box className="child-popover-box assignee none">
                              <CustomAvatar
                                name={"Unassigned"}
                                image={""}
                                tooltipProps={{
                                  placement: "top",
                                }}
                                avatarProps={{
                                  className: "avatar-style child-issue-avatar",
                                }}
                              />
                            </Box>
                          );
                        const soValue = selectedOptionData as OptionType;
                        return (
                          <Box className="child-popover-box assignee">
                            <CustomAvatar
                              name={soValue.name}
                              image={soValue.image}
                              tooltipProps={{
                                placement: "top",
                              }}
                              avatarProps={{
                                className: "avatar-style child-issue-avatar",
                              }}
                            />
                          </Box>
                        );
                      }}
                      onSelectedValueChange={(value) => {
                        updateDetails(item.id, "assignee", value);
                      }}
                      popoverProps={{
                        transformOrigin: {
                          vertical: -9,
                          horizontal: "left",
                        },
                      }}
                    />
                  </Box>
                </Box>
              );
            })}

            {showCreateInput && childInputComponent}
          </Box>
        )}

        {!relatedTicketsExist && showCreateInput && (
          <Box className="child-issue-container-box">{childInputComponent}</Box>
        )}
      </Box>
    </>
  );
};
export default RelatedTickets;
