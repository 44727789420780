import React, { useCallback, useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { ReactComponent as PendingStepperIcon } from "../../../assets/images/PendingStepperIcon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/images/_CrossIcon_.svg";
import { ReactComponent as LoaderIcon } from "../../../assets/images/LoaderIcon.svg";
import { ReactComponent as LockImage } from "../../../assets/images/LockImage.svg";
import UserprofileIcon from "../../../assets/images/profile-circle.svg";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import dayjs from "dayjs";
import {
  ApiStatusDataType,
  MixedStatusDataType,
  StatusActionType,
  TransitionType,
  UpdatedStatusActionType,
} from "../types/Types";
import { hasTicketEditPermission } from "../helpers/helper";

function convertDuration(duration: string) {
  const [hours, minutes] = duration.split(":").map(Number);
  // Convert hours and minutes to total minutes
  const totalMinutes = hours * 60 + minutes;
  // Convert total minutes to days, hours, and minutes
  const days = Math.floor(totalMinutes / (24 * 60));
  const remainingMinutes = totalMinutes % (24 * 60);
  const newHours = Math.floor(remainingMinutes / 60);
  const newMinutes = remainingMinutes % 60;

  return `${days}d:${String(newHours).padStart(2, "0")}h:${String(
    newMinutes
  ).padStart(2, "0")}m`;
}

interface StatusTransitionProps {
  defaultStatusFlowInput: MixedStatusDataType[];
  pastStatusFlowInput: ApiStatusDataType[];
  statusActionsInput?: StatusActionType[];
  statusList?: MixedStatusDataType[];
  transitionList?: TransitionType[];
  statusCallBack: (transition: TransitionType, remarks?: string) => void;
}

const StatusTransition: React.FC<StatusTransitionProps> = ({
  defaultStatusFlowInput,
  pastStatusFlowInput,
  statusActionsInput,
  transitionList,
  statusCallBack,
}) => {
  const [defaultStatusFlow, setDefaultStatusFlow] = useState<
    MixedStatusDataType[]
  >([]);
  const [pastStatusFlow, setPastStatusFlow] = useState<MixedStatusDataType[]>(
    []
  );
  const [futureStatusFlow, setFutureStatusFlow] = useState<
    MixedStatusDataType[]
  >([]);

  const [isLocked, setIsLocked] = useState<boolean>(true);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const getActionsByStatusId = useCallback(
    (statusId: number) => {
      const statusActions = statusActionsInput?.find(
        (status) => status.statusId === statusId
      );

      if (statusActions) {
        const actionObjects = statusActions.actions
          .map(
            (actionId) =>
              transitionList?.find(
                (transition) => transition.id === actionId
              ) ?? null
          )
          .filter((transition) => transition !== null);

        return {
          ...statusActions,
          actions: actionObjects,
        } as UpdatedStatusActionType;
      }
    },
    [statusActionsInput, transitionList]
  );

  const getDimmedStatusIds = useCallback(() => {
    return (
      transitionList
        ?.filter((transition) => transition.isTransitionStatus)
        .flatMap((transition) => transition.toStatus) ?? []
    );
  }, [transitionList]);

  useEffect(() => {
    const updatedPastStatusFlow: MixedStatusDataType[] =
      pastStatusFlowInput.map((status) => ({
        ...status,
        id: status.id,
        name: status.status,
        assignee: status.name,
        isPast: true,
      }));

    const dimmedStatusIds = getDimmedStatusIds();

    let updatedFutureStatusFlow = [
      ...defaultStatusFlowInput.filter(
        (status) => !dimmedStatusIds.includes(status.id)
      ),
    ];

    if (updatedPastStatusFlow.length > 0) {
      const lastPastStatus =
        updatedPastStatusFlow[updatedPastStatusFlow.length - 1];

      const currentStatusIndex = defaultStatusFlowInput.findIndex(
        (status) => status.id === lastPastStatus?.id
      );

      updatedFutureStatusFlow =
        currentStatusIndex !== -1
          ? defaultStatusFlowInput
              .slice(currentStatusIndex + 1)
              .filter((status) => !dimmedStatusIds.includes(status.id))
          : [];
    }

    setDefaultStatusFlow(defaultStatusFlowInput);
    setPastStatusFlow(updatedPastStatusFlow);
    setFutureStatusFlow(updatedFutureStatusFlow);
  }, [pastStatusFlowInput, defaultStatusFlowInput]);

  // Derived State

  let isLockApplicable = false;
  let slicedPastStatusFlow = [...pastStatusFlow];

  if (pastStatusFlow.length > 5) {
    isLockApplicable = true;

    if (isLocked) {
      slicedPastStatusFlow = pastStatusFlow.slice(-5);
    }
  }

  const pendingStatus: MixedStatusDataType = {
    id: 0,
    name: "Pending",
    code: "PENDING",
  };

  const completeStatusFlow = [
    ...slicedPastStatusFlow,
    pendingStatus,
    ...futureStatusFlow,
  ];

  let currentStatus =
    pastStatusFlow.length > 0
      ? pastStatusFlow[pastStatusFlow.length - 1]
      : null;

  let currentStatusActions: UpdatedStatusActionType | null = null;

  if (currentStatus) {
    currentStatusActions = getActionsByStatusId(currentStatus.id) ?? null;
  }

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box
        className=""
        sx={{
          display: "grid",
          padding: "16px 0px",
          width: "100%",
          height: "100%",
          overflowX: "auto",
          WebkitOverflowScrolling: "touch",
          "-webkit-overflow-scrolling": "touch", // iOS specific - enables momentum scrolling
          "scroll-behavior": "smooth", // Optional: Adds smooth scrolling behavior
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar on Chrome, Safari, and Opera
          },
        }}
      >
        <Box sx={{ display: "flex", overflowX: "auto" }}>
          {isLockApplicable && !isLocked && (
            <Box
              className="cursor__pointer"
              sx={{
                width: "40px",
                minWidth: "40px",
                height: "100px",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
              onClick={() => {
                setIsLocked(true);
              }}
            >
              {!isLocked && <LockImage style={{ position: "relative" }} />}
            </Box>
          )}
          {isLockApplicable && isLocked && (
            <Box
              className="cursor__pointer"
              sx={{
                width: "40px",
                minWidth: "40px",
                height: "100px",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
              onClick={() => {
                setIsLocked(false);
              }}
            >
              {isLocked && <CrossIcon style={{ position: "relative" }} />}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto", // Enable horizontal scrolling
              WebkitOverflowScrolling: "touch", // Enable smooth scrolling on touch devices
              maxHeight: "calc(6 * 48px)", // Assuming each status box is about 48px tall, adjust if needed
              "-ms-overflow-style": "none", // Hide scrollbar on IE and Edge
              scrollbarWidth: "none", // Hide scrollbar on Firefox
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar on Chrome, Safari, and Opera
              },
              justifyContent: "space-between",
              "-webkit-overflow-scrolling": "touch", // iOS specific - enables momentum scrolling
              "scroll-behavior": "smooth", // Optional: Adds smooth scrolling behavior
            }}
          >
            {completeStatusFlow.map((flow, index) => {
              const StepIcon = PendingStepperIcon;
              const textColor = flow.isPast ? "#20C374" : "#9E9E9E"; // To do - it can be based on status dynamically
              const iconFill = flow.isPast ? "#20C374" : "#9E9E9E"; // To do - it can be based on status dynamically

              const nextStepData = completeStatusFlow[index + 1];

              return (
                <React.Fragment key={index}>
                  <Box
                    className="position__relative"
                    sx={{
                      left: "20px",
                      display: "flex",
                      minWidth:
                        index === completeStatusFlow.length - 1
                          ? "190px"
                          : "auto",
                      flexDirection: "column",
                      borderLeft: "none", // To do -  Can be set based on lock
                      paddingLeft: "0", // To do - Can be set based on lock
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title={flow?.remarks ?? ""}>
                        <Box sx={{ width: "28px", height: "28px" }}>
                          <StepIcon fill={iconFill} width={28} height={28} />
                        </Box>
                      </Tooltip>
                      {index >= 0 && index < completeStatusFlow.length - 1 && (
                        <Box
                          sx={{
                            height: "2px",
                            backgroundColor: flow.isPast
                              ? "rgba(32, 195, 116, 1)"
                              : "#9E9E9E", // To do - It can be based on status
                            width: "100%",
                            minWidth: "200px",
                            margin: "16px 45px",
                          }}
                        >
                          <MediumTypography
                            label={
                              nextStepData?.duration
                                ? convertDuration(nextStepData?.duration)
                                : ""
                            }
                            textColor={"rgba(159, 173, 188, 1)"}
                            fontSize="11px"
                            fontWeight={500}
                            sxProps={{
                              margin: "5px 0px",
                              textAlign: "center",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box>
                      {flow.id !== 0 && (
                        <MediumTypography
                          label={flow.name}
                          textColor={textColor}
                          fontSize="10px"
                          fontWeight={500}
                        />
                      )}

                      {flow.id === 0 && (
                        <>
                          <MediumTypography
                            labelId="StatusText"
                            defaultLabel="Status"
                            textColor={textColor}
                            fontSize="10px"
                            fontWeight={500}
                          />
                          <Box className="flex__">
                            <LoaderIcon
                              className="position__relative"
                              style={{ top: "2px" }}
                            />
                            <MediumTypography
                              labelId="PendingText"
                              defaultLabel="Pending"
                              className="ml-sm"
                              textColor="#FFFFFF"
                              fontSize="14px"
                              fontWeight={400}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                    {flow?.transitionDateTime && (
                      <Box>
                        <MediumTypography
                          label={`${dayjs(flow.transitionDateTime).format(
                            "DD MMMM YYYY"
                          )} ${dayjs(flow.transitionDateTime).format("HH:mm")}`}
                          textColor="#9FADBC"
                          fontSize="14px"
                          fontWeight={500}
                        />
                      </Box>
                    )}
                    {flow?.assignee && (
                      <Box
                        className="flex__ position__relative"
                        sx={{
                          right: "6px",
                        }}
                      >
                        <div
                          style={{
                            width: "36px",
                            height: "36px",
                            backgroundImage: flow.assignee?.image
                              ? `url(${flow.assignee?.image})`
                              : `url(${UserprofileIcon})`, // Use imported image
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <MediumTypography
                          label={flow.assignee.name}
                          className="ml-sm"
                          textColor="#888888"
                          fontSize="14px"
                          fontWeight={700}
                          sxProps={{ alignContent: "center" }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        marginTop: "8px",
                      }}
                    >
                      {flow.id !== 0 && !flow.isPast && (
                        <>
                          <Box
                            sx={{
                              width: "110px",
                              height: "8px",
                              borderRadius: "4px",
                              position: "relative",
                              padding: "0px 8px",
                              gap: "8px",
                              marginBottom: "8px",
                              background:
                                "linear-gradient(to right, rgba(158, 158, 158, 0.32), rgba(56, 56, 56, 0))",
                            }}
                          />
                          <Box
                            sx={{
                              width: "70px",
                              height: "5px",
                              borderRadius: "4px",
                              padding: "2px 8px",
                              position: "relative",
                              gap: "8px",
                              background:
                                "linear-gradient(to right, rgba(158, 158, 158, 0.32), rgba(56, 56, 56, 0))",
                            }}
                          />
                        </>
                      )}
                    </Box>
                    {hasTicketEditPermission() && (
                      <Box sx={{ marginTop: "0px" }}>
                        <div>
                          {flow.id === 0 &&
                            currentStatusActions?.actions.map(
                              (action, index2) => (
                                <ButtonComponent
                                  key={index2}
                                  variantType={"outlined"}
                                  sxProps={{
                                    margin: "0 8px",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    borderRadius: "3px",
                                    border: "none",
                                    padding: "0px 12px",
                                    textTransform: "none",
                                    color: "#FFFFFF",
                                    backgroundColor: "rgba(12, 102, 228, 1)",
                                    "&:hover": {
                                      backgroundColor: "rgb(12, 80, 174)",
                                      border: "none",
                                      textTransform: "none",
                                    },
                                    "&:disabled": {
                                      backgroundColor: "rgb(76 111 165)",
                                      color: "rgba(182, 194, 207, 1)",
                                      cursor: "not-allowed",
                                    },
                                  }}
                                  disabled={false}
                                  label={action.transition}
                                  onClick={() => {
                                    statusCallBack(action);
                                  }}
                                />
                              )
                            )}
                        </div>
                      </Box>
                    )}
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StatusTransition;
