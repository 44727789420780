import { useContext, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import MediumTypography from "../../../components/formlib/MediumTypography";
import CloseIcon from "@mui/icons-material/Close";
import TextInput from "../../../components/formlib/TextInput";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { FC, useRef, useState } from "react";
import { AddTicketForm, SaveTicket } from "../types/formBuilder";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import {
  saveTicketType,
  saveTicketTypeById,
} from "../../../api/ticketing/TicketingAPI";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { useNavigate } from "react-router-dom";

interface TicketType {
  name: string;
  description: string;
  id?: number;
}
interface ModalProps {
  tickettypes?: TicketType[] | undefined;
  formData?: { name: string; description: string };
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback?: () => void;
}

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6,
};

const initialData: AddTicketForm = {
  name: "",
  description: "",
  id: 0,
};

const apiUri = "ticketType/saveOrUpdate";
const AddEditTicketTypeModal: FC<ModalProps> = ({
  tickettypes,
  formData,
  open,
  handleClose,
  id,
  getDataCallback,
}) => {
  const navigate = useNavigate();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [apiData, setApiData] = useState<AddTicketForm>(initialData);
  const formikRef = useRef<FormikProps<AddTicketForm>>(null);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [openErrorDuplicateModal, setopenErrorDuplicateModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (id && formData) {
      const { name, description } = formData;
      const newObj = { name, description, id };
      setApiData(newObj);
    }
  }, [id]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("validation.fieldRequired"),
    description: Yup.string().required("validation.fieldRequired"),
  });

  const saveData = (params: SaveTicket) => {
    const existingItem =
      tickettypes &&
      tickettypes.find(
        (item) => item.name === params.name && item.id !== params.id
      );
    if (existingItem) {
      setopenErrorDuplicateModal(true);
    } else {
      if (id) {
        saveTicketTypeById(apiUri, "en", id, params)
          .then((response) => {
            if (response) {
              handleClose();
              if (getDataCallback) {
                getDataCallback();
              }
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setOpenErrorModal(true);
            if (isCustomError(error)) {
              const apiError = error as ApiErrorResponse;
              setErrorDesc(apiError.issue[0].diagnostics);
            } else {
              setErrorDesc(error.id);
            }
          });
      } else {
        saveTicketType(apiUri, "en", params)
          .then((response) => {
            if (response) {
              const newState = {
                toggle: false,
                id: 0,
                numbers: [],
              };
              navigate("/master-data-management", {
                state: {
                  newState,
                },
              });
              handleClose();
              if (getDataCallback) {
                setTimeout(() => {
                  getDataCallback();
                }, 1000);
              }
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setOpenErrorModal(true);
            if (isCustomError(error)) {
              const apiError = error as ApiErrorResponse;
              setErrorDesc(apiError.issue[0].diagnostics);
            } else {
              setErrorDesc(error.id);
            }
          });
      }
    }
  };
  return (
    <>
      {openErrorDuplicateModal && (
        <ErrorModal
          descriptionText={"TicketType already exists"}
          open={openErrorDuplicateModal}
          handleClose={() => {
            setopenErrorDuplicateModal(false);
          }}
          onPositiveClick={() => {
            setopenErrorDuplicateModal(false);
          }}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={id ? "tickettype.update" : "tickettype.add"}
                  defaultLabel="Add New Ticket Type"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers>
                <Grid container direction={"column"}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId={"tickettype.name"}
                      defaultLabel="Ticket Type Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps} pt={2}>
                    <MediumTypography
                      className="input-label"
                      labelId={"tickettype.description"}
                      defaultLabel="tickettype.description"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.description}
                      handlechange={(value: string) => {
                        setFieldValue("description", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="description"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditTicketTypeModal;
