import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso, TableComponents } from "react-virtuoso";

import {
  Avatar,
  Box,
  IconButton,
  Popover,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

import { HeadCell } from "../MasterDataManagement/types/accountTypes";
import useAvailableWidth from "../../utils/useAvailableWidth";
import useAvailableHeight from "../../utils/useAvailableHeight";
import MediumTypography from "../../components/formlib/MediumTypography";
import { ReactComponent as Sort } from "../../assets/images/Sort.svg";
import SearchBox from "../../components/formlib/SearchBox";
import { useContext, useEffect, useState } from "react";
import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../MasterDataManagement/TableUtility";
import { ReactComponent as Filter } from "../../assets/images/filter.svg";
import { visuallyHidden } from "@mui/utils";
import { OnboardingDataType, OnboardingListType } from "./wizrads.types";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { hasPermission } from "../../utils/checkPermission";
import { ReactComponent as More } from "../../assets/images/ThreeDots.svg";
import dayjs from "dayjs";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { useNavigate } from "react-router-dom";
import AccountModal from "./AccountModal";
import { handleError } from "../../utils/commonFunctions";
import { fetchAllWizardsDataApi } from "../../api/wizardsApi/Common";
import {
  WIZARDS_SITE_DETAILS,
  WIZARDS_WELCOME_SCREEN,
} from "../../routes/Routing";

const staticColumn = "code";

const DataFields: HeadCell[] = [
  {
    key: "code",
    value: "Code",
    labelId: "Asset.Code",
  },
  {
    key: "name",
    value: "Sub Group",
    labelId: "Onboarding.subgroup",
  },
  {
    key: "dcCapacity",
    value: "DC Capacity",
    labelId: "assetGroupWizards.dcCapacity",
  },
  {
    key: "status",
    value: "Status",
    labelId: "User.Status",
  },
  {
    key: "account",
    value: "Account",
    labelId: "MasterDataManagement.Accounts",
  },
  {
    key: "onboardingStageName",
    value: "Stage",
    labelId: "Onboarding.StageName",
  },
  {
    key: "createdDatetime",
    value: "Start Date",
    labelId: "W/O.startDate",
  },
  {
    key: "onboardingEndDate",
    value: "End Date",
    labelId: "W/O.endDate",
  },
  {
    key: "createdBy",
    value: "Owner",
    labelId: "Onboarding.owner",
  },
  {
    key: "lastUpdated",
    value: "Last Updated By ",
    labelId: "LastUpdatedByLabelText",
  },
];

interface FixedHeaderProps {
  dataFieldsWithIcons: HeadCell[];
  staticColumn: string;
  sortDirection: Record<string, "asc" | "desc" | undefined>;
  handleSort: (column: HeadCell) => void;
  handleSearchIconClick: (
    event: React.MouseEvent<HTMLElement>,
    key: string
  ) => void;
  handleSearchClose: () => void;
  handleSearchChange: (value: string, key: string) => void;
  handleCancel: (key: string) => void;
  anchorE1Search: HTMLElement | null;
  activeCellKey: string | null;
  searchableKeys: string[];
  searchValue: Record<string, string>;
}

const renderFixedHeader = ({
  dataFieldsWithIcons,
  staticColumn,
  sortDirection,
  handleSort,
  handleSearchIconClick,
  handleSearchClose,
  handleSearchChange,
  handleCancel,
  anchorE1Search,
  activeCellKey,
  searchableKeys,
  searchValue,
}: FixedHeaderProps) => {
  return (
    <TableRow>
      <TableCell
        align="left"
        sx={{
          verticalAlign: "top",
          backgroundColor: "#22272B",
          border: "1px solid #1D2125",
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 2,
        }}
        className="p-md"
        style={{ width: "100px" }}
      >
        <MediumTypography
          sxProps={{
            fontWeight: 700,
            whiteSpace: "nowrap",
          }}
          labelId="srNo"
          defaultLabel="Sr. No."
        />
      </TableCell>
      {dataFieldsWithIcons.map((column) => (
        <React.Fragment key={column.key}>
          <TableCell
            variant="head"
            style={{ width: column.key === staticColumn ? "150px" : "250px" }}
            sx={{
              verticalAlign: "top",
              backgroundColor: "#22272B",
              position:
                column.key === staticColumn || column.key === "name"
                  ? "sticky"
                  : "relative",
              top: 0,
              left:
                column.key === staticColumn
                  ? 100
                  : column.key === "name"
                  ? 250
                  : undefined,
              zIndex:
                column.key === staticColumn || column.key === "name" ? 2 : 1,
              border: "1px solid #1D2125",
            }}
            className="p-md"
          >
            <Box className="flex__" sx={{ alignItems: "center" }}>
              {column.key === "dcCapacity" ? (
                <Box
                  className="flex__ cursor__pointer"
                  onClick={() => handleSort(column)}
                >
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      whiteSpace: "nowrap",
                    }}
                    label={`${column.value}`}
                    className="mr-sm"
                  />
                  <MediumTypography
                    sxProps={{
                      fontWeight: 600,
                      fontSize: "12px",
                      color: "#9FADBC",
                      whiteSpace: "nowrap",
                    }}
                    labelId={"dcCapacityUnits"}
                    defaultLabel="( MWp )"
                    className="mt-xs"
                  />
                </Box>
              ) : (
                <MediumTypography
                  sxProps={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  label={column.value}
                  onClick={() => handleSort(column)}
                />
              )}

              <TableSortLabel
                active={sortDirection[column.key] !== undefined}
                direction={sortDirection[column.key] || "asc"}
                onClick={() => handleSort(column)}
                sx={{
                  cursor: "pointer",
                  "&.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
                    {
                      opacity: 1,
                      color: "#FFFFFF",
                    },
                  "&.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                    opacity: 1,
                    color: "#B0B0B0",
                  },
                }}
              >
                {sortDirection[column.key] !== undefined && (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection[column.key] === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                )}
              </TableSortLabel>
              {searchValue[column.key] && <Filter className="ml-sm" />}
              {column.icon && (
                <Box
                  ml={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: searchableKeys.includes(column.key)
                      ? "pointer"
                      : "not-allowed",
                  }}
                  onClick={(event) => handleSearchIconClick(event, column.key)}
                >
                  {column.icon}
                </Box>
              )}
            </Box>
          </TableCell>
          {searchableKeys.includes(column.key) && (
            <Popover
              open={activeCellKey === column.key}
              anchorEl={anchorE1Search}
              onClose={handleSearchClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{
                left:
                  column.key === "acCapacity" ||
                  column.key === "dcCapacity" ||
                  column.key === "serialNo"
                    ? -150
                    : -90,
                top: 20,
              }}
            >
              <Box p={2} width="250px" sx={{ backgroundColor: "#323337" }}>
                <SearchBox
                  labelId="defaultSearch"
                  defaultlabel="Search"
                  backgroundColor="#22272B"
                  sxProps={{ minWidth: "180px" }}
                  value={searchValue[column.key] || ""}
                  onChange={(value: string) =>
                    handleSearchChange(value, column.key)
                  }
                  onCancel={() => handleCancel(column.key)}
                  cancelRequired={true}
                />
              </Box>
            </Popover>
          )}
        </React.Fragment>
      ))}
      <TableCell
        align="left"
        sx={{
          verticalAlign: "top",
          backgroundColor: "#22272B",
          padding: "8px 16px",
          border: " 1px solid #1D2125",
          position: "sticky",
          top: 0,
          right: 0,
          zIndex: 1,
        }}
        style={{ width: "50px" }}
      />
    </TableRow>
  );
};
const CustomEmptyPlaceholder = () => {
  const emptyTableHeight = useAvailableHeight(210);
  const availableWidth = useAvailableWidth(135);
  return (
    <Box
      sx={{
        height: emptyTableHeight,

        width: availableWidth,
      }}
      className="display-flex-center flex__justify__center "
    >
      <MediumTypography
        labelId="ManageAccounts.emptymsg"
        defaultLabel="No records available"
        fontSize="20px"
        fontWeight={700}
      />
    </Box>
  );
};
const VirtuosoTableComponents: TableComponents<OnboardingDataType> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => {
    const availableWidth = useAvailableWidth(130);
    const availableHeight = useAvailableHeight(200);

    return (
      <TableContainer
        component={Paper}
        {...props}
        ref={ref}
        sx={{
          width: availableWidth,
          height: availableHeight,
          position: "relative",
        }}
        className="tableContainer"
      />
    );
  }),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} sx={{ position: "relative" }} />
  )),
  TableRow,
  TableBody: React.forwardRef<HTMLTableSectionElement>(
    (props: React.HTMLAttributes<HTMLDivElement>, ref) => (
      <TableBody {...props} ref={ref} sx={{ position: "relative" }} />
    )
  ),
  EmptyPlaceholder: CustomEmptyPlaceholder,
};

const OnboardingListScreen = () => {
  const [rows, setRows] = React.useState<OnboardingDataType[]>([]);
  const [originalData, setOriginalData] = React.useState<OnboardingDataType[]>(
    []
  );
  const [listData, setListData] = React.useState<OnboardingListType | null>(
    null
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const availableHeight = useAvailableHeight(155);
  const [reorderedFields, setReorderedFields] = React.useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [activeFilters, setActiveFilters] = useState<{
    [key: string]: string;
  }>({});

  const [anchorE1Search, setAnchorE1Search] = useState<null | HTMLElement>(
    null
  );

  const [searchValue, setSearchValue] = useState<{ [key: string]: string }>({});
  const [activeCellKey, setActiveCellKey] = useState<string>("");
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);

  const navigate = useNavigate();

  const [sortDirection, setSortDirection] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});

  const handleSearchIconClick = (
    event: React.MouseEvent<HTMLElement>,
    columnKey: string
  ) => {
    setAnchorE1Search(event.currentTarget);
    setSearchValue((prev) => ({ ...prev, columnKey }));
    setActiveCellKey(columnKey);
  };

  const handleSearchClose = () => {
    setAnchorE1Search(null);
    setActiveCellKey("");
  };

  const handleSearchChange = (value: string, columnKey: string) => {
    setSearchValue((prev) => ({ ...prev, [columnKey]: value }));
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredRows = applySearchFilters(originalData, newFilters);
    setActiveFilters(newFilters);

    setRows(filteredRows as OnboardingDataType[]);
  };

  const handleCancel = (key: string) => {
    setSearchValue((prevValues) => ({
      ...prevValues,
      [key]: "",
    }));

    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalData,
      activeFilters,
      key
    );
    setActiveFilters(updatedFilters);
    setRows(filteredData as OnboardingDataType[]);
    handleSearchClose();
  };

  //sorting
  function handleSort(column: HeadCell) {
    const currentDirection = sortDirection[column.key];
    let newDirection: "asc" | "desc" | undefined;
    if (!currentDirection) {
      newDirection = "desc"; // First click should sort in descending order
    } else if (currentDirection === "desc") {
      newDirection = "asc"; // Second click should sort in ascending order
    } else if (currentDirection === "asc") {
      newDirection = undefined; // Third click should clear the sorting
    }
    setSortDirection({
      [column.key]: newDirection,
    });

    const sortedData = sortTableData(
      rows,
      column.key,
      newDirection ? newDirection : "",
      originalData
    );
    setRows(sortedData as OnboardingDataType[]);
  }

  const getData = async (
    resetData: boolean = false,
    language: string = "en",
    applyFilters: boolean = false
  ) => {
    const fullUri = `onboarding`;
    toggleLoader(true);

    fetchAllWizardsDataApi<OnboardingListType>(fullUri, language, "")
      .then((response) => {
        toggleLoader(false);
        if (response) {
          if (response.onboardingList.length === 0) {
            navigate(WIZARDS_WELCOME_SCREEN); // Navigate to welcome screen
          } else {
            setListData(response);

            if (resetData) {
              setRows(response.onboardingList);
              setOriginalData(response.onboardingList);
            } else {
              const updateTableData = (newRecords: OnboardingDataType[]) => {
                setRows((prevMessages) => [...prevMessages, ...newRecords]);
                setOriginalData((prevMessages) => [
                  ...prevMessages,
                  ...newRecords,
                ]);
              };

              // Use the helper function with response data
              updateTableData(response.onboardingList);
            }

            setReorderedFields(DataFields);

            if (applyFilters && Object.keys(activeFilters).length > 0) {
              const filteredData = applySearchFilters(
                response.onboardingList,
                activeFilters
              );
              setRows(filteredData as OnboardingDataType[]);
            }
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    setActiveFilters({});
  };

  useEffect(() => {
    getData(true);
  }, []);

  const dataFieldsWithIcons =
    reorderedFields &&
    reorderedFields.map((field) => {
      return { ...field, icon: <Sort /> };
    });

  const searchableKeys = [
    "name",
    "code",
    "status",
    "account",
    "dcCapacity",
    "onboardingStageName",
    "createdDatetime",
    "onboardingEndDate",
    "createdBy",
    "lastUpdated",
  ];
  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      {accountModalOpen && (
        <AccountModal
          open={accountModalOpen}
          handleClose={() => setAccountModalOpen(false)}
        />
      )}

      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {listData && (
        <Box className="p-lg">
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId="Onboarding.Title"
              defaultLabel="Onboarding"
              textColor="#FFF"
              fontSize="20px"
              fontWeight={700}
            />
            <Box className="flex__" alignItems="center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData?.totalRecords || 0
                    : `${rows.length} / ${listData?.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />

              <ButtonComponent
                className="btn-primary btn-submit ml-md"
                variantType="contained"
                labelId="BeginText"
                defaultLabelId="Onboard a new site"
                onClick={() => setAccountModalOpen(true)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              height: availableHeight,
              overflowY: "auto",
              position: "relative",
            }}
          >
            <TableVirtuoso
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() =>
                renderFixedHeader({
                  dataFieldsWithIcons,
                  staticColumn,
                  sortDirection,
                  handleSort,
                  handleSearchIconClick,
                  handleSearchClose,
                  handleSearchChange,
                  handleCancel,
                  anchorE1Search,
                  activeCellKey,
                  searchableKeys,
                  searchValue,
                })
              }
              itemContent={(rowIndex, row) => {
                return (
                  <>
                    {/* Sticky First Column */}
                    <TableCell
                      align={"left"}
                      sx={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        border: "1px solid #1D2125",
                      }}
                      style={{ width: "100px" }}
                      className="stickyColumn"
                    >
                      <MediumTypography label={(rowIndex + 1).toString()} />
                    </TableCell>

                    {dataFieldsWithIcons.map((cell: HeadCell) => {
                      const cellValue =
                        row[cell.key as keyof OnboardingDataType];

                      if (cell.key === "name") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              border: "1px solid #1D2125",
                              position: "sticky",
                              top: 0,
                              left: 250,
                              zIndex: 1,
                            }}
                            className="p-md cursor__pointer"
                            style={{ width: "250px" }}
                            onClick={() => {
                              if (
                                cellValue !== undefined &&
                                cellValue !== null &&
                                cellValue !== "" &&
                                hasPermission("Onboarding_Admin")
                              ) {
                                localStorage.setItem(
                                  "AccId",
                                  row.selectedAccountId.toString() as string
                                );
                                navigate(WIZARDS_SITE_DETAILS, {
                                  state: {
                                    accountId: row.selectedAccountId,
                                    siteId: row.selectedSiteId,
                                    subId: row.id,
                                  },
                                });
                              } else {
                                return;
                              }
                            }}
                          >
                            <Tooltip
                              title={
                                cellValue !== undefined &&
                                cellValue !== null &&
                                cellValue !== "" &&
                                cellValue.toString().length > 25
                                  ? cellValue.toString()
                                  : ""
                              }
                              followCursor
                              arrow
                            >
                              <Box>
                                <MediumTypography
                                  label={
                                    cellValue !== undefined &&
                                    cellValue !== null &&
                                    cellValue !== ""
                                      ? cellValue.toString().length > 25
                                        ? `${cellValue
                                            .toString()
                                            .substring(0, 25)}...`
                                        : cellValue.toString()
                                      : "-"
                                  }
                                  textColor="#25bafa"
                                />
                              </Box>
                            </Tooltip>
                          </TableCell>
                        );
                      }

                      if (cell.key === "lastUpdated") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              border: "1px solid #1D2125",
                            }}
                            className="p-md"
                            style={{ width: "250px" }}
                          >
                            <Box className="display-flex-center">
                              <Avatar
                                src={
                                  row.lastUpdated !== null &&
                                  row.lastUpdated !== undefined
                                    ? row.lastUpdated.imageUrl
                                    : "-"
                                }
                                alt="abc"
                                className="avatar-style"
                              />
                              <Box className="ml-sm">
                                <MediumTypography
                                  label={
                                    row.lastUpdated !== null &&
                                    row.lastUpdated !== undefined
                                      ? row.lastUpdated.name
                                      : "-"
                                  }
                                  sxProps={{
                                    lineHeight: "normal",
                                  }}
                                  className="mt-xs"
                                />

                                <MediumTypography
                                  label={
                                    row.lastUpdated !== null &&
                                    row.lastUpdated !== undefined
                                      ? dayjs(row.lastUpdated.timestamp).format(
                                          "DD MMM YYYY HH:mm"
                                        )
                                      : "-"
                                  }
                                  fontSize="12px"
                                  textColor={"rgba(255, 255, 255, 0.64)"}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      }
                      if (cell.key === "createdDatetime") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              border: "1px solid #1D2125",
                            }}
                            className="p-md"
                            style={{ width: "250px" }}
                          >
                            <MediumTypography
                              label={
                                row.createdDatetime !== null &&
                                row.createdDatetime !== undefined
                                  ? dayjs(row.createdDatetime).format(
                                      "DD MMM YYYY HH:mm"
                                    )
                                  : "-"
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (cell.key === "onboardingEndDate") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              border: "1px solid #1D2125",
                            }}
                            className="p-md"
                            style={{ width: "250px" }}
                          >
                            <MediumTypography
                              label={
                                row.onboardingEndDate !== null &&
                                row.onboardingEndDate !== undefined
                                  ? dayjs(row.onboardingEndDate).format(
                                      "DD MMM YYYY HH:mm"
                                    )
                                  : "-"
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (cell.key === "createdBy") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              border: "1px solid #1D2125",
                            }}
                            className="p-md"
                            style={{ width: "250px" }}
                          >
                            <Box className="display-flex-center">
                              <Avatar
                                src={
                                  row.createdBy !== null &&
                                  row.createdBy !== undefined
                                    ? row.createdBy.imageUrl
                                    : "-"
                                }
                                alt="abc"
                                className="avatar-style"
                              />
                              <Box className="ml-sm">
                                <MediumTypography
                                  label={
                                    row.createdBy !== null &&
                                    row.createdBy !== undefined
                                      ? row.createdBy.name
                                      : "-"
                                  }
                                  sxProps={{
                                    lineHeight: "normal",
                                  }}
                                  className="mt-xs"
                                />

                                <MediumTypography
                                  label={
                                    row.createdBy !== null &&
                                    row.createdBy !== undefined
                                      ? dayjs(row.createdBy.timestamp).format(
                                          "DD MMM YYYY HH:mm"
                                        )
                                      : ""
                                  }
                                  fontSize="12px"
                                  textColor={"rgba(255, 255, 255, 0.64)"}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      }
                      if (cell.key === "status") {
                        return (
                          <TableCell
                            key={cell.key}
                            align={"left"}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                              border: "1px solid #1D2125",
                            }}
                            className="p-md"
                            style={{ width: "250px" }}
                          >
                            <Box className="display-flex-center">
                              <Box
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    row.statusCode === "ACTIVE"
                                      ? "rgba(42, 187, 127, 1)"
                                      : "rgba(129, 145, 164, 1)",
                                  border:
                                    row.statusCode === "ACTIVE"
                                      ? "3px solid rgba(42, 187, 127, 0.32)"
                                      : "3px solid rgba(129, 145, 164, 0.32)",
                                }}
                              />
                              <Box className="ml-sm">
                                <MediumTypography
                                  label={
                                    row.status !== null &&
                                    row.status !== undefined
                                      ? row.status
                                      : "-"
                                  }
                                  sxProps={{
                                    lineHeight: "normal",
                                  }}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      }

                      return (
                        <>
                          {cell.key === staticColumn ? (
                            <TableCell
                              key={cell.key}
                              align={"left"}
                              sx={{
                                backgroundColor:
                                  rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                border: "1px solid #1D2125",
                                position: "sticky",
                                top: 0,
                                left: 100,
                                zIndex: 1,
                              }}
                              className="p-md cursor__pointer"
                              style={{ width: "150px" }}
                              onClick={() => {
                                if (
                                  cellValue !== undefined &&
                                  cellValue !== null &&
                                  cellValue !== "" &&
                                  hasPermission("Onboarding_Admin")
                                ) {
                                  navigate(WIZARDS_SITE_DETAILS, {
                                    state: {
                                      accountId: row.selectedAccountId,
                                      siteId: row.selectedSiteId,
                                      subId: row.id,
                                    },
                                  });
                                } else {
                                  return;
                                }
                              }}
                            >
                              <MediumTypography
                                label={
                                  cellValue !== undefined &&
                                  cellValue !== null &&
                                  cellValue !== ""
                                    ? cellValue.toString()
                                    : "-"
                                }
                                textColor="#25bafa"
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              key={cell.key}
                              align={"left"}
                              sx={{
                                backgroundColor:
                                  rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                border: "1px solid #1D2125",
                              }}
                              className="p-md"
                              style={{ width: "250px" }}
                            >
                              <MediumTypography
                                label={
                                  cellValue !== undefined &&
                                  cellValue !== null &&
                                  cellValue !== ""
                                    ? cellValue.toString()
                                    : "-"
                                }
                              />
                            </TableCell>
                          )}
                        </>
                      );
                    })}

                    {/* Sticky Last Column */}
                    <TableCell
                      align={"left"}
                      sx={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        border: "1px solid #1D2125",
                      }}
                      className="stickyColumn_right"
                      style={{ width: "50px" }}
                    >
                      <Tooltip title="More">
                        <IconButton>
                          <More />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </>
                );
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OnboardingListScreen;
