import React, { useState, FC, useEffect } from "react";
import { Box, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import "../css/styles.css";

interface RadioProps {
  value: string;
  getSelectedValue: (value: number) => void;
  defaultOptionFromApi: number;
}
const RadioPickList: FC<RadioProps> = ({
  value,
  getSelectedValue,
  defaultOptionFromApi,
}) => {
  const result = value
    .trim()
    .split(",")
    .filter((item) => item !== "")
    .map((item, index) => ({
      id: index + 1,
      name: item.trim(),
    }));

  const final_result = result.sort((a, b) => a.name.localeCompare(b.name));

  const [selectedValue, setSelectedValue] = useState<number>();

  useEffect(() => {
    if (defaultOptionFromApi === undefined) {
      getSelectedValue(final_result[0].id);
      setSelectedValue(final_result[0].id);
    } else {
      setSelectedValue(defaultOptionFromApi);
      getSelectedValue(defaultOptionFromApi);
    }
  }, [defaultOptionFromApi]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = final_result.find((item) => item.name === event.target.value);
    if (item) {
      setSelectedValue(item.id);
      getSelectedValue(item.id);
    }
  };
  return (
    <Box className="standard-radio-div custom-radio-div multiline-radio">
      <RadioGroup onChange={handleChange}>
        {final_result.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.name}
            control={<Radio checked={selectedValue === item.id} />}
            label={<MediumTypography labelId={item.name} />}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioPickList;
