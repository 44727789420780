import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "./PhoneNumberInputStyle.css";
import { useIntl } from "react-intl";
import { boolean } from "yup";
import { Box, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface PhoneNumberInputProps {
  labelId?: string;
  defaultLabelId?: string;
  inline?: boolean;
  inlineValue?: string;
  onInlineChange?: (value: string) => void;
  [key: string]: any;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  labelId,
  defaultLabelId,
  inline = false,
  inlineValue = "",
  onInlineChange = () => {},
  ...props
}) => {
  const intl = useIntl();
  const [phone, setPhone] = useState<string>(inlineValue);
  const [isFocused, setIsFocused] = useState(false);
  const clickedButtonRef = useRef<"icon" | null>(null);
  const textInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setPhone(inlineValue);
  }, [inlineValue]);

  const triggerBlur = () => {
    if (textInputRef?.current) {
      textInputRef.current.blur();
    }
  };

  const handleSave = () => {
    clickedButtonRef.current = "icon";
    if (phone !== inlineValue) {
      onInlineChange(phone);
    }
    triggerBlur();
  };

  const handleAutoSave = () => {
    if (phone !== inlineValue) {
      onInlineChange(phone);
    }
  };

  const handleCancel = () => {
    clickedButtonRef.current = "icon";
    setPhone(inlineValue);
    triggerBlur();
  };

  const inputComponent = (
    <PhoneInput
      value={phone}
      onChange={(value) => {
        setPhone(value);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        if (clickedButtonRef.current === null) {
          handleAutoSave();
        }

        clickedButtonRef.current = null;
        setIsFocused(false);
      }}
      inputProps={{
        ref: textInputRef, // Attach ref to the input element using inputProps
      }}
      country={"in"}
      placeholder={
        labelId &&
        intl.formatMessage({
          id: labelId,
          defaultMessage: defaultLabelId,
        })
      }
      containerClass="phone-input-container"
      containerStyle={{
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 600,
        color: "rgba(140, 155, 171, 1)",
      }}
      inputStyle={{
        backgroundColor: inline ? "transparent" : "rgba(34, 39, 43, 1)",
        border: "1px solid rgba(166, 197, 226, 0.16)",
        color: "rgba(140, 155, 171, 1)",
        width: "100%",
        height: "40px",
      }}
      buttonStyle={{
        backgroundColor: inline ? "transparent" : "rgba(34, 39, 43, 1)",
        border: "1px solid rgba(166, 197, 226, 0.16)",
      }}
      dropdownStyle={{
        backgroundColor: "rgba(34, 39, 43, 1)",
      }}
      searchStyle={{
        backgroundColor: "rgba(34, 39, 43, 1)",
      }}
      {...props}
    />
  );

  if (!inline) return inputComponent;

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {inputComponent}
      {isFocused && (
        <Box
          sx={{
            position: "absolute",
            top: "48px",
            right: 0,
            display: "flex",
            gap: 1,
            zIndex: 2,
          }}
        >
          <IconButton
            onClick={handleSave}
            onMouseDown={(event) => {
              // Prevent the default blur on mouse down
              event.preventDefault();
            }}
            size="small"
            sx={{
              color: "#9fadbc",
              backgroundColor: "rgb(26, 30, 34)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
              borderRadius: "4px", // Rounded corners
              "&:hover": {
                backgroundColor: "rgb(23, 25, 28)", // Slightly darker on hover
              },
            }}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={handleCancel}
            onMouseDown={(event) => {
              // Prevent the default blur on mouse down
              event.preventDefault();
            }}
            size="small"
            sx={{
              color: "#9fadbc",
              backgroundColor: "rgb(26, 30, 34)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
              borderRadius: "4px", // Rounded corners
              "&:hover": {
                backgroundColor: "rgb(23, 25, 28)", // Slightly darker on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default PhoneNumberInput;
