import { Avatar, Box } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { User } from "../types/CreateTicket";
import dayjs from "dayjs";
import HtmlContent from "./HtmlContent";
interface CommentsProps {
  attachments?: any;
  createdDatetime: string;
  id: number;
  mailSent: boolean;
  messageType: string;
  modifiedBy: string;
  to?: string[];
  conversation: string;
  cc: string[];
  bcc: string[];
  from: number | null;
  fromEmail: string | null;
}
const Comments = ({
  comments,
  users,
}: {
  comments: CommentsProps[];
  users: User[];
}) => {
  const getUserDetails = (userId: number) => {
    const userDetails = users.find((user: User) => user.id === userId); // Use 'find' for a single user
    return userDetails;
  };

  return (
    <Box className="common-flex-column" gap={3}>
      {comments.map((commentsListItem, index) => {
        if (index === comments.length - 1) return null;

        let commentAction;
        switch (commentsListItem.messageType) {
          case "REPLY":
            commentAction = "replied";
            break;
          case "FORWARD":
            commentAction = "forwarded";
            break;
          default:
            commentAction = "commented";
        }

        const fromUser = getUserDetails(commentsListItem.from ?? 0);

        return (
          <Box key={index} display={"flex"} gap={2}>
            <Avatar
              src={fromUser?.image}
              alt="Profile Photo"
              className="avatar-style"
            />

            <Box className="common-flex-column" gap={"12px"}>
              <Box>
                <Box display={"flex"} gap={"4px"}>
                  <MediumTypography
                    label={
                      fromUser?.name ?? commentsListItem?.fromEmail ?? "Unknown"
                    }
                    className="ticket-white-header"
                  />
                  <MediumTypography
                    labelId={`commnets.${commentAction}`}
                    defaultLabel={`commentAction`}
                    className="ticket-grey-text"
                  />
                </Box>

                <MediumTypography
                  label={dayjs(commentsListItem.createdDatetime).format(
                    "MMM D, YYYY [at] h:mm A"
                  )}
                  className="ticket-grey-mini-text"
                />
              </Box>
              <HtmlContent className="ticket-html-text">
                {commentsListItem.conversation}
              </HtmlContent>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default Comments;
