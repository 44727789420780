import { Menu, MenuProps, styled, Theme } from "@mui/material";

const CustomMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 300,
    padding: "0px 0px",
    borderWidth: "0 10px 10px 10px",
    boxShadow: "none",
    backgroundColor: "#22272B",
    border: "1px solid rgba(166, 197, 226, 0.16)",
  },
  "& .MuiPaper-root.MuiPaper-elevation > ul": {
    paddingBottom: "0px",
  },
  "& .MuiPaper-root::-webkit-scrollbar": {
    display: "none",
  },
}));

export default CustomMenu;
