import React, { FC } from "react";
import customTypeJSon from "../staticData/customTypes.json";
import { Box, Grid } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import DisplayFieldIcon from "../components/DisplayFieldIcon";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import "../css/styles.css";
interface CreateFieldCustomProps {
  onAdd: (type: string, name: string) => void;
}

const CreateFieldCustom: FC<CreateFieldCustomProps> = ({ onAdd }) => {
  const availableHeight = useAvailableHeight(300);
  const customTypeJSonArray = customTypeJSon;

  return (
    <>
      {customTypeJSonArray.map((field, index) => (
        <Grid item xs={12} sm={12} md={12} lg={6} key={field.code}>
          <Box
            className="custom_field_inner_Box"
            onClick={() => {
              onAdd(field.type, field.name);
            }}
          >
            <Box className="icon_box">
              <DisplayFieldIcon type={field.type} />
            </Box>
            <Box className="custom_typography">
              <MediumTypography label={field.name} />
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
};
export default CreateFieldCustom;
