import { Box, Button, Tooltip } from "@mui/material";
import TextInput from "./TextInput";
import MediumTypography from "./MediumTypography";
import { FC } from "react";
import "../../../src/index.css";
import { useIntl } from "react-intl";

interface InputMap {
  disabled?: boolean;
  values?: string;
  fieldname?: string;
  fieldValue: string[];
  buttonCallBackClick?: () => void;
}

const TextInputMap: FC<InputMap> = ({
  values,
  fieldname,
  fieldValue,
  buttonCallBackClick,
  disabled,
}) => {
  const intl = useIntl();
  const displayValues = (array: string[]) => {
    if (array.length === 0) {
      return "";
    }

    const firstValue =
      array[0].length > 35 ? `${array[0].substring(0, 35)}...` : array[0];
    const remainingCount = array.length - 1;

    if (remainingCount > 0) {
      return `${firstValue} +${remainingCount}`;
    } else {
      return firstValue;
    }
  };

  return (
    <>
      <Box className="main-box">
        <Tooltip title={fieldValue.toString()} followCursor arrow>
          <Box sx={{ width: "100%" }}>
            <TextInput
              className="text-input-field"
              type="text"
              variant="outlined"
              inputProps={{
                readOnly: false,
              }}
              disabled={true}
              labelId="common.placeHolderText"
              defaultLabelId="common.placeHolderText"
              Value={
                disabled
                  ? `${intl.formatMessage({
                      id: "commonText.All",
                      defaultMessage: "ALL",
                    })}`
                  : displayValues(fieldValue)
              }
            />
          </Box>
        </Tooltip>
        {/* <ButtonComponent
          labelId="btn.Map"
          defaultLabelId="Map"
          onClick={buttonCallBackClick}
        /> */}
        {!disabled && (
          <Button className="map-btn" onClick={buttonCallBackClick}>
            <MediumTypography
              labelId={"btn.Map"}
              defaultLabel="Map"
              textColor="#FFFFFF"
              sxProps={{
                fontSize: "12px",
                textAlign: "center",
                paddingBottom: "4px ",
                textTransform: "none",
              }}
            />
          </Button>
        )}
      </Box>
    </>
  );
};

export default TextInputMap;
