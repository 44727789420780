import { Box, IconButton, Tooltip } from "@mui/material";
import { ReactComponent as DocumentCode } from "../../../assets/images/document-code.svg";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as Send } from "../../../assets/images/send_download.svg";
import { ConversationAttachmentType, FileDataType } from "../types/Types";
import FileUpload from "./UploadFileDragDrop";
import { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { saveConversation } from "../../../api/ticketing/TicketDetails";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import dayjs from "dayjs";
import { getProcessedFiles } from "../../../utils/commonFunctions";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { hasTicketEditPermission } from "../helpers/helper";

function truncateFileName(fileName: string, maxLength = 20) {
  if (!fileName || typeof fileName !== "string") {
    // Handle empty or invalid input
    return { truncatedText: "", isTruncated: false };
  }

  if (fileName.length <= maxLength) {
    return { truncatedText: fileName, isTruncated: false };
  }

  const [name, extension = ""] = fileName.split(/(?=\.\w+$)/); // Handle cases without extensions
  const lastTwo = name.slice(-2); // Get the last two characters from the main name

  // Calculate how much of the name can be shown
  const visibleLength = maxLength - extension.length - 5; // Account for "..." + last two + extension
  let finalText = fileName;

  // Check if truncation is needed
  const isTruncated = fileName.length > maxLength;
  if (isTruncated) {
    finalText = name.slice(0, visibleLength) + "..." + lastTwo + extension;
  }

  return { truncatedText: finalText, isTruncated };
}

interface Props {
  attachment: ConversationAttachmentType[];
  afterSendCallback?: () => void;
  ticketId: number;
}
interface AttachmentDataType {
  attachments: FileDataType[];
}
const Attachements = ({
  attachment,
  ticketId,
  afterSendCallback = () => {},
}: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

  const filterImageFiles = (files: ConversationAttachmentType[]) => {
    return files.filter((file) => {
      const extension = file.fileName.split(".").pop()?.toLowerCase() ?? "";
      return !imageExtensions.includes(extension);
    });
  };

  const handleDownload = async (fileUrl: string) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileUrl.split("/").pop() ?? "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const addAttachment = (params: AttachmentDataType) => {
    toggleLoader(true);

    const url = `ticket/${ticketId}/addConversation?messageType=ATTACHMENT`;

    saveConversation(url, params)
      .then((response) => {
        setFiles([]);
        afterSendCallback();
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  const filteredFiles = filterImageFiles(attachment);

  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {filteredFiles?.map((attachmentVal, index) => {
        const { truncatedText, isTruncated } = truncateFileName(
          attachmentVal.fileName,
          19
        );

        let truncatedItem = (
          <MediumTypography
            label={truncatedText}
            sxProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "14px",
              fontWeight: "600",
              color: "#9fadbc",
            }}
          />
        );

        if (isTruncated) {
          truncatedItem = (
            <Tooltip
              title={
                <MediumTypography
                  label={attachmentVal.fileName ?? ""}
                  className="table-cell-value"
                />
              }
              arrow
              placement="top"
            >
              <Box>{truncatedItem}</Box>
            </Tooltip>
          );
        }

        return (
          <Box key={index} className="common-flex-column" gap={1}>
            <Box className="download-icon">
              <Box sx={{ width: "220px" }}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={1}
                  mb={"4px"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <DocumentCode />

                    <Box sx={{ maxWidth: "164px" }}>{truncatedItem}</Box>
                  </Box>

                  <Box>
                    <IconButton
                      onClick={() => handleDownload(attachmentVal.fileUrl)}
                      style={{ cursor: "pointer", padding: 0 }}
                    >
                      <Send />
                    </IconButton>
                  </Box>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <MediumTypography
                    label={dayjs(attachmentVal.createdDateTime).format(
                      "MMM D, YYYY [at] h:mm A"
                    )}
                    sxProps={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      color: "#9fadbc99",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
      {hasTicketEditPermission() && (
        <Box className="common-flex-column">
          <FileUpload
            viewType="BOX"
            width="74px"
            height="74px"
            files={files}
            onFilesChange={(files) => {
              setFiles(files);
            }}
          />
          {files.length > 0 && (
            <ButtonComponent
              className="btn-primary btn-ticket"
              variantType="contained"
              defaultLabelId={"Upload"}
              labelId={"Rca.Upload"}
              onClick={async () => {
                try {
                  const processedFiles = await getProcessedFiles(files);
                  addAttachment({
                    attachments: processedFiles,
                  });
                } catch (error) {
                  console.error("Unable to process files:", error);
                  setErrorDesc("Unable to process files");
                  setOpenErrorModal(true);
                }
              }}
            />
          )}
        </Box>
      )}
      {!hasTicketEditPermission() && filteredFiles?.length === 0 && (
        <Box
          sx={{
            bgcolor: "rgb(34, 39, 43)",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          <MediumTypography
            labelId={"No attachments found"}
            defaultLabel={"No attachments found"}
            textColor="#9fadbc80;"
          />
        </Box>
      )}
    </Box>
  );
};

export default Attachements;
