import { FC, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import SearchBox from "../../../components/formlib/SearchBox";
import CustomMenu from "./CustomMenu";
import { NumericOptionType } from "../types/Types";

interface TableHeaderProps {
  selectedFilter?: number | null;
  onFilterChange?: (value: number) => void;
  options: NumericOptionType[];
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  open: boolean;
  onCreateClick?: () => void;
  onSpecialClick?: () => void;
}

const FilterDropdown: FC<TableHeaderProps> = ({
  selectedFilter = null,
  onFilterChange = (value: number) => {},
  options,
  anchorEl,
  handleClose,
  open,
  onCreateClick = () => {},
  onSpecialClick = () => {},
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleFilterClick = (id: number) => {
    onFilterChange(id);
  };

  return (
    <CustomMenu
      id="demo-customized-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <Box className="filter-dropdown">
        {/* Search Field */}
        <Box pl={1} pr={1}>
          <SearchBox
            labelId="defaultSearch"
            defaultlabel="Search"
            backgroundColor="#22272B"
            sxProps={{
              minWidth: "unset",
              width: "100%",
            }}
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Box>

        {/* List of Filters */}
        {options?.length > 0 && (
          <Box sx={{ maxHeight: "230px", overflowY: "auto" }}>
            <List dense>
              {options
                .filter((filter) =>
                  filter.name.toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((filter) => (
                  <ListItem key={filter.id} disablePadding>
                    <ListItemButton
                      onClick={() => handleFilterClick(filter.id)}
                      selected={selectedFilter === filter.id}
                      className="filter-list-item"
                    >
                      <ListItemText
                        primary={<MediumTypography label={filter.name} />}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        )}
        {options?.length === 0 && (
          <MediumTypography
            labelId="filter.noSaved"
            defaultLabel={"No saved filters available."}
            className="no-filters-text"
          />
        )}

        <Divider />

        {/* Create Filter Button */}
        <MenuItem
          onClick={() => onCreateClick()}
          className="ticket-link ticket-link-slim"
        >
          <MediumTypography
            labelId="filter.createCustom"
            defaultLabel="Create Custom Filter"
            className=""
          />
        </MenuItem>

        <Divider />

        {/* Another Button */}
        <MenuItem
          onClick={() => onSpecialClick()}
          className="ticket-link ticket-link-slim"
        >
          <MediumTypography
            labelId="filter.viewSpecial"
            defaultLabel="View Special Filters"
            className=""
          />
        </MenuItem>
      </Box>
    </CustomMenu>
  );
};

export default FilterDropdown;
