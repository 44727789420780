import React, { useEffect, useRef } from "react";
import {
  FilledInputProps,
  IconButton,
  InputBaseComponentProps,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import { useIntl } from "react-intl";
import { Box, SxProps } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface TextInputProps {
  name?: string;
  fieldheight?: string;
  Value?: string | string[] | null;
  handlechange?: (value: string) => void;
  handleClick?: () => void;
  type?: string;
  inputProps?: InputBaseComponentProps;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<InputProps>
    | Partial<OutlinedInputProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  onblur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onPasteText?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  variant?: "standard" | "outlined" | "filled";
  disabled?: boolean;
  Required?: boolean;
  labelId?: string;
  defaultLabelId?: string;
  multiline?: boolean;
  maxRows?: number;
  sxProps?: SxProps;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoFocus?: boolean;
  autoComplete?: string;
  borderColor?: string;
  backGroundColor?: string;
  textColor?: string;
  inline?: boolean;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    type,
    fieldheight,
    name,
    Value,
    handlechange,
    inputProps,
    InputProps,
    onblur,
    onFocus,
    onKeyUp,
    variant,
    disabled,
    Required,
    labelId,
    defaultLabelId,
    multiline,
    maxRows,
    sxProps,
    className,
    inputLabelProps,
    inputRef,
    autoFocus,
    onPasteText,
    autoComplete,
    onKeyPress,
    borderColor,
    backGroundColor,
    textColor,
    handleClick,
    inline = false,
  } = props;
  const textFieldStyle = {
    height: fieldheight,
  };
  const intl = useIntl();

  const [localValue, setLocalValue] = React.useState(Value);
  const [isFocused, setIsFocused] = React.useState(false);
  const clickedButtonRef = useRef<"icon" | null>(null);
  const textInputRef = inputRef || React.createRef<HTMLInputElement>();

  useEffect(() => {
    setLocalValue(Value);
  }, [Value]);

  let newPlaceholder = intl.formatMessage({
    id: "common.placeHolderText",
    defaultMessage: "--- type here ---",
  });

  if (inline && !isFocused) {
    newPlaceholder = intl.formatMessage({
      id: "inline.noTextPlaceholder",
      defaultMessage: "None",
    });
  } else if (labelId) {
    newPlaceholder = intl.formatMessage({
      id: labelId,
      defaultMessage: defaultLabelId,
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (inline) {
      setLocalValue(newValue.trimStart());
    } else if (handlechange !== undefined) {
      setLocalValue(newValue.trimStart());
      handlechange(newValue.trimStart());
    }
  };

  const triggerBlur = () => {
    if (textInputRef?.current) {
      textInputRef.current.blur();
    }
  };

  const handleSave = () => {
    clickedButtonRef.current = "icon";
    if (inline && handlechange && localValue !== Value) {
      handlechange((localValue as string) ?? "");
    }
    triggerBlur();
  };

  const handleAutoSave = () => {
    if (inline && handlechange && localValue !== Value) {
      handlechange((localValue as string) ?? "");
    }
  };

  const handleCancel = () => {
    clickedButtonRef.current = "icon";
    setLocalValue(Value);
    triggerBlur();
  };

  const textInputComponent = (
    <TextField
      sx={{
        width: "auto",
        "& .MuiInputBase-root": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          alignItems: "center",
          color: textColor ? textColor : "#FFFFFF",
          backgroundColor: backGroundColor ? backGroundColor : "#2C2C2E",
        },
        "& label.Mui-focused": {
          color: "#2C2C2E",
        },
        "& .MuiOutlinedInput-multiline": {
          borderColor: "#2C2C2E",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#2C2C2E",
          },
          height: "inherit",
          border: borderColor,
        },
        "& .MuiOutlinedInput-input": {
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
          },
          "&:-webkit-autofill": {
            height: "7px",
          },
        },
        ...sxProps,
      }}
      inputRef={textInputRef}
      autoFocus={autoFocus}
      name={name}
      className={className}
      autoComplete={autoComplete ?? "off"}
      variant={variant ?? "outlined"}
      value={localValue}
      placeholder={newPlaceholder}
      onChange={handleChange}
      style={textFieldStyle}
      type={type ?? "text"}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        if (clickedButtonRef.current === null) {
          handleAutoSave();
        }

        clickedButtonRef.current = null;
        setIsFocused(false);

        if (onblur) {
          onblur(event);
        }
      }}
      onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        if (onFocus) {
          onFocus(event);
        }
      }}
      onKeyUp={onKeyUp}
      disabled={disabled}
      required={Required}
      multiline={multiline}
      maxRows={maxRows ?? 1}
      inputProps={inputProps}
      InputProps={InputProps}
      InputLabelProps={inputLabelProps}
      onPaste={onPasteText}
      onKeyDown={onKeyPress}
      onClick={handleClick}
    />
  );

  if (!inline) return textInputComponent;

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {textInputComponent}
      {inline && isFocused && (
        <Box
          sx={{
            position: "absolute",
            top: fieldheight ? `calc(${fieldheight} + 8px)` : "48px",
            right: 0,
            display: "flex",
            gap: 1,
            zIndex: 2,
          }}
        >
          <IconButton
            onClick={handleSave}
            onMouseDown={(event) => {
              // Prevent the default blur on mouse down
              event.preventDefault();
            }}
            size="small"
            sx={{
              color: textColor ?? "#9fadbc",
              backgroundColor: backGroundColor ?? "rgb(26, 30, 34)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
              borderRadius: "4px", // Rounded corners
              "&:hover": {
                backgroundColor: "rgb(23, 25, 28)", // Slightly darker on hover
              },
            }}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={handleCancel}
            onMouseDown={(event) => {
              // Prevent the default blur on mouse down
              event.preventDefault();
            }}
            size="small"
            sx={{
              color: textColor ?? "#9fadbc",
              backgroundColor: backGroundColor ?? "rgb(26, 30, 34)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
              borderRadius: "4px", // Rounded corners
              "&:hover": {
                backgroundColor: "rgb(23, 25, 28)", // Slightly darker on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default TextInput;
