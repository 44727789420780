// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  padding: 0px 12px;
  border-radius: 45px;
  height: 32px;
  width: auto;
  min-width: 100px;
}

.icon {
  background-color: #323337;
  border-radius: 4px;
  color: #fff;
  width: 16px !important;
  height: 16px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/wizards/workOrderMaster/WoMaster.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".title {\n  padding: 0px 12px;\n  border-radius: 45px;\n  height: 32px;\n  width: auto;\n  min-width: 100px;\n}\n\n.icon {\n  background-color: #323337;\n  border-radius: 4px;\n  color: #fff;\n  width: 16px !important;\n  height: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
