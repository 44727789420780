import { Box, IconButton } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { useContext, useRef, useState } from "react";
import ActivityMessage from "./ActivityMessage";
import Comments from "./Comments";
import RichTextEditor from "../../workOrderDetailScreens/correctiveMaintenance/RichTextEditor";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "./UploadFileDragDrop";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { getProcessedFiles } from "../../../utils/commonFunctions";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { saveConversation } from "../../../api/ticketing/TicketDetails";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import { hasTicketEditPermission } from "../helpers/helper";

interface CommentDataType {
  mention: number[];
  conversation: string;
  messageType: string;
  attachments: File[];
}

const initialCommentData: CommentDataType = {
  mention: [],
  conversation: "",
  messageType: "COMMENT",
  attachments: [],
};

let validationSchema = Yup.object().shape({
  conversation: Yup.string().required("validation.messageRequired"),
});

const ActivityMenu = ({
  comments,
  users,
  ticketId,
  afterSendCallback = () => {},
}: {
  comments: any;
  users: any;
  ticketId: number;
  afterSendCallback?: () => void;
}) => {
  const activityMenu = [
    { id: 1, name: "Comments" },
    { id: 2, name: "History" },
  ];

  const [selectedButton, setSelectedButton] = useState<number>(1);
  const [addingComment, setAddingComment] = useState(false);
  const formikRef = useRef<FormikProps<CommentDataType>>(null);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const sendMessage = (params: any) => {
    toggleLoader(true);

    const url = `ticket/${ticketId}/addConversation?messageType=COMMENT`;

    saveConversation(url, params)
      .then((response) => {
        formikRef.current?.resetForm({ values: initialCommentData });
        setAddingComment(false);
        afterSendCallback();
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  return (
    <Box className="common-flex-column" mb={5}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box>
        <MediumTypography label="Activity" className="ticket-header" />
      </Box>

      <Box className="common-flex-row">
        <MediumTypography
          labelId="showText"
          defaultLabel="Show:"
          className="ticket-detail-text"
        />
        {activityMenu.map((item) => {
          return (
            <Box
              onClick={() => setSelectedButton(item.id)}
              key={item.id}
              className="action_button"
              sx={{
                backgroundColor:
                  selectedButton === item.id
                    ? "rgba(37, 186, 250, 0.1)"
                    : "rgba(159, 173, 188, 0.06)",
              }}
            >
              <MediumTypography
                label={item.name}
                className="ticket-detail-text"
                sxProps={{
                  color:
                    selectedButton === item.id
                      ? "rgba(37, 186, 250, 1) !important"
                      : "#9fadbc !important",
                }}
              />
            </Box>
          );
        })}
      </Box>

      {hasTicketEditPermission() && selectedButton === 1 && (
        <Box className="ticket-quill-container ticket-rich-text-box" mb={1}>
          {!addingComment && (
            <Box
              onClick={() => setAddingComment(true)}
              sx={{
                cursor: "pointer",
                bgcolor: "rgba(34, 39, 43, 1)",
                padding: "12px 16px",
                borderRadius: "0px 16px 16px 16px",
              }}
            >
              <MediumTypography
                labelId="ticket.addANote"
                defaultLabel="Add a note..."
                textColor="#4e5861"
              />
            </Box>
          )}
          {addingComment && (
            <Formik
              initialValues={initialCommentData}
              enableReinitialize
              validateOnChange
              innerRef={formikRef}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                try {
                  const processedFiles = await getProcessedFiles(
                    values.attachments
                  );
                  const updatedValues = {
                    ...values,
                    attachments: processedFiles,
                  };

                  sendMessage(updatedValues);
                } catch (error) {
                  console.error("Unable to process files:", error);
                  setErrorDesc("Unable to process files");
                  setOpenErrorModal(true);
                }
              }}
            >
              {({ values, setFieldValue, handleSubmit, errors, touched }) => {
                return (
                  <Box
                    className="common-flex-column"
                    gap={1}
                    sx={{
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <RichTextEditor
                        labelId="wo.textAreaPlaceholder"
                        defaultLabelId="Type Message.."
                        data={values.conversation}
                        onChangeCallBack={(value) => {
                          setFieldValue("conversation", value);
                        }}
                        readOnly={false}
                      />
                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="conversation"
                      />
                    </Box>
                    <FileUpload
                      files={values.attachments}
                      onFilesChange={(files) => {
                        setFieldValue("attachments", files);
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        bottom: "-42px",
                        zIndex: 2,
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          handleSubmit();
                        }}
                        size="small"
                        sx={{
                          color: "#9fadbc",
                          backgroundColor: "rgb(26, 30, 34)",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "rgb(23, 25, 28)",
                          },
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          formikRef.current?.resetForm({
                            values: initialCommentData,
                          });
                          setAddingComment(false);
                        }}
                        size="small"
                        sx={{
                          color: "#9fadbc",
                          backgroundColor: "rgb(26, 30, 34)",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "rgb(23, 25, 28)",
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              }}
            </Formik>
          )}
        </Box>
      )}

      {selectedButton === 1 ? (
        <Comments comments={comments} users={users} />
      ) : (
        <ActivityMessage ticketId={ticketId} />
      )}
    </Box>
  );
};

export default ActivityMenu;
