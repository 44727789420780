import { FC } from "react";
import { IconButton } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as Dropdown } from "../../../assets/images/arrow-circle-down.svg";
import { ReactComponent as RichText } from "../../../assets/images/textalign-left.svg";
import { ReactComponent as Document } from "../../../assets/images/document-upload.svg";
import { ReactComponent as Link } from "../../../assets/images/link.svg";
import { ReactComponent as Calendar } from "../../../assets/images/calendar.svg";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

interface DisplayFieldIconProps {
  type: string;
}
const DisplayFieldIcon: FC<DisplayFieldIconProps> = ({ type }) => {
  const renderIcon = () => {
    switch (type) {
      case "number":
        return (
          <MediumTypography
            labelId="123"
            defaultLabel="123"
            fontSize="12px"
            textColor="#b6c2cf"
          />
        );
      case "text":
        return (
          <MediumTypography
            labelId="Aa"
            defaultLabel="Aa"
            textColor="#b6c2cf"
          />
        );
      case "date":
      case "date_time":
        return <Calendar />;
      case "single_select":
      case "multiple_select":
      case "dropdown":
        return <Dropdown />;
      case "email":
        return <Document />;
      case "url":
        return <Link />;
      case "phone_number":
        return <PhoneAndroidIcon sx={{ color: "#b6c2cf", fontSize: "18px" }} />;
      default:
        return <RichText />;
    }
  };

  return <IconButton sx={{ bottom: 2, right: 1 }}>{renderIcon()}</IconButton>;
};

export default DisplayFieldIcon;
