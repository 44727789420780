import TicketApiClient from "../TicketApi";
import { ApiResponseHandler } from "../ApiResponseHandler";
import { responseType } from "../../pages/MasterDataManagement/types/rolesAndPermission";

export const fetchMetaData = async <T>(): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(`/ticket/metadata`)
  );
};

export const fetchMetaDataSupport = async <T>(): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(`/ticket/support/metadata`)
  );
};

export const fetchTicketTypeBySubgroupId = async <T>(
  url: string,
  type: string,
  filter: string,
  Id: number
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `${url}type=${type}&${filter}=${Id}`
    )
  );
};

export const fetchAssestByAssestGroupAssestCatId = async <T>(
  url: string,
  type: string,
  assestCategory: number,
  assestGroupId: number
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `${url}type=${type}&assetGroupId=${assestGroupId}&assetCategoryId=${assestCategory}`
    )
  );
};
export const fetchSubGroupByAccountId = async <T>(
  id: number | null
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(`/ticket/${id}/subGroupMap`)
  );
};
export const fetchFieldsByType = async <T>(
  id: number | null,
  language: string
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `/ticketType/${id}/template?languageCode=${language}`
    )
  );
};

export const fetchFieldByTicketTypeId = async <T>(
  uri: string,
  id: number,
  language: string
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(
      `${uri}/${id}/template?languageCode=${language}`
    )
  );
};
export const editTicket = async <T>(id: number, data: T) => {
  const fieldsToUpdate = Object.keys(data as any);
  const queryParam = fieldsToUpdate.join(",");
  return ApiResponseHandler(
    await TicketApiClient.getInstance().put<T>(
      `ticket/${id}/update?fieldsToUpdate=${encodeURIComponent(queryParam)}`,
      data
    )
  );
};
export const saveTicket = async <T>(uri: string, param: T) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, T>(`${uri}`, param)
  );
};

export const deleteTypeApi = async (
  uri: string,
  id: number,
  language: string
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().delete(
      `/${id}/ticketType?languageCode=${language}`
    )
  );
};

export const saveTicketTypeById = async <T>(
  uri: string,
  language: string,
  id: number,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}&id=${id}`,
      param
    )
  );
};

export const saveTicketType = async <T>(
  uri: string,
  language: string,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}`,
      param
    )
  );
};

export const saveCustomField = async <T>(
  uri: string,
  language: string,
  param: T
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `${uri}?languageCode=${language}`,
      param
    )
  );
};

export const saveDataTemplateById = async <T>(
  id: number | null,
  language: string,
  param: T,
  ids?: number[]
) => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post<T, responseType>(
      `ticketType/${id}/template?languageCode=${language}&customFields=${ids}`,
      param
    )
  );
};

export const fetchAllCustomFieldApi = async <T>(
  uri: string,
  language: string
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get<T>(
      `${uri}/fetchAll?languageCode=${language}`
    )
  );
};
