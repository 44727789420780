import MediumTypography from "../../../components/formlib/MediumTypography";
import { Box } from "@mui/material";
import TicketTypeList from "./TicketTypeList";
import useAvailableHeight from "../../../utils/useAvailableHeight";
interface Ticket {
  selectedTicketID: (id: number, toggle: boolean) => void;
  refreshTicketTypes: boolean;
  resetRefreshTicketTypes: () => void;
}
const AddTicketTypeForm = ({
  selectedTicketID,
  refreshTicketTypes,
  resetRefreshTicketTypes,
}: Ticket) => {
  const availableHeight = useAvailableHeight(300);

  return (
    <>
      <Box
        sx={{
          height: availableHeight + 80,
          backgroundColor: "rgba(29, 33, 37, 1)",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box>
          <MediumTypography
            labelId={"selectTicketType.title"}
            defaultLabel={"selectTicketType.title"}
            sxProps={{ fontWeight: 500, fontSize: 16 }}
          />
        </Box>

        <TicketTypeList
          selectedTicketID={selectedTicketID}
          refreshTicketTypes={refreshTicketTypes}
          resetRefreshTicketTypes={resetRefreshTicketTypes}
        />
      </Box>
    </>
  );
};
export default AddTicketTypeForm;
