import { Box } from "@mui/material";
import { useRouteError } from "react-router-dom";
import MediumTypography from "../../components/formlib/MediumTypography";

interface RouteError {
  status?: number;
  data?: any;
}

function ErrorPage() {
  const error = useRouteError() as RouteError;

  let title = "An error occurred";
  let message = "Something went wrong.";

  if (error?.status === 500) {
    // Ensure error.data is handled properly (type-checking assumes it has a `message` field)
    message = error.data?.message || "Internal server error.";
  }

  if (error?.status === 404) {
    title = "Not Found";
    message = "Could not find resource or page.";
  }

  return (
    <Box mt={5}>
      <MediumTypography label={title} fontSize={"20px"} />
      <MediumTypography label={message} fontSize={"14px"} />
    </Box>
  );
}

export default ErrorPage;
