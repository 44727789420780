import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { FC, useContext, useMemo, useState, useEffect, useRef } from "react";
import DayPagination from "../../components/formlib/DayPagination";
import { ShiftDashboardType } from "../../utils/type";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction"; // Required for drag & drop
import { EventDropArg, EventInput } from "@fullcalendar/core";
import ScheduleItem from "../../components/scheduler/ScheduleItem";
import "./css/scheduler.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import "./css/TeamMembers.css";

import { SchedulerViewContext, dateContext } from "./SchedulerDashboard";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import {
  CardMovementActionType,
  MoveWorkOrderParams,
  moveWorkOrderApi,
} from "../../api/workOrderApi/schedulerApi/Scheduler";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { ReactComponent as NotAvialableIcon } from "../../assets/images/woNotFound.svg";
import Loader from "../../components/shared/Loader";
import { ResourceInput } from "@fullcalendar/resource";
import { handleError } from "../../utils/commonFunctions";
const moment = require("moment");

interface SchedulerTeamViewProps {
  cardDashboardDetails: ShiftDashboardType;
  selectedDate: Date;
  callApiCallBack: () => void;
}

const SchedulerShiftView: FC<SchedulerTeamViewProps> = ({
  cardDashboardDetails,
  selectedDate,
  callApiCallBack,
}) => {
  const schedulerViewContext = useContext(SchedulerViewContext);

  const initialResources: ResourceInput = {
    id: "1",
    title: "",
    eventColor: "transparent",
  };

  const resources: ResourceInput[] = [initialResources];

  const [events, setEvents] = useState<EventInput[]>([]);
  const [slotMinWidth, setSlotMinWidth] = useState(300);
  const [scrollTime, setScrollTime] = useState("00:00:00");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    description: "",
    onNegative: () => {},
    onPositive: () => {},
  });
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const dateCtx = useContext(dateContext);

  const shiftSortOrder = ["A", "G", "B", "C"];

  const calendarRef = useRef<FullCalendar>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getSortedShifts = () => {
    const allShifts = [...cardDashboardDetails.shiftTypes];
    // Sort the shiftTypes array based on the sortOrder
    allShifts.sort((a, b) => {
      return (
        shiftSortOrder.indexOf(a.shiftName) -
        shiftSortOrder.indexOf(b.shiftName)
      );
    });
    return allShifts;
  };

  const getShiftByScheduledStart = (scheduledStart: Date | null) => {
    if (!scheduledStart) {
      return null;
    }

    for (const shiftData of getSortedShifts()) {
      if (
        timeIsBetweenShift(
          scheduledStart,
          shiftData.shiftStartTime,
          shiftData.shiftEndTime
        )
      ) {
        return shiftData;
      }
    }
    return null;
  };

  const isNewScheduleWithinSameShift = (
    scheduledStart: Date | null,
    shiftId: number
  ) => {
    if (!scheduledStart) {
      return null;
    }

    const currentShiftData = cardDashboardDetails.shiftTypes.find(
      (shift) => shift.shiftId === shiftId
    );

    if (currentShiftData) {
      return timeIsBetweenShift(
        scheduledStart,
        currentShiftData.shiftStartTime,
        currentShiftData.shiftEndTime
      );
    }

    return null;
  };

  const moveWorkOrderApiRequest = (
    requestParams: MoveWorkOrderParams,
    cardId: number,
    action: CardMovementActionType,
    dropInfo: EventDropArg
  ) => {
    moveWorkOrderApi(requestParams, cardId, action)
      .then(() => {
        callApiCallBack();
      })
      .catch((error) => {
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
        dropInfo.revert();
      });
  };

  const eventFormattedDate = useMemo(() => {
    const activeDate = dateCtx.activeDate;
    return () => {
      return moment(activeDate).format("YYYY-MM-DD");
    };
  }, [dateCtx.activeDate]);

  const timeIsBetween = (
    time: Date | string,
    startTime: string,
    endTime: string
  ) => {
    return moment(time).isBetween(startTime, endTime, null, "[]");
  };

  const timeIsBetweenShift = useMemo(() => {
    const activeDate = dateCtx.activeDate;

    return (
      time: Date | string,
      shiftStartTime: string,
      shiftEndTime: string
    ) => {
      let today = moment(activeDate).format("YYYY-MM-DD");
      let yesterday = moment(activeDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      let tomorrow = moment(activeDate).add(1, "days").format("YYYY-MM-DD");

      // For shift A
      if (
        moment(shiftStartTime, "HH:mm:ss").isAfter(
          moment(shiftEndTime, "HH:mm:ss")
        )
      ) {
        return (
          timeIsBetween(
            time,
            yesterday + "T" + shiftStartTime,
            today + "T" + shiftEndTime
          ) ||
          timeIsBetween(
            time,
            today + "T" + shiftStartTime,
            tomorrow + "T" + shiftEndTime
          )
        );
      }

      // For Other shifts
      let startTime = today + "T" + shiftStartTime;
      let endTime = today + "T" + shiftEndTime;

      return timeIsBetween(time, startTime, endTime);
    };
  }, [dateCtx.activeDate]);

  // This function will be called when an event is dropped in a new time slot or resource
  const onEventDrop = (dropInfo: EventDropArg) => {
    const workOrder = dropInfo.event.extendedProps.workOrder;

    const cardId = workOrder.cardId;
    const sequenceId = workOrder.sequenceId;

    const shiftId = workOrder.shiftId;

    const scheduledStart = dropInfo.event.start;
    const scheduledEnd = dropInfo.event.end;

    const oldMemberId = dropInfo.oldResource?.id;
    const newMemberId = dropInfo.newResource?.id;

    if (moment(scheduledStart).isBefore(moment())) {
      setErrorDesc("teamView.pastTimeError");
      setOpenErrorModal(true);
      dropInfo.revert();
      return;
    }

    const newShift = getShiftByScheduledStart(scheduledStart);

    const isWithinShift = isNewScheduleWithinSameShift(scheduledStart, shiftId);

    if (!newShift) {
      // Error popup
      setErrorDesc("teamView.shiftNotFound");
      setOpenErrorModal(true);
      dropInfo.revert();
      return;
    } else if (isWithinShift === null) {
      // Error popup
      setErrorDesc("somethingWrongError");
      setOpenErrorModal(true);
      dropInfo.revert();
      return;
    } else {
      let requestParams = {
        oldMemberId: oldMemberId ? Number(oldMemberId) : 0,
        newMemberId: newMemberId ? Number(newMemberId) : 0,
        woUniqueId: sequenceId,
        shiftId: shiftId,
        startTime: moment(scheduledStart).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: moment(scheduledEnd).format("YYYY-MM-DDTHH:mm:ss"),
        currentDate: eventFormattedDate(),
        version: workOrder.version,
      };

      if (!isWithinShift) {
        openConfirmationModal(
          "teamView.shiftChangeWarning",
          () => {
            dropInfo.revert();
            return;
          },
          () => {
            requestParams.shiftId = newShift.shiftId;
            moveWorkOrderApiRequest(
              requestParams,
              cardId,
              "MOVE_THROUGH_SHIFTS",
              dropInfo
            );
          }
        );
      } else {
        // No confirmation popups required
        moveWorkOrderApiRequest(
          requestParams,
          cardId,
          "MOVE_WO_HORIZONTAL",
          dropInfo
        );
      }
    }
  };

  const openConfirmationModal = (
    description: string,
    onNegative: () => void,
    onPositive: () => void
  ) => {
    setModalContent({ description, onNegative, onPositive });
    setIsModalOpen(true);
  };

  // useEffect(() => {
  //   setTeamViewMembersData(teamViewMembersApiData);
  //   setTeamViewMembersDataOriginal(teamViewMembersApiData);
  // }, [cardDashboardDetails]);

  useEffect(() => {
    const initialEvents: EventInput[] = [];

    cardDashboardDetails.shifts.forEach((item) => {
      item.cards.forEach((card) => {
        const eventData: EventInput = {
          id: card.cardId.toString(),
          start: card.scheduledStartTime,
          end: card.scheduledEndTime,
          resourceId: "1",
          workOrder: {
            cardId: card.cardId,
            sequenceId: card.sequenceId,
            code: card.code,
            asset: card.asset,
            frequency: card.frequency,
            description: card.description,
            status: card.dispatchStatusType,
            actualStartTime: card.actualStartTime || null,
            actualEndTime: card.actualEndTime || null,
            progress: card.progress,
            members: card.members,
            shiftId: card.shiftId,
            shiftName: item.shiftName[0],
            shiftStartTime: card.shiftStartTime,
            shiftEndTime: card.shiftEndTime,
            version: card.version,
            cardDetails: card,
          },
        };

        initialEvents.push(eventData);
      });
    });

    setEvents(initialEvents);
  }, [cardDashboardDetails]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalNegative = () => {
    modalContent.onNegative();
    handleModalClose();
  };

  const handleModalPositive = () => {
    modalContent.onPositive();
    handleModalClose();
  };

  const getMinEventData = (
    getEvents: EventInput[]
  ): { slotMinWidth: number; scrollTime: string } => {
    let minDurationMinutes = Number.MAX_SAFE_INTEGER;
    let minEventStartMoment = moment("23:59:59", "HH:mm:ss"); // Latest time in a day as a moment object

    getEvents.forEach((event) => {
      const eventStartMoment = moment(event.start);
      const eventEndMoment = moment(event.end);
      const durationMinutes = eventEndMoment.diff(eventStartMoment, "minutes");

      if (durationMinutes < minDurationMinutes) {
        minDurationMinutes = durationMinutes;
      }

      if (eventStartMoment.isBefore(minEventStartMoment)) {
        minEventStartMoment = eventStartMoment;
      }
    });

    let minEventStart = minEventStartMoment.format("HH:mm:ss");

    // Handle case where there are no events
    if (getEvents.length === 0) {
      minDurationMinutes = 0;
      minEventStart = "00:00:00";
    }

    let calculatedSlotMinWidth = getSlotMinWidth(minDurationMinutes);

    return { slotMinWidth: calculatedSlotMinWidth, scrollTime: minEventStart };
  };

  const getSlotMinWidth = (minEventDuration: number): number => {
    const baseDuration = 30; // Base duration in minutes
    const baseWidth = 300; // Base width in pixels
    const minWidthThreshold = 300; // Ensure the slot width is not smaller than a minimum threshold (e.g., 100 pixels)

    // If the minimum event duration is equal to the base duration, return the base width
    if (minEventDuration === baseDuration) {
      return baseWidth;
    }

    if (minEventDuration === 0) {
      return minWidthThreshold;
    }

    // Calculate the width proportionally. If the duration is more, the width will be less.
    const proportion = baseDuration / minEventDuration;
    const slotWidth = baseWidth * proportion;

    if (slotWidth < minWidthThreshold) {
      return minWidthThreshold;
    }
    return slotWidth;
  };

  // When events change, recalculate the minimum duration and set slotMinWidth and scrollTime
  useEffect(() => {
    const minEventData = getMinEventData(events);
    if (minEventData.slotMinWidth !== slotMinWidth) {
      setSlotMinWidth(minEventData.slotMinWidth);
    }
    if (minEventData.scrollTime !== scrollTime) {
      setScrollTime(minEventData.scrollTime);
    }
    if (events.length > 0) {
      setIsLoading(false);
    }
  }, [events]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.scrollToTime(scrollTime);
    }
  }, [scrollTime]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const isOlderThanToday = moment(dateCtx.activeDate).isBefore(
    moment().startOf("day")
  );

  return (
    <>
      <Box
        className="tableStyles sticky-container"
        display={"flex"}
        sx={{ color: "#FFFFFF", fontSize: "12px" }}
      >
        <ModalPopup
          descriptionText={modalContent.description}
          open={isModalOpen}
          handleClose={() => {}}
          onPositiveClick={handleModalPositive}
          onNegativeClick={handleModalNegative}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
        <Box flexGrow={1}>
          <Box
            className="sticky-header"
            display={"flex"}
            sx={{ bgcolor: "rgba(22, 26, 29, 1)", p: "15px" }}
          >
            <Box flexGrow={1}>
              <DayPagination />
            </Box>
            <Box sx={{ ml: "10px" }}>
              <Stack direction="row" spacing={4}>
                <ToggleButtonGroup
                  value={schedulerViewContext.schedulerView}
                  exclusive
                  onChange={(_, newView) => {
                    if (newView !== null) {
                      schedulerViewContext.onSchedulerViewChange(newView);
                    }
                  }}
                  aria-label="Dashboard View"
                  sx={{
                    bgcolor: "rgba(33, 39, 44, 1)",
                    display: "flex",
                    alignItems: "center",
                    height: "42px",
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                    borderRadius: "50px",
                    padding: "4px",
                    "& .MuiToggleButton-root": {
                      padding: "4px",
                      border: "none",
                      bgcolor: "transparent",
                      color: "#44546f",
                      borderRadius: "50%",
                      width: 32,
                      height: 32,
                      "&.Mui-selected": {
                        bgcolor: "#FFFFFF",
                        color: "#3b78fb",
                        "&:hover": {
                          bgcolor: "#FFFFFF",
                          color: "#3b78fb",
                          cursor: "default",
                        },
                      },
                      "&:hover": {
                        color: "#FFF",
                      },
                    },
                  }}
                >
                  <Tooltip title="Shift View" arrow>
                    <ToggleButton
                      value="shift"
                      aria-label="shift view"
                      sx={{ mr: 1 }}
                    >
                      <CalendarMonthIcon />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title="Team View" arrow>
                    <ToggleButton value="team" aria-label="team view">
                      <GroupsIcon />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Stack>
            </Box>
          </Box>
          {!isLoading && events.length > 0 && (
            <Box>
              <Box id="myCalendar" sx={{ pt: "15px" }}>
                <FullCalendar
                  ref={calendarRef}
                  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  plugins={[resourceTimelinePlugin, interactionPlugin]}
                  initialView="resourceTimeline"
                  duration={{ days: 1, hours: 6 }}
                  headerToolbar={false}
                  height="auto" // Adjust to fit the calendar within its parent container without vertical scrolling
                  contentHeight="auto"
                  resources={resources}
                  resourceAreaWidth={0}
                  events={events}
                  eventContent={(event) => (
                    <ScheduleItem
                      eventSchedule={event}
                      callApiCallBack={callApiCallBack}
                      subGroupId={cardDashboardDetails.selectedSubgroupId}
                      schedulerViewChange="shift"
                    />
                  )}
                  editable={isOlderThanToday ? false : true} // Enable dragging and dropping of events
                  droppable={isOlderThanToday ? false : true} // Allow dropped items
                  eventDrop={onEventDrop} // Handle event drops
                  eventResizableFromStart={false} // Disables resizing from the event's start
                  eventDurationEditable={false} // Disables resizing from the event's end
                  snapDuration="00:15" // Snap/drag events to 15-minute on time slot
                  slotLabelFormat={[
                    { day: "numeric", month: "short", omitCommas: true },
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                      meridiem: false,
                    },
                  ]}
                  initialDate={eventFormattedDate()}
                  slotMinWidth={slotMinWidth}
                  scrollTime={scrollTime}
                  nowIndicator={true}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!isLoading && events.length <= 0 && (
        <Box className="woNotAvialable">
          <NotAvialableIcon width={`50px`} height={`50px`} />
          <MediumTypography
            className="noWOTitle"
            labelId="schedulerView.noWorkorderTitle"
            defaultLabel="No Work Orders for the Day"
          />
          {/* <MediumTypography
            className="noWOText"
            labelId="schedulerView.noWorkorderText"
            defaultLabel="Please contact your site in-charge."
          /> */}
        </Box>
      )}
      {isLoading && (
        <Box className="woNotAvialable">
          <Loader />
        </Box>
      )}
    </>
  );
};

export default SchedulerShiftView;
