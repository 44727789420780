import React from "react";
import { SxProps, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface TextProps {
  label?: string | React.ReactNode;
  fontWeight?: number;
  labelId?: string;
  defaultLabel?: string;
  labelValues?: {
    [key: string]: any;
  };
  fontSize?: string;
  sxProps?: SxProps;
  onClick?: () => void;
  id?: string;
  className?: string;
  textColor?: string;
  dangerouslySetInnerHTML?: string;
}
const MediumTypography: React.FC<TextProps> = ({
  label,
  fontWeight,
  labelId,
  defaultLabel,
  labelValues = {},
  fontSize,
  sxProps,
  onClick,
  id,
  className,
  textColor,
  dangerouslySetInnerHTML,
}) => {
  return (
    <Typography
      onClick={onClick}
      id={id}
      className={className}
      // dangerouslySetInnerHTML={{
      //   __html: dangerouslySetInnerHTML,
      // }}
      sx={{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: fontWeight ?? 500,
        fontSize: fontSize ?? "14px",
        color: textColor ?? "#FFFFFF",
        ...sxProps,
      }}
    >
      {labelId ? (
        <FormattedMessage
          id={labelId}
          values={labelValues}
          defaultMessage={defaultLabel}
        />
      ) : (
        label
      )}
    </Typography>
  );
};
export default MediumTypography;
