import { Box, Dialog, DialogContent, Grid } from "@mui/material";
import { FC, useContext, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import "../../MasterDataManagement/styles.css";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";

import { FileDataType, MessageType, UserType } from "../types/Types";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { getOptions, getProcessedFiles } from "../../../utils/commonFunctions";
import RichTextEditor from "../../workOrderDetailScreens/correctiveMaintenance/RichTextEditor";
import { trim } from "lodash";
import { saveConversation } from "../../../api/ticketing/TicketDetails";
import FileUpload from "./UploadFileDragDrop";

interface DataType {
  to: number;
  cc: number[];
  bcc: number[];
  mention: number[];
  conversation: string;
  messageType: MessageType;
  attachments: File[];
}

const initialData: DataType = {
  to: 0,
  cc: [],
  bcc: [],
  mention: [],
  conversation: "",
  messageType: null,
  attachments: [],
};

const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.toDropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const muiColumnProps = {
  xs: 12,
};

interface ConversationModalProps {
  open: boolean;
  messageType: MessageType;
  handleClose: () => void;
  afterSendCallback?: () => void;
  ticketId: number;
  users: UserType[];
}

const ConversationModal: FC<ConversationModalProps> = ({
  open,
  messageType,
  handleClose,
  ticketId,
  afterSendCallback = () => {},
  users,
}) => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<DataType>>(null);

  let validationSchema = Yup.object().shape({
    conversation: Yup.string().required("validation.messageRequired"),
  });

  if (messageType === "FORWARD") {
    validationSchema = Yup.object().shape({
      to: dropdownValidation.test(
        "custom-required",
        "validation.toDropdown",
        customRequiredFn
      ),
      conversation: Yup.string().required("validation.messageRequired"),
    });
  }

  const sendMessage = (params: any) => {
    toggleLoader(true);

    const url = `ticket/${ticketId}/addConversation?messageType=${messageType}`;

    saveConversation(url, params)
      .then((response) => {
        console.log("response", response);
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        afterSendCallback();
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Formik
        initialValues={initialData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const processedFiles = await getProcessedFiles(values.attachments);
            const updatedValues = {
              ...values,
              attachments: processedFiles,
              messageType: messageType,
            };

            if (messageType === "COMMENT") {
              const { to, cc, bcc, ...updatedPayloadData } = updatedValues; // Remove fields
              sendMessage(updatedPayloadData);
            } else if (messageType === "REPLY") {
              const { to, ...updatedPayloadData } = updatedValues; // Remove to field
              sendMessage(updatedPayloadData);
            } else if (messageType === "FORWARD") {
              sendMessage(updatedValues);
            }
          } catch (error) {
            console.error("Unable to process files:", error);
            setErrorDesc("Unable to process files");
            setOpenErrorModal(true);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogContent sx={{ p: 2 }}>
                <Grid container spacing={1}>
                  {messageType === "FORWARD" && (
                    <Grid item {...muiColumnProps} className="common-flex-row">
                      <MediumTypography
                        className="input-label"
                        labelId="message.toText"
                        defaultLabel="To"
                        sxProps={{ width: "30px" }}
                      />
                      <Box flexGrow={1}>
                        <CustomDropdown
                          disableCreate={true}
                          options={getOptions(users)}
                          selectedValue={values.to}
                          onSelectedValueChange={(value) => {
                            setFieldValue("to", value);
                          }}
                          overrideWidth="300px"
                          labelId="message.selectPlaceholder"
                          defaultLabel="--- select user ---"
                        />
                        <FormikErrorComponent
                          errors={errors}
                          touched={touched}
                          field="to"
                        />
                      </Box>
                    </Grid>
                  )}

                  {messageType !== "COMMENT" && (
                    <>
                      <Grid
                        item
                        {...muiColumnProps}
                        className="common-flex-row"
                      >
                        <MediumTypography
                          className="input-label"
                          labelId="message.ccText"
                          defaultLabel="CC"
                          sxProps={{ width: "30px" }}
                        />
                        <Box flexGrow={1}>
                          <CustomDropdown
                            multiple
                            disableCreate={true}
                            options={getOptions(users)}
                            selectedValue={values.cc}
                            onSelectedValueChange={(value) => {
                              setFieldValue("cc", value);
                            }}
                            labelId="message.multiSelectPlaceholder"
                            defaultLabel="--- select users ---"
                          />
                          <FormikErrorComponent
                            errors={errors}
                            touched={touched}
                            field="cc"
                          />
                        </Box>
                      </Grid>

                      <Grid
                        item
                        {...muiColumnProps}
                        className="common-flex-row"
                      >
                        <MediumTypography
                          className="input-label"
                          labelId="bccText"
                          defaultLabel="BCC"
                          sxProps={{ width: "30px" }}
                        />
                        <Box flexGrow={1}>
                          <CustomDropdown
                            multiple
                            disableCreate={true}
                            options={getOptions(users)}
                            selectedValue={values.bcc}
                            onSelectedValueChange={(value) => {
                              setFieldValue("bcc", value);
                            }}
                            labelId="message.multiSelectPlaceholder"
                            defaultLabel="--- select users ---"
                          />
                          <FormikErrorComponent
                            errors={errors}
                            touched={touched}
                            field="bcc"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item {...muiColumnProps} className="common-flex-row">
                    <Box
                      flexGrow={1}
                      className="ticket-quill-container ticket-rich-text-box"
                    >
                      <RichTextEditor
                        labelId="wo.textAreaPlaceholder"
                        defaultLabelId="Type Message.."
                        data={values.conversation}
                        onChangeCallBack={(value) =>
                          setFieldValue("conversation", value)
                        }
                        readOnly={false}
                      />
                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="conversation"
                      />
                    </Box>
                  </Grid>

                  <Grid item {...muiColumnProps}>
                    <FileUpload
                      files={values.attachments}
                      onFilesChange={(files) => {
                        setFieldValue("attachments", files);
                      }}
                    />
                  </Grid>
                </Grid>

                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={1}
                  mt={2}
                >
                  <Box>
                    <ButtonComponent
                      className="btn-primary btn-ticket-text"
                      variantType="contained"
                      defaultLabelId={"Cancel"}
                      labelId={"CancelBtnText"}
                      onClick={() => {
                        formikRef.current?.resetForm();
                        handleClose();
                      }}
                    />
                  </Box>
                  <Box>
                    <ButtonComponent
                      className="btn-primary btn-ticket"
                      variantType="contained"
                      defaultLabelId={"Send"}
                      labelId={"SendLabel"}
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default ConversationModal;
