import { Box, Button, Tabs, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState, useCallback } from "react";
import "./ShiftComponent.css";
import { ShiftType } from "../../utils/type";
import MediumTypography from "./MediumTypography";

interface ShiftComponentProps {
  handleShiftSelected: (value: number[]) => void;
  shifts: ShiftType[];
  selectedShifts: number[];
}

const ShiftComponent: FC<ShiftComponentProps> = ({
  handleShiftSelected,
  shifts,
  selectedShifts,
}) => {
  const [selectedShiftIds, setSelectedShiftIds] = useState<number[]>([]);

  useEffect(() => {
    if (selectedShifts && selectedShifts.length === shifts.length) {
      setSelectedShiftIds([0]); // Activate "All" if all shifts are selected
    } else if (selectedShifts && selectedShifts.length > 0) {
      setSelectedShiftIds(selectedShifts);
    } else {
      setSelectedShiftIds([0]); // "All" is selected by default
    }
  }, [selectedShifts, shifts]);

  const handleShiftChange = useCallback(
    (tabIndex: number) => {
      let updatedTabs: number[];
      if (selectedShiftIds.includes(tabIndex)) {
        updatedTabs = selectedShiftIds.filter((tab) => tab !== tabIndex);
        if (updatedTabs.length === 0) updatedTabs = [0]; // Ensure at least one tab is selected
      } else if (tabIndex === 0) {
        updatedTabs = [];
      } else {
        updatedTabs = selectedShiftIds.includes(0)
          ? [tabIndex]
          : [...selectedShiftIds, tabIndex];
      }

      setSelectedShiftIds(updatedTabs);
      handleShiftSelected(updatedTabs); // Immediately pass the updated tabs to the parent
    },
    [handleShiftSelected, selectedShiftIds]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflowX: "auto" }}>
      <Tabs
        color="primary"
        value={selectedShiftIds}
        aria-label="Shift"
        sx={{ overflowX: "auto" }}
      >
        <Button
          className={`button-ui p-none shift_btn ${
            selectedShiftIds.includes(0) ? "active" : ""
          } flex__`}
          onClick={() => handleShiftChange(0)}
          sx={{
            fontSize: { xs: "10px", sm: "12px" },
            padding: { xs: "4px", sm: "8px" },
          }}
        >
          <MediumTypography
            labelId="ALL"
            defaultLabel="ALL"
            fontSize="12px"
            fontWeight={600}
            className="mr-xs"
          />
        </Button>

        {shifts &&
          shifts.length > 0 &&
          shifts.map((item, index) => (
            <Button
              key={index}
              className={` p-none shift_btn  ${
                selectedShiftIds.includes(item.shiftId) ? "active" : ""
              } flex__`}
              sx={{
                minWidth: "auto",
                fontSize: { xs: "10px", sm: "12px" },
                padding: { xs: "4px", sm: "8px" },
              }}
              onClick={() => handleShiftChange(item.shiftId)}
            >
              <Box className="shift_btn_child">
                <Tooltip title={item.shiftName}>
                  <Box className="position__relative" sx={{ bottom: "2px" }}>
                    {item.shiftStaticName}
                  </Box>
                </Tooltip>
              </Box>
            </Button>
          ))}
      </Tabs>
    </Box>
  );
};

export default ShiftComponent;
