import { Avatar, Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import { ImageType } from "../../api/moduleCleaning/ModuleCleaningApi";
import { GeoStampTy } from "./ModuleCleaningComponent";
import GalleryPreviewModal from "../../components/formlib/modal/GalleryPreviewModal";

export interface WorkOrderGalleryProps {
  dumgImage: ImageType[];
  afterImage: ImageType[];
  beforeImage: ImageType[];
  pmTaskImage?: ImageType[];
  titlesRequired?: boolean;
}

const WorkOrderGallery: FC<WorkOrderGalleryProps> = ({
  dumgImage,
  afterImage,
  beforeImage,
  pmTaskImage,
  titlesRequired = true,
}) => {
  const [openGalleryModal, setOpenGalleryModal] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedMetadata, setSelectedMetadata] = useState<GeoStampTy[]>([]);
  const [initialSlide, setInitialSlide] = useState<number>(0);

  const allImages = [
    ...dumgImage.map((img) => ({
      ...img,
      category: titlesRequired ? "ProofOfDumpLabelText" : "",
    })),
    ...beforeImage.map((img) => ({ ...img, category: "BeforeImageLabelText" })),
    ...afterImage.map((img) => ({ ...img, category: "AfterImageLabelText" })),
    ...(pmTaskImage?.map((img) => ({ ...img, category: "" })) || []),
  ];
  const renderImages = (images: ImageType[], imageGroupIndexOffset: number) => {
    const displayedImages = images.slice(0, 4);
    const additionalImagesCount = images.length - 4;

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {displayedImages.map((img, index) => (
          <Box
            key={index}
            className="mainBoxthumbImages_small"
            onClick={() =>
              images.length > 0
                ? handleImageClick(imageGroupIndexOffset + index)
                : undefined
            }
          >
            <Avatar
              className="thumbImages_small"
              alt=""
              src={img.document}
              style={{ width: "70px", height: "70px", borderRadius: "5px" }}
            />
          </Box>
        ))}
        {additionalImagesCount > 0 && (
          <Box className="mainBoxthumbImages_small additionalImages">
            <Typography
              variant="body2"
              style={{ lineHeight: "50px", color: "#FFFFFF" }}
            >
              +{additionalImagesCount}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const handleImageClick = (index: number) => {
    setSelectedImages(allImages.map((img) => img.document));
    setSelectedMetadata(
      allImages.map((img) => ({
        comments: img.comments,
        geoStamp: img.geoStamp,
        createdDateTime: img.createdDateTime,
        category: img.category || "",
      }))
    );
    setInitialSlide(index);
    setOpenGalleryModal(true);
  };

  return (
    <>
      <GalleryPreviewModal
        open={openGalleryModal}
        handleClose={() => setOpenGalleryModal(!openGalleryModal)}
        images={selectedImages}
        metadata={selectedMetadata}
        initialSlide={initialSlide}
        titlesRequired={titlesRequired}
      />
      <Box className={titlesRequired ? "mt-md" : ""}>
        {titlesRequired && (
          <MediumTypography
            labelId="GalleryLabeText"
            defaultLabel="Gallery"
            fontSize="20px"
            fontWeight={500}
            textColor="#FFFFFF"
            className="mb-md"
          />
        )}
        <Box
          className="flex__"
          sx={{
            width: "fit-content",
            gap: "32px",
            padding: titlesRequired ? "0px 0px 24px 0px" : "5px 0px 5px 0px",
          }}
        >
          {dumgImage.length > 0 && (
            <Box sx={{ alignItems: "center", cursor: "pointer" }}>
              {titlesRequired && (
                <MediumTypography
                  labelId="ProofOfDumpLabelText"
                  defaultLabel="Proof Of Dump"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={600}
                />
              )}

              {renderImages(dumgImage, 0)}
            </Box>
          )}
          {beforeImage.length > 0 && (
            <Box
              className="ml-md"
              sx={{ alignItems: "center", cursor: "pointer" }}
            >
              <MediumTypography
                labelId="BeforeImageLabelText"
                defaultLabel="Before Image"
                textColor="rgba(159, 173, 188, 1)"
                fontSize="14px"
                fontWeight={600}
              />
              {renderImages(beforeImage, dumgImage.length)}
            </Box>
          )}
          {afterImage.length > 0 && (
            <Box
              className="mr-md"
              sx={{ alignItems: "center", cursor: "pointer" }}
            >
              <MediumTypography
                labelId="AfterImageLabelText"
                defaultLabel="After Image"
                textColor="rgba(159, 173, 188, 1)"
                fontSize="14px"
                fontWeight={600}
              />
              {renderImages(afterImage, beforeImage.length + dumgImage.length)}
            </Box>
          )}
          {pmTaskImage && pmTaskImage.length > 0 && (
            <Box
              className="mr-md"
              sx={{ alignItems: "center", cursor: "pointer" }}
            >
              {renderImages(pmTaskImage, 0)}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default WorkOrderGallery;
