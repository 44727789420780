import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "../shiftMaster/ShiftDetailScreen.css";
import { Box, Grid } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { EventDetailsTypes, EventListingType } from "../wizrads.types";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import TextInput from "../../../components/formlib/TextInput";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
  deleteWizardsDataApi,
  fetchAllEventsDataApi,
} from "../../../api/wizardsApi/Common";
import { handleError } from "../../../utils/commonFunctions";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { ReactComponent as NotAvialableIcon } from "../../../assets/images/woNotFound.svg";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import FileUploadInput from "../../workOrderDetailScreens/correctiveMaintenance/FileUploadInput";
import { DocumentTypeBean } from "../../workOrderDetailScreens/correctiveMaintenance/types";

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};

interface Shiftsinterface {
  isMenuOpen: boolean;
  selectedSubGroupId: number;
}

const initialEventData: EventDetailsTypes = {
  eventId: 0,
  eventName: "",
  startDate: null,
  endDate: null,
  eventBannerImage: null,
  eventCoverImage: null,
  deleteBannerImage: false,
  deleteCoverImage: false,
};

const validationSchema = Yup.object().shape({
  eventName: Yup.string().required("validation.fieldRequired"),
  startDate: Yup.date().required("validation.fieldRequired"),
  endDate: Yup.date().required("validation.fieldRequired"),
  eventBannerImage: Yup.mixed().nullable(),
  eventCoverImage: Yup.mixed().nullable(),
});

const EventDetailScreen: FC<Shiftsinterface> = ({
  isMenuOpen,
  selectedSubGroupId,
}) => {
  const availableWidth = useAvailableWidth(540);
  const availableWidth1 = useAvailableWidth(200);
  const availableHeight = useAvailableHeight(420);
  const availableHeight1 = useAvailableHeight(750);
  const availableHeight2 = useAvailableHeight(300);
  const [expandedRow, setExpandedRow] = useState<number>(0);
  const formikRef = useRef<FormikProps<EventDetailsTypes>>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [eventLists, setEventLists] = useState<EventDetailsTypes[]>([]);
  const [eventValues, setEventValues] =
    useState<EventDetailsTypes>(initialEventData); // Initialize with default values>
  const [apiResponseModal, setApiResponseModal] = useState(false);

  const extractDocumentTypeBean = (fileUrl: string): DocumentTypeBean => {
    const fileName = fileUrl.split("/").pop() || "file";
    const extension = fileName.split(".").pop() || "";
    const base64String = fileUrl.split(",")[1]; // assuming the fileUrl is a base64 encoded string

    return {
      document: base64String,
      fileName: fileName,
      extension: extension,
      fileUrl: fileUrl,
    };
  };

  const handleRowClick = (
    id: number,
    actualId: number | undefined,
    shiftValuesRow: EventDetailsTypes
  ) => {
    if (expandedRow !== id) {
      setExpandedRow(id); // Only open the clicked row
    }

    if (actualId) {
      const processDocument = (file: any) => {
        if (file?.fileUrl) {
          // Use the extractDocumentTypeBean if fileUrl exists
          return extractDocumentTypeBean(file.fileUrl);
        } else if (file?.fileName) {
          // Generate base64 using FileReader if fileName exists
          const fileInput = document.querySelector(
            `input[type="file"]`
          ) as HTMLInputElement;
          if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const selectedFile = Array.from(fileInput.files).find(
              (f) => f.name === file.fileName
            );
            if (selectedFile) {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64StringWithPrefix = reader.result as string;
                const base64String = base64StringWithPrefix.split(",")[1];
                const extension = selectedFile.name.split(".").pop() || "";
                const updatedFile: DocumentTypeBean = {
                  document: base64String,
                  fileName: selectedFile.name,
                  extension,
                };
                file.document = updatedFile.document; // Update the original object
              };
              reader.readAsDataURL(selectedFile); // Start reading the file
            }
          }
        }
        return file; // If neither condition is met, return the original object
      };

      const updatedRow = {
        ...shiftValuesRow,
        eventBannerImage: processDocument(shiftValuesRow.eventBannerImage),
        eventCoverImage: processDocument(shiftValuesRow.eventCoverImage),
      };

      setEventValues(updatedRow);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = () => {
    const fetchAllShiftsUrl = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/event`;

    fetchAllEventsDataApi<EventListingType>(fetchAllShiftsUrl, "en", "")
      .then((response) => {
        const updatedResponse = response.events.map((eventRes) => ({
          ...initialEventData,
          ...eventRes,
          eventId: eventRes.id ?? 0,
        }));
        setEventLists(updatedResponse);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const defaultShift: EventDetailsTypes = {
    eventId: eventLists.length + 1, // Assign serial number
    eventName: "",
    startDate: null,
    endDate: null,
    eventBannerImage: null,
    eventCoverImage: null,
    deleteBannerImage: false,
    deleteCoverImage: false,
  };

  const handleAddNew = () => {
    const newShift = { ...defaultShift, eventId: eventLists.length + 1 };
    setEventLists((prev) => [...prev, newShift]);
    setEventValues(newShift);
    setExpandedRow(newShift.id as number); // Expand the newly added row
  };

  const saveShiftPlan = (data: EventDetailsTypes) => {
    toggleLoader(true);
    const saveShiftURL = `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/event/save`;
    const { eventId, ...params } = data;
    const finalParams = { ...params };

    customSaveDataApi(saveShiftURL, finalParams)
      .then(() => {
        getAllEvents();
        setExpandedRow(0);

        toggleLoader(false);
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const updateShiftPlan = (
    data: EventDetailsTypes,
    removeShift: boolean = false
  ) => {
    toggleLoader(true);
    const updateShiftURL = `${WORK_FLOW_BASE_URL}event/${data.id}/update`;
    const { eventId, ...params } = data;

    console.log("delete banner image", params.eventBannerImage);
    console.log("delete banner image", params.eventCoverImage);

    if (!data.eventBannerImage?.document) {
      params.eventBannerImage = null;
    }

    if (!data.eventCoverImage?.document) {
      params.eventCoverImage = null;
    }

    if (data.eventBannerImage?.document === "") {
      params.deleteBannerImage = true;
    }
    if (data.eventCoverImage?.document === "") {
      params.deleteCoverImage = true;
    }

    const finalParams = { ...params };

    if (removeShift) {
      const formattedDate = dayjs(new Date()).format("YYYY-MM-DD");
      finalParams.endDate = formattedDate;
    }

    customUpdateDataApi(updateShiftURL, finalParams)
      .then(() => {
        setApiResponseModal(true);
        getAllEvents();
        setExpandedRow(0);
        toggleLoader(false);
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const handleRemoveShift = (eventId: number) => {
    const deleteShiftURL = `${WORK_FLOW_BASE_URL}event`;

    deleteWizardsDataApi(deleteShiftURL, eventId)
      .then(() => {
        getAllEvents();
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <Box>
      {apiResponseModal && (
        <ModalPopup
          descriptionText={"Events.updatedText"}
          open={apiResponseModal}
          handleClose={() => setApiResponseModal(false)}
          onPositiveClick={() => {
            setApiResponseModal(false);
          }}
          hideButton={true}
          positiveButtonLabel="Ok"
          positiveButtonLabelId="WarningText.ok"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography
          labelId="EventMaster.title"
          defaultLabel="Events"
          fontSize="20px"
          fontWeight={600}
        />
        <Box className="button_class_box">
          <ButtonComponent
            className="btn-primary btn-submit cursor__pointer"
            variantType="contained"
            defaultLabelId={"Add New"}
            labelId={"btn.AddNew"}
            onClick={handleAddNew} // Handle Add New Button
          />
        </Box>
      </Box>

      <Box
        sx={{
          width: isMenuOpen ? availableWidth : availableWidth1,
          height: availableHeight,
        }}
      >
        <Box
          className="flex__ inner_box_class"
          sx={{
            minHeight: availableHeight2,
            WebkitOverflowScrolling: "touch",
            "-webkit-overflow-scrolling": "touch",
            "scroll-behavior": "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {eventLists.length > 0 &&
            eventLists.map((event, index) => (
              <Box
                key={event.id}
                className="shift_inner_class cursor__pointer"
                onClick={() =>
                  handleRowClick(event.eventId ?? 0, event.id, event)
                }
              >
                <Box
                  className="flex__justify__space-between p-md"
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <MediumTypography
                      defaultLabel="Sr. No."
                      labelId="Event.SrNo"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="indexBox_css">{`0${index + 1}`}</Box>
                  </Box>

                  <Box sx={{ flexBasis: event?.eventName ? "10%" : "0%" }}>
                    <MediumTypography
                      labelId="Events.eventName"
                      defaultLabel="Event Name"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="nameBox_css">
                      {event.eventName ? event.eventName : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      defaultLabel="Start Date"
                      labelId="Shifts.startDate"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {event.startDate ? event.startDate?.toString() : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      defaultLabel="End Date"
                      labelId="Shifts.endDate"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {event.endDate ? event.endDate?.toString() : "-"}
                    </Box>
                  </Box>

                  <Box>
                    <MediumTypography
                      defaultLabel="Image Upload"
                      labelId="Events.imageUplaod"
                      textColor="rgba(159, 173, 188, 1)"
                      fontWeight={500}
                      fontSize="12px"
                    />
                    <Box className="time_css">
                      {event.imageCount
                        ? (event.imageCount < 10 ? "0" : "") +
                          event.imageCount.toString()
                        : "-"}
                    </Box>
                  </Box>
                </Box>
                {expandedRow === event.eventId && (
                  <Box className="formClass_css">
                    <Formik
                      initialValues={eventValues}
                      enableReinitialize
                      innerRef={formikRef}
                      validateOnChange
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        if (values.id) {
                          updateShiftPlan(values);
                        } else {
                          saveShiftPlan(values);
                        }
                      }}
                    >
                      {({
                        values,
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                      }) => {
                        return (
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                overflowY: "auto",
                                height: availableHeight1,
                              }}
                            >
                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  className="input-label"
                                  labelId="Events.eventName"
                                  defaultLabel="Event Name"
                                />
                                <TextInput
                                  className="text-input-field"
                                  type="text"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: false,
                                  }}
                                  labelId="common.placeHolderText"
                                  defaultLabelId="--- type here ---"
                                  Value={values.eventName}
                                  handlechange={(value: string) => {
                                    setFieldValue("eventName", value);
                                  }}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="eventName"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.startDate"
                                  defaultLabel="Start Date"
                                  textColor={"rgba(159, 173, 188, 1)"}
                                  className="mb-sm"
                                />

                                <DateTimePickerComponent
                                  value={
                                    values.startDate !== null
                                      ? dayjs(values.startDate)
                                      : null
                                  }
                                  labelid="commonDateText"
                                  defaultlabelid="DD MMM YYYY"
                                  handlechange={(date: Dayjs) => {
                                    const formattedDate =
                                      dayjs(date).format("YYYY-MM-DD");
                                    setFieldValue("startDate", formattedDate);
                                  }}
                                  disabledDate={false}
                                  inputFormat="DD MMM YYYY"
                                  textWidth="100%"
                                  width="100%"
                                  views={["year", "month", "day"]}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="startDate"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Shifts.endDate"
                                  defaultLabel="End Date"
                                  textColor={"rgba(159, 173, 188, 1)"}
                                  className="mb-sm"
                                />

                                <DateTimePickerComponent
                                  value={
                                    values.endDate !== null
                                      ? dayjs(values.endDate)
                                      : null
                                  }
                                  labelid="commonDateText"
                                  defaultlabelid="DD MMM YYYY"
                                  handlechange={(date: Dayjs) => {
                                    const formattedDate =
                                      dayjs(date).format("YYYY-MM-DD");
                                    setFieldValue("endDate", formattedDate);
                                  }}
                                  disabledDate={false}
                                  inputFormat="DD MMM YYYY"
                                  textWidth="100%"
                                  width="100%"
                                  views={["year", "month", "day"]}
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="endDate"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  className="input-label"
                                  labelId="Events.bannerImage"
                                  defaultLabel="Banner Image"
                                />
                                <FileUploadInput
                                  fileCategory="image"
                                  labelId="accounts.uploadPlaceholder"
                                  defaultLabel="--- upload ---"
                                  value={
                                    values.eventBannerImage !== null
                                      ? values.eventBannerImage.fileName
                                      : ""
                                  }
                                  handleChange={(value: DocumentTypeBean) => {
                                    setFieldValue("eventBannerImage", value);
                                  }}
                                  reset={false}
                                  cardEdit={true}
                                  url={
                                    values.eventBannerImage !== null &&
                                    values.eventBannerImage !== undefined &&
                                    values.eventBannerImage.fileUrl !== null &&
                                    values.eventBannerImage.fileUrl !==
                                      undefined &&
                                    values.eventBannerImage.fileUrl !== ""
                                      ? values.eventBannerImage.fileUrl
                                      : ""
                                  }
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="eventBannerImage"
                                />
                              </Grid>

                              <Grid item {...muiColumnProps}>
                                <MediumTypography
                                  labelId="Events.coverImage"
                                  defaultLabel="Cover Image"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <FileUploadInput
                                  fileCategory="image"
                                  labelId="Events.uploadText"
                                  defaultLabel="--- upload ---"
                                  value={
                                    values.eventCoverImage !== null
                                      ? values.eventCoverImage.fileName
                                      : ""
                                  }
                                  handleChange={(value: DocumentTypeBean) => {
                                    setFieldValue("eventCoverImage", value);
                                  }}
                                  reset={false}
                                  cardEdit={true}
                                  url={
                                    values.eventCoverImage !== null &&
                                    values.eventCoverImage !== undefined &&
                                    values.eventCoverImage.fileUrl !== null &&
                                    values.eventCoverImage.fileUrl !==
                                      undefined &&
                                    values.eventCoverImage.fileUrl !== ""
                                      ? values.eventCoverImage.fileUrl
                                      : ""
                                  }
                                />
                                <FormikErrorComponent
                                  errors={errors}
                                  touched={touched}
                                  field="eventCoverImage"
                                />
                              </Grid>
                            </Grid>

                            <Box
                              mt={"10px"}
                              className="flex__ justifyContent-FlexEnd"
                            >
                              <ButtonComponent
                                sxProps={{ color: "#B6C2CF !important" }}
                                className="btn-primary btn-cancel mr-sm"
                                variantType="outlined"
                                defaultLabelId={"Close"}
                                labelId={"CloseText"}
                                onClick={() => setExpandedRow(0)}
                              />
                              <ButtonComponent
                                className="btn-primary btn-submit"
                                variantType="contained"
                                defaultLabelId={"Save"}
                                labelId={event.id ? "btn.update" : "btn.save"}
                                onClick={() => {
                                  handleSubmit();
                                }}
                              />
                              {event.id && (
                                <ButtonComponent
                                  className="btn-delete-code ml-md"
                                  variantType="contained"
                                  type="submit"
                                  labelId="RemoveEvents.text"
                                  defaultLabelId="+ Remove Event"
                                  onClick={() => {
                                    handleRemoveShift(event.id ?? 0);
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        );
                      }}
                    </Formik>
                  </Box>
                )}
              </Box>
            ))}
          {eventLists.length <= 0 && (
            <Box
              className="position__relative flex__"
              sx={{
                flexDirection: "column", // Stack items vertically
                justifyContent: "center", // Center items vertically
                alignItems: "center", // Center items horizontally
                textAlign: "center", // Optional: Center the text
                top: "40%",
              }}
            >
              <NotAvialableIcon width={`50px`} height={`50px`} />
              <MediumTypography
                className="noWOTitle"
                labelId="event.noEventText"
                defaultLabel="No Events Added"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EventDetailScreen;
