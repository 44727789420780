import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { hasPermission } from "../../utils/checkPermission";
import NoPermission from "./components/NoPermission";

interface TicketingLayoutProps {}

const TicketLayout: FC<TicketingLayoutProps> = () => {
  return (
    <Box component="main" id="ticketing" className="ticketing">
      {!hasPermission("Ticket_Management") && !hasPermission("Read_Only") ? (
        <NoPermission />
      ) : (
        <Outlet />
      )}
    </Box>
  );
};

export default TicketLayout;
