import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import { FC } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as SuccessIcon } from "../../../assets/images/SuccessIcon.svg";

import CloseIcon from "@mui/icons-material/Close";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  getDataCallback: () => void;
}

const WoCreatedSuccessModal: FC<ModalProps> = ({
  open,
  handleClose,
  getDataCallback,
}) => {
  return (
    <Dialog
      className="account-dialog main-wrapper-box"
      open={open}
      fullWidth
      maxWidth={"sm"}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
          getDataCallback();
        }}
        className="closeIcon"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers className="padding_20" sx={{ height: "400px" }}>
        <Box
          className="flex__ align__items__center"
          sx={{ padding: "50px", flexDirection: "column" }}
        >
          <Box className="flex__container align__items__center">
            <SuccessIcon />
          </Box>

          <MediumTypography
            labelId="Wo.successText"
            defaultLabel="Your Schedules are generated for the contract period."
            fontSize="24px"
            fontWeight={700}
            className="mb-md mt-md"
          />
          <MediumTypography
            labelId="Wo.successSubText"
            defaultLabel="Let`s get started with your next steps"
            fontSize="16px"
            textColor="rgba(255, 255, 255, 0.64)"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WoCreatedSuccessModal;
